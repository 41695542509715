/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    env: any;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const Environment = () => {
  window.env = {
    LOGISTIK_BACKEND_URL: window.env.LOGISTIK_BACKEND_URL || process.env.REACT_APP_LOGISTIK_BACKEND_URL,
    LOGISTIK_I18N_DEBUG: window.env.LOGISTIK_I18N_DEBUG || process.env.REACT_APP_LOGISTIK_I18N_DEBUG,
    LOGISTIK_SENTRY_KEY: window.env.LOGISTIK_SENTRY_KEY || process.env.REACT_APP_LOGISTIK_SENTRY_KEY,
    LOGISTIK_SENTRY_ENVIRONMENT:
      window.env.LOGISTIK_SENTRY_ENVIRONMENT || process.env.REACT_APP_LOGISTIK_SENTRY_ENVIRONMENT,
    ENV_DEVELOPMENT: window.env.ENV_DEVELOPMENT || process.env.REACT_APP_ENV_DEVELOPMENT === 'true' || false,
  };
};
