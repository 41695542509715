import React, { useContext } from 'react';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonListHeader,
  IonRow,
} from '@ionic/react';
import {
  StyledIcon,
  StyledInfoText,
  StyledList,
  StyledSpan,
} from '@src/components/Mobile/Pages/Private/Orders/Detail/Detail.styles';
import { checkmark } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { InventoryNode } from '@src/generated/schema';
import { isTemporaryId } from '@src/helpers/Logic';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';

export interface InventoryListProps {
  inventories: InventoryNode[];
  onInventoryDelete: (id: string) => void;
}

export const InventoryList: React.FC<InventoryListProps> = ({ inventories, onInventoryDelete }) => {
  const { t } = useTranslation();
  const { isOnline } = useContext(NetworkContext);

  return (
    <StyledList>
      <IonListHeader mode='md'>
        <IonGrid>
          <IonRow>
            <IonCol size='4'>
              <IonLabel>{t('mobile.pages.orders.detail.inventoryList.number')}</IonLabel>
            </IonCol>
            <IonCol size='3'>
              <IonLabel>{t('mobile.pages.orders.detail.inventoryList.amount')}</IonLabel>
            </IonCol>
            <IonCol size='2'>
              <IonLabel>{t('mobile.pages.orders.detail.inventoryList.count')}</IonLabel>
            </IonCol>
            <IonCol size='3'>
              <IonLabel>{t('mobile.pages.orders.detail.inventoryList.broached')}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonListHeader>

      {inventories.map((inventory: InventoryNode) => (
        <IonItemSliding key={inventory.id}>
          <IonItem style={{ opacity: isTemporaryId(inventory.id) ? 0.5 : 1 }}>
            <IonGrid>
              <IonRow>
                <IonCol size='4'>
                  <IonLabel>{inventory.pile?.number}</IonLabel>
                </IonCol>

                <IonCol size='3'>
                  <IonLabel>{inventory.amount}</IonLabel>
                </IonCol>

                <IonCol size='2'>
                  <IonLabel>{inventory.count}</IonLabel>
                </IonCol>

                <IonCol size='3'>
                  {inventory.isBroached ? (
                    <StyledIcon icon={checkmark} />
                  ) : (
                    <StyledSpan>{t('mobile.pages.orders.detail.inventoryList.notBroached')}</StyledSpan>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItemOptions side='end'>
            <IonItemOption
              disabled={isOnline && isTemporaryId(inventory.id)}
              color='danger'
              onClick={() => onInventoryDelete(inventory.id)}
            >
              {t('mobile.pages.orders.detail.inventoryList.delete')}
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
      <IonItem>
        <IonGrid>
          <IonRow>
            <IonCol size='4'>
              <IonLabel>{t('mobile.pages.orders.detail.inventoryList.sum')}</IonLabel>
            </IonCol>

            <IonCol size='3'>
              <IonLabel color='primary'>
                {inventories.reduce(
                  (sum: number, inventory: InventoryNode) => sum + (inventory.amount ? Number(inventory.amount) : 0),
                  0,
                )}
              </IonLabel>
            </IonCol>

            <IonCol size='2'>
              <IonLabel color='primary'>
                {inventories.reduce((sum: number, inventory: InventoryNode) => sum + (inventory.count || 0), 0)}
              </IonLabel>
            </IonCol>

            <IonCol size='3' />
          </IonRow>
        </IonGrid>
      </IonItem>

      <StyledInfoText>{t('mobile.pages.orders.detail.inventoryList.deleteInfo')}</StyledInfoText>
    </StyledList>
  );
};
