import React, { FC, useState } from 'react';

interface ComponentCache {
  [key: string]: any;
}

export interface ComponentCacheContextProps {
  cacheValue: (componentId: string, key: string, value: any) => void;
  getComponentCache: (componentId: string) => ComponentCache | undefined;
}

export const ComponentCacheContext = React.createContext<ComponentCacheContextProps>({
  getComponentCache: () => undefined,
  cacheValue: () => undefined,
});

/**
 * This provider can be used if a component wants to save data over its lifetime (so for the next time the component
 * is mounted again). This does NOT work after the app is closed. If the app is closed the values in the cache
 * are reset.
 */
export const ComponentCacheProvider: FC = ({ children }) => {
  const [componentCache, setComponentCache] = useState<ComponentCache>({});

  /**
   * Caches a given value for a component. The component id should be a constant for that component.
   */
  const cacheValue = (componentId, key, value) => {
    setComponentCache(previous => {
      const currentCache = previous[componentId] || {};

      currentCache[key] = value;

      return { ...previous, [componentId]: currentCache };
    });
  };

  /**
   * Returns the current cache of the given component.
   */
  const getComponentCache = componentId => componentCache[componentId];

  return (
    <ComponentCacheContext.Provider value={{ cacheValue, getComponentCache }}>
      {children}
    </ComponentCacheContext.Provider>
  );
};
