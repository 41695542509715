// Minimum 8 Characters, one big letter, one small letter, one number and one special sign
// Maximum 32 Characters
export const MINIMUM_PASSWORD_LENGTH = 10;

export const MAXIMUM_PASSWORD_LENGTH = 32;

export const MINIMUM_PASSWORD_STRENGTH = new RegExp(
  // eslint-disable-next-line max-len
  `^(?=.{${MINIMUM_PASSWORD_LENGTH},${MAXIMUM_PASSWORD_LENGTH}}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])`,
  'g',
);

export const CLIENT_ID_LENGTH = 4;
