import React, { FC, useContext } from 'react';
import { useFormik } from 'formik';
import { Account } from '@src/graphql/Account';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Elements';
import { MeContext } from '@src/components/Providers';
import { CustomObtainJsonWebToken, MutationTokenAuthArgs } from '@src/generated/schema';
import { APIError, Form as StyledForm, Input } from '@src/components/Elements/Wrapper/Form';
import { CLIENT_ID_LENGTH, MINIMUM_PASSWORD_LENGTH, TestIds } from '@src/global';
import * as Yup from 'yup';

export const Form: FC = () => {
  const [tokenAuth, { error }] = useMutation(Account.authenticate);
  const { t } = useTranslation();
  const { setSession } = useContext(MeContext);

  const initialValues: MutationTokenAuthArgs = {
    clientKey: '',
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    clientKey: Yup.string()
      .required('pages.login.input.clientKey.errors.required')
      .length(CLIENT_ID_LENGTH, 'pages.login.input.clientKey.errors.format'),
    email: Yup.string()
      .required('pages.login.input.email.errors.required')
      .email('pages.login.input.email.errors.format'),
    password: Yup.string()
      .required('pages.login.input.password.errors.required')
      .min(MINIMUM_PASSWORD_LENGTH, 'pages.login.input.password.errors.format'),
  });

  const { handleSubmit, handleChange, values, isValid, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    initialErrors: {
      clientKey: 'pages.login.input.clientKey.errors.required',
      email: 'pages.login.input.email.errors.required',
      password: 'pages.login.input.password.errors.required',
    },
    onSubmit: async () => {
      try {
        const { data }: any = await tokenAuth({ variables: { ...values } });

        if (data && data.tokenAuth && data.tokenAuth.token) {
          const newSession: CustomObtainJsonWebToken = { token: data.tokenAuth.token };
          setSession(newSession);
        }
      } catch {
        return false;
      }
    },
  });

  return (
    <StyledForm onSubmit={handleSubmit} data-testid={TestIds.forms.login}>
      <Input
        onChange={handleChange}
        label={t('pages.login.input.email.label')}
        value={values.email}
        disabled={isSubmitting}
        type='email'
        name='email'
      />
      <Input
        label={t('pages.login.input.clientKey.label')}
        onChange={handleChange}
        value={values.clientKey}
        disabled={isSubmitting}
        maxlength={4}
        name='clientKey'
      />
      <Input
        label={t('pages.login.input.password.label')}
        onChange={handleChange}
        value={values.password}
        disabled={isSubmitting}
        type='password'
        name='password'
      />

      <Button disabled={!isValid || isSubmitting} type='submit' size='large'>
        {t('pages.login.loginButton')}
      </Button>

      {error && <APIError errors={error} />}
    </StyledForm>
  );
};
