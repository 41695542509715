import React, { FC, useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Preferences } from '@capacitor/preferences';
import { useHistory } from 'react-router';
import { Routes } from '@src/global';
import { MeContext } from '@src/components/Providers';
import { QUEUE_STORAGE_KEY } from '@src/components/Mobile/Providers/OfflineHandler/OfflineHandler';

export const Logout: FC = () => {
  const { setSession } = useContext(MeContext);
  const { cache } = useApolloClient();
  const { push } = useHistory();

  // Sideeffect:
  // We need to clean up the storage
  // we can not just clear it, because we want to keep map downloads
  useEffect(() => {
    const logoutCleanup = async () => {
      await Promise.all([Preferences.remove({ key: 'token' }), Preferences.remove({ key: QUEUE_STORAGE_KEY })]);
      setSession({ token: '' });
      await cache.reset();
      push(Routes.LOGIN);
    };

    logoutCleanup();
  }, [cache, push, setSession]);

  return <></>;
};
