import * as Yup from 'yup';
import { isDecimal, isLongitudeOrLatitude, maxStringLength } from '@src/helpers/Formik/Formik';
import {
  AmountUnitChoice,
  LocationTypeChoice,
  OldSystemStatusChoice,
  PileTypeChoice,
  QualityTypeChoice,
  UsageSortChoice,
} from '@src/generated/schema';

const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';

export const getPileValidationSchema = t =>
  Yup.object().shape({
    number: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.number') }))
      .test(maxStringLength(t, 36)),
    location: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.location') }))
      .test(maxStringLength(t, 50)),
    woodtype: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.woodtype') }))
      .test(maxStringLength(t, 15)),
    sort: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.sort') }))
      .test(maxStringLength(t, 15)),
    logLength: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.logLength') }))
      .test(isDecimal(t)),
    cbkNet: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.cbkNet') }))
      .test(isDecimal(t)),
    cbkSolid: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.cbkSolid') }))
      .test(isDecimal(t)),
    longitude: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.longitude') }))
      .test(isDecimal(t))
      .test(isLongitudeOrLatitude(t)),
    latitude: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.latitude') }))
      .test(isDecimal(t))
      .test(isLongitudeOrLatitude(t)),
    status: Yup.mixed<OldSystemStatusChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.status.title') }))
      .oneOf(Object.values(OldSystemStatusChoice)),
    type: Yup.mixed<PileTypeChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.type.title') }))
      .oneOf(Object.values(PileTypeChoice)),
    amountUnit: Yup.mixed<AmountUnitChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.amountUnit.title') }))
      .oneOf(Object.values(AmountUnitChoice)),
    locationType: Yup.mixed<LocationTypeChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.locationType.title') }))
      .oneOf(Object.values(LocationTypeChoice)),
    usageSort: Yup.mixed<UsageSortChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.usageSort.title') }))
      .oneOf(Object.values(UsageSortChoice)),
    qualityType: Yup.mixed<QualityTypeChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.pile.qualityType.title') }))
      .oneOf(Object.values(QualityTypeChoice)),

    count: Yup.number(),
    category: Yup.string().test(maxStringLength(t, 50)),
    district: Yup.string().test(maxStringLength(t, 50)),
    note: Yup.string(),
    date: Yup.date(),
    markedAsBroached: Yup.boolean(),
    woodtype2: Yup.string().test(maxStringLength(t, 15)),
    locationPlan: Yup.string(),
    pileNumberFurnisher: Yup.string().test(maxStringLength(t, 50)),
    factorCbkNetCbkSolid: Yup.string().test(isDecimal(t)),
    mustBeAccepted: Yup.boolean(),
    timber: Yup.string(),
  });
