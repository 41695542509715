export const DefaultMapCenter = [51.1642292, 10.4541194];
export const DefaultMarkerHeight = 50;
export const MapMaxZoom = 16;
export const MapMinZoom = 0;
export const MapTotalZoom = 18;
export const DownloadMinZoom = 10;
export const GeolocationMaximumAge = 1000000000000000;
export const GeolocationTimeout = 1000;
export const MarkerToolTipOffsetX = 6;
export const MarkerToolTipOffsetY = -19;
export const MarkerToolTipOpacity = 1;
export const MarkerOpacity = 1;
export const MarkerOpacityBroached = 0.5;

// MapTilesHandler
export const MAP_DOWNLOADS_STORAGE_KEY = 'logistik-map-downloads';
export const ZOOM_LEVELS_TO_DOWNLOAD = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
export const ESTIMATED_TILE_SIZE_KB = 35;

export const INDEXEDDB_NAME = 'leaflet.offline';
export const INDEXEDDB_VERSION = 1;
export const TILE_STORE_NAME = 'tileStore';
export const URL_TEMPLATE_INDEX = 'urlTemplate';
