import { TFunction } from 'i18next';
import { TestOptions } from 'yup';

/**
 * When building forms with Formik, we use yup as validator. To set the according fields to required or not, this
 * functions parses a field from within the given schema and returns the required state.
 */
export const isFieldRequired = (fieldName: string, schema: any): boolean =>
  schema?.fields[fieldName]?._exclusive?.required;

/**
 * Can be used in validation to check if a field is a decimal. You can use this function like:
 * Yup.string().test(isDecimal(t))
 */
export const isDecimal: (t: TFunction) => TestOptions = t => ({
  name: 'is-decimal',
  message: t('forms.validation.errors.notDecimal'),
  test: value => !value || !!`${value}`.match(/^(\d+\.\d+)|(\d+)$/),
});

export const isLongitudeOrLatitude: (t: TFunction) => TestOptions = t => ({
  name: 'is-longitude-or-latitude',
  message: t('forms.validation.errors.notLangOrLat'),
  test: value => !value || !!`${value}`.match(/^\d{1,2}\.?,?\d{1,8}$/),
});

/**
 * Can be used in validation to check if the value of a field has a length of at least X. You can use this function
 * like: Yup.string().test(maxStringLength(t, X))
 */
export const maxStringLength: (t: TFunction, maxLength: number) => TestOptions = (t, maxLength) => ({
  name: 'max-length',
  message: t('forms.validation.errors.maxLength', { maxNumber: maxLength }),
  test: value => !value || (value && value.length <= maxLength),
});
