import gql from 'graphql-tag';
import { CompanyLocationNodeFragment, CompanyNodeFragment } from '@src/graphql/Company/Fragments';

export const companies = gql`
  query companies($first: Int, $after: String, $name_Icontains: String, $search: String) {
    companies(first: $first, after: $after, name_Icontains: $name_Icontains, search: $search) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...companyNode
          billingAddress {
            ...companyLocationNode
          }
        }
      }
    }
  }
  ${CompanyNodeFragment}
  ${CompanyLocationNodeFragment}
`;

export const companyLocations = gql`
  query companyLocations($first: Int, $after: String, $fullAddress: String, $company: ID) {
    companyLocations(first: $first, after: $after, fullAddress: $fullAddress, company: $company) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...companyLocationNode
        }
      }
    }
  }
  ${CompanyLocationNodeFragment}
`;

export const suppliers = gql`
  query companies($first: Int, $after: String, $name_Icontains: String) {
    companies(supplier: true, first: $first, after: $after, name_Icontains: $name_Icontains) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...companyNode
        }
      }
    }
  }
  ${CompanyNodeFragment}
`;

export const getCompanyDetail = gql`
  query company($companyId: ID) {
    company(companyId: $companyId) {
      ...companyNode
      billingAddress {
        ...companyLocationNode
      }
    }
  }
  ${CompanyNodeFragment}
  ${CompanyLocationNodeFragment}
`;
