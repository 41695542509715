import React, { FC } from 'react';
import { FormItem, FormItemProps, Input as AntInput, InputProps as AntdInputProps } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { HelpTextWrapper } from '@src/components/Desktop/Containers/Input/Input.styles';

interface InputProps extends Omit<AntdInputProps, 'name'>, Pick<FormItemProps, 'label' | 'name'> {
  dataTestid?: string;
  helpText?: string;
  placeholder?: string;
  required?: boolean;
  type: string;
}

export const Input: FC<InputProps> = ({ label, helpText, name, placeholder, dataTestid, required, ...props }) => {
  const { t } = useTranslation();

  return (
    <FormItem labelCol={{ span: 24 }} label={label} name={name} required={required}>
      <AntInput {...props} placeholder={placeholder || t('forms.validation.info.noValueSet')} name={name} />
      {helpText && (
        <HelpTextWrapper>
          <label htmlFor={name}>{helpText}</label>
        </HelpTextWrapper>
      )}
    </FormItem>
  );
};
