import React, { useContext, useEffect, useRef, useState } from 'react';
import { Map } from '@src/components/Containers/Map';
import { DefaultMarkerHeight } from '@src/global/Map';
import { PileNode, PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { ReloadOutlined } from '@ant-design/icons';
import { StyledButton, StyledCustomMapControls } from '@src/components/Mobile/Pages/Private/Piles/Piles.styles';
import { IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { PileDetailDrawer } from '@src/components/Mobile/Containers/MapWrapper/PileDetailDrawer';
import { FilterDrawer, PileFilterForm } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/FilterDrawer';
import { ComponentCacheContext } from '@src/components/Providers/ComponentCache';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { useLazyQuery } from 'react-apollo';
import { FormikValues } from 'formik';

const CACHE_ID_COMPONENT = 'mobile_piles_map';

const CACHE_QUERY_FILTERS = 'filtersQuery';
const CACHE_LOCAL_FILTERS = 'filtersLocal';

const CACHE_KEY_FILTERS_ACTIVE = 'filters_active';

const DEFAULT_FILTER = { includeBroached: false };

/**
 * Check if a pile field contains a given value.
 */
const pileFieldContains = (pileField?, value?) => {
  if (!pileField) return true;

  if (Array.isArray(value)) {
    if (value.length === 0) return true;

    return value.some(v =>
      pileField
        .toString()
        .toLowerCase()
        .includes(v.toString().toLowerCase()),
    );
  }

  if (!value) return false;

  return pileField
    .toString()
    .toLowerCase()
    .includes(value.toString().toLowerCase());
};

/**
 * This function is needed in the Piles as well as in this component. It filters piles with given Formik values.
 */
export const getFilteredPiles = (values: PileFilterForm | FormikValues, piles?: PileNode[]) => {
  if (!piles) return [];

  return piles
    .filter(pile => values.includeBroached || !pile.isBroached)
    .filter(pile => !values.pileNumber || pileFieldContains(pile.number, values.pileNumber))
    .filter(pile => !values.category || pileFieldContains(pile.category, values.category))
    .filter(pile => !values.location || pileFieldContains(pile.location, values.location))
    .filter(pile => !values.qualityType || pileFieldContains(pile.qualityType, values.qualityType))
    .filter(pile => !values.sort || pileFieldContains(pile.sort, values.sort))
    .filter(pile => !values.client || pileFieldContains(pile.client?.name, values.client))
    .filter(pile => !values.timber || pileFieldContains(pile.timber, values.timber))
    .filter(pile => !values.type || pileFieldContains(pile.type, values.type))
    .filter(pile => !values.usageSort || pileFieldContains(pile.usageSort, values.usageSort))
    .filter(pile => !values.company || pileFieldContains(pile.plot?.company?.name, values.company))
    .filter(pile => !values.plotNumber || pileFieldContains(pile.plot?.number, values.plotNumber));
};

/**
 * Translates the filter form values to the ones that are passed to the backend to
 * filter everything there.
 */
const getBackendVariablesFromFilters = (filters: PileFilterForm) => ({
  category: filters.category && filters.category?.length > 0 ? filters.category : undefined,
  client_Name_Icontains: filters.client && filters.client?.length > 0 ? filters.client : undefined,
  plot_Company_Name: filters.company && filters.company.length > 0 ? filters.company : undefined,
  includeBroached: filters.includeBroached,
  location: filters.location && filters.location.length > 0 ? filters.location : undefined,
  number_Contains: filters.pileNumber,
  plot_Number_Contains: filters.plotNumber,
  sort: filters.sort && filters.sort.length > 0 ? filters.sort : undefined,
  type: filters.type && filters.type.length > 0 ? filters.type : undefined,
  qualityType: filters.qualityType && filters.qualityType.length > 0 ? filters.qualityType : undefined,
  timber: filters.timber && filters.timber.length > 0 ? filters.timber : undefined,
  usageSort: filters.usageSort && filters.usageSort.length > 0 ? filters.usageSort : undefined,
});

export const Piles = () => {
  const { t } = useTranslation();
  const initialFetch = useRef(true);
  const { isOnline } = useContext(NetworkContext);
  const { cacheValue, getComponentCache } = useContext(ComponentCacheContext);
  const componentCache = getComponentCache(CACHE_ID_COMPONENT);

  // this cache contains the filters that were used the last time locally
  const localFilterCache =
    componentCache && componentCache[CACHE_LOCAL_FILTERS] ? componentCache[CACHE_LOCAL_FILTERS] : DEFAULT_FILTER;
  // this cache contains the filters that were used last time to query the backend
  const defaultQueryFilter =
    componentCache && componentCache[CACHE_QUERY_FILTERS] ? componentCache[CACHE_QUERY_FILTERS] : DEFAULT_FILTER;

  const [longTimeWarningVisible, setLongTimeWarningVisible] = useState<boolean>(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);
  const [piles, setPiles] = useState<PileNode[]>([]);
  const [filtersAreActive, setFiltersActive] = useState<boolean>(
    componentCache ? componentCache[CACHE_KEY_FILTERS_ACTIVE] : false,
  );
  const [resetMapCenter, setResetMapCenter] = useState<boolean>(false);
  const [mapMarkers, setMapMarkers] = useState<PileNode[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<PileNode | undefined>(undefined);
  const [showPileDrawer, setShowPileDrawer] = useState<boolean>(false);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);

  // always use the last local filter as the current filter on start up
  const [currentFilter, setCurrentFilter] = useState<PileFilterForm>(localFilterCache);
  const [getPiles, { loading, data, refetch }] = useLazyQuery<{ pilesNoLimit: PileNodeConnection }>(
    Pile.getPilesNoLimit,
    {
      fetchPolicy: 'cache-and-network',
      // while offline, the app will filter itself, so use the default query filters here instead
      variables: getBackendVariablesFromFilters(isOnline ? currentFilter : defaultQueryFilter),
    },
  );

  /**
   * This useEffect is used to update the data after filters have been changed if the user is online.
   */
  useEffect(() => {
    if (isOnline && !initialFetch.current) {
      getPiles();
    }
  }, [getPiles, currentFilter, isOnline, initialFetch]);

  /**
   * Whenever the filters are changed, re-evaluate which piles should be visible on the map.
   */
  useEffect(() => {
    // when offline filter the current piles - if offline the backend will return the filtered piles
    if (!isOnline) {
      setMapMarkers(getFilteredPiles(currentFilter, piles));
    } else {
      setMapMarkers(piles);
    }
  }, [currentFilter, piles, setMapMarkers, isOnline]);

  /**
   * Initially fetch all the piles.
   */
  useEffect(() => {
    getPiles();
    initialFetch.current = false;
  }, [getPiles]);

  /**
   * Show a waning that loading takes some time if the query was called by apollo and no data exists yet.
   */
  useEffect(() => {
    if (loading && !isRefetching && !data) {
      setLongTimeWarningVisible(true);
    }
  }, [loading, isRefetching, setLongTimeWarningVisible, data]);

  /**
   * Resets the map center whenever the map markers change
   */
  useEffect(() => {
    setResetMapCenter(true);
  }, [mapMarkers, setResetMapCenter]);

  /**
   * Reset the value of resetMapCenter to false again whenever the center was reset.
   */
  useEffect(() => {
    if (resetMapCenter) {
      setResetMapCenter(false);
    }
  }, [resetMapCenter, setResetMapCenter]);

  /**
   * Sets the piles that are generally available.
   *
   * Add a small timeout because without it, the map would make the app lag because the piles are fetched before
   * the map is displayed.
   */
  useEffect(() => {
    const { pilesNoLimit: { edges } = { edges: [] as any } } = data || {};
    const timer = setTimeout(() => {
      setPiles(edges.map(edge => edge.node));
    }, 15);

    return () => clearTimeout(timer);
  }, [data, setPiles]);

  /**
   * Refetches the piles.
   */
  const handleRefetchPiles = async () => {
    setIsRefetching(true);
    setLongTimeWarningVisible(true);
    await refetch(currentFilter);
    setIsRefetching(false);
  };

  /**
   * Called when a marker is clicked.
   */
  const onMarkerSelect = (marker: PileNode) => {
    setSelectedMarker(marker);
    setShowPileDrawer(true);
  };

  /**
   * Called to close the drawer that shows the details of a pile.
   */
  const onCloseDrawer = () => {
    setShowPileDrawer(false);
    setSelectedMarker(undefined);
  };

  /**
   * Handles the submit of filters.
   */
  const handleFilterSubmit = (values: PileFilterForm, filtersActive: boolean) => {
    setFiltersActive(filtersActive);
    setShowFilterDrawer(false);
    setCurrentFilter(values);
    cacheValue(CACHE_ID_COMPONENT, CACHE_KEY_FILTERS_ACTIVE, filtersActive);
    cacheValue(CACHE_ID_COMPONENT, CACHE_LOCAL_FILTERS, values);

    if (isOnline) {
      cacheValue(CACHE_ID_COMPONENT, CACHE_QUERY_FILTERS, values);
    }
  };

  /**
   * Handles the click on the filter button.
   */
  const onClickFilter = () => {
    setShowFilterDrawer(true);
  };

  /**
   * Handles closing the filter drawer.
   */
  const onCloseFilter = () => {
    setShowFilterDrawer(false);
  };

  return (
    <>
      <StyledCustomMapControls className={ScreenOrientation?.type}>
        <StyledButton disabled={isRefetching || loading} onClick={handleRefetchPiles}>
          <ReloadOutlined spin={isRefetching || loading} />
        </StyledButton>
      </StyledCustomMapControls>
      <IonAlert
        isOpen={longTimeWarningVisible}
        onDidDismiss={() => setLongTimeWarningVisible(false)}
        header={t('general.alert.title')}
        subHeader={t('mobile.pages.piles.uploadText')}
        buttons={[
          {
            text: t('general.ok'),
            role: 'cancel',
            cssClass: 'secondary',
          },
        ]}
      />
      <Map
        markerHeight={DefaultMarkerHeight}
        markers={mapMarkers}
        mapCenterReset={resetMapCenter}
        markerFilterIsActive={filtersAreActive}
        onMarkerClick={onMarkerSelect}
        showActionBar
        showZoomActionBar={false}
        allowDownload={false}
        allowNavigationToOfflineMaps={false}
        allowClusteringToggle={false}
        onToggleMarkerFilter={onClickFilter}
      />

      {!!selectedMarker && (
        <PileDetailDrawer
          pile={selectedMarker}
          visible={showPileDrawer}
          onCloseDrawer={onCloseDrawer}
          enableBooking={false}
          enableEditing
        />
      )}

      <FilterDrawer
        piles={piles}
        initialValue={currentFilter}
        visible={showFilterDrawer}
        onCloseDrawer={onCloseFilter}
        onSubmit={handleFilterSubmit}
      />
    </>
  );
};
