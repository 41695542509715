import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import debounce from 'lodash.debounce';

const { Search } = Input;

export interface InputProps {
  className?: string;
  onSearch: (value: string) => void;
}

export const CompaniesSearch: React.FC<InputProps> = ({ className, onSearch }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (event: any) => {
    const { value } = event.target;
    setInputValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      onSearch(searchValue);
    }, 500),
    [],
  );

  return (
    <Search
      className={className}
      enterButton
      onChange={handleChange}
      onPressEnter={handleChange}
      onSearch={onSearch}
      placeholder={t('general.search')}
      size='large'
      value={inputValue}
    />
  );
};
