import React, { FC } from 'react';
import { IonToolbar } from '@ionic/react';
import { Logo } from '@src/components/Elements/Logo';
import { StyledHeader, StyledLeft, StyledUserDropdownWrapper } from './Header.styles';
import { Navigation } from '@src/components/Desktop/Sections/Header/Navigation';
import { Container, Wrapper } from '@src/components/Elements';
import { UserDropdown } from './UserDropdown';

export const Header: FC = () => (
  <StyledHeader>
    <IonToolbar>
      <Container>
        <Wrapper>
          <StyledLeft>
            <Logo />
          </StyledLeft>
          <Navigation />
          <StyledUserDropdownWrapper>
            <UserDropdown />
          </StyledUserDropdownWrapper>
        </Wrapper>
      </Container>
    </IonToolbar>
  </StyledHeader>
);
