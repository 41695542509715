import { Select } from 'formik-antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum CollectedOptions {
  YES = '0',
  NO = '1',
  ALL = '2',
}

export const CollectedFromOtherSystemSelect = (props: Props) => {
  const { t } = useTranslation();

  const collectedSelectOptions = [
    {
      title: t('desktop.pages.orders.filter.collectedFromOtherSystem.yes'),
      value: CollectedOptions.YES,
    },
    {
      title: t('desktop.pages.orders.filter.collectedFromOtherSystem.no'),
      value: CollectedOptions.NO,
    },
    {
      title: t('desktop.pages.orders.filter.collectedFromOtherSystem.all'),
      value: CollectedOptions.ALL,
    },
  ];

  return (
    <Select {...props}>
      {collectedSelectOptions.map(({ value, title }) => (
        <Select.Option key={value} value={value}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};

type Props = React.ComponentProps<typeof Select>;
