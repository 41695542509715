import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from '@src/global';
import { NotFound } from '@src/components/Desktop/Pages/Response';
import { List as PilesList } from './Piles/List';
import { List as PlotList } from './Plots/List';
import { List as OrdersList } from './Orders/List';
import { List as DeliveryStatusList } from './DeliveryStatus/List';
import { List as AccountsList } from './Accounts/List';
import { List as CompaniesList } from './Companies/List';
import { Page } from '@src/components/Desktop/Sections/Page';
import { PasswordChangePage } from '@src/components/Pages/Private/PasswordChangePage/PasswordChangePage';

export const Private: FC = () => {
  const { PILES, ORDERS, PASSWORD, PLOTS, DELIVERY_STATUS, ACCOUNTS, COMPANIES } = Routes;

  return (
    <Page>
      <Switch>
        <Route exact path={PILES} component={PilesList} />
        <Route exact path={PLOTS} component={PlotList} />
        <Route exact path={ORDERS} component={OrdersList} />
        <Route exact path={DELIVERY_STATUS} component={DeliveryStatusList} />
        <Route exact path={PASSWORD} component={PasswordChangePage} />
        <Route exact path={ACCOUNTS} component={AccountsList} />
        <Route exact path={COMPANIES} component={CompaniesList} />
        <Route component={NotFound} />
      </Switch>
    </Page>
  );
};
