import React, { FC, useContext } from 'react';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { useMutation } from 'react-apollo';
import { Wrapper } from '@src/components/Elements';
import { Company } from '@src/graphql/Company';
import { useTranslation } from 'react-i18next';
import { Form, FormValues } from '../Form';

export interface AddProps {
  closeModal: () => void;
}

export const Add: FC<AddProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [createCompany, { error }] = useMutation(Company.createCompany);

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: data_ } = await createCompany({
        variables,
        refetchQueries: ['companies'],
      });

      if (data_) {
        closeModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.companies.add.form.success.message'),
          description: t('desktop.pages.companies.add.form.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div>
      <Wrapper>
        <Form onSubmit={onSubmit} error={error} />
      </Wrapper>
    </div>
  );
};
