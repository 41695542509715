import styled from 'styled-components';
import { Button } from 'antd';

import { PilesSearch } from '@src/components/Elements/PilesSearch';

export const StyledList = styled.div`
  position: relative;
`;

export const StyledCustomMapControls = styled.div`
  top: 10px;
  right: 10px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 401; /** Leaflet-Pane Z-Index + 1 */
`;

export const StyledPilesSearch = styled(PilesSearch)`
  max-width: 350px;
`;

export const StyledButton = styled(Button)`
  height: auto;
  margin-left: 10px;
`;
