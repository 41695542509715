import React, { FC, useEffect } from 'react';
import { Pages } from './Pages';
import { TestIds } from '@src/global';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  ApolloProvider,
  FileProvider,
  InventoriesProvider,
  LocationProvider,
  OfflineHandlerProvider,
  OfflineMapTilesHandlerProvider,
} from '@src/components/Mobile/Providers';
import { MeProvider } from '../Providers';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { NetworkProvider } from '@src/components/Mobile/Providers/Network';
import { ComponentCacheProvider } from '@src/components/Providers/ComponentCache';
import { RoutePathCacheHandlerProvider } from '@src/components/Mobile/Providers/RoutePathsCache';

export const Mobile: FC = () => {
  useEffect(() => {
    ScreenOrientation.unlock();

    KeepAwake.keepAwake();

    return () => {
      KeepAwake.allowSleep();
    };
  }, []);

  return (
    <div data-testid={TestIds.platforms.mobile}>
      <ApolloProvider>
        <FileProvider>
          <NetworkProvider>
            <InventoriesProvider>
              <OfflineHandlerProvider>
                <OfflineMapTilesHandlerProvider>
                  <RoutePathCacheHandlerProvider>
                    <ComponentCacheProvider>
                      <MeProvider>
                        <LocationProvider>
                          <IonRouterOutlet>
                            <IonReactRouter>
                              <Pages />
                            </IonReactRouter>
                          </IonRouterOutlet>
                        </LocationProvider>
                      </MeProvider>
                    </ComponentCacheProvider>
                  </RoutePathCacheHandlerProvider>
                </OfflineMapTilesHandlerProvider>
              </OfflineHandlerProvider>
            </InventoriesProvider>
          </NetworkProvider>
        </FileProvider>
      </ApolloProvider>
    </div>
  );
};
