import React, { FC, Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import { FormItem, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form as FormWrapper } from '@src/components/Elements/Wrapper/Form';
import { Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { Input } from '@src/components/Desktop/Containers/Input';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import { SupplierSelect } from '@src/components/Desktop/Containers/SearchSelects/SupplierSelect';
import { StatusSelect } from './StatusSelect';

interface FilterFormValues {
  plotNumber?: number;
  selection?: string;
  showInactive?: boolean;
  status?: string;
  supplier?: string;
}

const validationSchema = Yup.object().shape({
  plotNumber: Yup.number(),
  selection: Yup.string(),
  showInactive: Yup.boolean(),
  status: Yup.string(),
  supplier: Yup.string(),
});

const initialValues: FilterFormValues = {
  plotNumber: undefined,
  selection: undefined,
  showInactive: undefined,
  status: undefined,
  supplier: undefined,
};

interface FilterFormProps {
  itemsPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setQueryVariables: Dispatch<SetStateAction<any>>;
}

export const FilterForm: FC<FilterFormProps> = ({ setPage, setQueryVariables, itemsPerPage }) => {
  const { t } = useTranslation();

  const handleSubmit = values => {
    const { plotNumber, selection, status, supplier, showInactive } = values;
    setPage(1);
    setQueryVariables({
      selection,
      status,
      supplier,
      activeOnly: !showInactive,
      plotNumber: plotNumber ? plotNumber : undefined,
      first: itemsPerPage,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      initialErrors={{}}
      onSubmit={handleSubmit}
    >
      <FormWrapper>
        <AutoSaveForm />
        <Row>
          <FormItem labelCol={{ span: 24 }} label={t('desktop.pages.plots.filter.showInactive')} name='showInactive'>
            <Switch name='showInactive' />
          </FormItem>
        </Row>
        <Row>
          <Input label={t('desktop.pages.plots.filter.plotNumber')} type='text' name='plotNumber' />
        </Row>
        <Row>
          <Input label={t('desktop.pages.plots.filter.selection')} type='text' name='selection' />
        </Row>
        <Row>
          <StatusSelect />
        </Row>
        <Row>
          <SupplierSelect />
        </Row>
      </FormWrapper>
    </Formik>
  );
};
