import React, { FC } from 'react';
import { Wrapper } from '@src/components/Elements';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { StyledPilesDetailTable } from './InventoryDetails.styles';
import { ModalDataState } from '@src/components/Desktop/Pages/Private/Piles/List/List';
import { InventoryNode, PileNode } from '@src/generated/schema';
import { useQuery } from '@apollo/react-hooks';
import { Pile } from '@src/graphql/Pile';
import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import { Alert } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';

export interface ShowProps {
  modalData: ModalDataState;
}

interface ReadOnlyInventoryPileNode extends Omit<PileNode, 'inventories'> {
  inventories?: ReadonlyArray<InventoryNode>;
}

export const InventoryDetails: FC<ShowProps> = ({ modalData }) => {
  const { t } = useTranslation();
  const pile: PileNode | undefined = Array.isArray(modalData) ? modalData[0] : modalData;

  const { loading, error, data } = useQuery<{
    pile: ReadOnlyInventoryPileNode;
  }>(Pile.getDetailPile, {
    variables: { pileId: pile && pile.id },
    fetchPolicy: 'network-only',
  });

  const columnsInventories: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.order'),
      dataIndex: 'order',
      render: inv => (inv ? inv.number : ''),
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.amount'),
      dataIndex: 'amount',
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.count'),
      dataIndex: 'count',
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.time'),
      dataIndex: 'time',
      render: text => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.broached'),
      dataIndex: 'isBroached',
      render: text =>
        text
          ? t('desktop.pages.piles.inventoryDetails.inventories.values.broached.true')
          : t('desktop.pages.piles.inventoryDetails.inventories.values.broached.false'),
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.rest'),
      dataIndex: 'rest',
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.createdBy'),
      dataIndex: 'createdBy',
      render: user => (user ? `${user.firstName} ${user.lastName}` : ''),
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.createdAt'),
      dataIndex: 'createdAt',
      render: text => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.piles.inventoryDetails.inventories.columns.lastModifiedBy'),
      dataIndex: 'lastmodifiedBy',
      render: user => <span>{user ? `${user.firstName} ${user.lastName}` : `-`}</span>,
    },
  ];

  return (
    <Wrapper>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}

      {error && (
        <Alert
          message={t('desktop.pages.orders.show.error.message')}
          description={t('desktop.pages.orders.show.error.description')}
          type='error'
          showIcon
        />
      )}

      {data && (
        <StyledPilesDetailTable
          title={() => t('desktop.pages.piles.inventoryDetails.inventories.title')}
          columns={columnsInventories}
          dataSource={data?.pile?.inventories || []}
          rowKey='id'
          pagination={false}
        />
      )}
    </Wrapper>
  );
};
