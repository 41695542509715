import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';
import { OperationVariables } from '@apollo/react-common/lib/types/types';
import { useApolloClient } from '@apollo/react-hooks';
import { Subscription } from 'apollo-client/util/Observable';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { FilesystemRoutes, FileType, RelayItemsPerPageMobile } from '@src/global';
import { OrderNode, Query } from '@src/generated/schema';
import { Order } from '@src/graphql/Order';
import { ToastContext } from '@src/components/Providers';
import { FileContext, InventoriesContext } from '@src/components/Mobile/Providers';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { OrderStatus } from '@src/graphql/Order/Types';
import { captureEvent, captureException } from '@sentry/minimal';

export const QUEUE_STORAGE_KEY = 'logistik-queue';

interface MutationJob {
  id: string;
  mutation?: any;
  refetchOrders?: boolean;
  refId?: string;
  variables?: OperationVariables;
}

export interface OrderVariables {
  activeOnly?: boolean;
  first?: number;
  status?: OrderStatus;
}

interface FileUploadJob {
  file: File;
  fileName?: string;
  id: string;
}

type InternetConnectionType = 'wifi' | 'cellular' | 'none' | 'unknown';

function instanceOfFileUploadJob(object: any): object is FileUploadJob {
  return 'fileName' in object;
}

function instanceOfMutationJob(object: any): object is MutationJob {
  return 'mutation' in object;
}

type Job = MutationJob | FileUploadJob;

interface OfflineHandlerContextProps {
  addJob: (args: Omit<Job, 'id'>) => void;
  internetConnectionType: InternetConnectionType;
  jobs: Job[];
  lastOrdersFetch: Date | null;
  refetchOrders: (variables?: OrderVariables) => void;
  removeJob: (id: string) => void;
}

export const OfflineHandlerContext = createContext<OfflineHandlerContextProps>({
  addJob: () => undefined,
  internetConnectionType: 'unknown',
  jobs: [],
  lastOrdersFetch: null,
  refetchOrders: () => undefined,
  removeJob: () => undefined,
});

// these are some predefined filters for the orders that are used in the app. It can be be useful to use these
// to access the cache
export type OrderCacheFilterType = 'all' | 'activeOnly' | 'finished';
export const ORDERS_QUERY_FINISHED_ONLY = { activeOnly: undefined, status: OrderStatus.Finished };
export const ORDERS_QUERY_ACTIVE_ONLY = { activeOnly: true, status: undefined };
export const ORDERS_QUERY_ALL = { activeOnly: undefined, status: undefined };
export const ORDERS_QUERY_FALLBACK = { ...ORDERS_QUERY_ACTIVE_ONLY, first: RelayItemsPerPageMobile };

export const OfflineHandlerProvider: FC = ({ children }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { parseFetchedOrders } = useContext(InventoriesContext);
  const { setIonToast } = useContext(ToastContext);
  const { uploadFile } = useContext(FileContext);
  const { isOnline, setIsOnline } = useContext(NetworkContext);
  const [internetConnectionType, setInternetConnectionType] = useState<InternetConnectionType>('unknown');
  const [jobs, setJobs] = useState<Job[]>([]);
  const [lastOrdersFetch, setLastOrdersFetch] = useState<Date | null>(null);
  const activeOrderSubscriptionRef = useRef<Subscription | undefined>();
  const allOrderSubscriptionRef = useRef<Subscription | undefined>();
  const finishedOrderSubscriptionRef = useRef<Subscription | undefined>();

  // saves the .id of the job that is current handled by e.g. `handleMutationJob`. This is needed because there might
  // be cases where these function are called while they are still processing other jobs, this ref is used
  // to determine if there is currently no job that needs to be finished first
  const currentProcessedJobIdRef = useRef<string | undefined>();

  const getDriverOrdersQueryObservable = useCallback(
    (variables: OrderVariables) =>
      client.watchQuery({
        variables: { ...variables, ...(variables.first ? {} : { first: RelayItemsPerPageMobile }) },
        query: Order.getDriverOrders,
        fetchPolicy: 'network-only',
      }),
    [client],
  );

  /**
   * Returns the ref for given order variables. There are several ref objects that are used to store the data
   * of orders with different filters in it.
   */
  const getOrdersRef = useCallback(
    (variables: OrderVariables) => {
      const { activeOnly, status } = variables;

      if (!status && !activeOnly) {
        return allOrderSubscriptionRef;
      } else if (status === OrderStatus.Finished) {
        return finishedOrderSubscriptionRef;
      } else if (activeOnly) {
        return activeOrderSubscriptionRef;
      }

      throw new Error('This should not happen!!');
    },
    [allOrderSubscriptionRef, finishedOrderSubscriptionRef, activeOrderSubscriptionRef],
  );

  /**
   * @description Refetch and override locally stored driver orders.
   * By default apollo cancels any subsequent queries if there already is a pending query of the same type.
   * In order to always get the most up-to-date orders when refetching, pending queries are canceled and restarted.
   */
  const refetchOrders = useCallback(
    async (variables?: OrderVariables) => {
      const variablesWithFallback = variables || ORDERS_QUERY_FALLBACK;
      const ref: MutableRefObject<Subscription | undefined> | null = getOrdersRef(variablesWithFallback);

      // Cancel any previous refetch query.
      ref.current?.unsubscribe();

      // "Re-"start query for updated driver orders.
      ref.current = getDriverOrdersQueryObservable(variablesWithFallback).subscribe(
        ({ data: driverOrdersData }: { data: Query }) => {
          setLastOrdersFetch(new Date());

          // Upon successful refetch we update our custom state storages
          // For now this only affects inventories
          const orderNodes = driverOrdersData?.prefetchOrders?.edges.map(edge => edge?.node).filter(node => !!node);

          if (!!orderNodes) {
            parseFetchedOrders(orderNodes as OrderNode[]);
          }
        },

        // TODO: Let 'refetchOrders' actually return the error, so it can be handled by its invoker.
        (error: Error) => error,
      );

      return;
    },
    [getDriverOrdersQueryObservable, getOrdersRef, parseFetchedOrders],
  );

  const addJob = (args: Pick<Job, Exclude<keyof Job, 'id'>>): void => {
    const mutationJobs = jobs.filter(instanceOfMutationJob);
    const fileUploadJobs = jobs.filter(instanceOfFileUploadJob);
    const nextJob: Job = { id: uuidv4(), ...args };

    if (instanceOfMutationJob(nextJob)) {
      setJobs([...mutationJobs, nextJob, ...fileUploadJobs]);
    }

    if (instanceOfFileUploadJob(nextJob)) {
      const foundJob = jobs.find(job => {
        const fileName = (instanceOfFileUploadJob(job) && job?.fileName) as string;
        const nextJobFileName = nextJob?.fileName as string;

        return fileName === nextJobFileName;
      });

      if (foundJob) {
        setJobs([...mutationJobs, ...fileUploadJobs.filter(job => job.id !== foundJob.id), nextJob]);
      } else {
        setJobs([...mutationJobs, ...fileUploadJobs, nextJob]);
      }
    }
  };

  const removeJob = (id: string): void => {
    setJobs((prevJobs: Job[]) => prevJobs.filter((job: any) => job.id !== id && job.refId !== id));
  };

  /**
   * Handles all mutations that are not related to files.
   */
  const handleMutationJob = useCallback(
    async (mutationJob: MutationJob): Promise<void> => {
      // if this is called while another job is handled, skip for now; since the jobs list is changed at the end of
      // this function, the provided job will be handled sooner or later anyway
      if (currentProcessedJobIdRef.current) {
        return;
      }

      try {
        // save that this job is handled now
        currentProcessedJobIdRef.current = mutationJob.id;

        const isLastMutationJobInQueue = !jobs.find(
          job => instanceOfMutationJob(job) && job.refetchOrders && job.id !== mutationJob.id,
        );

        await client.mutate({
          mutation: mutationJob.mutation,
          variables: mutationJob.variables,
        });

        if (mutationJob.refetchOrders && isLastMutationJobInQueue) {
          await refetchOrders();
        }

        setJobs(prevJobs => prevJobs.filter(job => job.id !== mutationJob.id));
      } catch (errors) {
        const { graphQLErrors, networkError } = errors;

        // if network error keep in queue and set handler to inactive
        // INFO: we need to remove failing jobs from queue
        if (networkError) {
          setIsOnline(false);
        } else {
          setIsOnline(true);

          graphQLErrors.forEach(({ message }) => {
            setIonToast({
              message,
              show: true,
            });
          });

          // only remove the job while online
          setJobs(prevJobs => prevJobs.filter(job => job.id !== mutationJob.id));
        }
      } finally {
        // set the new jobs and free up the slot for handling jobs
        currentProcessedJobIdRef.current = undefined;
      }
    },
    [client, jobs, setJobs, setIonToast, refetchOrders, currentProcessedJobIdRef, setIsOnline],
  );

  const handleFileUploadJob = useCallback(
    async (fileUploadJob: FileUploadJob): Promise<void> => {
      const { fileName, file } = fileUploadJob;
      
      const fileNameWithoutMimeType = fileName?.split('.')[0];
      const fileType = fileNameWithoutMimeType?.split('-').pop();
      const orderId = fileNameWithoutMimeType?.split('-')[0];

      // if data is missing or there is a job being handled at the moment, skip it
      if (!fileName || !file || currentProcessedJobIdRef.current) {
        return;
      }

      // save that the job is handled
      currentProcessedJobIdRef.current = fileUploadJob.id;

      try {
        const uploadResponse = await uploadFile(file);
        
        if(!uploadResponse.ok){
          captureEvent(new Error(`Error while uploading file: ${JSON.stringify(uploadResponse)}`));
          setIonToast({
            message: t('mobile.offlineHandler.fileJobError'),
            show: true,
          });
        }

        const editedJobs = jobs.filter(job => job.id !== fileUploadJob.id);
        const fileUploadId = (await uploadResponse.json()).id;

        if (fileType === FileType.CUSTOMER) {
          setJobs([
            ...editedJobs,
            {
              id: uuidv4(),
              mutation: Order.setDeliveryNoteCustomer,
              variables: { orderId, deliveryNoteCustomerId: fileUploadId },
            },
          ]);
        }

        if (fileType === FileType.SUPPLIER) {
          setJobs([
            ...editedJobs,
            {
              id: uuidv4(),
              mutation: Order.setDeliveryNoteSupplier,
              variables: { orderId, deliveryNoteSupplierId: fileUploadId },
            },
          ]);
        }
      } catch (errors) {
        // TODO Check if this is not caught before
        if (errors.code === 3) {
          setIsOnline(false);
        } else {
          setIsOnline(true);

          setIonToast({
            message: t('mobile.offlineHandler.fileJobError'),
            show: true,
          });

          const editedJobs = jobs.filter(job => job.id !== fileUploadJob.id);

          setJobs(editedJobs);
        }
      } finally {
        // free up the job
        currentProcessedJobIdRef.current = undefined;
      }
    },
    [uploadFile, jobs, setJobs, setIonToast, t, currentProcessedJobIdRef, setIsOnline],
  );

  const handleNextJob = useCallback(async () => {
    const nextJob: Job = !!jobs && jobs[0];

    if (instanceOfMutationJob(nextJob)) {
      await handleMutationJob(nextJob);
    } else if (instanceOfFileUploadJob(nextJob)) {
      await handleFileUploadJob(nextJob);
    }
  }, [jobs, handleFileUploadJob, handleMutationJob]);

  // SideEffect: initialize jobs from storage. Also get the network status on initialization.
  useEffect(() => {
    Preferences.get({ key: QUEUE_STORAGE_KEY }).then(({ value }) => {
      if (value) setJobs(JSON.parse(value));
    });

    Network.getStatus().then(({ connected, connectionType }) => {
      setIsOnline(connected);
      setInternetConnectionType(connectionType);
    });
  }, [setIsOnline]);

  // SideEffect: initial order fetch if there is no data yet
  useEffect(() => {
    const ref = getOrdersRef(ORDERS_QUERY_FALLBACK);

    if (!ref.current && isOnline) {
      refetchOrders(ORDERS_QUERY_FALLBACK);
    }
  }, [refetchOrders, getOrdersRef, isOnline]);

  // SideEffect: network status changes
  useEffect(() => {
    Network.addListener('networkStatusChange', ({ connected, connectionType }: ConnectionStatus) => {
      setIsOnline(connected);
      setInternetConnectionType(connectionType);
    });
  }, [setIsOnline]);

  // SideEffect: update storage on job change
  useEffect(() => {
    Preferences.set({ key: QUEUE_STORAGE_KEY, value: JSON.stringify(jobs) });
  }, [jobs]);

  // SideEffect: trigger next job on jobs or active change while active
  useEffect(() => {
    let isOnlineInterval: NodeJS.Timeout | undefined;

    if (isOnline && jobs.length && !isOnlineInterval) {
      clearInterval(isOnlineInterval);

      handleNextJob();
    }

    if (!isOnline && jobs.length) {
      isOnlineInterval = setInterval(() => {
        setIsOnline(true);
      }, 1000 * 60 * 15);

      setIonToast({
        show: true,
        message: t('general.offlineMutation'),
      });
    }
  }, [jobs, isOnline, handleNextJob, t, setIonToast, setIsOnline]);

  return (
    <OfflineHandlerContext.Provider
      value={{ addJob, internetConnectionType, jobs, lastOrdersFetch, refetchOrders, removeJob }}
    >
      {children}
    </OfflineHandlerContext.Provider>
  );
};
