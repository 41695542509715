import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
  &.ant-menu-vertical {
    border-right: none !important;
    border: ${({ theme }) => theme.global.borderWidth}px solid ${({ theme }) => theme.color.alto} !important;
    border-radius: ${({ theme }) => theme.global.borderRadius}px !important;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex !important;
  align-items: center;
  justify-content: center;
`;
