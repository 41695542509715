import React, { FC, useContext } from 'react';
import { FormItem, FormItemProps } from 'formik-antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadProps } from 'antd/es/upload';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Routes, FILETYPE_PDF } from '@src/global';
import { MeContext } from '../../../Providers/Me/Me';

interface FileUploadProps extends UploadProps, Pick<FormItemProps, 'label'> {
  name: string;
  required?: boolean;
}

export const FileUpload: FC<FileUploadProps> = ({ label, name, required, onChange, ...props }) => {
  const { t } = useTranslation();
  const { session } = useContext(MeContext);

  return (
    <FormItem labelCol={{ span: 24 }} label={label} name={name} required={required} hasFeedback>
      <Field name={name}>
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
          <Upload
            accept={FILETYPE_PDF}
            action={`${window.env.LOGISTIK_BACKEND_URL}${Routes.UPLOAD}`}
            headers={{ Authorization: `JWT ${session.token}` }}
            fileList={value && [value]}
            onChange={({ file, fileList }) => {
              const fieldValue = file.status === 'removed' ? null : file;
              setFieldValue(name, fieldValue);
              setFieldTouched(name, true);
              if (onChange) onChange({ file, fileList });
            }}
            onDownload={({ response }) => window.open(response.file)}
            {...props}
          >
            <Button>
              <UploadOutlined />
              {t('desktop.containers.fileUpload.label')}
            </Button>
          </Upload>
        )}
      </Field>
    </FormItem>
  );
};
