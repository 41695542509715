import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-scroll {
    overflow-x: auto !important;
  }

  th,
  td {
    padding: 15px !important;
    white-space: pre;
  }

  .ant-pagination {
    float: initial !important;
    text-align: center;
  }
`;
