import React, { FC } from 'react';
import { DeliveryStatusNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverRow.styles';
import { ModalEnum } from '../List';

export interface PopoverRowProps {
  deliveryStatus: DeliveryStatusNode;
  toggleModal: (modalType: ModalEnum, order: DeliveryStatusNode) => void;
}

export const PopoverRow: FC<PopoverRowProps> = ({ toggleModal, deliveryStatus }) => {
  const { t } = useTranslation();

  return (
    <StyledPopover>
      <StyledPopoverOption onClick={() => toggleModal(ModalEnum.SHOW, deliveryStatus)}>
        {t('desktop.pages.deliveryStatus.list.popover.show')}
      </StyledPopoverOption>
      <StyledPopoverOption onClick={() => toggleModal(ModalEnum.CHANGE, deliveryStatus)}>
        {t('desktop.pages.deliveryStatus.list.popover.edit')}
      </StyledPopoverOption>
    </StyledPopover>
  );
};
