import { Formik } from 'formik';
import React, { FC, Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { Form as FormWrapper } from '@src/components/Elements/Wrapper/Form';
import { Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import { FormItem, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { EmailSelect } from './EmailSelect';
import { LastNameSelect } from '@src/components/Desktop/Pages/Private/Accounts/List/FilterForm/LastNameSelect';
import { FirstNameSelect } from '@src/components/Desktop/Pages/Private/Accounts/List/FilterForm/FirstNameSelect';

interface FilterFormValues {
  email?: boolean;
  firstName?: string;
  includeInactive?: boolean;
  lastName?: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string(),
  firstName: Yup.string(),
  includeInactive: Yup.boolean(),
  lastName: Yup.string(),
});

const initialValues: FilterFormValues = {
  email: undefined,
  firstName: undefined,
  includeInactive: false,
  lastName: undefined,
};

interface FilterFormProps {
  itemsPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setQueryVariables: Dispatch<SetStateAction<any>>;
}

export const FilterForm: FC<FilterFormProps> = ({ setPage, setQueryVariables, itemsPerPage }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const { email, firstName, lastName, includeInactive } = values;

        setPage(1);
        setQueryVariables(() => ({
          includeInactive,
          email,
          firstName,
          lastName,
          first: itemsPerPage,
        }));
      }}
    >
      <FormWrapper>
        <AutoSaveForm />
        <Row>
          <FormItem
            labelCol={{ span: 24 }}
            label={t('desktop.pages.accounts.filter.showInactive')}
            name='includeInactive'
          >
            <Switch defaultChecked name='includeInactive' />
          </FormItem>
        </Row>
        <Row>
          <EmailSelect />
        </Row>
        <Row>
          <FirstNameSelect />
        </Row>
        <Row>
          <LastNameSelect />
        </Row>
      </FormWrapper>
    </Formik>
  );
};
