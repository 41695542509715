import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { AccountNodeConnection } from '@src/generated/schema';
import { Account } from '@src/graphql/Account';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';

export const noDriver = 'noDriver';

export const DriverSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ accounts: AccountNodeConnection }>(Account.accounts);
  const { accounts: { edges } = { edges: [] as any } } = data || {};

  const searchSelectOptions = edges.map(({ node: { firstName, lastName, id } }) => ({
    title: `${firstName} ${lastName}`,
    value: id,
  }));

  searchSelectOptions.push({ title: t('desktop.pages.general.filter.withoutDriver'), value: noDriver });

  return (
    <SearchSelect
      {...props}
      label={t('general.driver')}
      options={searchSelectOptions}
      name='driver'
      loading={loading}
    />
  );
};
