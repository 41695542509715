import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '@src/generated/schema/fragmentTypes.json';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { Preferences } from '@capacitor/preferences';
import { isPlatform } from '@ionic/react';
import { Environment } from '@src/global';
import { createUploadLink } from 'apollo-upload-client';

Environment();
export const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
export const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => object.id,
  cacheRedirects: {
    Query: {
      getCachedDriverOrder: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'OrderNode', id: args.id }),
    },
  },
});

export const uploadHttpLink = createUploadLink({ uri: `${window.env.LOGISTIK_BACKEND_URL}/graphql` });
export const httpLink = new HttpLink({ uri: `${window.env.LOGISTIK_BACKEND_URL}/graphql` });
export const authLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const { value } = await Preferences.get({ key: 'token' });
  const ionHeaders = { 'ion-platform': isPlatform('hybrid') ? 'app' : 'web' };

  // return the headers to the context so httpLink can read them
  if (value) {
    return { headers: { ...headers, ...ionHeaders, authorization: `JWT ${String(value)}` } };
  }

  return { headers: { ...headers, ...ionHeaders } };
});
