import styled from 'styled-components';
import { PilesSearch } from '@src/components/Elements/PilesSearch';

export const StyledCustomMapControls = styled.div`
  top: 10px;
  right: 10px;
  position: absolute;
  display: flex;
  z-index: 401; /** Leaflet-Pane Z-Index + 1 */
`;

export const StyledPilesSearch = styled(PilesSearch)`
  max-width: 350px;
`;
