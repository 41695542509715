import React from 'react';
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { Route, Switch } from 'react-router-dom';
import { List } from '@src/components/Mobile/Pages/Private/Orders/List';
import { Routes } from '@src/global';
import { Piles } from '@src/components/Mobile/Pages/Private/Piles/Piles';
import { AimOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TabbarIconStyle } from '@src/components/Mobile/Pages/Private/Home/Home.styles';

export const Home = () => {
  const { t } = useTranslation();
  const { ORDERS, PILES, HOME } = Routes;

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Route exact path={`${HOME}${ORDERS}`} component={List} />
          <Route exact path={`${HOME}${PILES}`} component={Piles} />
        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot='bottom'>
        <IonTabButton tab={`${HOME}${ORDERS}`} href={`${HOME}${ORDERS}`}>
          <UnorderedListOutlined style={TabbarIconStyle} />
          {t('mobile.navigation.home.orders')}
        </IonTabButton>
        <IonTabButton tab={`${HOME}${PILES}`} href={`${HOME}${PILES}`}>
          <AimOutlined style={TabbarIconStyle} />
          {t('mobile.navigation.home.piles')}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
