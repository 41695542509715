import React, { FC, useContext, useEffect, useState } from 'react';
import { APIError, Form as FormWrapper } from '@src/components/Elements/Wrapper/Form';
import { useMutation, useLazyQuery } from 'react-apollo';
import { Order } from '@src/graphql/Order';
import { PileNode, OrderNodeConnection } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import Moment from 'react-moment';
import { Tag, TagType } from '@src/components/Elements/Tag';
import { Alert, Row } from 'antd';
import { IonCol, IonSpinner, IonTitle } from '@ionic/react';
import { Input } from '@src/components/Desktop/Containers/Input';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import * as Yup from 'yup';

import { StyledButton, LoadingSpinnerIonRow, StyledTable } from '../../List/Table/Table.styles';
import { Pagination } from '@src/components/Desktop/Elements';
import { StyledAddPileToOrderHeading, StyledAddPileToOrderHeadingLabel } from './AddPileToOrder.styles';
import { Formik } from 'formik';
import { ColumnProps } from 'antd/lib/table/Column';
import { OrderStatus } from '@src/graphql/Order/Types';

export interface AddPileToOrderProps {
  closeModal: () => void;
  piles?: PileNode[];
}

export const AddPileToOrder: FC<AddPileToOrderProps> = ({ piles, closeModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [appendPilesToExistingOrder] = useMutation(Order.appendPilesToExistingOrder);
  const [rowWasClicked, setRowWasClicked] = useState(false);
  const [queryVariables, setQueryVariables] = useState<any>({
    first: 10,
  });
  const [page, setPage] = useState<number>(1);
  const [getPaginatedOrders, { loading, error, data, refetch }] = useLazyQuery<{ orders: OrderNodeConnection }>(
    Order.getPaginatedOrders,
    {
      variables: {
        activeOnly: true,
        addablePiles: piles?.map(pile => pile.id),
        ...queryVariables,
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setToast({
          type: TOAST_TYPES.ERROR,
          message: t('desktop.pages.orders.list.error.message'),
          description: t('desktop.pages.orders.list.error.description'),
        });
      },
    },
  );

  const pileIds = piles?.map(pile => pile.id);

  const onCellClicked = async (e, record, index) => {
    const orderId = data?.orders?.edges[index]?.node?.id;
    if (!rowWasClicked) {
      setRowWasClicked(true);
      await appendPilesToExistingOrder({
        variables: { orderId, pileIdList: pileIds },
      })
        .then(() => {
          setToast({
            type: TOAST_TYPES.SUCCESS,
            message: t('desktop.pages.orders.addToExisting.form.success.message'),
            description: t('desktop.pages.orders.addToExisting.form.success.description'),
          });
        })
        .catch(errors => {
          setToast({
            type: TOAST_TYPES.ERROR,
            message: t('desktop.pages.orders.list.error.message'),
            description: <APIError errors={errors} />,
          });
        })
        .finally(closeModal);
    } else {
      closeModal();
    }
  };

  const columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.orders.list.columns.number'),
      dataIndex: 'number',
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.customer'),
      dataIndex: 'customer.name',
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.supplier'),
      dataIndex: 'supplier.name',
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.status'),
      dataIndex: 'status',
      render: text => (
        <Tag
          type={text === OrderStatus.Created ? TagType.PRIMARY : TagType.DEFAULT}
          key={text}
          value={text}
          text={t(`general.status.${text}`)}
        />
      ),
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.driver'),
      dataIndex: 'driver.firstName',
      render: (text, record: any) => (
        <span>
          {text} {record.driver && record.driver.lastName}
        </span>
      ),
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.planDate'),
      dataIndex: 'planDate',
      render: text => text && <Moment format='DD.MM.YY'>{text}</Moment>,
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.vehicle'),
      dataIndex: ['vehicle', 'registrationNumber'],
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
  ];

  useEffect(() => {
    getPaginatedOrders();
  }, [queryVariables, getPaginatedOrders]);

  const { orders: { edges } = { edges: [] as any } } = data || {};
  const orders = edges.map(({ node }) => node);

  return (
    <>
      {error && (
        <Alert
          message={t('desktop.pages.orders.list.error.message')}
          description={t('desktop.pages.orders.list.error.description')}
          type='error'
          showIcon
        />
      )}
      {loading && (
        <LoadingSpinnerIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </LoadingSpinnerIonRow>
      )}
      <StyledAddPileToOrderHeading>
        <StyledAddPileToOrderHeadingLabel>
          {t('desktop.pages.orders.addToExisting.tableHint')}
        </StyledAddPileToOrderHeadingLabel>
        <Formik
          initialValues={{ orderNumber: '' }}
          validationSchema={Yup.object().shape({ orderNumber: Yup.number() })}
          initialErrors={{ orderNumber: '' }}
          onSubmit={values => {
            const { orderNumber } = values;
            setPage(1);
            setQueryVariables({
              first: 10,
              number_Contains: orderNumber ? orderNumber : null,
            });
          }}
        >
          <FormWrapper>
            <AutoSaveForm />
            <Row>
              <Input label={t('desktop.pages.orders.filter.orderNumber')} type='text' name='orderNumber' />
            </Row>
          </FormWrapper>
        </Formik>
      </StyledAddPileToOrderHeading>
      {data &&
        (edges && edges.length > 0 ? (
          <>
            <StyledTable
              columns={columns}
              dataSource={orders}
              rowKey='id'
              key={10}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.orders && data.orders.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}
            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.orders.list.noOrdersAvailable')}</IonTitle>
        ))}
    </>
  );
};
