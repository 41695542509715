// @ts-nocheck https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table

import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { FixedSizeList } from 'react-window';
import { Column, SortingRule, useBlockLayout, useRowSelect, useSortBy, useTable } from 'react-table';
import classNames from 'classnames';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { Checkbox } from 'antd';
import { PileNode } from '@src/generated/schema';
import { Styles } from './VirtualizedTable.styles';

interface IIndeterminateInputProps {
  indeterminate?: boolean;
  name: string;
}

// This Ref provides functionality for the selection of rows in the table. This is the recommended way by the react
// table library.
const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IIndeterminateInputProps>(
  ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = React.useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={combinedRef} {...rest} />
      </>
    );
  },
);

type Data = T & { id: string | number };

export interface VirtualizedTableProps {
  columns: Array<Column<PileNode>>;
  data: Data[];
  onCellClicked: (e: React.MouseEvent, record: Data, index: number) => void;
  onSortChange: (sorting: SortingRule<any>) => void;
  setSelectedRows: Dispatch<SetStateAction<Data[]>>;
}

export const VirtualizedTable = ({
  columns,
  data,
  setSelectedRows,
  onCellClicked,
  onSortChange,
}: VirtualizedTableProps) => {
  const skipPageResetRef = useRef({ current: true });

  React.useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  });

  useEffect(() => {
    skipPageResetRef.current = true;
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    totalColumnsWidth,
    state: { selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      getRowId: (row: Data, cg, c) => row.id.toString(),
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      manualSortBy: true,
    },
    useSortBy,
    useRowSelect,
    useBlockLayout,
    hooks => {
      hooks.visibleColumns.push(selectedColumns => [
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...selectedColumns,
      ]);
    },
  );

  useEffect(() => {
    if (sortBy.length > 0) {
      const sortObj = sortBy[0];
      onSortChange(sortObj);
    }
  }, [sortBy]);

  useEffect(() => {
    setSelectedRows(selectedFlatRows.map(row => row.original));
  }, [selectedRowIds]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map(cell => (
            <div
              key={cell}
              {...cell.getCellProps()}
              className={classNames(['edit', 'selection'].includes(cell.column.id) ? 'icon-td' : 'td', {
                isSelected: row.isSelected,
              })}
              {...cell.getCellProps()}
              onClick={event => {
                // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
                cell.column.id !== 'selection' &&
                  cell.column.id !== 'edit' &&
                  onCellClicked(event, cell.row.original, index);
              }}
            >
              {cell.render('Cell')}
            </div>
          ))}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds],
  );

  // Render the UI for your table
  return (
    <Styles>
      <div {...getTableProps()} className='table'>
        <div>
          {headerGroups.map((headerGroup, indexHeaderGroup) => (
            <div key={indexHeaderGroup} {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map((column, indexHeader) => (
                <div
                  key={indexHeader}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={['edit', 'selection'].includes(column.id) ? 'icon-th' : 'th'}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <IonIcon className={'icon'} mode='md' icon={arrowDownOutline} size='md' />
                    ) : (
                      <IonIcon className={'icon'} mode='md' icon={arrowUpOutline} size='md' />
                    )
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          <FixedSizeList height={800} itemCount={rows.length} itemSize={60} width={totalColumnsWidth + 20}>
            {RenderRow}
          </FixedSizeList>
        </div>
      </div>
    </Styles>
  );
};
