import gql from 'graphql-tag';
import { VehicleNodeFragment } from './Fragments';

export const vehicles = gql`
  query vehicles {
    vehicles {
      edges {
        node {
          ...vehicleNode
        }
      }
    }
  }
  ${VehicleNodeFragment}
`;
