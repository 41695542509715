import React, { FC, SyntheticEvent } from 'react';
import { StyledCustomControl } from '@src/components/Containers/Map/Map.styles';
import { IonIcon } from '@ionic/react';
import { albums, analyticsOutline, funnel } from 'ionicons/icons';

export enum POSITION_CLASSES {
  BOTTOM_LEFT = 'leaflet-bottom leaflet-left',
  BOTTOM_RIGHT = 'leaflet-bottom leaflet-right',
  TOP_LEFT = 'leaflet-top leaflet-left',
  TOP_RIGHT = 'leaflet-top leaflet-right',
}

export enum SCREEN_ORIENTATIONS {
  LANDSCAPE_PRIMARY = 'landscape-primary', // left
  LANDSCAPE_SECONDARY = 'landscape-secondary', // right
}

interface MapControlProps {
  clusterEnabled?: boolean;
  markerFilterIsActive?: boolean;
  onCenterLocation?: (event: SyntheticEvent) => void;
  onClusteringClick?: (event: SyntheticEvent) => void;
  onDownload?: (event: SyntheticEvent) => void;
  onRouteClick?: (event: SyntheticEvent) => void;
  onSettingsClick?: (event: SyntheticEvent) => void;
  onToggleMarkerFilter?: (event: SyntheticEvent) => void;
  position: POSITION_CLASSES;
}

export const MapControl: FC<MapControlProps> = ({
  children,
  position,
  onCenterLocation,
  onToggleMarkerFilter,
  onDownload,
  onSettingsClick,
  onClusteringClick,
  onRouteClick,
  markerFilterIsActive = false,
  clusterEnabled = false,
}) => (
  <div style={{ marginBottom: '5px' }} className={position}>
    <div className='leaflet-control leaflet-bar' style={{ border: 'unset' }}>
      {onCenterLocation && (
        <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
          <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={onCenterLocation}>
            <span className='fa-map-marker fa' />
          </a>
        </StyledCustomControl>
      )}

      {onToggleMarkerFilter && (
        <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
          <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={onToggleMarkerFilter}>
            <IonIcon slot='icon-only' icon={funnel} color={markerFilterIsActive ? 'primary' : ''} />
          </a>
        </StyledCustomControl>
      )}

      {onDownload && (
        <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
          <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={onDownload}>
            <span className='fa-download fa' />
          </a>
        </StyledCustomControl>
      )}

      {onSettingsClick && (
        <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
          <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={onSettingsClick}>
            <span className='fa-cog fa' />
          </a>
        </StyledCustomControl>
      )}

      {onClusteringClick && (
        <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
          <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={onClusteringClick}>
            <IonIcon slot='icon-only' icon={albums} color={!clusterEnabled ? '' : 'primary'} />
          </a>
        </StyledCustomControl>
      )}

      {onRouteClick && (
        <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
          <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={onRouteClick}>
            <IonIcon slot='icon-only' icon={analyticsOutline} color={'primary'} />
          </a>
        </StyledCustomControl>
      )}

      {children}
    </div>
  </div>
);
