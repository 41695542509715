import React, { FC } from 'react';
import { StyledPasswordPage, StyledPasswordPageVisual, StyledSubline } from './PasswordPage.styles';
import { Wrapper } from '@src/components/Elements/Wrapper';
import { Logo } from '@src/components/Elements/Logo';

export interface PasswordPageProps {
  header: string;
  subHeader: string;
}

export const PasswordPage: FC<PasswordPageProps> = ({ header, subHeader, children }) => (
  <StyledPasswordPage>
    <Wrapper>
      <h1>{header}</h1>
      <StyledSubline>{subHeader}</StyledSubline>
      {children}
    </Wrapper>
    <StyledPasswordPageVisual>
      <Wrapper>
        <Logo />
      </Wrapper>
    </StyledPasswordPageVisual>
  </StyledPasswordPage>
);
