import React, { FC } from 'react';
import { PileNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverTitle.styles';
import { closePopups } from '@src/helpers/DomManipulations';

export interface PopoverTitleProps {
  handleDeletePilesFromOrder: (pilesIds: string[]) => Promise<void>;
  piles: PileNode[];
}

export const PopoverTitle: FC<PopoverTitleProps> = ({ handleDeletePilesFromOrder, piles }) => {
  const { t } = useTranslation();

  const handleSetValues = () => {
    handleDeletePilesFromOrder(piles.map(pile => pile.id));
    closePopups();
  };

  return (
    <StyledPopover>
      <StyledPopoverOption onClick={handleSetValues}>
        {t('desktop.pages.orders.show.piles.popover.deleteMultiple')}
      </StyledPopoverOption>
    </StyledPopover>
  );
};
