import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form } from '../Form';
import { FormValues, PrefilledFormValues } from '../Form/Form';
import { useMutation, useQuery } from 'react-apollo';
import { Order } from '@src/graphql/Order';
import { OrderNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import moment from 'moment';
import { IonCol, IonSpinner, IonRow } from '@ionic/react';
import { UploadFile } from 'antd/es/upload/interface';
import { getDeliveryStatusSelectText } from '@src/components/Desktop/Containers/SearchSelects/DeliveryStatusSelect/DeliveryStatusSelect';

export interface DuplicateProps {
  orderIdToDuplicate?: string;
  toggleModal: () => void;
}

export const Duplicate: FC<DuplicateProps> = ({ orderIdToDuplicate, toggleModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [createOrder, { error }] = useMutation(Order.createOrder);

  const { data, loading } = useQuery<{ order: OrderNode }>(Order.getOrderDetail, {
    variables: { orderId: orderIdToDuplicate },
  });

  const previousFile: UploadFile | undefined = data?.order?.transportMedia
    ? {
        uid: data?.order?.transportMedia?.id || '',
        size: 1,
        type: 'application/pdf',
        name: 'Anfuhrauftrag_Kopie',
        fileName: 'Anfuhrauftrag_Kopie',
        url: data?.order?.transportMedia?.s3Path || '',
      }
    : undefined;

  const initialValues: FormValues = {
    amount: data?.order?.amount || undefined,
    deliveryStatus: data?.order?.deliveryStatus?.id || undefined,
    customer: data?.order?.customer?.id || undefined,
    transportMedia: previousFile,
    driver: data?.order?.driver?.id || undefined,
    note: data?.order?.note || undefined,
    planDate: data?.order?.planDate || moment(),
    repeat: data?.order?.repeat || false,
    isPlausible: data?.order?.isPlausible || false,
    repeatEndDate: data?.order?.repeatEndDate || undefined,
    supplier: data?.order?.supplier?.id || undefined,
    vehicle: data?.order?.vehicle?.id || undefined,
  };

  const prefilledValues: PrefilledFormValues = {
    customer: data?.order?.customer?.name || '',
    supplier: data?.order?.supplier?.name || '',
    deliveryStatus: getDeliveryStatusSelectText(data?.order?.deliveryStatus),
  };

  const pileEdges = data?.order?.piles?.edges || [];
  const piles = pileEdges?.map(pile => pile?.node?.id);

  const routeEdges = data?.order?.route?.edges || [];
  const routes = routeEdges?.map(route => route?.node?.id);

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: data_ } = await createOrder({
        variables: { ...variables, piles, routes },
        refetchQueries: ['orders'],
      });

      if (data_) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.orders.add.form.success.message'),
          description: t('desktop.pages.orders.add.form.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}
      {data && (
        <Wrapper>
          <Form
            prefilledValues={prefilledValues}
            initialOrderValues={initialValues}
            showSupplier={piles?.length === 0}
            onSubmit={onSubmit}
            error={error}
          />
        </Wrapper>
      )}
    </div>
  );
};
