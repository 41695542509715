import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form, FormValues } from '../Form';
import { useQuery, useMutation } from 'react-apollo';
import { PileNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { IonCol, IonSpinner, IonRow } from '@ionic/react';
import { Pile } from '@src/graphql/Pile';

export interface AlterProps {
  pile?: PileNode;
  toggleModal: () => void;
}

// eslint-disable-next-line complexity
export const Alter: FC<AlterProps> = ({ toggleModal, pile }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [changePile, { error: changeError }] = useMutation(Pile.changePile);
  const [deletePile, { error: deleteError, loading: deleteLoading }] = useMutation(Pile.deletePile);
  const { data, loading } = useQuery<{
    pile: PileNode;
  }>(Pile.getDetailPile, {
    variables: { pileId: pile?.id },
  });

  const initialValues: FormValues = {
    cbkNet: data?.pile?.cbkNet || undefined,
    cbkSolid: data?.pile?.cbkSolid || undefined,
    count: data?.pile?.count !== null && data?.pile?.count !== undefined ? data?.pile?.count : undefined,
    category: data?.pile?.category || undefined,
    date: data?.pile?.date ? new Date(data.pile.date) : undefined,
    district: data?.pile?.district || undefined,
    factorCbkNetCbkSolid: data?.pile?.factorCbkNetCbkSolid || '0.6',
    latitude: data?.pile?.latitude || undefined,
    location: data?.pile?.location || undefined,
    locationPlan: data?.pile?.locationPlan || undefined,
    logLength: data?.pile?.logLength || undefined,
    longitude: data?.pile?.longitude || undefined,
    markedAsBroached: data?.pile?.markedAsBroached || undefined,
    note: data?.pile?.note || undefined,
    number: data?.pile?.number,
    pileNumberFurnisher: data?.pile?.pileNumberFurnisher || undefined,
    plotNumber: data?.pile?.plot?.number || undefined,
    sort: data?.pile?.sort || undefined,
    status: data?.pile?.statusOldSystem || undefined,
    type: data?.pile?.type || undefined,
    woodtype: data?.pile?.woodtype || undefined,
    woodtype2: data?.pile?.woodtype2 || undefined,
    usageSort: data?.pile?.usageSort || undefined,
    amountUnit: data?.pile?.amountUnit || undefined,
    locationType: data?.pile?.locationType || undefined,
    qualityType: data?.pile?.qualityType || undefined,
    timber: data?.pile?.timber || undefined,
    mustBeAccepted: data?.pile?.mustBeAccepted || undefined,
  };

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: dataChange } = await changePile({
        variables: {
          ...variables,
          pileId: pile?.id,
        },
        refetchQueries: ['piles'],
      });

      if (dataChange) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.piles.alter.form.submit.success.message'),
          description: t('desktop.pages.piles.alter.form.submit.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  const handleDelete = async () => {
    try {
      const { data: dataDelete } = await deletePile({
        variables: {
          pileId: pile?.id,
        },
        refetchQueries: ['piles'],
      });
      if (dataDelete) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.piles.delete.form.submit.success.message'),
          description: t('desktop.pages.piles.delete.form.submit.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}
      {data && (
        <Wrapper>
          <Form
            initialFormValues={initialValues}
            onSubmit={onSubmit}
            onDelete={handleDelete}
            error={changeError || deleteError}
            deleteLoading={deleteLoading}
          />
        </Wrapper>
      )}
    </div>
  );
};
