import styled from 'styled-components';
import { StyledTable } from '../List/Table/Table.styles';


export const StyledOrderDetailTable = styled(StyledTable)`
  margin-top: 1.4rem;

  .ant-table-title {
      font-size: 16px;
      font-weight: bold;
  }
`;
