import React, { FC } from 'react';
import { Radio } from 'antd';
import { RadioGroupProps as AntdRadioGroupProps } from 'antd/lib/radio';
import { StyledHorizontalScrollRadioGroup } from '@src/components/Elements/HorizontalRadioGroup/HorizontalRadioGroup.styles';

export interface RadioButtonProps {
  text: string;
  value: string;
}

export interface RadioGroupProps extends AntdRadioGroupProps {
  buttonValues: RadioButtonProps[];
}

export const HorizontalRadioGroup: FC<RadioGroupProps> = ({ buttonValues, ...props }) => (
  <StyledHorizontalScrollRadioGroup {...props}>
    {buttonValues.map(buttonConf => (
      <Radio.Button key={buttonConf.value} value={buttonConf.value}>
        {buttonConf.text}
      </Radio.Button>
    ))}
  </StyledHorizontalScrollRadioGroup>
);
