import styled from 'styled-components';
import { MapContainer, Tooltip } from 'react-leaflet';
import { LeafletMapProps } from './Map';
import { Button as DesktopButton } from 'antd';
import { IonButton as MobileButton } from '@ionic/react';
import {BREAKPOINTS} from "@src/theme";

export const StyledMapContainer = styled(MapContainer)<LeafletMapProps>`
  &.leaflet-container {
    height:calc(100vh - 15rem) ;
    width: 100%;
    
    @media (max-width: ${BREAKPOINTS.large}px) {
      height: 100%;
    }

    img {
      /** Fixes small visible lines in the map **/
      width: 256.5px !important;
      height: 256.5px !important;
    
      &[src=''] {
        /** Fixes an error where tiles are highlighted as broken although they are present: simply hide them **/
        visibility: hidden;
      }
    }
  }

  &.landscape-primary {
    /** Top (and a possible display notch) of the device is left **/

    .leaflet-left {
      left: env(safe-area-inset-left);
    }
  }

  .dummy {
    margin-top: -${({ markerHeight }) => markerHeight}px !important;
    height: ${({ markerHeight }) => markerHeight} !important;
  }

  .leaflet-control-container {
    position: relative;
  }
  
  .leaflet-control-container .leaflet-routing-container-hide {
    display: none;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  font-size: 0.85em;
  padding: 0.1em;
  z-index: -1;
`;

export const StyledCustomControl = styled.div`
  &.leaflet-control-custom.leaflet-bar.leaflet-control {
    margin: 0;
    margin-bottom: 0.5em;
    font-size: 1.3em;
  }
`;

export const StyledClusteringButton = styled(DesktopButton)`
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 401; /** Leaflet-Pane Z-Index + 1 */
  font-size: 1.75em;
  height: auto;
  padding: 0.25em;
  border-radius: 0.3em;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const StyledErrorWrapper = styled.div`
  position: absolute;
  top: 0;
  padding: 0 5%;
  z-index: 500;
  bottom: 25%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledClusteringButtonMobile = styled(MobileButton)`
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 401; /** Leaflet-Pane Z-Index + 1 */
`;
