import React, { FC } from 'react';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyNode } from '@src/generated/schema/index';

export interface DetailProps {
  company?: CompanyNode;
}

export const Detail: FC<DetailProps> = ({ company }) => {
  const { t } = useTranslation();

  return (
    <>
      {company && (
        <Descriptions>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.name')}>{company.name}</Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.matchcode')}>
            {company.matchcode}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.note')}>{company.note}</Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.info')}>{company.info}</Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.street')}>
            {company?.billingAddress?.street}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.housenumber')}>
            {company?.billingAddress?.housenumber}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.postcode')}>
            {company?.billingAddress?.postcode}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.partOfVillage')}>
            {company?.billingAddress?.partOfVillage}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.companies.list.columns.country')}>
            {company?.billingAddress?.country}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
