import gql from 'graphql-tag';

export const createCompany = gql`
  mutation createCompany(
    $name: String
    $note: String
    $matchcode: String
    $customer: Boolean
    $carrier: Boolean
    $supplier: Boolean
    $information: String
    $billingAddressStreet: String
    $billingAddressHousenumber: String
    $billingAddressPostcode: String!
    $billingAddressPartOfVillage: String
    $billingAddressCountry: String
  ) {
    createCompany(
      name: $name
      note: $note
      matchcode: $matchcode
      customer: $customer
      carrier: $carrier
      supplier: $supplier
      information: $information
      billingAddressStreet: $billingAddressStreet
      billingAddressHousenumber: $billingAddressHousenumber
      billingAddressPostcode: $billingAddressPostcode
      billingAddressPartOfVillage: $billingAddressPartOfVillage
      billingAddressCountry: $billingAddressCountry
    ) {
      ok
    }
  }
`;

export const changeCompany = gql`
  mutation changeCompany(
    $companyId: ID!
    $name: String
    $note: String
    $matchcode: String
    $customer: Boolean
    $carrier: Boolean
    $supplier: Boolean
    $information: String
    $billingAddressStreet: String
    $billingAddressHousenumber: String
    $billingAddressPostcode: String!
    $billingAddressPartOfVillage: String
    $billingAddressCountry: String
  ) {
    changeCompany(
      companyId: $companyId
      name: $name
      note: $note
      matchcode: $matchcode
      customer: $customer
      carrier: $carrier
      supplier: $supplier
      information: $information
      billingAddressStreet: $billingAddressStreet
      billingAddressHousenumber: $billingAddressHousenumber
      billingAddressPostcode: $billingAddressPostcode
      billingAddressPartOfVillage: $billingAddressPartOfVillage
      billingAddressCountry: $billingAddressCountry
    ) {
      ok
    }
  }
`;
