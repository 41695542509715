import styled from 'styled-components';
import { IonButton, IonCol, IonGrid, IonInput, IonRow, IonSelect, IonText, IonToggle } from '@ionic/react';

export const StyledTitle = styled.h5`
  text-align: center;
  color: ${({ theme }) => theme.color.twine};
`;

export const StyledIonText = styled(IonText)`
  font-size: 15px;
`;

export const StyledIonTextError = styled(IonText)`
  display: block;
  font-size: 15px;
`;

export const StyledIonGrid = styled(IonGrid)`
  --ion-grid-padding: 0;
`;

export const StyledForm = styled.form`
  margin-top: 30px;
`;

export const StyledIonRow = styled(IonRow)`
  align-items: center;
`;

export const StyledIonRowLeft = styled(IonRow)`
  align-items: left;
`;

export const StyledIonButtonRow = styled(IonRow)`
  margin-top: 1em;
`;

export const StyledIonCol = styled(IonCol)`
  --ion-grid-column-padding: 0.6em;
`;

export const StyledIonColBroachedToggle = styled(IonCol)`
  display: flex;
  --ion-grid-column-padding: 0.6em;
`;

export const StyledIonToggle = styled(IonToggle)`
  padding-inline: 0;
`;

export const StyledIonInput = styled(IonInput)`
  --background: ${({ theme }) => theme.color.gallery};
  --padding-top: 0.2em;
  --padding-bottom: 0.2em;
  --padding-start: 0.5em;
  font-size: 15px;

  input:invalid {
    border: 1px solid #f04141;
  }
`;

export const StyledIonSelect = styled(IonSelect)`
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  font-size: 15px;
`;

export const StyledIonButton = styled(IonButton)`
  margin: 0;
  --padding-top: 0;
  --padding-end: 0.5em;
  --padding-bottom: 0;
  --padding-start: 0.5em;
`;

export const StyledIonButtonDelete = styled(IonButton)`
  margin: 0;
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
`;

export const StyledButton = styled(IonButton)`
  margin: 30px auto;
  max-width: 500px;
`;
