import React, { FC, useContext } from 'react';
import { useFormik } from 'formik';
import { Account } from '@src/graphql/Account';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Elements';
import { APIError, Form as StyledForm, Input } from '@src/components/Elements/Wrapper/Form';
import {
  MINIMUM_PASSWORD_LENGTH,
  TestIds,
  Routes,
  MAXIMUM_PASSWORD_LENGTH,
  MINIMUM_PASSWORD_STRENGTH,
} from '@src/global';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { isPlatform } from '@ionic/react';
import { ErrorMessage } from '@src/components/Pages/Private/PasswordChangePage/Form/Form.styles';

export const Form: FC = () => {
  const [changePassword, { error, data }] = useMutation(Account.changePassword);
  const { t } = useTranslation();
  const { push } = useHistory();
  const { setToast, setIonToast } = useContext(ToastContext);

  const backendValidationErrors = {
    oldPassword: data?.changePassword?.errors?.find(errorObject => errorObject.field === 'old_password')?.messages[0]
      ?.message,
    newPassword: data?.changePassword?.errors?.find(errorObject => errorObject.field === 'new_password1')?.messages[0]
      ?.message,
    newPasswordConfirm: data?.changePassword?.errors?.find(errorObject => errorObject.field === 'new_password2')
      ?.messages[0]?.message,
  };

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('pages.changePassword.input.oldPassword.errors.required')),
    newPassword: Yup.string()
      .required(t('pages.changePassword.input.newPassword.errors.required'))
      .min(MINIMUM_PASSWORD_LENGTH, t('pages.changePassword.input.newPassword.errors.length'))
      .max(MAXIMUM_PASSWORD_LENGTH, t('pages.changePassword.input.newPassword.errors.length'))
      .matches(MINIMUM_PASSWORD_STRENGTH, t('pages.changePassword.input.newPassword.errors.tooWeak')),
    newPasswordConfirm: Yup.string().when(['newPassword'], {
      is: val => val === '' || !val,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword')], t('pages.changePassword.input.newPasswordConfirm.errors.notEqual'))
        .notRequired(),
      otherwise: Yup.string()
        .oneOf([Yup.ref('newPassword')], t('pages.changePassword.input.newPasswordConfirm.errors.notEqual'))
        .required('form.input.required'),
    }),
  });

  const { handleSubmit, handleChange, values, isValid, errors, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    initialErrors: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    onSubmit: async () => {
      try {
        const { data: _data }: any = await changePassword({
          variables: {
            oldPassword: values.oldPassword,
            newPassword1: values.newPassword,
            newPassword2: values.newPasswordConfirm,
          },
        });

        if (_data?.changePassword?.ok) {
          push(Routes.ORDERS);
          isPlatform('hybrid')
            ? setIonToast({
                show: true,
                message: t('pages.changePassword.toast.success.message'),
              })
            : setToast({
                type: TOAST_TYPES.SUCCESS,
                message: t('pages.changePassword.toast.success.message'),
                description: t('pages.changePassword.toast.success.description'),
              });
        }
      } catch {
        return false;
      }
    },
  });

  return (
    <StyledForm onSubmit={handleSubmit} data-testid={TestIds.forms.PasswordChangePage}>
      <Input
        label={t('pages.changePassword.input.oldPassword.label')}
        onChange={handleChange}
        value={values.oldPassword}
        disabled={isSubmitting}
        type='password'
        name='oldPassword'
      />
      <ErrorMessage>{errors.oldPassword || backendValidationErrors.oldPassword}</ErrorMessage>
      <Input
        label={t('pages.changePassword.input.newPassword.label')}
        onChange={handleChange}
        value={values.newPassword}
        disabled={isSubmitting}
        type='password'
        name='newPassword'
      />
      <ErrorMessage>
        {(values.newPassword.length > 0 && errors.newPassword) || backendValidationErrors.newPassword}
      </ErrorMessage>
      <Input
        label={t('pages.changePassword.input.newPasswordConfirm.label')}
        onChange={handleChange}
        value={values.newPasswordConfirm}
        disabled={isSubmitting}
        type='password'
        name='newPasswordConfirm'
      />
      <ErrorMessage>
        {(values.newPasswordConfirm.length > 0 && errors.newPasswordConfirm) ||
          backendValidationErrors.newPasswordConfirm}
      </ErrorMessage>

      <Button disabled={!isValid || isSubmitting} type='submit' size='large'>
        {t('pages.changePassword.input.submitButton')}
      </Button>

      {error && <APIError errors={error} />}
    </StyledForm>
  );
};
