import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { PasswordPage } from '@src/components/Sections/PasswordPage';
import { Page } from '@src/components/Mobile/Sections/Page';
import { isPlatform } from '@ionic/react';

export const PasswordChangePage: FC = () => {
  const { t } = useTranslation();

  const passwordPage_ = (
    <PasswordPage header={t('pages.changePassword.header')} subHeader={t('pages.changePassword.subHeader')}>
      <Form />
    </PasswordPage>
  );

  return <>{isPlatform('hybrid') ? <Page>{passwordPage_}</Page> : passwordPage_}</>;
};
