import styled from 'styled-components';
import { Wrapper } from '@src/components/Elements';
import { ActiveInactiveProps } from './ActiveInactiveStatus';

export const StyledWrapper = styled(Wrapper)`
  padding: 1.2em 1em;
`;

export const StyledActiveInactiveStatus = styled.div<ActiveInactiveProps>`
  flex: 50%;
  background-color: ${({ theme, active }) => (active ? theme.color.emerald : theme.color.doveGray)};
`;

export const StyledStatus = styled.p`
  margin: 0;
  padding-left: 1.4em;
  position: relative;
  color: ${({ theme }) => theme.color.white};

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 5px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.borderRadius.rounded}%;
  }
`;
