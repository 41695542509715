import { companies, companyLocations, suppliers, getCompanyDetail } from './Queries';
import { createCompany, changeCompany } from './Mutations';

export const Company = {
  companies,
  suppliers,
  companyLocations,
  createCompany,
  changeCompany,
  getCompanyDetail,
};
