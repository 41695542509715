import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '@src/components/Providers';
import { PileNode } from '@src/generated/schema';
import {Input, InputRef} from 'antd';

const { Search } = Input;

export interface InputProps {
  className?: string;
  onSearch: (piles: PileNode[]) => void;
  piles: PileNode[];
  resetPilesSearch?: boolean;
}

export const PilesSearch: React.FC<InputProps> = ({ className, piles, onSearch, resetPilesSearch }) => {
  const { t } = useTranslation();
  const searchInputElement = useRef<InputRef>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeSearchValue, setActiveSearchValue] = useState<string>('');
  const { setIonToast } = useContext(ToastContext);

  /**
   * @description Resets the search input value to the active search value in case the user has modified the search
   * value but has not sent a the search query.
   */
  const handleBlur = (event: any): void => {
    // The blur event is fired when pressing the search icon before "handleSearch" was called.
    // In that case the search value should not bet reset.
    if (event?.relatedTarget?.classList?.contains('ant-input-search-button')) return;

    setSearchValue(activeSearchValue);
  };

  /**
   * @description Filters all piles by using the search value.
   */
  const handleSearch = (): void => {
    // Set the active search value so the input field can be reset to this, when leaving the search input.
    // This will indicate which search value is used to filter the piles.
    setActiveSearchValue(searchValue);

    const normalizedSearchValue = searchValue.toLowerCase();

    if (!searchValue) {
      onSearch(piles);
    }

    if (searchValue) {
      const result: PileNode[] = piles.filter((pile: PileNode) => {
        const { number, location, locationPlan, woodtype, sort } = pile;

        return (
          `${number}`.toLowerCase().includes(normalizedSearchValue) ||
          `${location}`.toLowerCase().includes(normalizedSearchValue) ||
          `${locationPlan}`.toLowerCase().includes(normalizedSearchValue) ||
          `${woodtype}`.toLowerCase().includes(normalizedSearchValue) ||
          `${sort}`.toLowerCase().includes(normalizedSearchValue)
        );
      });

      if (result.length === 0) {
        setIonToast({
          show: true,
          message: t('mobile.pages.orders.detail.map.toast.noSearchResult'),
        });
      }

      onSearch(result);
    }
  };

  /**
   * @description Leaves the input field whenever a search request was sent (activeSearchValue updates).
   * The main reason for this is to close the mobile keyboard after querying a search.
   */
  useEffect(() => {
    searchInputElement?.current?.blur();
  }, [activeSearchValue]);

  /**
   * @description Resets the search value when something outside the component changes.
   */
  useEffect(() => {
    setSearchValue('');
  }, [resetPilesSearch]);

  return (
    <Search
      className={className}
      enterButton
      onBlur={handleBlur}
      onChange={event => setSearchValue(event?.target?.value)}
      onPressEnter={handleSearch}
      onSearch={handleSearch}
      placeholder={t('general.search')}
      ref={searchInputElement}
      size='large'
      value={searchValue}
    />
  );
};
