import React, { FC, HTMLProps } from 'react';
import { StyledForm } from './Form.styles';

export const Form: FC<Omit<HTMLProps<HTMLFormElement>, 'ref' | 'type' | 'as'>> = ({ children, ...props }) => (
  <StyledForm {...props}>
    {children}

    {/* Workaround to submit forms by pressing enter and restoring the default form behavior */}
    <input style={{ visibility: 'hidden' }} type='submit' />
  </StyledForm>
);
