import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { PileNode, PileNodeConnection } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { FullPageLoadingWithDataWrapper, LoadingWithoutDataWrapper, StyledButton } from './Table.styles';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Tag, TagType } from '@src/components/Elements/Tag';
import {
  add,
  checkmark,
  documentOutline,
  documentsOutline,
  funnel,
  mapOutline,
  createOutline,
  trashOutline,
  cloudUploadOutline,
  codeDownloadOutline,
} from 'ionicons/icons';
import { Pagination } from '@src/components/Desktop/Elements';
import { Alert, Tooltip } from 'antd';
import { ModalData, ModalEnum } from '../List';
import {
  StyledTableHeaderActions,
  StyledResponsiveTableHeaderButton,
  StyledTableHeaderButton,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { FilterForm } from '../FilterForm/FilterForm';
import { DrawerButton } from '@src/components/Elements/DrawerButton';
import { useLazyQuery } from '@apollo/react-hooks';
import { Pile } from '@src/graphql/Pile';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { PileStatus } from '@src/graphql/Pile/Types';
import { VirtualizedTable } from '@src/components/Desktop/Containers/VirtualizedTable';
import { Column, SortingRule } from 'react-table';
import { SortOrder } from 'antd/es/table/interface';

interface TableProps extends AntdTableProps<any> {
  itemsPerPage: number;
  onItemClick?: (event: React.MouseEvent, row: PileNode, rowIndex: number) => void;
  openModal: (modalType: ModalEnum, modalData?: ModalData) => void;
  selectedRows: PileNode[];
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setSelectedRows: Dispatch<SetStateAction<PileNode[]>>;
  setShowPopover: ({ id }: { id: string }) => void;
  showPopover: { id: string };
}

export interface SortSettings {
  field: string;
  sortOrder: SortOrder;
}

export const Table: FC<TableProps> = ({
  itemsPerPage,
  selectedRows,
  setItemsPerPage,
  setSelectedRows,
  onItemClick,
  setShowPopover,
  openModal,
  showPopover,
  ...props
}) => {
  const [piles, setPiles] = useState<PileNode[]>([]);
  const { t, i18n } = useTranslation();
  const { userHasPermission, me } = useContext(MeContext);
  const { setToast } = useContext(ToastContext);
  const [sort, setSort] = useState<SortSettings | undefined>();
  const [queryVariables, setQueryVariables] = useState<any>({
    first: itemsPerPage,
  });
  const [page, setPage] = useState<number>(1);
  const [getPiles, { loading, error, data, refetch }] = useLazyQuery<{ piles: PileNodeConnection }>(
    Pile.getPaginatedPiles,
    {
      variables: {
        includeBroached: false,
        ...queryVariables,
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'none',
      onError: () => {
        setToast({
          type: TOAST_TYPES.ERROR,
          message: t('desktop.pages.piles.list.error.message'),
          description: t('desktop.pages.piles.list.error.description'),
        });
      },
    },
  );

  useEffect(() => {
    if (data) {
      const { piles: { edges } = { edges: [] as any } } = data || {};
      setPiles(edges.map(({ node }) => node));
    }
  }, [data]);

  useEffect(() => {
    setQueryVariables(currentQueryVariables => ({
      ...currentQueryVariables,
      first: itemsPerPage,
      before: undefined,
      last: undefined,
      after: undefined,
    }));
    setPage(1);
  }, [itemsPerPage, setQueryVariables, setPage]);

  useEffect(() => {
    getPiles();
  }, [queryVariables, getPiles]);

  useEffect(() => {
    if (!sort) {
      setQueryVariables(previous => ({ ...previous, orderBy: undefined }));
    } else {
      const sortIndicator = sort?.sortOrder === 'descend' ? '-' : '';

      setQueryVariables(previous => ({ ...previous, orderBy: `${sortIndicator}${sort?.field}` }));
    }
  }, [sort, setQueryVariables]);

  const onSortChange = (sorting: SortingRule<any>) => {
    setSort({ field: sorting.id, sortOrder: sorting.desc ? 'descend' : 'ascend' });
  };

  const onCellClicked = (e: React.MouseEvent, record: PileNode, index: number | undefined) => {
    if (onItemClick && index !== undefined) {
      onItemClick(e, record, index);
    }
  };

  useEffect(() => {
    const el = document;
    const event = document.createEvent('HTMLEvents');

    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  });

  const getTagType = (text: PileStatus | string) => {
    if (text === PileStatus.Disposable || text === PileStatus.InRemoval) return TagType.PRIMARY;
    if (text === PileStatus.Locked) return TagType.ERROR;

    return TagType.DEFAULT;
  };

  const columns = React.useMemo(
    () =>
      [
        {
          id: 'edit',
          accessor: pile => (
            <StyledTableHeaderButton
              size='large'
              type='link'
              onClick={() => openModal(ModalEnum.CHANGE, [pile])}
              disabled={!(userHasPermission(Permissions.CAN_CHANGE_PILE) && me?.client?.canChangePlotPile)}
            >
              <IonIcon icon={createOutline} />
            </StyledTableHeaderButton>
          ),
          disableSortBy: true,
          maxWidth: 50,
        },
        {
          id: 'plotNumber',
          Header: t('desktop.pages.piles.list.columns.plotNumber') as string,
          accessor: 'plot.number',
          disableSortBy: true,
          maxWidth: 90,
        },
        {
          id: 'number',
          Header: t('desktop.pages.piles.list.columns.number') as string,
          accessor: 'number',
          maxWidth: 90,
        },
        {
          id: 'date',
          Header: t('desktop.pages.piles.list.columns.date') as string,
          accessor: ({ date }) =>
            new Date(date).toLocaleDateString(i18n.language, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          maxWidth: 110,
        },
        {
          id: 'location',
          Header: t('desktop.pages.piles.list.columns.location') as string,
          accessor: 'location',
        },
        {
          id: 'district',
          Header: t('desktop.pages.piles.list.columns.district') as string,
          accessor: 'district',
          maxWidth: 70,
        },
        {
          id: 'status',
          Header: t('desktop.pages.piles.list.columns.status') as string,
          accessor: ({ status }) =>
            !!status && (
              <Tag
                type={getTagType(status)}
                key={status}
                value={status}
                text={t(`desktop.pages.piles.list.status.${status}`)}
              />
            ),
        },
        {
          id: 'woodtype',
          Header: t('desktop.pages.piles.list.columns.woodtype') as string,
          accessor: ({ woodtype }) => <span>{woodtype}</span>,
          maxWidth: 80,
        },
        {
          id: 'sort',
          Header: t('desktop.pages.piles.list.columns.sort') as string,
          accessor: 'sort',
          maxWidth: 90,
        },
        {
          id: 'logLength',
          Header: t('desktop.pages.piles.list.columns.logLength') as string,
          accessor: 'logLength',
          disableSortBy: true,
          maxWidth: 90,
        },
        {
          id: 'rest',
          Header: t('desktop.pages.piles.list.columns.rest') as string,
          accessor: 'rest',
          maxWidth: 90,
        },
        {
          id: 'cubikActual',
          Header: t('desktop.pages.piles.list.columns.cubikActual') as string,
          accessor: 'cubikActual',
          disableSortBy: true,
          maxWidth: 90,
        },
        {
          id: 'cubikSolidActual',
          Header: t('desktop.pages.piles.list.columns.cubikSolidActual') as string,
          accessor: 'cubikSolidActual',
          disableSortBy: true,
          maxWidth: i18n.language === 'de' ? 100 : 120,
        },
        {
          id: 'cbkNet',
          Header: t('desktop.pages.piles.list.columns.cbkNet') as string,
          accessor: 'cbkNet',
          disableSortBy: true,
          maxWidth: 100,
        },
        {
          id: 'cbkSolid',
          Header: t('desktop.pages.piles.list.columns.cbkSolid') as string,
          accessor: 'cbkSolid',
          disableSortBy: true,
          maxWidth: 100,
        },
        {
          id: 'category',
          Header: t('desktop.pages.piles.list.columns.category') as string,
          accessor: 'category',
          disableSortBy: true,
          maxWidth: 120,
        },
        {
          id: 'locationPlan',
          Header: t('desktop.pages.piles.list.columns.locationPlan') as string,
          accessor: 'locationPlan',
          disableSortBy: true,
        },
        {
          id: 'count',
          Header: t('desktop.pages.piles.list.columns.count') as string,
          accessor: 'count',
          disableSortBy: true,
          maxWidth: 90,
        },
        {
          id: 'countActual',
          Header: t('desktop.pages.piles.list.columns.countActual') as string,
          accessor: 'countActual',
          disableSortBy: true,
          maxWidth: 90,
        },
        {
          id: 'pileNumberFurnisher',
          Header: t('desktop.pages.piles.list.columns.pileNumberFurnisher') as string,
          accessor: 'pileNumberFurnisher',
          disableSortBy: true,
        },
        {
          id: 'isBroached',
          Header: t('desktop.pages.piles.list.columns.isBroached'),
          maxWidth: 90,
          accessor: ({ isBroached }) =>
            isBroached && (
              <>
                <IonIcon mode='md' icon={checkmark} size='small' />
              </>
            ),
          disableSortBy: true,
        },
      ] as Array<Column<PileNode>>,
    [t, i18n.language],
  );

  return (
    <>
      {error && !data && (
        <Alert
          message={t('desktop.pages.piles.list.error.message')}
          description={t('desktop.pages.piles.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <>
          {piles.length === 0 ? (
            <LoadingWithoutDataWrapper>
              <IonCol className='ion-text-center'>
                <IonSpinner name='crescent' />
              </IonCol>
            </LoadingWithoutDataWrapper>
          ) : (
            <FullPageLoadingWithDataWrapper>
              <IonCol className='ion-text-center'>
                <IonSpinner name='crescent' />
              </IonCol>
            </FullPageLoadingWithDataWrapper>
          )}
        </>
      )}

      <StyledTableHeaderActions>
        {
          <StyledResponsiveTableHeaderButton
            size='large'
            type='link'
            onClick={() => openModal(ModalEnum.MAP, selectedRows)}
            disabled={!selectedRows.length}
          >
            <Tooltip title={t('desktop.pages.piles.list.popover.showOnMap')}>
              <IonIcon icon={mapOutline} />
            </Tooltip>
            {t('desktop.pages.piles.list.popover.showOnMap')}
          </StyledResponsiveTableHeaderButton>
        }
        {me?.client?.canExportPiles && (
          <StyledResponsiveTableHeaderButton
            size='large'
            type='link'
            onClick={() => openModal(ModalEnum.EXPORT_PILES)}
            disabled={!selectedRows.length}
          >
            <Tooltip title={t('desktop.pages.piles.exportPiles.title')}>
              <IonIcon icon={codeDownloadOutline} />
            </Tooltip>
            {t('desktop.pages.piles.exportPiles.title')}
          </StyledResponsiveTableHeaderButton>
        )}
        {
          <StyledResponsiveTableHeaderButton
            size='large'
            type='link'
            onClick={() => openModal(ModalEnum.TRANSFER_TO_ORDER, selectedRows)}
            disabled={!selectedRows.length}
          >
            <Tooltip title={t('desktop.pages.piles.list.popover.createOrder')}>
              <IonIcon icon={documentOutline} />
            </Tooltip>
            {t('desktop.pages.piles.list.popover.createOrder')}
          </StyledResponsiveTableHeaderButton>
        }
        {userHasPermission(Permissions.CAN_ADD_PILES_TO_EXISTING_ORDER) && (
          <StyledResponsiveTableHeaderButton
            size='large'
            type='link'
            onClick={() => openModal(ModalEnum.ADD_TO_EXISTING, selectedRows)}
            disabled={!selectedRows.length}
          >
            <Tooltip title={t('desktop.pages.piles.list.popover.AddPileToOrder')}>
              <IonIcon icon={documentsOutline} />
            </Tooltip>
            {t('desktop.pages.piles.list.popover.AddPileToOrder')}
          </StyledResponsiveTableHeaderButton>
        )}
        <StyledResponsiveTableHeaderButton
          size='large'
          type='link'
          onClick={() => openModal(ModalEnum.DELETE, selectedRows)}
          disabled={!selectedRows.length}
        >
          <Tooltip title={t('desktop.pages.piles.list.popover.delete')}>
            <IonIcon icon={trashOutline} />
          </Tooltip>
          {t('desktop.pages.piles.list.popover.delete')}
        </StyledResponsiveTableHeaderButton>
        {userHasPermission(Permissions.CAN_ADD_PILE) && me?.client?.canChangePlotPile && (
          <StyledResponsiveTableHeaderButton size='large' type='link' onClick={() => openModal(ModalEnum.CREATE)}>
            <Tooltip title={t('desktop.pages.piles.list.createPile')}>
              <IonIcon icon={add} />
            </Tooltip>
            {t('desktop.pages.piles.list.createPile')}
          </StyledResponsiveTableHeaderButton>
        )}
        {userHasPermission(Permissions.CAN_ADD_PILE) && me?.client?.canChangePlotPile && (
          <StyledTableHeaderButton size='large' type='link' onClick={() => openModal(ModalEnum.IMPORT_PILES)}>
            <IonIcon icon={cloudUploadOutline} />
            {t('desktop.pages.piles.list.uploadPiles')}
          </StyledTableHeaderButton>
        )}
        <DrawerButton icon={funnel} buttonText={t('desktop.pages.general.filter.button')}>
          <FilterForm
            setQueryVariables={setQueryVariables}
            setSelectedRows={setSelectedRows}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
          />
        </DrawerButton>
      </StyledTableHeaderActions>
      <VirtualizedTable
        onCellClicked={onCellClicked}
        columns={columns}
        data={piles}
        setSelectedRows={setSelectedRows}
        onSortChange={onSortChange}
      />
      {data &&
        (data?.piles?.edges?.length > 0 ? (
          <>
            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.piles && data.piles.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.piles.list.noPilesAvailable')}</IonTitle>
        ))}
    </>
  );
};
