import gql from 'graphql-tag';
import { AccountMeNodeFragment, AccountNodeFragment, GroupNode } from './Fragments';

export const me = gql`
  query me {
    me {
      ...accountMeNode
    }
  }
  ${AccountMeNodeFragment}
`;

export const account = gql`
  query account($accountId: ID) {
    account(accountId: $accountId) {
      ...accountNode
    }
  }
  ${AccountNodeFragment}
`;

export const emails = gql`
  query emails($page: Int, $pageSize: Int, $filterValue: String) {
    emails(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const firstNames = gql`
  query firstNames($page: Int, $pageSize: Int, $filterValue: String) {
    firstNames(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const lastNames = gql`
  query lastNames($page: Int, $pageSize: Int, $filterValue: String) {
    lastNames(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const accounts = gql`
  query accounts(
    $email: String
    $firstName: String
    $lastName: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $includeInactive: Boolean
  ) {
    accounts(
      email: $email
      firstName: $firstName
      lastName: $lastName
      after: $after
      before: $before
      first: $first
      last: $last
      includeInactive: $includeInactive
    ) {
      edges {
        cursor
        node {
          ...accountNode
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${AccountNodeFragment}
`;

export const groups = gql`
  query groups($after: String, $before: String, $first: Int, $last: Int) {
    groups(after: $after, before: $before, first: $first, last: $last) {
      edges {
        cursor
        node {
          ...groupNode
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GroupNode}
`;

export const timeSheets = gql`
  query timeSheets($user: ID, $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
    timeSheets(user: $user, before: $before, offset: $offset, after: $after, first: $first, last: $last) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          checkInCreatedAt
          checkInDispatchedAt
          checkOutCreatedAt
          checkOutDispatchedAt
          createdBy {
            firstName
            lastName
          }
          lastmodifiedBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
