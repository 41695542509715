import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TestIds } from '@src/global';
import { Container, Wrapper } from '@src/components/Elements';
import { PageHeadline } from '@src/components/Elements/PageHeadline';
import { ItemsPerPagePicker } from '@src/components/Desktop/Containers/ItemsPerPagePicker';

import { Table } from './Table';
import { StyledList } from './List.styles';
import { Show } from '@src/components/Desktop/Pages/Private/Plots/Show';
import { Modal, TabComponentsType } from '@src/components/Elements/Modal/Modal';
import { closePopups } from '@src/helpers/DomManipulations';
import { PlotNode } from '@src/generated/schema';
import { Add as PlotAdd } from '../Edit/Add';
import { Alter as PlotAlt } from '../Edit/Alter';
import { PlotPilesMap } from '../Show/PlotPilesMap';
export enum ModalEnum {
  SHOW = 'show',
  CREATE = 'create',
  CHANGE = 'change',
  MAP = 'map',
}

const modalTitles = {
  [ModalEnum.SHOW]: 'desktop.pages.plots.show.title',
  [ModalEnum.CREATE]: 'desktop.pages.plots.create.title',
  [ModalEnum.CHANGE]: 'desktop.pages.plots.change.title',
  [ModalEnum.MAP]: 'desktop.pages.piles.list.mapModal.title',
};

export const List: FC = () => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState({ id: '' });
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [showModal, setShowModal] = useState<string>('');
  const [clickedPlot, setClickedPlot] = useState<PlotNode | undefined>(undefined);

  const closeModal = () => {
    setShowModal('');
    setClickedPlot(undefined);
  };

  const openModal = (modalType?: ModalEnum, plotNode?: any) => {
    closePopups();
    setShowModal(modalType || '');
    setClickedPlot(plotNode);
  };

  const toggleModal: () => void = (modalType?: ModalEnum, plotNode?: any) => {
    if (showModal) {
      closeModal();
    } else {
      openModal(modalType, plotNode);
    }
  };

  const onItemClicked = (e, plot) => {
    openModal(ModalEnum.SHOW, plot);
  };

  const makeModal = (tabComponents: TabComponentsType) => (
    <Modal
      title={clickedPlot ? `${t(modalTitles[showModal])}: ${clickedPlot?.number}` : t(modalTitles[showModal])}
      visible={!!showModal}
      onCancel={toggleModal}
      tabComponents={tabComponents}
    />
  );

  return (
    <div data-testid={TestIds.pages.piles.list.identifier}>
      {showModal === ModalEnum.SHOW &&
        makeModal([
          { component: <Show plot={clickedPlot} />, tabName: t(modalTitles[ModalEnum.SHOW]) },
          {
            component: <PlotPilesMap plot={clickedPlot} />,
            tabName: t(modalTitles.map),
            isMap: true,
          },
        ])}
      {showModal === ModalEnum.CREATE &&
        makeModal([
          {
            component: <PlotAdd closeModal={closeModal} />,
            tabName: t(modalTitles[ModalEnum.CREATE]),
          },
        ])}
      {showModal === ModalEnum.CHANGE &&
        makeModal([
          {
            component: <PlotAlt toggleModal={closeModal} plot={clickedPlot} />,
            tabName: t(modalTitles[ModalEnum.CHANGE]),
          },
        ])}

      <Container>
        <Wrapper>
          <PageHeadline>{t('desktop.pages.plots.headline')}</PageHeadline>
          <ItemsPerPagePicker setItemsPerPage={setItemsPerPage} itemsPerPage={itemsPerPage} />
          <StyledList>
            <Table
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setShowPopover={setShowPopover}
              showPopover={showPopover}
              toggleModal={toggleModal}
              onItemClick={onItemClicked}
            />
          </StyledList>
        </Wrapper>
      </Container>
    </div>
  );
};
