import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverRow.styles';
import { closePopups } from '@src/helpers/DomManipulations';
import { ModalEnum } from '../List';
import { AccountNode } from '@src/generated/schema';
import { MeContext, Permissions } from '@src/components/Providers';

export interface PopoverRowProps {
  toggleModal: (modalType: ModalEnum, user: AccountNode) => void;
  user: AccountNode;
}

export const PopoverRow: FC<PopoverRowProps> = ({ toggleModal, user }) => {
  const { t } = useTranslation();
  const { userHasPermission } = useContext(MeContext);

  return (
    <StyledPopover>
      <StyledPopoverOption
        onClick={() => {
          closePopups();
          toggleModal(ModalEnum.SHOW, user);
        }}
      >
        {t('desktop.pages.accounts.list.popover.show')}
      </StyledPopoverOption>
      {userHasPermission(Permissions.CAN_CHANGE_ACCOUNT) && (
        <StyledPopoverOption onClick={() => toggleModal(ModalEnum.ALTER, user)}>
          {t('desktop.pages.accounts.list.popover.alter')}
        </StyledPopoverOption>
      )}
    </StyledPopover>
  );
};
