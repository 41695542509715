import styled from 'styled-components';
import { StyledIonItem } from './Input';
import { StyledError } from './APIError';

export const StyledForm = styled.form`
  ${StyledIonItem} {
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }

  ion-button {
    margin-top: 1.6em !important;
  }

  ${StyledError} {
    margin-top: 1.4em;
  }
`;
