import styled from 'styled-components';

export const Styles = styled.div`
  padding: 1rem;
  overflow: auto;

  .table {
    display: inline-block;
    border-spacing: 0;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;

    .th {
      background-color: #fafafa;
      width: 100%;
      white-space: nowrap;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }

      :hover {
        background-color: #fff9f0;
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #e8e8e8;
      border-right: none;
      text-align: left;

      width: 1%;

      :last-child {
        border-right: none;
      }
    }

    .isSelected {
      background-color: #fafafa;
    }

    .icon {
      margin-left: 0.5rem;
    }

    .icon-td {
      width: 40px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .icon-th {
      width: 40px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;
    }
  }
`;
