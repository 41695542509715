import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { GroupNodeConnection } from '@src/generated/schema';
import debounce from 'lodash.debounce';
import { MultiSelect, MultiSelectProps } from '@src/components/Elements/MultiSelect/MultiSelect';
import { Account } from '@src/graphql/Account';

export const noName = 'noName';

export const GroupsSelect: FC<Pick<MultiSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ groups: GroupNodeConnection }>(Account.groups, {
    variables: { first: 10 },
    fetchPolicy: 'network-only',
  });
  const { groups: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 10,
        name_Icontains: value,
      });
    }, 100),
    [],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.groups.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newEdges = fetchMoreResult.groups.edges;
          const pageInfo = fetchMoreResult.groups.pageInfo;

          return newEdges.length
            ? {
                groups: {
                  pageInfo,
                  __typename: prev.groups.__typename,
                  edges: [...prev.groups.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <MultiSelect
      {...props}
      label={t('desktop.pages.accounts.field.groups')}
      options={edges.map(({ node: { name } }) => ({
        title: name || t('desktop.pages.accounts.field.withoutName'),
        value: name || noName,
      }))}
      name='groups'
      loading={loading}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
    />
  );
};
