import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { PlotNode, PlotNodeConnection } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { StyledButton, StyledIonRow, StyledTable } from './Table.styles';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Pagination } from '@src/components/Desktop/Elements';
import { Alert, Popover } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { Plot } from '@src/graphql/Plot';
import { Tag, TagType } from '@src/components/Elements/Tag';
import { add, ellipsisVertical, funnel } from 'ionicons/icons';
import { ColumnProps } from 'antd/lib/table/Column';
import {
  StyledTableHeaderActions,
  StyledTableHeaderButton,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { DrawerButton } from '@src/components/Elements/DrawerButton';
import { FilterForm } from '../FilterForm';
import { ModalEnum } from '@src/components/Desktop/Pages/Private/Plots/List/List';
import { PopoverRow } from '@src/components/Desktop/Pages/Private/Plots/List/PopoverRow';
import { addRowClickHandler } from '@src/helpers/Table';

interface TableProps extends AntdTableProps<any> {
  itemsPerPage: number;
  onItemClick?: (event: React.MouseEvent, row: PlotNode, rowIndex: number) => void;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setShowPopover: ({ id }: { id: string }) => void;
  showPopover: { id: string };
  toggleModal: (modalType: ModalEnum, plot?: PlotNode) => void;
}

enum PlotStatus {
  BLOCKED = 'blocked',
  DEPARTED = 'departed',
  IN_REMOVAL = 'in_removal',
  DISPOSABLE = 'disposable',
}

export const Table: FC<TableProps> = ({
  onItemClick,
  itemsPerPage,
  setItemsPerPage,
  setShowPopover,
  showPopover,
  toggleModal,
  ...props
}) => {
  const { t } = useTranslation();
  const { userHasPermission, me } = useContext(MeContext);

  const { setToast } = useContext(ToastContext);
  const [queryVariables, setQueryVariables] = useState<any>({
    first: itemsPerPage,
  });
  const [page, setPage] = useState<number>(1);
  const [getPlots, { loading, error, data, refetch }] = useLazyQuery<{ plots: PlotNodeConnection }>(Plot.plots, {
    variables: { activeOnly: true, ...queryVariables },
    errorPolicy: 'none',
    onError: () => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.piles.list.error.message'),
        description: t('desktop.pages.piles.list.error.description'),
      });
    },
  });
  const { plots: { edges } = { edges: [] as any } } = data || {};
  const piles = edges.map(({ node }) => node);

  useEffect(() => {
    setQueryVariables(currentQueryVariables => ({ ...currentQueryVariables, first: itemsPerPage }));
    setPage(1);
  }, [itemsPerPage, setQueryVariables, setPage]);

  useEffect(() => {
    getPlots();
  }, [queryVariables, getPlots]);

  useEffect(() => {
    const el = document;
    const event = document.createEvent('HTMLEvents');

    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  });

  const onCellClicked = (e: React.MouseEvent, record: PlotNode, index?: number | null) => {
    if (onItemClick && index !== null && index !== undefined) {
      onItemClick(e, record, index);
    }
  };

  let columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.plots.list.columns.number'),
      dataIndex: 'number',
    },
    {
      title: t('desktop.pages.plots.list.columns.company'),
      dataIndex: ['company', 'name'],
    },
    {
      title: t('desktop.pages.plots.list.columns.selection'),
      dataIndex: 'strSelection',
      render: text => text || '-',
    },
    {
      title: t('desktop.pages.plots.list.columns.numberOfPiles'),
      dataIndex: 'numberOfPiles',
    },
    {
      title: t('desktop.pages.plots.list.columns.numberOfBroachedPiles'),
      dataIndex: 'numberOfBroachedPiles',
    },
    {
      title: t('desktop.pages.plots.list.columns.sumCbkNet'),
      dataIndex: 'sumCbkNet',
    },
    {
      title: t('desktop.pages.plots.list.columns.sumCbkSolid'),
      dataIndex: 'sumCbkSolid',
    },
    {
      title: t('desktop.pages.plots.list.columns.sumCubikActual'),
      dataIndex: 'sumCubikActual',
    },
    {
      title: t('desktop.pages.plots.list.columns.sumCubikSolidActual'),
      dataIndex: 'sumCubikSolidActual',
    },
    {
      title: t('desktop.pages.plots.list.columns.sumCount'),
      dataIndex: 'sumCount',
    },
    {
      title: t('desktop.pages.plots.list.columns.sumCountActual'),
      dataIndex: 'sumCountActual',
    },
    {
      title: t('desktop.pages.plots.list.columns.status'),
      dataIndex: 'status',
      render: text => (
        <Tag
          type={text === PlotStatus.BLOCKED || text === PlotStatus.DEPARTED ? TagType.DEFAULT : TagType.PRIMARY}
          key={text}
          value={text}
          text={t(`desktop.pages.plots.list.status.${text}`)}
        />
      ),
    },
    {
      title: () => <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />,
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record) => (
        <Popover
          content={<PopoverRow toggleModal={toggleModal} plot={record} />}
          placement='leftTop'
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  // add onCell click handler to each column except the action column
  columns = addRowClickHandler(columns, onCellClicked);

  return (
    <>
      {error && !data && (
        <Alert
          message={t('desktop.pages.piles.list.error.message')}
          description={t('desktop.pages.piles.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <StyledIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </StyledIonRow>
      )}

      <StyledTableHeaderActions>
        {userHasPermission(Permissions.CAN_ADD_PLOT) && me?.client?.canChangePlotPile && (
          <StyledTableHeaderButton size='large' type='link' onClick={() => toggleModal(ModalEnum.CREATE)}>
            <IonIcon icon={add} />
            {t('desktop.pages.plots.list.createPlot')}
          </StyledTableHeaderButton>
        )}
        <DrawerButton icon={funnel} buttonText={t('desktop.pages.general.filter.button')}>
          <FilterForm setQueryVariables={setQueryVariables} setPage={setPage} itemsPerPage={itemsPerPage} />
        </DrawerButton>
      </StyledTableHeaderActions>

      {data &&
        (edges?.length > 0 ? (
          <>
            <StyledTable
              {...props}
              columns={columns}
              dataSource={piles}
              rowKey='id'
              pagination={false}
              scroll={{ x: 'max-content' }}
            />

            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.plots && data.plots.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.plots.list.noPlotsAvailable')}</IonTitle>
        ))}
    </>
  );
};
