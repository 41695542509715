export enum OrderStatus {
  Created = 'CREATED',
  Allocated = 'ALLOCATED',
  Transmitted = 'TRANSMITTED',
  Commenced = 'COMMENCED',
  Loaded = 'LOADED',
  Delivered = 'DELIVERED',
  Finished = 'FINISHED',
  Aborted = 'ABORTED',
}
