import React, { FC, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PileNode, PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { MobileSearchSelect } from '@src/components/Mobile/Elements/SearchSelect';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { MobileSelectProps } from '@src/components/Mobile/Elements/SearchSelect/SearchSelect';
import { getUniqueList } from '@src/helpers/Logic/Logic';

export interface MobilePileTypeSelectProps extends Omit<MobileSelectProps, 'options'> {
  piles?: PileNode[];
}

export const MobilePileTypeSelect: FC<MobilePileTypeSelectProps> = ({ piles, ...props }) => {
  const { t } = useTranslation();
  const { isOnline } = useContext(NetworkContext);
  const { data, loading, fetchMore, refetch } = useQuery<{ pileTypes: PileNodeConnection }>(Pile.getPileTypes, {
    variables: {
      first: 20,
    },
    fetchPolicy: 'network-only',
  });
  const { pileTypes: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 20,
        searchString: value,
      });
    }, 500),
    [],
  );

  const handlePopupScroll = () => {
    fetchMore({
      variables: {
        after: data?.pileTypes.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newEdges = fetchMoreResult.pileTypes.edges;
        const pageInfo = fetchMoreResult.pileTypes.pageInfo;

        return newEdges.length
          ? {
              pileTypes: {
                pageInfo,
                __typename: prev.pileTypes.__typename,
                edges: [...prev.pileTypes.edges, ...newEdges],
              },
            }
          : prev;
      },
    });
  };

  return (
    <MobileSearchSelect
      {...props}
      options={
        isOnline
          ? edges.map(({ node: { type, id } }) => ({
              id,
              title: t(`general.pile.type.${type}`),
              value: type,
            }))
          : getUniqueList(piles?.map(p => p.type)).map(type => ({
              id: type,
              value: type,
              title: t(`general.pile.type.${type}`),
            }))
      }
      loading={loading}
      multi
      onSearch={handleSearch}
      onLoadMore={handlePopupScroll}
      allowNoSelection
    />
  );
};
