import styled from 'styled-components';
import { StyledLogo } from '@src/components/Elements/Logo';
import { StyledWrapper } from '@src/components/Elements';
import { IonHeader } from '@ionic/react';
import { transparentize } from 'polished';

export const StyledHeader = styled(IonHeader)`
  ${StyledLogo} {
    width: 172px;
  }

  ${StyledWrapper} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    .navigation-links {
      flex: 2;
    }
  }

  &::after {
    background-image: none !important;
    box-shadow: 0 0 20px 5px ${({ theme }) => transparentize(0.4, theme.color.alto)};
  }
`;

export const StyledLeft = styled.div`
  flex: 4;
`;

export const StyledUserDropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 4;
`;
