import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { VehicleNodeConnection } from '@src/generated/schema';
import { Vehicle } from '@src/graphql/Vehicle';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';

export const noVehicle = 'noVehicle';

export const VehicleSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ vehicles: VehicleNodeConnection }>(Vehicle.vehicles);
  const { vehicles: { edges } = { edges: [] as any } } = data || {};

  const searchSelectOptions = edges.map(({ node: { registrationNumber, id } }) => ({
    title: registrationNumber,
    value: id,
  }));

  searchSelectOptions.push({ title: t('desktop.pages.general.filter.withoutVehicle'), value: noVehicle });

  return (
    <SearchSelect
      {...props}
      label={t('general.vehicle')}
      options={searchSelectOptions}
      name='vehicle'
      loading={loading}
    />
  );
};
