import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Wrapper } from '@src/components/Elements';

export const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <p>{t('pages.response.http.notFound')}</p>
      </Wrapper>
    </Container>
  );
};
