import React, { createContext, FC } from 'react';
import { ApolloProvider as ApolloClientProvider } from '@apollo/react-hooks';
import { authLink, cache, uploadHttpLink } from '@src/global';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
interface ApolloContextProps {
  client?: ApolloClient<any>;
}

export const ApolloContext = createContext<ApolloContextProps>({});

export const ApolloProvider: FC = ({ children }) => {
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, uploadHttpLink]),
  });

  return <ApolloClientProvider client={client}>{children}</ApolloClientProvider>;
};
