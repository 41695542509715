import React from 'react';
import { IonCol, IonGrid, IonItem, IonLabel, IonListHeader, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { InventorySummaryNode, Maybe } from '@src/generated/schema';
import { StyledList } from '@src/components/Mobile/Pages/Private/Orders/Detail/Detail.styles';

export interface InventorySummaryProps {
  // disable linting here because two rules are contradicting
  // eslint-disable-next-line no-array-constructor, @typescript-eslint/no-array-constructor
  inventorySummary: Array<Maybe<InventorySummaryNode>>;
}

export const InventorySummary: React.FC<InventorySummaryProps> = ({ inventorySummary }) => {
  const { t } = useTranslation();

  return (
    <StyledList>
      <IonListHeader mode='md'>
        <IonGrid>
          <IonRow>
            <IonCol size='3'>
              <IonLabel>{t('mobile.pages.orders.detail.inventorySummaryList.number')}</IonLabel>
            </IonCol>
            <IonCol size='3'>
              <IonLabel>{t('mobile.pages.orders.detail.inventorySummaryList.length')}</IonLabel>
            </IonCol>
            <IonCol size='2'>
              <IonLabel>{t('mobile.pages.orders.detail.inventorySummaryList.width')}</IonLabel>
            </IonCol>
            <IonCol size='2'>
              <IonLabel>{t('mobile.pages.orders.detail.inventorySummaryList.height')}</IonLabel>
            </IonCol>
            <IonCol size='2'>
              <IonLabel>{t('mobile.pages.orders.detail.inventorySummaryList.amount')}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonListHeader>

      {inventorySummary?.map((summaryEntry, index) => (
        <IonItem key={index.toString()}>
          <IonGrid>
            <IonRow>
              <IonCol size='3'>
                <IonLabel>{summaryEntry?.stackNumber}</IonLabel>
              </IonCol>

              <IonCol size='3'>
                <IonLabel>{summaryEntry?.woodLength}</IonLabel>
              </IonCol>

              <IonCol size='2'>
                <IonLabel>{summaryEntry?.stackWidth}</IonLabel>
              </IonCol>

              <IonCol size='2'>
                <IonLabel>{summaryEntry?.combinedHeight}</IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel>{summaryEntry?.totalAmount}</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      ))}
    </StyledList>
  );
};
