import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form } from '../Form';
import { FormValues } from '../Form/Form';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { Pile } from '@src/graphql/Pile';

export interface AddProps {
  closeModal: () => void;
}

export const Add: FC<AddProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [createPile, { error }] = useMutation(Pile.createPile);

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: data_ } = await createPile({
        variables,
        refetchQueries: ['piles'],
      });

      if (data_) {
        closeModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.piles.add.form.success.message'),
          description: t('desktop.pages.piles.add.form.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div>
      <Wrapper>
        <Form onSubmit={onSubmit} error={error} />
      </Wrapper>
    </div>
  );
};
