import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Input } from '@src/components/Desktop/Containers/Input';
import { Form as FormWrapper, Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { Column } from '@src/components/Desktop/Elements/Wrapper/Form/Column';
import { isFieldRequired } from '@src/helpers';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';
import { CategoryTypeChoice } from '@src/generated/schema';
import { SearchSelect } from '@src/components/Elements/SearchSelect';
import { StyledCheckbox, StylesSubmitButton } from './Form.styles';
import { CompanySelect } from '@src/components/Desktop/Containers/SearchSelects';
import { Button } from 'antd';

export interface FormValues {
  category: string | undefined;
  customer: string | undefined;
  done: boolean;
  mustBeRemovedSeparately: boolean;
  number: number | undefined;
}

export interface FormProps {
  deleteLoading?: boolean;
  error?: ApolloError;
  initialFormValues?: FormValues;
  onDelete?: () => Promise<false | undefined>;
  onSubmit: (variables: FormValues) => Promise<false | undefined>;
  prefilledValues?: PrefilledFormValues;
}

export interface PrefilledFormValues {
  customer: string;
}

export const Form: FC<FormProps> = ({
  error,
  prefilledValues,
  initialFormValues,
  onSubmit,
  onDelete,
  deleteLoading,
}) => {
  const { t } = useTranslation();

  const initialValues: FormValues = initialFormValues || {
    number: undefined,
    customer: undefined,
    category: undefined,
    mustBeRemovedSeparately: false,
    done: false,
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';
  const validationSchema = Yup.object().shape({
    number: Yup.number().required(t(i18nFormFieldRequired, { field: t('general.plot.number') })),
    customer: Yup.string(),
    category: Yup.mixed<CategoryTypeChoice>()
      .required(t(i18nFormFieldRequired, { field: t('general.plot.category.title') }))
      .oneOf(Object.values(CategoryTypeChoice)),
    mustBeRemovedSeparately: Yup.boolean(),
    done: Yup.boolean(),
  });

  const handleSubmit = async (values: FormValues) => onSubmit(values);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      validateOnBlur
    >
      {({ isValid }) => (
        <FormWrapper>
          <h3>{t('desktop.pages.plots.add.form.title.general')}</h3>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.plot.number')}
                type='number'
                name='number'
                required={isFieldRequired('number', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <SearchSelect
                name='category'
                required={isFieldRequired('category', validationSchema)}
                options={Object.values(CategoryTypeChoice).map(category => ({
                  value: category,
                  title: t(`general.plot.category.${category}`),
                }))}
                label={t('general.plot.category.title')}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <CompanySelect
                required={isFieldRequired('customer', validationSchema)}
                prefilledValue={prefilledValues?.customer}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <StyledCheckbox name='mustBeRemovedSeparately'>
                {t('general.plot.mustBeRemovedSeparately')}
              </StyledCheckbox>
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <StyledCheckbox name='done'>{t('general.plot.done')}</StyledCheckbox>
            </Column>
          </Row>

          <Row justify='space-between'>
            <StylesSubmitButton type='primary' disabled={!isValid}>
              {t('desktop.pages.accounts.form.submit')}
            </StylesSubmitButton>
            <Button loading={deleteLoading} onClick={onDelete} type='primary' danger disabled={false}>
              {t('desktop.pages.plots.delete.form.delete')}
            </Button>
          </Row>
          {error && <APIError errors={error} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
