import React, { FC, useContext } from 'react';
import { LocationContext } from '@src/components/Mobile/Providers';
import { StyledSVG } from '@src/components/Containers/Map/Marker/Marker.styles';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { DefaultMarkerHeight } from '@src/global/Map';
import { renderToString } from 'react-dom/server';

interface MarkerProps {
  height: number;
}

const LocationMarkerIcon: FC<MarkerProps> = ({ height }: { height: number }) => (
  <StyledSVG height={`${height}px`} viewBox='0 0 1280.000000 1275.000000' xmlSpace='preserve' x={0} y={0}>
    <g transform='translate(0.000000,1275.000000) scale(0.100000,-0.100000)' fill='#0000ff' stroke='none'>
      <path
        d='M6125 12744 c-165 -11 -425 -36 -545 -54 -1199 -179 -2339 -719
          -3322 -1577 -158 -137 -492 -472 -635 -634 -408 -466 -732 -951 -998 -1494
          -130 -265 -204 -441 -287 -680 -450 -1292 -450 -2568 0 -3860 83 -239 157
          -415 287 -680 266 -543 590 -1028 998 -1494 143 -162 477 -497 635 -634 1071
          -934 2309 -1484 3632 -1614 243 -24 777 -24 1020 0 1323 130 2561 680 3632
          1614 158 137 492 472 635 634 408 466 732 951 998 1494 130 265 204 441 287
          680 450 1292 450 2568 0 3860 -83 239 -157 415 -287 680 -266 543 -590 1028
          -998 1494 -143 162 -477 497 -635 634 -1068 931 -2319 1488 -3622 1612 -156
          15 -666 27 -795 19z'
      />
    </g>
  </StyledSVG>
);

const markerIcon = markerHeight => {
  const height = markerHeight || DefaultMarkerHeight;
  const width = height * 1.5;

  return L.divIcon({
    className: 'custom-icon',
    iconSize: [width, height],
    html: renderToString(<LocationMarkerIcon height={height} />),
    iconAnchor: [width * 1.5, height],
  });
};

export const LocationMarker: FC = () => {
  const location = useContext(LocationContext);

  return (
    <>
      {location?.longitude && location?.latitude && location?.accuracy && (
        <>
          <Marker position={[location?.latitude, location?.longitude]} icon={markerIcon(20)} />
        </>
      )}
    </>
  );
};
