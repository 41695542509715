import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isFieldRequired } from '@src/helpers';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';

import { Formik } from 'formik';
import { SubmitButton } from 'formik-antd';
import { Column } from '@src/components/Desktop/Elements/Wrapper/Form/Column';
import { Input } from '@src/components/Desktop/Containers/Input';
import { Form as FormWrapper, Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { StyledCheckbox } from './Form.styles';
import { MAXIMUM_POSTCODE_LENGTH, MINIMUM_COUNTRY_LENGTH } from '@src/global';

export interface FormValues {
  billingAddressCountry: string | undefined;
  billingAddressHousenumber: string | undefined;
  billingAddressPartOfVillage: string | undefined;
  billingAddressPostcode: string | undefined;
  billingAddressStreet: string | undefined;

  carrier: boolean | undefined;
  customer: boolean | undefined;
  information: string | undefined;
  matchcode: string | undefined;
  name: string | undefined;
  note: string | undefined;
  supplier: boolean | undefined;
}

export interface FormProps {
  deleteLoading?: boolean;
  error?: ApolloError;
  initialFormValues?: FormValues;
  onDelete?: () => Promise<false | undefined>;
  onSubmit: (variables: FormValues) => Promise<any>;
}

export const Form: FC<FormProps> = ({ error, initialFormValues, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues: FormValues = initialFormValues || {
    name: undefined,
    matchcode: undefined,
    note: undefined,
    information: undefined,

    customer: undefined,
    carrier: undefined,
    supplier: undefined,

    billingAddressStreet: undefined,
    billingAddressHousenumber: undefined,
    billingAddressPostcode: undefined,
    billingAddressPartOfVillage: undefined,
    billingAddressCountry: undefined,
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    matchcode: Yup.string(),
    note: Yup.string(),
    information: Yup.string(),
    customer: Yup.boolean().nullable(),
    carrier: Yup.boolean().nullable(),
    supplier: Yup.boolean().nullable(),

    billingAddressStreet: Yup.string().required(
      t(i18nFormFieldRequired, { field: t('desktop.pages.companies.list.columns.billingAddressStreet') }),
    ),
    billingAddressHousenumber: Yup.string(),
    billingAddressPostcode: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.companies.list.columns.billingAddressPostcode') }))
      .max(MAXIMUM_POSTCODE_LENGTH, t('desktop.pages.companies.add.form.billingAddressPostcode.length.error')),
    billingAddressPartOfVillage: Yup.string(),
    billingAddressCountry: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.companies.list.columns.billingAddressCountry') }))
      .min(MINIMUM_COUNTRY_LENGTH, t('desktop.pages.companies.add.form.billingAddressCountry.length.error'))
      .max(MINIMUM_COUNTRY_LENGTH, t('desktop.pages.companies.add.form.billingAddressCountry.length.error')),
  });

  const handleSubmit = async (values: FormValues) =>
    onSubmit({
      ...values,
    });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid }) => (
        <FormWrapper>
          <h3>{t('desktop.pages.companies.add.form.title.general')}</h3>
          <Row>
            <Column>
              <Input
                label={t('general.name')}
                type='text'
                name='name'
                required={isFieldRequired('name', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('general.matchcode')}
                type='text'
                name='matchcode'
                required={isFieldRequired('matchcode', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('general.note')}
                type='text'
                name='note'
                required={isFieldRequired('note', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('general.info')}
                type='text'
                name='information'
                required={isFieldRequired('information', validationSchema)}
              />
            </Column>
          </Row>

          <Row>
            <Column lg={8}>
              <StyledCheckbox name='customer'>{t('general.customer')}</StyledCheckbox>
            </Column>
            <Column lg={8}>
              <StyledCheckbox name='carrier'>{t('general.carrier')}</StyledCheckbox>
            </Column>
            <Column lg={8}>
              <StyledCheckbox name='supplier'>{t('general.supplier')}</StyledCheckbox>
            </Column>
          </Row>

          <h3>{t('desktop.pages.companies.add.form.title.billingAddress')}</h3>
          <Row>
            <Column>
              <Input
                label={t('desktop.pages.companies.list.columns.street')}
                type='text'
                name='billingAddressStreet'
                required={isFieldRequired('billingAddressStreet', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('desktop.pages.companies.list.columns.housenumber')}
                type='text'
                name='billingAddressHousenumber'
                required={isFieldRequired('billingAddressHousenumber', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('desktop.pages.companies.list.columns.postcode')}
                type='text'
                name='billingAddressPostcode'
                required={isFieldRequired('billingAddressPostcode', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('desktop.pages.companies.list.columns.partOfVillage')}
                type='text'
                name='billingAddressPartOfVillage'
                required={isFieldRequired('billingAddressPartOfVillage', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                label={t('desktop.pages.companies.list.columns.country')}
                placeholder={t('desktop.pages.companies.list.columns.placeholder.abbreviation')}
                type='text'
                name='billingAddressCountry'
                required={isFieldRequired('billingAddressCountry', validationSchema)}
              />
            </Column>
          </Row>

          <Row justify='space-between'>
            <SubmitButton type='primary' disabled={!isValid}>
              {t('desktop.pages.accounts.form.submit')}
            </SubmitButton>
          </Row>
          {error && <APIError errors={error} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
