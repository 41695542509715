import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { StringPaginationType } from '@src/generated/schema';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import { Account } from '@src/graphql/Account';

export const EmailSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ emails: StringPaginationType }>(Account.emails, {
    variables: {
      pageSize: 10,
    },
  });
  const { emails: { page, strings, hasNext } = { page: 0, strings: [], hasNext: false } } = data || {};

  const handleSearch = useCallback(
    debounce((filterValue: string) => {
      refetch({ filterValue, pageSize: 10 });
    }, 100),
    [],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight && hasNext) {
      const previousPage: number = page || 0;

      fetchMore({
        variables: {
          page: previousPage + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newPage = fetchMoreResult?.emails?.page || 0;
          const oldPage = prev?.emails?.page || 0;

          if (!fetchMoreResult || newPage <= oldPage) return prev;

          const newEdges = fetchMoreResult.emails.strings;
          const previousEdges = prev?.emails?.strings || [];

          return newEdges?.length
            ? {
                emails: {
                  page: fetchMoreResult.emails.page,
                  pages: fetchMoreResult.emails.pages,
                  hasNext: fetchMoreResult.emails.hasNext,
                  hasPrevious: fetchMoreResult.emails.hasPrevious,
                  __typename: prev.emails.__typename,
                  strings: [...previousEdges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <SearchSelect
      {...props}
      label={t('general.email')}
      options={strings?.map(email => ({
        title: email,
        value: email,
      }))}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
      name='email'
      loading={loading}
    />
  );
};
