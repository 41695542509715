import styled from 'styled-components';
import { THEME } from '@src/theme';

export const StyledSVG = styled.svg`
  margin-left: 2.75em;
`;

export const StyledMarkerPath = styled.path`
  fill: url(#SVGID_1_);
`;

export const StyledBubblePath = styled.path`
  fill: ${THEME.color.cameo};
`;

export const StyledEllipse = styled.ellipse`
  fill: ${THEME.color.white};
`;

export const StyledRouteEllipse = styled.ellipse`
    fill: ${THEME.color.black};
    border: 1px solid white;
`

export const StyledText = styled.text`
  font-size: 2.5em;
  fill: ${THEME.color.codGray};
  font-family: Verdana-Bold, Verdana, serif;
  font-weight: 700;
`;

export const StyledTspan = styled.tspan``;
