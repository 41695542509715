import React, { FC, useEffect, useState } from 'react';
import { CallbackID, Geolocation, Position } from '@capacitor/geolocation';

export interface Location {
  accuracy: number | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
}

export const noLocation: Location = {
  accuracy: undefined,
  latitude: undefined,
  longitude: undefined,
};

export const LocationContext = React.createContext(noLocation);

export const LocationProvider: FC = ({ children }) => {
  const [currentPosition, setCurrentPosition] = useState<Position | null>();
  const [geolocationWatcher, setGeolocationWatcher] = useState<CallbackID | null>();

  useEffect(() => {
    Geolocation.watchPosition(
      { enableHighAccuracy: true },
      (position: Position | null, err: any) => {
        if (!err) setCurrentPosition(position);
      }).then(
        callbackId => setGeolocationWatcher(callbackId)
    );

    return () => {
      if(geolocationWatcher) Geolocation.clearWatch({ id: geolocationWatcher })
    }
  }, []);

  return (
    <LocationContext.Provider
      value={{
        latitude: currentPosition?.coords.latitude,
        longitude: currentPosition?.coords.longitude,
        accuracy: currentPosition?.coords.accuracy,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
