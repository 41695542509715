import { StyledOrderDetailTable } from '@src/components/Desktop/Pages/Private/Orders/Show/Show.styles';
import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table/Column';
import { Tag, TagType } from '@src/components/Elements/Tag';
import { OrderStatus } from '@src/graphql/Order/Types';
import Moment from 'react-moment';
import { Button, Popover } from 'antd';
import { IonIcon } from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { StatusChangeNode } from '@src/generated/schema';
import { useMutation } from 'react-apollo';
import { Order } from '@src/graphql/Order';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { useQuery } from '@apollo/react-hooks';
import { closePopups } from '@src/helpers/DomManipulations';

interface IStatusChangesList {
  orderId: string;
}

export const StatusChangesList = ({ orderId }: IStatusChangesList) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);

  const { loading, error: fetchError, data } = useQuery<{
    order: any;
  }>(Order.getOrderStatusChanges, {
    variables: { orderId },
    fetchPolicy: 'network-only',
  });

  const statusChanges: ReadonlyArray<StatusChangeNode> = data?.order?.statusChanges || [];

  const [rollbackOrderStatusChange, { loading: rollbackLoading }] = useMutation(Order.rollbackOrderStatusChange, {
    variables: { orderId },
    errorPolicy: 'ignore',
    onError: error => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.timeSheets.show.error.message'),
        description: <APIError errors={error} />,
      });
    },
    refetchQueries: [{ query: Order.getOrderStatusChanges, variables: { orderId } }],
  });

  const [changeToNextStatus, { loading: chanestatusLoading }] = useMutation(Order.changeToNextStatus, {
    variables: { orderId },
    errorPolicy: 'ignore',
    onError: error => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.timeSheets.show.error.message'),
        description: <APIError errors={error} />,
      });
    },
    refetchQueries: [{ query: Order.getOrderStatusChanges, variables: { orderId } }],
  });

  const columnsStatusChange: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.piles.list.columns.oldStatus'),
      dataIndex: 'oldStatus',
      render: text => (
        <>
          {text ? (
            <Tag
              type={text === OrderStatus.Created ? TagType.PRIMARY : TagType.DEFAULT}
              key={text}
              value={text}
              text={t(`general.status.${text}`)}
            />
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: t('desktop.pages.piles.list.columns.newStatus'),
      dataIndex: 'newStatus',
      render: text => (
        <>
          {text ? (
            <Tag
              type={text === OrderStatus.Created ? TagType.PRIMARY : TagType.DEFAULT}
              key={text}
              value={text}
              text={t(`general.status.${text}`)}
            />
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: t('desktop.pages.piles.list.columns.dispatchedAt'),
      dataIndex: 'dispatchedAt',
      render: text => <Moment format='DD.MM.YYYY HH:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.piles.list.columns.createdAt'),
      dataIndex: 'createdAt',
      render: text => <Moment format='DD.MM.YYYY HH:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.piles.list.columns.createdBy'),
      dataIndex: 'createdBy',
      render: user => <span>{user ? `${user.firstName} ${user.lastName}` : `-`}</span>,
    },
    {
      title: () => (
        <Popover
          placement='leftTop'
          content={
            <>
              <Button
                style={{ display: 'block' }}
                onClick={() => {
                  rollbackOrderStatusChange();
                  closePopups();
                }}
                type='link'
                disabled={statusChanges.length <= 1}
              >
                {t('desktop.pages.piles.list.columns.actions.deleteLastStatus')}
              </Button>
              <Button
                style={{ display: 'block' }}
                type='link'
                onClick={() => {
                  changeToNextStatus();
                  closePopups();
                }}
              >
                {t('desktop.pages.piles.list.columns.actions.nextStatus')}
              </Button>
            </>
          }
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  return (
    <>
      {fetchError && <APIError errors={fetchError} />}
      <StyledOrderDetailTable
        title={() => t('desktop.pages.orders.show.statusChange.title')}
        columns={columnsStatusChange}
        dataSource={statusChanges}
        rowKey='id'
        pagination={false}
        loading={loading || rollbackLoading || chanestatusLoading}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};
