import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';
import { MultiSelect } from '@src/components/Elements/MultiSelect';

export const UnitSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ deliveryStatusUnitSelection: any }>(
    DeliveryStatus.getDeliveryStatusUnitSelectOptions,
  );
  const { deliveryStatusUnitSelection } = data || {};

  const unitOptions = deliveryStatusUnitSelection?.map(option => ({ value: option, title: option })) || [];

  return <MultiSelect {...props} label={t('general.unit')} options={unitOptions} name='units' loading={loading} />;
};
