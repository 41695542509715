import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledPaginationButton,
  StyledPaginationIndicator,
  StyledPaginationItem,
  StyledPaginationList,
} from './Pagination.styles';
import { PageInfo, QueryPilesArgs, QueryOrderArgs } from '@src/generated/schema';

interface PaginationProps {
  loading: any;
  page: number;
  pageInfo: PageInfo;
  queryVariables: QueryPilesArgs;
  setPage: Dispatch<SetStateAction<number>>;
  setQueryVariables: Dispatch<SetStateAction<QueryPilesArgs | QueryOrderArgs>>;
}

export const Pagination: FC<PaginationProps> = ({
  loading,
  pageInfo,
  queryVariables,
  setQueryVariables,
  page,
  setPage,
}) => {
  const { t } = useTranslation();
  const hasNextPage = queryVariables.first ? pageInfo.hasNextPage : true;
  const hasPreviousPage = queryVariables.last ? pageInfo.hasPreviousPage : page > 1;

  const prevPage = () => {
    setPage(prevPageState => prevPageState - 1);

    setQueryVariables({
      ...queryVariables,
      before: pageInfo.startCursor,
      last: queryVariables.last || queryVariables.first,
      first: undefined,
      after: undefined,
    });
  };

  const nextPage = () => {
    setPage(prevPageState => prevPageState + 1);

    setQueryVariables({
      ...queryVariables,
      after: pageInfo.endCursor,
      first: queryVariables.first || queryVariables.last,
      last: undefined,
      before: undefined,
    });
  };

  return (
    <StyledPaginationList>
      <StyledPaginationItem>
        <StyledPaginationButton type='button' onClick={prevPage} disabled={!hasPreviousPage || loading}>
          {t('desktop.elements.pagination.previousPage')}
        </StyledPaginationButton>
      </StyledPaginationItem>
      <StyledPaginationItem>
        <StyledPaginationIndicator>{page}</StyledPaginationIndicator>
      </StyledPaginationItem>
      <StyledPaginationItem>
        <StyledPaginationButton type='button' onClick={nextPage} disabled={!hasNextPage || loading}>
          {t('desktop.elements.pagination.nextPage')}
        </StyledPaginationButton>
      </StyledPaginationItem>
    </StyledPaginationList>
  );
};
