import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { PileNode, PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MobileSearchSelect } from '@src/components/Mobile/Elements/SearchSelect';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { MobileSelectProps } from '@src/components/Mobile/Elements/SearchSelect/SearchSelect';
import { getUniqueList } from '@src/helpers/Logic/Logic';

export interface MobileLocationSelectProps extends Omit<MobileSelectProps, 'options'> {
  piles?: PileNode[];
}

export const MobileLocationSelect: FC<MobileLocationSelectProps> = ({ piles, ...props }) => {
  const { isOnline } = useContext(NetworkContext);
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ pileLocations: PileNodeConnection }>(Pile.getPileLocations, {
    variables: {
      first: 20,
    },
    fetchPolicy: 'network-only',
  });
  const { pileLocations: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 20,
        searchString: value,
      });
    }, 500),
    [],
  );

  const handlePopupScroll = () => {
    fetchMore({
      variables: {
        after: data?.pileLocations.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newEdges = fetchMoreResult.pileLocations.edges;
        const pageInfo = fetchMoreResult.pileLocations.pageInfo;

        return newEdges.length
          ? {
              pileLocations: {
                pageInfo,
                __typename: prev.pileLocations.__typename,
                edges: [...prev.pileLocations.edges, ...newEdges],
              },
            }
          : prev;
      },
    });
  };

  return (
    <MobileSearchSelect
      {...props}
      label={t('desktop.pages.piles.list.columns.location')}
      options={
        isOnline
          ? edges.map(({ node: { name, id } }) => ({
              id,
              title: name,
              value: name,
            }))
          : getUniqueList(piles?.map(p => p.location)).map(location => ({
              id: location,
              value: location,
              title: location,
            }))
      }
      loading={loading}
      multi
      onSearch={handleSearch}
      onLoadMore={handlePopupScroll}
      allowNoSelection
    />
  );
};
