import React, { FC } from 'react';
import { StyledForm } from './Form.styles';

export const Form: FC = ({ children }) => (
  <StyledForm>
    {children}

    {/* Workaround to submit forms by pressing enter and restoring the default form behavior */}
    <input style={{ visibility: 'hidden' }} type='submit' />
  </StyledForm>
);
