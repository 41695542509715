import styled from 'styled-components';
import { StyledTable } from '../List/Table/Table.styles';
import { Button } from 'antd';


export const StyledPilesDetailTable = styled(StyledTable)`
  margin-top: 0;

  .ant-table-title {
      padding-top: 0;
      font-size: 16px;
      font-weight: bold;
  }
`;

export const StyledButton = styled(Button)`
  display: block !important;
  margin: 2em auto;
`;
