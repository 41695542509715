import React, { FC } from 'react';
import { FormItem, FormItemProps, Input as AntInput, TextAreaProps as AntdTextAreaProps } from 'formik-antd';
import { useTranslation } from 'react-i18next';
const { TextArea: AntTextarea } = AntInput;

interface TextAreaProps extends Omit<AntdTextAreaProps, 'name'>, Pick<FormItemProps, 'label' | 'name'> {
  dataTestid?: string;
  placeholder?: string;
  required?: boolean;
  type: string;
}

export const TextArea: FC<TextAreaProps> = ({ label, name, placeholder, dataTestid, required, ...props }) => {
  const { t } = useTranslation();

  return (
    <FormItem labelCol={{ span: 24 }} label={label} name={name} required={required}>
      <AntTextarea {...props} placeholder={placeholder || t('forms.validation.info.noValueSet')} name={name} />
    </FormItem>
  );
};
