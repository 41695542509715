import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledIonText,
  StyledIonTextError,
  StyledIonRow,
  StyledIonButtonRow,
  StyledIonCol,
  StyledIonColBroachedToggle,
  StyledIonInput,
  StyledButton,
  StyledIonToggle,
  StyledForm,
} from '../InventoryForm.styles';
import { InventoryState, ValidationError } from '../InventoryForm';
import { InventoryNode } from '@src/generated/schema';
import { isPlatform } from '@ionic/core';

interface FormProps {
  handleDeleteSubmit: (e: any) => void;
  handleSaveSubmit: (e: any) => void;
  handleUpdateInventory: (e: any, field: any) => void;
  inventory: InventoryState | InventoryNode;
  isSubmitting: boolean;
  showDelete: boolean;
  showSave: boolean;
  validationErrors: ValidationError[] | undefined;
}

export const Form: FC<FormProps> = ({
  handleSaveSubmit,
  handleDeleteSubmit,
  showDelete,
  showSave,
  inventory,
  isSubmitting,
  handleUpdateInventory,
  validationErrors,
}) => {
  const { t } = useTranslation();

  return (
    <StyledForm onSubmit={handleSaveSubmit}>
      <StyledIonRow>
        <StyledIonCol size={isPlatform('hybrid') ? '3' : '2.5'} push={isPlatform('hybrid') ? '5' : undefined}>
          <StyledIonText color='dark'>{t('containers.inventoryForm.form.labels.sum')}</StyledIonText>
        </StyledIonCol>

        <StyledIonCol size={isPlatform('hybrid') ? '4' : '2.5'} push={isPlatform('hybrid') ? '5' : undefined}>
          <StyledIonText color='dark'>
            {Number(inventory.amount)
              .toFixed(2)
              .replace('.', ',')}
          </StyledIonText>
        </StyledIonCol>
      </StyledIonRow>

      <StyledIonRow>
        <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
          <StyledIonText>{t('containers.inventoryForm.form.labels.quantity')}:</StyledIonText>
        </StyledIonCol>

        <StyledIonCol size={isPlatform('hybrid') ? '3' : '2.5'}>
          <StyledIonInput
            type='number'
            value={inventory.count ? inventory.count.toString() : null}
            onIonChange={e => handleUpdateInventory(e, 'count')}
          />
        </StyledIonCol>
      </StyledIonRow>

      <StyledIonRow>
        <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
          <StyledIonText>{t('containers.inventoryForm.form.labels.broached')}:</StyledIonText>
        </StyledIonCol>

        <StyledIonColBroachedToggle>
          <StyledIonToggle
            value='false'
            checked={inventory.isBroached}
            color='success'
            onIonChange={e => handleUpdateInventory(e, 'broached')}
          />
        </StyledIonColBroachedToggle>
      </StyledIonRow>

      <StyledIonRow>
        <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
          <StyledIonText>{t('containers.inventoryForm.form.labels.rest')}:</StyledIonText>
        </StyledIonCol>

        <StyledIonCol size={isPlatform('hybrid') ? '3' : '2.5'}>
          <StyledIonInput type='number' value={inventory.rest} onIonChange={e => handleUpdateInventory(e, 'rest')} />
        </StyledIonCol>
      </StyledIonRow>

      {validationErrors && validationErrors.length > 0 && (
        <StyledIonButtonRow>
          <StyledIonCol size='12'>
            {validationErrors.map(item => (
              <StyledIonTextError key={item.field} color='danger'>
                {item.message}
              </StyledIonTextError>
            ))}
          </StyledIonCol>
        </StyledIonButtonRow>
      )}

      {(showDelete || showSave) && (
        <StyledIonButtonRow>
          {showSave && (
            <StyledIonCol size={showDelete ? '6' : '12'}>
              <StyledButton type='submit' color='primary' fill='solid' expand='block' disabled={isSubmitting}>
                {t('containers.inventoryForm.form.submit')}
              </StyledButton>
            </StyledIonCol>
          )}

          {showDelete && (
            <StyledIonCol size={showSave ? '6' : '12'}>
              <StyledButton
                type='button'
                onClick={handleDeleteSubmit}
                color='primary'
                fill='solid'
                expand='block'
                disabled={isSubmitting}
              >
                {t('containers.inventoryForm.form.delete')}
              </StyledButton>
            </StyledIonCol>
          )}
        </StyledIonButtonRow>
      )}
    </StyledForm>
  );
};
