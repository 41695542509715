import React, { FC } from 'react';
import { StyledActiveInactiveStatus, StyledStatus, StyledWrapper } from './ActiveInactiveStatus.styles';

export interface ActiveInactiveProps {
  active: boolean;
}

export interface ActiveInactiveStatusProps {
  active: boolean;
  text: string;
}

export const ActiveInactiveStatus: FC<ActiveInactiveStatusProps> = ({ text, active }) => (
  <StyledActiveInactiveStatus active={active}>
    <StyledWrapper>
      <StyledStatus>{text}</StyledStatus>
    </StyledWrapper>
  </StyledActiveInactiveStatus>
);
