import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { LoadingSpinnerIonRow, StyledButton, StyledCompaniesSearch } from './Table.styles';
import { StyledTable } from '@src/components/Elements/Table';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Pagination } from '@src/components/Desktop/Elements';
import { Alert, Popover } from 'antd';

import { useLazyQuery } from '@apollo/react-hooks';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { ColumnProps } from 'antd/lib/table/Column';

import { Company } from '@src/graphql/Company';
import { CompanyNode, CompanyNodeConnection } from '@src/generated/schema';
import {
  StyledTableHeaderActions,
  StyledTableHeaderButton,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { add, ellipsisVertical } from 'ionicons/icons';
import { ModalEnum } from '../List';
import { PopoverRow } from '../PopoverRow';

interface TableProps extends AntdTableProps<any> {
  itemsPerPage: number;
  onItemClick?: (event: React.MouseEvent, row: CompanyNode, rowIndex: number) => void;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setShowPopover: ({ id }: { id: string }) => void;
  showPopover: { id: string };
  toggleModal: (modalType: ModalEnum) => void;
}

export const Table: FC<TableProps> = ({
  itemsPerPage,
  onItemClick,
  setItemsPerPage,
  showPopover,
  setShowPopover,
  toggleModal,
  ...props
}) => {
  const { userHasPermission, me } = useContext(MeContext);
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);

  const [queryVariables, setQueryVariables] = useState<any>({
    first: itemsPerPage,
    search: '',
  });

  const [page, setPage] = useState<number>(1);

  const [getCompanies, { loading, error, data, refetch }] = useLazyQuery<{
    companies: CompanyNodeConnection;
  }>(Company.companies, {
    variables: queryVariables,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onError: () => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.companies.list.error.message'),
        description: t('desktop.pages.companies.list.error.description'),
      });
    },
  });
  const { companies: { edges } = { edges: [] as any } } = data || {};
  const companies = edges.map(({ node }) => node);

  useEffect(() => {
    setQueryVariables(currentQueryVariables => ({
      ...currentQueryVariables,
      first: itemsPerPage,
    }));
    setPage(1);
  }, [itemsPerPage, setQueryVariables, setPage]);

  useEffect(() => {
    getCompanies();
  }, [queryVariables, getCompanies]);

  const onCellClicked = (e: React.MouseEvent, record: CompanyNode, index: number | undefined) => {
    if (onItemClick && index !== undefined) {
      onItemClick(e, record, index);
    }
  };

  useEffect(() => {
    const el = document;
    const event = document.createEvent('HTMLEvents');

    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  });

  const columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.companies.list.columns.name'),
      dataIndex: 'name',
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.companies.list.columns.matchcode'),
      dataIndex: 'matchcode',
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.companies.list.columns.street'),
      dataIndex: ['billingAddress', 'street'],
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.companies.list.columns.country'),
      dataIndex: ['billingAddress', 'country'],
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.companies.list.columns.housenumber'),
      dataIndex: ['billingAddress', 'housenumber'],
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.companies.list.columns.postcode'),
      dataIndex: ['billingAddress', 'postcode'],
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.companies.list.columns.partOfVillage'),
      dataIndex: ['billingAddress', 'partOfVillage'],
      onCell: (record, index) => ({
        onClick: e => onCellClicked(e, record, index),
      }),
    },
    {
      title: () => <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />,
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record: any) => (
        <Popover
          placement='leftTop'
          content={<PopoverRow toggleModal={toggleModal} company={record} />}
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  return (
    <>
      {error && !data && (
        <Alert
          message={t('desktop.pages.companies.list.error.message')}
          description={t('desktop.pages.companies.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <LoadingSpinnerIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </LoadingSpinnerIonRow>
      )}

      <StyledTableHeaderActions>
        {userHasPermission(Permissions.CAN_ADD_COMPANY) && me?.client?.canChangePlotPile && (
          <StyledTableHeaderButton size='large' type='link' onClick={() => toggleModal(ModalEnum.ADD)}>
            <IonIcon icon={add} />
            {t('desktop.pages.companies.list.createCompany')}
          </StyledTableHeaderButton>
        )}

        <StyledCompaniesSearch onSearch={string => setQueryVariables(prev => ({ ...prev, search: string }))} />
      </StyledTableHeaderActions>

      {data &&
        (edges?.length > 0 ? (
          <>
            <StyledTable
              {...props}
              columns={columns}
              dataSource={companies}
              pagination={false}
              scroll={{ x: 'max-content' }}
              rowKey='id'
            />

            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.companies && data.companies.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.companies.list.noCompaniesAvailable')}</IonTitle>
        ))}
    </>
  );
};
