import { Checkbox, Switch as AntdSwitch } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuid4 } from 'uuid';
import { PileNode } from '@src/generated/schema';
import { usePrevState } from '@src/hooks';
import {
  AggregationLength,
  AggregationSort,
  AggregationsSelectWrapper,
  AggregationSwitchWrapper,
  AggregationTextWrapper,
  AggregationWoodType,
} from './AggregationsSelect.styles';
import { PileAggregationForm } from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer/FilterDrawer';
import FormItem from 'antd/lib/form/FormItem';
import { useTranslation } from 'react-i18next';

export interface PileAggregation {
  id: string;
  length: number;
  sort: string;
  type: string;
  value: boolean;
  woodType: string;
}

export interface AggregationInformationProps {
  aggregation: PileAggregation;
  onClick: (value: boolean) => void;
  selected: boolean;
}

/**
 * Contains the main information about an aggregation and if it was selected.
 */
const AggregationInformation: FC<AggregationInformationProps> = ({ selected, onClick, aggregation }) => (
  <AggregationTextWrapper>
    <Checkbox
      checked={selected}
      onChange={event => {
        // prevent the events from the checkbox to bubble to the switch of Antd
        event.preventDefault();
        event.stopPropagation();
        onClick(!!event.target.value);
      }}
    />
    <AggregationWoodType>{aggregation.woodType}</AggregationWoodType>
    <AggregationSort>{aggregation.sort}</AggregationSort>
    <AggregationLength>{aggregation.length}</AggregationLength>
  </AggregationTextWrapper>
);

/**
 * Checks if two aggregations are equal.
 */
const aggregationsAreEqual = (agg1: PileAggregation, agg2: PileAggregation) =>
  agg1.sort === agg2.sort && agg1.length === agg2.length && agg1.type === agg2.type && agg1.woodType === agg2.woodType;

/**
 * Generates aggregations from a list of piles.
 */
export const getAggregationFromPiles = (piles: PileNode[] | undefined, includeBroached: boolean) => {
  if (!piles) return [];

  // get all aggregations that from given piles. To get them, do several things:
  // 1) only include piles that are not broached if includeBroached is false
  // 2) build the aggregation itself
  // 3) filter out any aggregations that are more than once in the array
  // 4) sort the values by their attributes
  const agg: PileAggregation[] = piles
    .filter(pile => includeBroached || !pile.isBroached)
    .map(pile => ({
      sort: pile.sort,
      woodType: pile.woodtype,
      length: pile.logLength,
      type: pile.type,
      id: uuid4(),
      value: true,
    }))
    .filter((pile, index, array) => array.findIndex(val => aggregationsAreEqual(val, pile)) === index)
    .sort((value1, value2) => {
      if (value1.woodType !== value2.woodType) return value1.woodType.localeCompare(value2.woodType);
      if (value1.sort !== value2.sort) return value1.sort.localeCompare(value2.woodType);
      if (value1.length !== value2.length) return value1.length > value2.length ? 1 : -1;

      return 0;
    });

  return agg;
};

export const AggregationsSelect = ({ piles }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<PileAggregationForm>();
  const [aggregations, setAggregations] = useState<PileAggregation[]>([]);
  const previousIncludeBroached = usePrevState<boolean>(values.includeBroached);

  /**
   * If any values changes that would influence the number of aggregations, we need to re-generate them.
   */
  useEffect(() => {
    const pileAggregations: PileAggregation[] = getAggregationFromPiles(piles, values.includeBroached);
    setAggregations(pileAggregations);

    if (previousIncludeBroached === undefined || previousIncludeBroached !== values.includeBroached) {
      setFieldValue('pileAggregations', pileAggregations);
    }
  }, [piles, setAggregations, values.includeBroached, previousIncludeBroached, setFieldValue]);

  /**
   * Whenever the pileAggregations change, we can assume that the form has all the correct values already and set
   * the new values of the form elements.
   */
  useEffect(() => {
    setAggregations(values.pileAggregations);
  }, [values.pileAggregations]);

  /**
   * Updates the value of an aggregation. `Value` refers to true or false; the value that a switch of that
   * aggregation has.
   */
  const updateAggregationValue = (index, newSwitchValue: boolean) => {
    const newValue = values.pileAggregations.map((oldValue, i) =>
      index === i ? { ...oldValue, value: newSwitchValue } : oldValue,
    );

    setFieldValue('pileAggregations', newValue);
  };

  return (
    <FormItem labelCol={{ span: 24 }} label={t('mobile.containers.mapWrapper.filterDrawer.label.aggregations')}>
      <AggregationsSelectWrapper>
        {aggregations.map((agg, aggIndex) => (
          <AggregationSwitchWrapper key={agg.id}>
            <AntdSwitch
              checkedChildren={
                <AggregationInformation
                  onClick={newValue => updateAggregationValue(aggIndex, newValue)}
                  aggregation={agg}
                  selected
                />
              }
              unCheckedChildren={
                <AggregationInformation
                  onClick={newValue => updateAggregationValue(aggIndex, newValue)}
                  aggregation={agg}
                  selected={false}
                />
              }
              onChange={newValue => updateAggregationValue(aggIndex, newValue)}
              checked={agg.value}
            />
          </AggregationSwitchWrapper>
        ))}
      </AggregationsSelectWrapper>
    </FormItem>
  );
};

export default AggregationsSelect;
