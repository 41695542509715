import React, { useContext } from 'react';
import { Upload, message } from 'antd';
import { PlotSelect } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form/PlotSelect';
import { isFieldRequired } from '@src/helpers';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { APIError, StyledForm } from '@src/components/Elements/Wrapper/Form';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import { IonCol, IonSpinner } from '@ionic/react';
import { FormItem } from 'formik-antd';
import { UploadFile } from 'antd/es/upload/interface';
import { FullPageLoadingWithDataWrapper } from '@src/components/Desktop/Pages/Private/Piles/List/Table/Table.styles';
import { useMutation } from 'react-apollo';
import { Pile } from '@src/graphql/Pile';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';

export interface IImportPiles {
  closeModal: () => void;
}

const { Dragger } = Upload;
export interface FormValues {
  file: UploadFile | undefined;
  plotNumber: number | undefined;
}

export const ImportPiles = ({ closeModal }: IImportPiles): React.ReactElement => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);

  const [importPiles, { error: importError, loading: importLoading }] = useMutation(Pile.importPiles, {
    refetchQueries: ['piles'],
  });

  const initialValues: FormValues = {
    plotNumber: undefined,
    file: undefined,
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';

  const validationSchema = Yup.object().shape({
    plotNumber: Yup.number().required(t(i18nFormFieldRequired, { field: t('general.pile.plotNumber') })),
    file: Yup.mixed().required(t(i18nFormFieldRequired, { field: t('general.pile.pilesFile') })),
  });

  const onSubmit = async ({ plotNumber, file: pilesFile }: FormValues) => {
    setToast({
      type: TOAST_TYPES.INFO,
      message: 'Info',
      description: t('general.pile.import.toast.info.takestime'),
    });

    const { data: importResult } = await importPiles({ variables: { plotNumber, pilesFile } });
    if (importResult) {
      closeModal();
      setToast({
        type: TOAST_TYPES.SUCCESS,
        message: t('general.pile.import.toast.success.message'),
        description: t('general.pile.import.toast.success.description', {
          count: importResult?.importPiles?.count || 0,
        }),
      });
    }
  };

  // shorturl.at/hiILP
  const dummyRequest = ({ onSuccess }: { onSuccess?: any }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleSubmit = async (values: FormValues) => onSubmit(values);

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return (
    <div>
      {importLoading && (
        <FullPageLoadingWithDataWrapper>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </FullPageLoadingWithDataWrapper>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnMount
      >
        {({ setFieldValue, isSubmitting }) => (
          <StyledForm>
            {isSubmitting && (
              <FullPageLoadingWithDataWrapper>
                <IonCol className='ion-text-center'>
                  <IonSpinner name='crescent' />
                </IonCol>
              </FullPageLoadingWithDataWrapper>
            )}
            <AutoSaveForm />
            <PlotSelect required={isFieldRequired('plotNumber', validationSchema)} />
            <FormItem
              labelCol={{ span: 24 }}
              style={{ width: '100%' }}
              label={t('general.pile.import.file')}
              name='file'
              required
            >
              <Dragger
                {...props}
                onChange={(info: UploadChangeParam) => {
                  const { status } = info.file;
                  if (status === 'uploading') {
                    // uploading
                  } else if (status === 'done' && info.file.response) {
                    message.success(t('general.pile.import.fileUploaded', { filename: info.file.name }));
                    setFieldValue('file', info.file);
                  } else {
                    // it is error or removed
                    message.error(t('general.pile.import.fileNotUploaded', { filename: info.file.name }));
                  }
                }}
              >
                <>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>{t('general.pile.import.clickOrDrag')}</p>
                  <p className='ant-upload-hint'>{t('general.pile.import.uploadHint')}</p>
                </>
              </Dragger>
            </FormItem>
          </StyledForm>
        )}
      </Formik>
      {importError && <APIError errors={importError} />}
    </div>
  );
};
