import React, { FC } from 'react';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeliveryStatusNode } from '@src/generated/schema/index';
import Moment from 'react-moment';

export interface DetailProps {
  deliveryStatus?: DeliveryStatusNode;
}

export const Detail: FC<DetailProps> = ({ deliveryStatus }) => {
  const { t } = useTranslation();

  return (
    <>
      {deliveryStatus && (
        <Descriptions>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.number')}>
            {deliveryStatus.number}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.createdBy')}>
            {deliveryStatus.createdBy
              ? `${deliveryStatus.createdBy.firstName} ${deliveryStatus.createdBy.lastName}`
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.createdAt')}>
            {deliveryStatus.createdAt && <Moment format='DD.MM.YYYY'>{deliveryStatus.createdAt}</Moment>}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.lastModifiedAt')}>
            {deliveryStatus.lastmodifiedAt && <Moment format='DD.MM.YYYY'>{deliveryStatus.lastmodifiedAt}</Moment>}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.lastModifiedBy')}>
            {deliveryStatus.lastmodifiedBy
              ? `${deliveryStatus.lastmodifiedBy.firstName} ${deliveryStatus.lastmodifiedBy.lastName}`
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.dateBegin')}>
            <Moment format='DD.MM.YYYY'>{deliveryStatus.dateBegin}</Moment>
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.dateEnd')}>
            <Moment format='DD.MM.YYYY'>{deliveryStatus.dateEnd}</Moment>
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.unit')}>
            {t(`general.deliveryStatusUnit.${deliveryStatus.unit}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.type')}>
            {t(`general.deliveryStatusType.${deliveryStatus.type}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.shortDescription')}>
            {deliveryStatus.shortDescription}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.deliveryStatus.list.columns.amount')}>
            {deliveryStatus.amount}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
