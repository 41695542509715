import React, { FC, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import { OrderNode } from '@src/generated/schema';
import { Order } from '@src/graphql/Order';
import { Page } from '@src/components/Mobile/Sections/Page';

import { StyledTitle } from './Add.styles';
import { LocationContext } from '@src/components/Mobile/Providers';
import { WagonInventoryForm } from '@src/components/Containers/InventoryForm/WagonInventoryForm';

export const AddWagon: FC = () => {
  const client = useApolloClient();
  const { goBack } = useHistory();
  const { orderId } = useParams<{ orderId: string; pileId: string }>();
  const [order, setOrder] = useState<OrderNode | null | undefined>();
  const location = useContext(LocationContext);

  /**
   * Sets the order via given orderId.
   */
  useEffect(() => {
    if (orderId) {
      setOrder(
        client.readFragment({
          id: orderId,
          fragment: Order.DriverOrderNodeFragment,
          fragmentName: 'driverOrderNode',
        }),
      );
    }
  }, [orderId, client, setOrder]);

  return (
    <Page>
      <StyledTitle />

      <WagonInventoryForm
        deleteEnabled={false}
        createEnabled
        offlineHandling
        order={order || undefined}
        onSubmit={goBack}
        locationData={location}
      />
    </Page>
  );
};
