import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Wrapper } from '@src/components/Elements';
import { TestIds } from '@src/global';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeliveryStatusNode } from '@src/generated/schema';
import { IonRow, IonCol, IonSpinner } from '@ionic/react';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';
import { Detail } from '@src/components/Desktop/Pages/Private/DeliveryStatus/Show/Detail';

export interface ShowProps {
  deliveryStatus?: DeliveryStatusNode;
}

export const Show: FC<ShowProps> = ({ deliveryStatus }) => {
  const { t } = useTranslation();

  // initial data
  const { loading, error, data } = useQuery<{
    deliveryStatus: DeliveryStatusNode;
  }>(DeliveryStatus.getDeliveryStatusDetail, {
    variables: { deliveryStatusId: deliveryStatus && deliveryStatus.id },
    fetchPolicy: 'network-only',
  });

  return (
    <div data-testid={TestIds.pages.orders.show.identifier}>
      <Wrapper>
        {loading && (
          <IonRow>
            <IonCol className='ion-text-center'>
              <IonSpinner name='crescent' />
            </IonCol>
          </IonRow>
        )}

        {data && data.deliveryStatus && (
          <>
            <Detail deliveryStatus={data.deliveryStatus} />
          </>
        )}

        {error && (
          <Alert
            message={t('desktop.pages.deliveryStatus.show.overview.error.message')}
            description={t('desktop.pages.deliveryStatus.show.overview.error.description')}
            type='error'
            showIcon
          />
        )}
      </Wrapper>
    </div>
  );
};
