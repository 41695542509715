import { Map } from '@src/components/Containers/Map';
import { DefaultMarkerHeight } from '@src/global/Map';
import React, { FC, useEffect, useState } from 'react';
import { PileNode, PileNodeConnection, PlotNode } from '@src/generated/schema';
import { useQuery } from '@apollo/react-hooks';
import { Pile } from '@src/graphql/Pile';
import { StyledCustomMapControls, StyledPilesSearch } from '../PlotPilesMap/PlotPiles.styles';

export interface PlotPilesMapProps {
  plot: PlotNode | undefined;
}
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export enum InventoryModalEnum {
  INVENTORY_CREATE = 'createInventory',
}

export const PlotPilesMap: FC<PlotPilesMapProps> = ({ plot }) => {
  const [mapMarkers, setMapMarkers] = useState<PileNode[]>([]);
  const { data } = useQuery<{
    piles: PileNodeConnection;
  }>(Pile.getPaginatedPiles, {
    variables: { plotId: plot && plot.id },
    fetchPolicy: 'cache-only', // already fetched in previous tab
  });

  useEffect(() => {
    if (data && data.piles) {
      const { piles: { edges: pileEdges } = { edges: [] as any } } = data || {};

      const newPiles = pileEdges.map(edge => edge?.node);
      setMapMarkers(newPiles.filter(notEmpty));
    }
  }, [data]);

  return (
    <>
      <StyledCustomMapControls>
        <StyledPilesSearch piles={mapMarkers} onSearch={setMapMarkers} />
      </StyledCustomMapControls>
      <Map markers={mapMarkers} markerHeight={DefaultMarkerHeight} />
    </>
  );
};
