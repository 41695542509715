export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  CustomDecimal: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
}

/** This node provides information of users. */
export interface AccountNode extends Node {
  __typename?: 'AccountNode';
  email: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Legt fest, ob dieser Benutzer aktiv ist. Kann deaktiviert werden, anstatt Benutzer zu löschen. */
  isActive: Scalars['Boolean'];
  client?: Maybe<ClientNode>;
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  groups?: Maybe<Array<Maybe<GroupNode>>>;
  activeOrdersCount?: Maybe<Scalars['Int']>;
  checkedIn?: Maybe<Scalars['Boolean']>;
}

export interface AccountNodeConnection {
  __typename?: 'AccountNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountNodeEdge>>;
}

/** A Relay edge containing a `AccountNode` and its cursor. */
export interface AccountNodeEdge {
  __typename?: 'AccountNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AccountNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum AmountUnitChoice {
  Atr = 'ATR',
  Lut = 'LUT',
  Cft = 'CFT',
  Srm = 'SRM',
  Stk = 'STK',
  Lfm = 'LFM',
  Cbm = 'CBM',
  Fmo = 'FMO',
  Rmm = 'RMM'
}

export interface AppendPilesToExistingOrderMutation {
  __typename?: 'AppendPilesToExistingOrderMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

/** An enumeration. */
export enum CategoryTypeChoice {
  Transport = 'TRANSPORT',
  Purchase = 'PURCHASE'
}

export interface ChangeCompanyMutation {
  __typename?: 'ChangeCompanyMutation';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompanyNode>;
}

export interface ChangeDeliveryStatusMutation {
  __typename?: 'ChangeDeliveryStatusMutation';
  ok?: Maybe<Scalars['Boolean']>;
  deliveryStatus?: Maybe<DeliveryStatusNode>;
}

/**
 * A mutation that allows a logged in user to change his/her password by providing the old password.
 *
 * Make sure to query for the ok field as well as the errors to see if changing the password was successful. For
 * example:
 *
 * ```
 * mutation {
 *   changePassword(newPassword1: "<new-pw>", newPassword2: "<new-pw>", oldPassword: "<old-pw>") {
 *     ok
 *     errors {
 *       field
 *       messages {
 *         code
 *         message
 *       }
 *     }
 *   }
 * }
 * ```
 */
export interface ChangePasswordMutation {
  __typename?: 'ChangePasswordMutation';
  /** Indicates whether changing the password was successful or not. */
  ok?: Maybe<Scalars['Boolean']>;
  /** A list of errors in case ok is false. */
  errors?: Maybe<Array<Maybe<FieldErrorType>>>;
}

export interface ChangePileMutation {
  __typename?: 'ChangePileMutation';
  ok?: Maybe<Scalars['Boolean']>;
  pile?: Maybe<PileNode>;
}

export interface ChangePlotMutation {
  __typename?: 'ChangePlotMutation';
  ok?: Maybe<Scalars['Boolean']>;
  plot?: Maybe<PlotNode>;
}

export interface ChangeToNextStatus {
  __typename?: 'ChangeToNextStatus';
  ok?: Maybe<Scalars['Boolean']>;
}

/** A mutation that users can use to check in and create a Timesheet. */
export interface CheckInMutation {
  __typename?: 'CheckInMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

/** A mutation that users can use to end the current Timesheet and check out. */
export interface CheckOutMutation {
  __typename?: 'CheckOutMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

/** This node provides information of clients. */
export interface ClientNode extends Node {
  __typename?: 'ClientNode';
  name: Scalars['String'];
  tileServerUrl?: Maybe<Scalars['String']>;
  /** Können Nutzer die Zeiterfassung in der Applikation nutzen, die dem Client zugeordnet sind? */
  canUseTimeRecording: Scalars['Boolean'];
  /** Können Nutzer Polter erstellen und bearbeiten? */
  canChangePlotPile: Scalars['Boolean'];
  /** Können Nutzer Polter exportieren? */
  canExportPiles: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  graphhopperApiKey?: Maybe<Scalars['String']>;
}

/** An enumeration. */
export enum CompanyLocationCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Ägypten */
  Eg = 'EG',
  /** Åland-Inseln */
  Ax = 'AX',
  /** Albanien */
  Al = 'AL',
  /** Algerien */
  Dz = 'DZ',
  /** Amerikanisch-Samoa */
  As = 'AS',
  /** Amerikanische Jungferninseln */
  Vi = 'VI',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarktis */
  Aq = 'AQ',
  /** Antigua und Barbuda */
  Ag = 'AG',
  /** Äquatorialguinea */
  Gq = 'GQ',
  /** Argentinien */
  Ar = 'AR',
  /** Armenien */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Aserbaidschan */
  Az = 'AZ',
  /** Äthiopien */
  Et = 'ET',
  /** Australien */
  Au = 'AU',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesch */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belgien */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivien */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius und Saba */
  Bq = 'BQ',
  /** Bosnien und Herzegowina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvetinsel */
  Bv = 'BV',
  /** Brasilien */
  Br = 'BR',
  /** Britische Jungferninseln */
  Vg = 'VG',
  /** Britisches Territorium im Indischen Ozean */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgarien */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Commonwealth der Nördlichen Marianen */
  Mp = 'MP',
  /** Cookinseln */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Curaçao */
  Cw = 'CW',
  /** Dänemark */
  Dk = 'DK',
  /** Deutschland */
  De = 'DE',
  /** Dominica */
  Dm = 'DM',
  /** Dominikanische Republik */
  Do = 'DO',
  /** Dschibuti */
  Dj = 'DJ',
  /** Ecuador */
  Ec = 'EC',
  /** El Salvador */
  Sv = 'SV',
  /** Eritrea */
  Er = 'ER',
  /** Estland */
  Ee = 'EE',
  /** Falklandinseln (Malwinen) */
  Fk = 'FK',
  /** Faröerinseln */
  Fo = 'FO',
  /** Fidschi */
  Fj = 'FJ',
  /** Finnland */
  Fi = 'FI',
  /** Frankreich */
  Fr = 'FR',
  /** Französisch Guinea */
  Gf = 'GF',
  /** Französisch-Polynesien */
  Pf = 'PF',
  /** Französische Süd- und Antarktisgebiete */
  Tf = 'TF',
  /** Gabun */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgien */
  Ge = 'GE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Granada */
  Gd = 'GD',
  /** Griechenland */
  Gr = 'GR',
  /** Grönland */
  Gl = 'GL',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard und McDonaldinseln */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Indien */
  In = 'IN',
  /** Indonesien */
  Id = 'ID',
  /** Irak */
  Iq = 'IQ',
  /** Iran (Islamische Republik) */
  Ir = 'IR',
  /** Irland */
  Ie = 'IE',
  /** Island */
  Is = 'IS',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italien */
  It = 'IT',
  /** Jamaika */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jemen */
  Ye = 'YE',
  /** Jersey */
  Je = 'JE',
  /** Jordanien */
  Jo = 'JO',
  /** Kaimaninseln */
  Ky = 'KY',
  /** Kambodscha */
  Kh = 'KH',
  /** Kamerun */
  Cm = 'CM',
  /** Kanada */
  Ca = 'CA',
  /** Kap Verde */
  Cv = 'CV',
  /** Kasachstan */
  Kz = 'KZ',
  /** Katar */
  Qa = 'QA',
  /** Kenia */
  Ke = 'KE',
  /** Kirgisistan */
  Kg = 'KG',
  /** Kirivati */
  Ki = 'KI',
  /** Kokosinseln (Keelinginseln) */
  Cc = 'CC',
  /** Kolumbien */
  Co = 'CO',
  /** Komoren */
  Km = 'KM',
  /** Kongo */
  Cg = 'CG',
  /** Kongo (Demokratische Republik) */
  Cd = 'CD',
  /** Kroatien */
  Hr = 'HR',
  /** Kuba */
  Cu = 'CU',
  /** Kuwait */
  Kw = 'KW',
  /** Laos, Demokratische Volksrepublik */
  La = 'LA',
  /** Lesotho */
  Ls = 'LS',
  /** Lettland */
  Lv = 'LV',
  /** Libanon */
  Lb = 'LB',
  /** Liberia */
  Lr = 'LR',
  /** Libyen */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Litauen */
  Lt = 'LT',
  /** Luxemburg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagaskar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Malediven */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marokko */
  Ma = 'MA',
  /** Marshallinseln */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauretanien */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mazedonien */
  Mk = 'MK',
  /** Mexiko */
  Mx = 'MX',
  /** Mikronesien (Föderierte Staaten von) */
  Fm = 'FM',
  /** Moldawien */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolei */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Neukaledonien */
  Nc = 'NC',
  /** Neuseeland */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niederlande */
  Nl = 'NL',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Nordkorea */
  Kp = 'KP',
  /** Norfolkinsel */
  Nf = 'NF',
  /** Norwegen */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Österreich */
  At = 'AT',
  /** Osttimor */
  Tl = 'TL',
  /** Pakistan */
  Pk = 'PK',
  /** Palästina */
  Ps = 'PS',
  /** Palau */
  Pw = 'PW',
  /** Panama */
  Pa = 'PA',
  /** Papua Neu Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippinen */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Polen */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Réunion */
  Re = 'RE',
  /** Ruanda */
  Rw = 'RW',
  /** Rumänien */
  Ro = 'RO',
  /** Russland */
  Ru = 'RU',
  /** Saint-Barthélemy */
  Bl = 'BL',
  /** Saint-Pierre und Miquelon */
  Pm = 'PM',
  /** Salomonen */
  Sb = 'SB',
  /** Sambia */
  Zm = 'ZM',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** São Tomé und Príncipe */
  St = 'ST',
  /** Saudi Arabien */
  Sa = 'SA',
  /** Schweden */
  Se = 'SE',
  /** Schweiz */
  Ch = 'CH',
  /** Senegal */
  Sn = 'SN',
  /** Serbien */
  Rs = 'RS',
  /** Seychellen */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Simbabwe */
  Zw = 'ZW',
  /** Singapur */
  Sg = 'SG',
  /** Sint Maarten (niederländischer Teil) */
  Sx = 'SX',
  /** Slowakei */
  Sk = 'SK',
  /** Slowenien */
  Si = 'SI',
  /** Somalia */
  So = 'SO',
  /** Spanien */
  Es = 'ES',
  /** Spitzbergen und Jan Mayen */
  Sj = 'SJ',
  /** Sri Lanka */
  Lk = 'LK',
  /** St. Helena, Ascension und Tristan da Cunha */
  Sh = 'SH',
  /** St. Kitts und Nevis */
  Kn = 'KN',
  /** St. Lucia */
  Lc = 'LC',
  /** St. Martin (französischer Teil) */
  Mf = 'MF',
  /** St. Vincent und die Grenadinen */
  Vc = 'VC',
  /** Südafrika */
  Za = 'ZA',
  /** Sudan */
  Sd = 'SD',
  /** Südgeorgien und die Südlichen Sandwichinseln */
  Gs = 'GS',
  /** Südkorea */
  Kr = 'KR',
  /** Südsudan */
  Ss = 'SS',
  /** Surinam */
  Sr = 'SR',
  /** Swasiland */
  Sz = 'SZ',
  /** Syrien */
  Sy = 'SY',
  /** Tadschikistan */
  Tj = 'TJ',
  /** Taiwan (Provinz Chinas) */
  Tw = 'TW',
  /** Tansania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad und Tobago */
  Tt = 'TT',
  /** Tschad */
  Td = 'TD',
  /** Tschechien */
  Cz = 'CZ',
  /** Tunesien */
  Tn = 'TN',
  /** Türkei */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks- und Caicosinseln */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** USA - Sonstige Kleine Inseln */
  Um = 'UM',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** Ungarn */
  Hu = 'HU',
  /** Uruguay */
  Uy = 'UY',
  /** Usbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Vatikanstadt */
  Va = 'VA',
  /** Venezuela (Bolivarische Republik) */
  Ve = 'VE',
  /** Vereinigte Arabische Emirate */
  Ae = 'AE',
  /** Vereinigte Staaten von Amerika */
  Us = 'US',
  /** Vereinigtes Königreich */
  Gb = 'GB',
  /** Vietnam */
  Vn = 'VN',
  /** Wallis und Futuna */
  Wf = 'WF',
  /** Weihnachtsinsel */
  Cx = 'CX',
  /** Weißrussland */
  By = 'BY',
  /** Westsahara */
  Eh = 'EH',
  /** Zentralafrikanische Republik */
  Cf = 'CF',
  /** Zypern */
  Cy = 'CY'
}

/** This node provides information of delivery addresses. */
export interface CompanyLocationNode extends Node {
  __typename?: 'CompanyLocationNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  street: Scalars['String'];
  housenumber?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  partOfVillage?: Maybe<Scalars['String']>;
  country: CompanyLocationCountry;
}

export interface CompanyLocationNodeConnection {
  __typename?: 'CompanyLocationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyLocationNodeEdge>>;
}

/** A Relay edge containing a `CompanyLocationNode` and its cursor. */
export interface CompanyLocationNodeEdge {
  __typename?: 'CompanyLocationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyLocationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** This node provides information of companies. */
export interface CompanyNode extends Node {
  __typename?: 'CompanyNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  matchcode?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  customer: Scalars['Boolean'];
  carrier: Scalars['Boolean'];
  supplier: Scalars['Boolean'];
  pkOtherSystem?: Maybe<Scalars['Int']>;
  billingAddress?: Maybe<CompanyLocationNode>;
}

export interface CompanyNodeConnection {
  __typename?: 'CompanyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyNodeEdge>>;
}

/** A Relay edge containing a `CompanyNode` and its cursor. */
export interface CompanyNodeEdge {
  __typename?: 'CompanyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface CreateAccountMutation {
  __typename?: 'CreateAccountMutation';
  ok?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountNode>;
}

export interface CreateCompanyMutation {
  __typename?: 'CreateCompanyMutation';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<CompanyNode>;
}

export interface CreateDeliveryStatusMutation {
  __typename?: 'CreateDeliveryStatusMutation';
  ok?: Maybe<Scalars['Boolean']>;
  deliveryStatus?: Maybe<DeliveryStatusNode>;
}

export interface CreateInventoryMutation {
  __typename?: 'CreateInventoryMutation';
  inventory?: Maybe<InventoryNode>;
}

export interface CreateOrderMutation {
  __typename?: 'CreateOrderMutation';
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderNode>;
}

export interface CreatePileMutation {
  __typename?: 'CreatePileMutation';
  ok?: Maybe<Scalars['Boolean']>;
  pile?: Maybe<PileNode>;
}

export interface CreatePlotMutation {
  __typename?: 'CreatePlotMutation';
  ok?: Maybe<Scalars['Boolean']>;
  plot?: Maybe<PlotNode>;
}

export interface CreateWagonInventoryMutation {
  __typename?: 'CreateWagonInventoryMutation';
  inventories?: Maybe<Array<Maybe<InventoryNode>>>;
}


/** Obtain JSON Web Token mutation */
export interface CustomObtainJsonWebToken {
  __typename?: 'CustomObtainJSONWebToken';
  token: Scalars['String'];
}




export interface DeleteInventoryMutation {
  __typename?: 'DeleteInventoryMutation';
  ok?: Maybe<Scalars['Boolean']>;
  inventory?: Maybe<InventoryNode>;
}

export interface DeletePileMutation {
  __typename?: 'DeletePileMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

export interface DeletePilesFromExistingOrderMutation {
  __typename?: 'DeletePilesFromExistingOrderMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

export interface DeletePilesListMutation {
  __typename?: 'DeletePilesListMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

export interface DeletePlotMutation {
  __typename?: 'DeletePlotMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

export interface DeleteTimeSheetMutation {
  __typename?: 'DeleteTimeSheetMutation';
  ok?: Maybe<Scalars['Boolean']>;
  timesheet?: Maybe<TimeSheetNode>;
}

/** This node provides information of delivery status. */
export interface DeliveryStatusNode extends Node {
  __typename?: 'DeliveryStatusNode';
  createdBy?: Maybe<AccountNode>;
  lastmodifiedBy?: Maybe<AccountNode>;
  createdAt: Scalars['DateTime'];
  lastmodifiedAt: Scalars['DateTime'];
  number: Scalars['Int'];
  shortDescription?: Maybe<Scalars['String']>;
  customer: CompanyNode;
  deliveryAddress?: Maybe<CompanyLocationNode>;
  dateBegin: Scalars['Date'];
  dateEnd: Scalars['Date'];
  amount: Scalars['Int'];
  unit: DeliveryStatusUnit;
  allowTemporaryStorage: Scalars['Boolean'];
  type: DeliveryStatusType;
  planType: DeliveryStatusPlanType;
  active: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface DeliveryStatusNodeConnection {
  __typename?: 'DeliveryStatusNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeliveryStatusNodeEdge>>;
}

/** A Relay edge containing a `DeliveryStatusNode` and its cursor. */
export interface DeliveryStatusNodeEdge {
  __typename?: 'DeliveryStatusNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DeliveryStatusNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum DeliveryStatusPlanType {
  /** Einzelplan */
  Single = 'SINGLE',
  /** Gesamtplan */
  Total = 'TOTAL'
}

/** An enumeration. */
export enum DeliveryStatusType {
  /** Containerverladung */
  ContainerLoading = 'CONTAINER_LOADING',
  /** Waggon/Schiff */
  WagonShip = 'WAGON_SHIP',
  /** Frei Werk */
  FreeFacility = 'FREE_FACILITY'
}

/** An enumeration. */
export enum DeliveryStatusUnit {
  /** FM */
  Fm = 'FM',
  /** RM */
  Rm = 'RM',
  /** ATRO */
  Atro = 'ATRO',
  /** LUTRO */
  Lutro = 'LUTRO',
  /** SRM */
  Srm = 'SRM'
}

export interface DownloadPileMutation {
  __typename?: 'DownloadPileMutation';
  ok?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface DownloadWagonInventoryMutation {
  __typename?: 'DownloadWagonInventoryMutation';
  ok?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface EditAccountMutation {
  __typename?: 'EditAccountMutation';
  ok?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountNode>;
}

export interface EditInventoryMutation {
  __typename?: 'EditInventoryMutation';
  inventory?: Maybe<InventoryNode>;
}

/**
 * A mutation that allows to update an instance of :py:class:`apps.order.models.Order`.
 *
 * This update mutation is idempotent with regard to the fields: customer, supplier, vehicle, driver, transport_media,
 * amount, plan_date, repeat, and note. Therefore, even if one of these fields is not to change, its old value needs
 * to be provided, otherwise its value will be reset to the default value. In that regard it behaves similar to a HTTP
 * PUT method.
 *
 * Requires the ``order.change_order`` permission on the executing user.
 *
 * An example mutation can be found below:
 *
 * ```
 * mutation {
 *   editOrder(
 *     order: "<global id of the order to be edited>",
 *     amount: <amount>,
 *     customer: "<global id of a customer>",
 *     supplier: "<global id of a supplier>",
 *     vehicle: "<global id of a vehicle>",
 *     planDate: "<date in ISO-8601>",
 *     driver: "<global id of a driver>",
 *     repeat: <boolean>,
 *     transportMedia: "<global id of a file>",
 *     note: "<note text>"
 *   ) {
 *     ok
 *     order {
 *       id
 *     }
 *   }
 * }
 * ```
 */
export interface EditOrderMutation {
  __typename?: 'EditOrderMutation';
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderNode>;
}

/** A mutation that users can use edit a Timesheet. */
export interface EditTimeSheetMutation {
  __typename?: 'EditTimeSheetMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

/**
 * A type that represents a specific field error message with a message and code.
 *
 * >>> FieldErrorMessageType(
 * >>>     message=_('Password is wrong'),
 * >>>     code='wrong_password'
 * >>> )
 */
export interface FieldErrorMessageType {
  __typename?: 'FieldErrorMessageType';
  /** The error message. */
  message?: Maybe<Scalars['String']>;
  /** A code that represents the error message. */
  code?: Maybe<Scalars['String']>;
}

/**
 * A type that represents errors of an input field.
 *
 * For example, if a field "password" has a wrong value, the error can be indicated with:
 * >>> FieldErrorType(field='password', messages=[
 * >>>     FieldErrorMessageType(
 * >>>         message=_('Password is wrong'),
 * >>>         code='wrong_password'
 * >>>     )
 * >>> ])
 */
export interface FieldErrorType {
  __typename?: 'FieldErrorType';
  /** The name of the form field. */
  field?: Maybe<Scalars['String']>;
  /** A list of FieldErrorMessageTypes */
  messages?: Maybe<Array<Maybe<FieldErrorMessageType>>>;
}

/** This node provides information of file uploads. */
export interface FileUploadNode extends Node {
  __typename?: 'FileUploadNode';
  file: Scalars['String'];
  name: Scalars['String'];
  hashSum: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  s3Path?: Maybe<Scalars['String']>;
}


/** This node provides information of users */
export interface GroupNode extends Node {
  __typename?: 'GroupNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface GroupNodeConnection {
  __typename?: 'GroupNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupNodeEdge>>;
}

/** A Relay edge containing a `GroupNode` and its cursor. */
export interface GroupNodeEdge {
  __typename?: 'GroupNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<GroupNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** This node provides information of inventories by delivery status. */
export interface InventoriesByDeliveryStatusNode {
  __typename?: 'InventoriesByDeliveryStatusNode';
  allAmount?: Maybe<Scalars['Float']>;
  allCount?: Maybe<Scalars['Float']>;
  plotNumber?: Maybe<Scalars['Int']>;
  wagonNumber?: Maybe<Scalars['Int']>;
  isWagonReady?: Maybe<Scalars['Boolean']>;
  orderNumber?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['ID']>;
  salesNumber?: Maybe<Scalars['String']>;
}

export interface InventoryDetailNode extends Node {
  __typename?: 'InventoryDetailNode';
  woodLength: Scalars['Decimal'];
  stackWidth: Scalars['Decimal'];
  stackHeight: Scalars['Decimal'];
  stackNumber: Scalars['Decimal'];
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface InventoryDetailType {
  woodLength?: Maybe<Scalars['Float']>;
  stackWidth?: Maybe<Scalars['Float']>;
  stackHeight?: Maybe<Scalars['Float']>;
  stackNumber?: Maybe<Scalars['Int']>;
}

/** This node provides information of Inventory. */
export interface InventoryNode extends Node {
  __typename?: 'InventoryNode';
  createdBy?: Maybe<AccountNode>;
  lastmodifiedBy?: Maybe<AccountNode>;
  createdAt?: Scalars['DateTime'];
  order?: Maybe<OrderNode>;
  pile?: Maybe<PileNode>;
  amount: Scalars['Decimal'];
  time: Scalars['DateTime'];
  isBroached: Scalars['Boolean'];
  rest?: Maybe<Scalars['Int']>;
  count: Scalars['Int'];
  longitude?: Maybe<Scalars['Decimal']>;
  latitude?: Maybe<Scalars['Decimal']>;
  accuracy?: Maybe<Scalars['Decimal']>;
  isCalculated?: Maybe<Scalars['Boolean']>;
  wagonNumber?: Maybe<Scalars['Int']>;
  isWagonReady?: Maybe<Scalars['Boolean']>;
  inventoryDetails?: Maybe<Array<Maybe<InventoryDetailType>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isMutable?: Maybe<Scalars['Boolean']>;
}

export interface InventoryNodeConnection {
  __typename?: 'InventoryNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InventoryNodeEdge>>;
}

/** A Relay edge containing a `InventoryNode` and its cursor. */
export interface InventoryNodeEdge {
  __typename?: 'InventoryNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<InventoryNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** This node provides information about InventoryPlaning */
export interface InventoryPlaningNode extends Node {
  __typename?: 'InventoryPlaningNode';
  order: OrderStatusNode;
  pile: PileNode;
  amount: Scalars['Decimal'];
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface InventoryPlaningNodeConnection {
  __typename?: 'InventoryPlaningNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InventoryPlaningNodeEdge>>;
}

/** A Relay edge containing a `InventoryPlaningNode` and its cursor. */
export interface InventoryPlaningNodeEdge {
  __typename?: 'InventoryPlaningNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<InventoryPlaningNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** This node provides a summary of all inventories in an order. */
export interface InventorySummaryNode {
  __typename?: 'InventorySummaryNode';
  stackWidth?: Maybe<Scalars['Float']>;
  combinedHeight?: Maybe<Scalars['Float']>;
  woodLength?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  stackNumber?: Maybe<Scalars['Int']>;
}

/** An enumeration. */
export enum LocationTypeChoice {
  Pile = 'PILE',
  Forest = 'FOREST',
  Station = 'STATION',
  Wagon = 'WAGON',
  Harbor = 'HARBOR',
  Ship = 'SHIP',
  Factory = 'FACTORY'
}

/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface Mutation {
  __typename?: 'Mutation';
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<CustomObtainJsonWebToken>;
  /** A mutation that users can use to check in and create a Timesheet. */
  checkIn?: Maybe<CheckInMutation>;
  /** A mutation that users can use to end the current Timesheet and check out. */
  checkOut?: Maybe<CheckOutMutation>;
  /** A mutation that users can use edit a Timesheet. */
  editTimesheet?: Maybe<EditTimeSheetMutation>;
  deleteTimesheet?: Maybe<DeleteTimeSheetMutation>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  createOrder?: Maybe<CreateOrderMutation>;
  rollbackOrderStatusChange?: Maybe<RollbackOrderStatusChange>;
  changeToNextStatus?: Maybe<ChangeToNextStatus>;
  /**
   * A mutation that allows to update an instance of :py:class:`apps.order.models.Order`.
   *
   * This update mutation is idempotent with regard to the fields: customer, supplier, vehicle, driver, transport_media,
   * amount, plan_date, repeat, and note. Therefore, even if one of these fields is not to change, its old value needs
   * to be provided, otherwise its value will be reset to the default value. In that regard it behaves similar to a HTTP
   * PUT method.
   *
   * Requires the ``order.change_order`` permission on the executing user.
   *
   * An example mutation can be found below:
   *
   * ```
   * mutation {
   *   editOrder(
   *     order: "<global id of the order to be edited>",
   *     amount: <amount>,
   *     customer: "<global id of a customer>",
   *     supplier: "<global id of a supplier>",
   *     vehicle: "<global id of a vehicle>",
   *     planDate: "<date in ISO-8601>",
   *     driver: "<global id of a driver>",
   *     repeat: <boolean>,
   *     transportMedia: "<global id of a file>",
   *     note: "<note text>"
   *   ) {
   *     ok
   *     order {
   *       id
   *     }
   *   }
   * }
   * ```
   */
  editOrder?: Maybe<EditOrderMutation>;
  createPile?: Maybe<CreatePileMutation>;
  importPiles?: Maybe<UploadPilesMutation>;
  exportPiles?: Maybe<DownloadPileMutation>;
  changePile?: Maybe<ChangePileMutation>;
  deletePilesList?: Maybe<DeletePilesListMutation>;
  deletePile?: Maybe<DeletePileMutation>;
  createPlot?: Maybe<CreatePlotMutation>;
  changePlot?: Maybe<ChangePlotMutation>;
  deletePlot?: Maybe<DeletePlotMutation>;
  appendPilesToExistingOrder?: Maybe<AppendPilesToExistingOrderMutation>;
  deletePilesFromExistingOrder?: Maybe<DeletePilesFromExistingOrderMutation>;
  createInventory?: Maybe<CreateInventoryMutation>;
  createWagonInventory?: Maybe<CreateWagonInventoryMutation>;
  downloadWagonInventory?: Maybe<DownloadWagonInventoryMutation>;
  createDeliveryStatus?: Maybe<CreateDeliveryStatusMutation>;
  changeDeliveryStatus?: Maybe<ChangeDeliveryStatusMutation>;
  editInventory?: Maybe<EditInventoryMutation>;
  deleteInventory?: Maybe<DeleteInventoryMutation>;
  setOrderStatus?: Maybe<OrderStatusMutation>;
  setDeliveryNoteCustomer?: Maybe<OrderDeliveryNoteCustomerMutation>;
  setDeliveryNoteSupplier?: Maybe<OrderDeliveryNoteSupplierMutation>;
  /**
   * A mutation that allows a logged in user to change his/her password by providing the old password.
   *
   * Make sure to query for the ok field as well as the errors to see if changing the password was successful. For
   * example:
   *
   * ```
   * mutation {
   *   changePassword(newPassword1: "<new-pw>", newPassword2: "<new-pw>", oldPassword: "<old-pw>") {
   *     ok
   *     errors {
   *       field
   *       messages {
   *         code
   *         message
   *       }
   *     }
   *   }
   * }
   * ```
   */
  changePassword?: Maybe<ChangePasswordMutation>;
  createAccount?: Maybe<CreateAccountMutation>;
  editAccount?: Maybe<EditAccountMutation>;
  changeRoute?: Maybe<RouteMutation>;
  createCompany?: Maybe<CreateCompanyMutation>;
  changeCompany?: Maybe<ChangeCompanyMutation>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationTokenAuthArgs {
  clientKey: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCheckInArgs {
  checkInDispatchedAt: Scalars['DateTime'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCheckOutArgs {
  checkOutDispatchedAt: Scalars['DateTime'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationEditTimesheetArgs {
  checkInDispatchedAt?: Maybe<Scalars['DateTime']>;
  checkOutDispatchedAt?: Maybe<Scalars['DateTime']>;
  timesheet: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDeleteTimesheetArgs {
  timesheet: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationVerifyTokenArgs {
  token: Scalars['String'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationRefreshTokenArgs {
  token: Scalars['String'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreateOrderArgs {
  amount: Scalars['Int'];
  customer: Scalars['ID'];
  deliveryStatus?: Maybe<Scalars['ID']>;
  driver?: Maybe<Scalars['ID']>;
  isPlausible?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  noteDriver?: Maybe<Scalars['String']>;
  piles?: Maybe<Array<Maybe<Scalars['String']>>>;
  planDate?: Maybe<Scalars['DateTime']>;
  repeat?: Maybe<Scalars['Boolean']>;
  repeatEndDate?: Maybe<Scalars['DateTime']>;
  routes?: Maybe<Array<Maybe<Scalars['String']>>>;
  supplier?: Maybe<Scalars['ID']>;
  transportMedia?: Maybe<Scalars['ID']>;
  vehicle?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationRollbackOrderStatusChangeArgs {
  orderId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangeToNextStatusArgs {
  orderId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationEditOrderArgs {
  amount: Scalars['Int'];
  customer: Scalars['ID'];
  deliveryStatus?: Maybe<Scalars['ID']>;
  driver?: Maybe<Scalars['ID']>;
  isPlausible?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  noteDriver?: Maybe<Scalars['String']>;
  order: Scalars['ID'];
  piles?: Maybe<Array<Maybe<Scalars['String']>>>;
  planDate?: Maybe<Scalars['DateTime']>;
  repeat?: Maybe<Scalars['Boolean']>;
  repeatEndDate?: Maybe<Scalars['DateTime']>;
  routes?: Maybe<Array<Maybe<Scalars['String']>>>;
  supplier?: Maybe<Scalars['ID']>;
  transportMedia?: Maybe<Scalars['ID']>;
  vehicle?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreatePileArgs {
  amountUnit: AmountUnitChoice;
  category?: Maybe<Scalars['String']>;
  cbkNet: Scalars['CustomDecimal'];
  cbkSolid: Scalars['CustomDecimal'];
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  factorCbkNetCbkSolid?: Maybe<Scalars['CustomDecimal']>;
  latitude: Scalars['CustomDecimal'];
  location: Scalars['String'];
  locationPlan?: Maybe<Scalars['String']>;
  locationType: LocationTypeChoice;
  logLength: Scalars['CustomDecimal'];
  longitude: Scalars['CustomDecimal'];
  markedAsBroached?: Maybe<Scalars['Boolean']>;
  mustBeAccepted?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  pileNumberFurnisher?: Maybe<Scalars['String']>;
  plotNumber: Scalars['Int'];
  qualityType: QualityTypeChoice;
  sort: Scalars['String'];
  status: OldSystemStatusChoice;
  timber?: Maybe<Scalars['String']>;
  type: PileTypeChoice;
  usageSort: UsageSortChoice;
  woodtype: Scalars['String'];
  woodtype2?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationImportPilesArgs {
  pilesFile: Scalars['Upload'];
  plotNumber: Scalars['Int'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationExportPilesArgs {
  pilesIds?: Maybe<Array<Scalars['ID']>>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangePileArgs {
  amountUnit: AmountUnitChoice;
  category?: Maybe<Scalars['String']>;
  cbkNet: Scalars['CustomDecimal'];
  cbkSolid: Scalars['CustomDecimal'];
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  factorCbkNetCbkSolid?: Maybe<Scalars['CustomDecimal']>;
  latitude: Scalars['CustomDecimal'];
  location: Scalars['String'];
  locationPlan?: Maybe<Scalars['String']>;
  locationType: LocationTypeChoice;
  logLength: Scalars['CustomDecimal'];
  longitude: Scalars['CustomDecimal'];
  markedAsBroached?: Maybe<Scalars['Boolean']>;
  mustBeAccepted?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  pileId: Scalars['ID'];
  pileNumberFurnisher?: Maybe<Scalars['String']>;
  plotNumber: Scalars['Int'];
  qualityType: QualityTypeChoice;
  sort: Scalars['String'];
  status: OldSystemStatusChoice;
  timber?: Maybe<Scalars['String']>;
  type: PileTypeChoice;
  usageSort: UsageSortChoice;
  woodtype: Scalars['String'];
  woodtype2?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDeletePilesListArgs {
  pilesIds?: Maybe<Array<Scalars['ID']>>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDeletePileArgs {
  pileId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreatePlotArgs {
  category: CategoryTypeChoice;
  customer?: Maybe<Scalars['ID']>;
  done?: Maybe<Scalars['Boolean']>;
  mustBeRemovedSeparately?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangePlotArgs {
  category: CategoryTypeChoice;
  customer?: Maybe<Scalars['ID']>;
  done?: Maybe<Scalars['Boolean']>;
  mustBeRemovedSeparately?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  plotId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDeletePlotArgs {
  plotId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationAppendPilesToExistingOrderArgs {
  orderId: Scalars['ID'];
  pileIdList: Array<Maybe<Scalars['ID']>>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDeletePilesFromExistingOrderArgs {
  orderId: Scalars['ID'];
  pileIdList: Array<Maybe<Scalars['ID']>>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreateInventoryArgs {
  accuracy?: Maybe<Scalars['Decimal']>;
  amount: Scalars['Float'];
  count?: Maybe<Scalars['Int']>;
  inventoryDetails?: Maybe<Array<Maybe<InventoryDetailType>>>;
  isBroached: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  order: Scalars['ID'];
  pile: Scalars['ID'];
  rest?: Maybe<Scalars['Int']>;
  time: Scalars['String'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreateWagonInventoryArgs {
  accuracy?: Maybe<Scalars['Decimal']>;
  amount: Scalars['Float'];
  count: Scalars['Int'];
  inventoryDetails?: Maybe<Array<Maybe<InventoryDetailType>>>;
  isWagonReady: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Decimal']>;
  loadEverything?: Maybe<Scalars['Boolean']>;
  longitude?: Maybe<Scalars['Decimal']>;
  order: Scalars['ID'];
  time: Scalars['String'];
  wagonNumber: Scalars['Int'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDownloadWagonInventoryArgs {
  deliveryStatusId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreateDeliveryStatusArgs {
  allowTemporaryStorage: Scalars['Boolean'];
  amount?: Maybe<Scalars['Int']>;
  customer: Scalars['ID'];
  dateBegin: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  deliveryAddress?: Maybe<Scalars['ID']>;
  planType: PlanTypeChoice;
  shortDescription?: Maybe<Scalars['String']>;
  type: TypeChoice;
  unit: UnitChoice;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangeDeliveryStatusArgs {
  allowTemporaryStorage: Scalars['Boolean'];
  amount?: Maybe<Scalars['Int']>;
  customer: Scalars['ID'];
  dateBegin: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  deliveryAddress?: Maybe<Scalars['ID']>;
  deliveryStatusId: Scalars['ID'];
  planType: PlanTypeChoice;
  shortDescription?: Maybe<Scalars['String']>;
  type: TypeChoice;
  unit: UnitChoice;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationEditInventoryArgs {
  accuracy?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  count?: Maybe<Scalars['Int']>;
  inventoryDetails?: Maybe<Array<Maybe<InventoryDetailType>>>;
  inventoryId: Scalars['ID'];
  isBroached: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Int']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationDeleteInventoryArgs {
  inventoryId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationSetOrderStatusArgs {
  accuracy?: Maybe<Scalars['Decimal']>;
  dispatchedAt?: Maybe<Scalars['DateTime']>;
  idList: Array<Maybe<Scalars['ID']>>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  repeatOnFinished?: Maybe<Scalars['Boolean']>;
  status: StatusChoice;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationSetDeliveryNoteCustomerArgs {
  deliveryNoteCustomerId: Scalars['ID'];
  orderId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationSetDeliveryNoteSupplierArgs {
  deliveryNoteSupplierId: Scalars['ID'];
  orderId: Scalars['ID'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangePasswordArgs {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  oldPassword: Scalars['String'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreateAccountArgs {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat: Scalars['String'];
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationEditAccountArgs {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  passwordRepeat?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangeRouteArgs {
  orderId: Scalars['ID'];
  routes?: Maybe<Array<Maybe<RouteNodeInput>>>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationCreateCompanyArgs {
  billingAddressCountry?: Maybe<Scalars['String']>;
  billingAddressHousenumber?: Maybe<Scalars['String']>;
  billingAddressPartOfVillage?: Maybe<Scalars['String']>;
  billingAddressPostcode: Scalars['String'];
  billingAddressStreet?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<Scalars['Boolean']>;
  information?: Maybe<Scalars['String']>;
  matchcode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['Boolean']>;
}


/** This class will inherit from multiple Mutations as we begin to add more apps to our project. */
export interface MutationChangeCompanyArgs {
  billingAddressCountry?: Maybe<Scalars['String']>;
  billingAddressHousenumber?: Maybe<Scalars['String']>;
  billingAddressPartOfVillage?: Maybe<Scalars['String']>;
  billingAddressPostcode: Scalars['String'];
  billingAddressStreet?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  customer?: Maybe<Scalars['Boolean']>;
  information?: Maybe<Scalars['String']>;
  matchcode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['Boolean']>;
}

/** An object with an ID */
export interface Node {
  /** The ID of the object. */
  id: Scalars['ID'];
}

/** An enumeration. */
export enum OldSystemStatusChoice {
  Preplanning = 'PREPLANNING',
  Disposable = 'DISPOSABLE'
}

export interface OrderDeliveryNoteCustomerMutation {
  __typename?: 'OrderDeliveryNoteCustomerMutation';
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderNode>;
}

export interface OrderDeliveryNoteSupplierMutation {
  __typename?: 'OrderDeliveryNoteSupplierMutation';
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderNode>;
}

/** An enumeration. */
export enum OrderDeliveryType {
  /** Vorlieferung */
  Predelivery = 'PREDELIVERY',
  /** Lieferung */
  Delivery = 'DELIVERY'
}

/** This node provides information of order. */
export interface OrderNode extends Node {
  __typename?: 'OrderNode';
  createdBy?: Maybe<AccountNode>;
  lastmodifiedBy?: Maybe<AccountNode>;
  createdAt: Scalars['DateTime'];
  lastmodifiedAt: Scalars['DateTime'];
  amount: Scalars['Int'];
  collectedFromOtherSystem: Scalars['Boolean'];
  customer: CompanyNode;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryNoteCustomer?: Maybe<FileUploadNode>;
  deliveryNoteSupplier?: Maybe<FileUploadNode>;
  deliveryType?: Maybe<OrderDeliveryType>;
  driver?: Maybe<AccountNode>;
  note?: Maybe<Scalars['String']>;
  noteDriver?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  piles: PileNodeConnection;
  planDate?: Maybe<Scalars['DateTime']>;
  repeat: Scalars['Boolean'];
  repeatEndDate?: Maybe<Scalars['DateTime']>;
  isPlausible: Scalars['Boolean'];
  transportMedia?: Maybe<FileUploadNode>;
  deliveryStatus?: Maybe<DeliveryStatusNode>;
  type: OrderType;
  supplier?: Maybe<CompanyNode>;
  vehicle?: Maybe<VehicleNode>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  statusChanges?: Maybe<Array<Maybe<StatusChangeNode>>>;
  route: RouteNodeConnection;
  inventories: InventoryNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  canBeAborted?: Maybe<Scalars['Boolean']>;
  inventorySummary?: Maybe<Array<Maybe<InventorySummaryNode>>>;
}


/** This node provides information of order. */
export interface OrderNodePilesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This node provides information of order. */
export interface OrderNodeRouteArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['ID']>;
}


/** This node provides information of order. */
export interface OrderNodeInventoriesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['ID']>;
}

export interface OrderNodeConnection {
  __typename?: 'OrderNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderNodeEdge>>;
}

/** A Relay edge containing a `OrderNode` and its cursor. */
export interface OrderNodeEdge {
  __typename?: 'OrderNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface OrderStatusMutation {
  __typename?: 'OrderStatusMutation';
  ok?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<Array<Maybe<OrderNode>>>;
}

/** This node provides information about Status */
export interface OrderStatusNode extends Node {
  __typename?: 'OrderStatusNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
}

export interface OrderStatusNodeConnection {
  __typename?: 'OrderStatusNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderStatusNodeEdge>>;
}

/** A Relay edge containing a `OrderStatusNode` and its cursor. */
export interface OrderStatusNodeEdge {
  __typename?: 'OrderStatusNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderStatusNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum OrderType {
  /** mit_polter */
  WithPile = 'WITH_PILE',
  /** ohne_polter */
  WithoutPile = 'WITHOUT_PILE'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
}

/** An enumeration. */
export enum PileAmountUnit {
  /** Tonne atro */
  Atr = 'ATR',
  /** Tonne lutro */
  Lut = 'LUT',
  /** Kubikfuß */
  Cft = 'CFT',
  /** Schüttraummeter */
  Srm = 'SRM',
  /** Stück */
  Stk = 'STK',
  /** Laufmeter */
  Lfm = 'LFM',
  /** Kubikmeter */
  Cbm = 'CBM',
  /** Festmeter ohne Rinde */
  Fmo = 'FMO',
  /** Raummeter mit Rinde */
  Rmm = 'RMM'
}

/** A node representing the location of a pile. */
export interface PileLocationNode extends Node {
  __typename?: 'PileLocationNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The location name. */
  name?: Maybe<Scalars['String']>;
}

export interface PileLocationNodeConnection {
  __typename?: 'PileLocationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PileLocationNodeEdge>>;
}

/** A Relay edge containing a `PileLocationNode` and its cursor. */
export interface PileLocationNodeEdge {
  __typename?: 'PileLocationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PileLocationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum PileLocationType {
  /** Polter */
  Pile = 'PILE',
  /** Waldlager */
  Forest = 'FOREST',
  /** Bahnhof */
  Station = 'STATION',
  /** Waggon */
  Wagon = 'WAGON',
  /** Hafen */
  Harbor = 'HARBOR',
  /** Schiff */
  Ship = 'SHIP',
  /** Werk */
  Factory = 'FACTORY'
}

/** This node provides information of users. */
export interface PileNode extends Node {
  __typename?: 'PileNode';
  logLength: Scalars['Decimal'];
  plot?: Maybe<PlotNode>;
  number: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
  location: Scalars['String'];
  woodtype: Scalars['String'];
  woodtype2: Scalars['String'];
  sort: Scalars['String'];
  cbkNet: Scalars['Decimal'];
  factorCbkNetCbkSolid: Scalars['Decimal'];
  cbkSolid: Scalars['Decimal'];
  cbkSolidActual: Scalars['Decimal'];
  cbkNetActual: Scalars['Decimal'];
  locationPlan: Scalars['String'];
  longitude: Scalars['Decimal'];
  latitude: Scalars['Decimal'];
  markedAsBroached: Scalars['Boolean'];
  pileNumberFurnisher: Scalars['String'];
  count: Scalars['Int'];
  oldSystemCount: Scalars['Int'];
  note: Scalars['String'];
  pkOtherSystem?: Maybe<Scalars['Int']>;
  client?: Maybe<ClientNode>;
  type: PileType;
  category?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  statusOldSystem: PileStatusOldSystem;
  timber?: Maybe<Scalars['String']>;
  amountUnit: PileAmountUnit;
  locationType: PileLocationType;
  usageSort: PileUsageSort;
  qualityType: PileQualityType;
  mustBeAccepted: Scalars['Boolean'];
  cubikActual: Scalars['Decimal'];
  cubikSolidActual: Scalars['Decimal'];
  countActual: Scalars['Int'];
  isBroached: Scalars['Boolean'];
  rest: Scalars['Decimal'];
  inventories?: Maybe<Array<Maybe<InventoryNode>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  canBeAddedToAnyOrder?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
}

export interface PileNodeConnection {
  __typename?: 'PileNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PileNodeEdge>>;
}

/** A Relay edge containing a `PileNode` and its cursor. */
export interface PileNodeEdge {
  __typename?: 'PileNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PileNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum PileQualityType {
  /** O */
  O = 'O',
  /** IN */
  In = 'IN',
  /** IF */
  If = 'IF',
  /** IK */
  Ik = 'IK',
  /** FK */
  Fk = 'FK',
  /** NF */
  Nf = 'NF',
  /** NFK */
  Nfk = 'NFK',
  /** B */
  B = 'B',
  /** BK */
  Bk = 'BK',
  /** C */
  C = 'C',
  /** D */
  D = 'D',
  /** OA */
  Oa = 'OA',
  /** BC */
  Bc = 'BC',
  /** CD */
  Cd = 'CD',
  /** BCD */
  Bcd = 'BCD',
  /** A_FU */
  AFu = 'A_FU',
  /** A_IS */
  AIs = 'A_IS',
  /** A_IF */
  AIf = 'A_IF',
  /** A_I2 */
  AI2 = 'A_I2',
  /** A_ID */
  AId = 'A_ID',
  /** A_IM */
  AIm = 'A_IM',
  /** A_SP */
  ASp = 'A_SP',
  /** A_Y */
  AY = 'A_Y',
  /** A_X */
  AX = 'A_X',
  /** A_Z */
  AZ = 'A_Z',
  /** A_BH */
  ABh = 'A_BH',
  /** CH_K */
  ChK = 'CH_K',
  /** CH_AB */
  ChAb = 'CH_AB',
  /** CH_BC */
  ChBc = 'CH_BC',
  /** CH_CD */
  ChCd = 'CH_CD',
  /** CH_ABC */
  ChAbc = 'CH_ABC',
  /** CH_R */
  ChR = 'CH_R',
  /** CH_1 */
  Ch_1 = 'CH_1',
  /** CH_2 */
  Ch_2 = 'CH_2',
  /** CH_BK */
  ChBk = 'CH_BK',
  /** CH_SK */
  ChSk = 'CH_SK',
  /** A */
  A = 'A',
  /** AK */
  Ak = 'AK'
}

/** An enumeration. */
export enum PileStatusOldSystem {
  /** Vorplanung */
  PrePlanning = 'PRE_PLANNING',
  /** Disponierbar */
  Disposable = 'DISPOSABLE'
}

/** An enumeration. */
export enum PileType {
  /** Polter */
  Pile = 'PILE',
  /** Einzelstamm */
  Log = 'LOG'
}

/** An enumeration. */
export enum PileTypeChoice {
  Pile = 'PILE',
  Log = 'LOG'
}

/** An enumeration. */
export enum PileUsageSort {
  /** XY */
  Xy = 'XY',
  /** F */
  F = 'F',
  /** TF */
  Tf = 'TF',
  /** SS */
  Ss = 'SS',
  /** SG */
  Sg = 'SG',
  /** WH */
  Wh = 'WH',
  /** PZ */
  Pz = 'PZ',
  /** SF */
  Sf = 'SF',
  /** RH */
  Rh = 'RH',
  /** HS */
  Hs = 'HS',
  /** EH */
  Eh = 'EH',
  /** RU */
  Ru = 'RU',
  /** IH */
  Ih = 'IH',
  /** P */
  P = 'P',
  /** M */
  M = 'M',
  /** R */
  R = 'R',
  /** SW */
  Sw = 'SW',
  /** OV */
  Ov = 'OV',
  /** PA */
  Pa = 'PA',
  /** PK */
  Pk = 'PK',
  /** ST */
  St = 'ST',
  /** OSB */
  Osb = 'OSB',
  /** MDF */
  Mdf = 'MDF',
  /** CB */
  Cb = 'CB',
  /** LA */
  La = 'LA',
  /** SB */
  Sb = 'SB',
  /** VB */
  Vb = 'VB',
  /** PH */
  Ph = 'PH',
  /** SL */
  Sl = 'SL',
  /** NL */
  Nl = 'NL',
  /** GL */
  Gl = 'GL',
  /** NS */
  Ns = 'NS',
  /** AU */
  Au = 'AU',
  /** CH_SW */
  ChSw = 'CH_SW',
  /** CH_KST */
  ChKst = 'CH_KST',
  /** CH_KBU */
  ChKbu = 'CH_KBU',
  /** CH_Z */
  ChZ = 'CH_Z',
  /** CH_P */
  ChP = 'CH_P',
  /** CH_H */
  ChH = 'CH_H',
  /** CH_HS */
  ChHs = 'CH_HS',
  /** CH_HHAP */
  ChHhap = 'CH_HHAP',
  /** CH_HHEB */
  ChHheb = 'CH_HHEB',
  /** CH_BRHL */
  ChBrhl = 'CH_BRHL',
  /** CH_BRHK */
  ChBrhk = 'CH_BRHK',
  /** CH_SP */
  ChSp = 'CH_SP',
  /** CH_KSP */
  ChKsp = 'CH_KSP',
  /** CH_RU */
  ChRu = 'CH_RU',
  /** CH_LANG */
  ChLang = 'CH_LANG'
}

/** An enumeration. */
export enum PlanTypeChoice {
  Single = 'SINGLE',
  Total = 'TOTAL'
}

/** An enumeration. */
export enum PlotCategory {
  /** Transport */
  Transport = 'TRANSPORT',
  /** Zukauf */
  Purchase = 'PURCHASE'
}

/** This node provides information of users. */
export interface PlotNode extends Node {
  __typename?: 'PlotNode';
  done: Scalars['Boolean'];
  number: Scalars['Int'];
  company?: Maybe<CompanyNode>;
  pkOtherSystem?: Maybe<Scalars['Int']>;
  category?: Maybe<PlotCategory>;
  mustBeRemovedSeparately: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  strSelection?: Maybe<Scalars['String']>;
  numberOfPiles?: Maybe<Scalars['Int']>;
  numberOfBroachedPiles?: Maybe<Scalars['Int']>;
  sumCbkNet?: Maybe<Scalars['Float']>;
  sumCbkSolid?: Maybe<Scalars['Float']>;
  sumCubikActual?: Maybe<Scalars['Float']>;
  sumCubikSolidActual?: Maybe<Scalars['Float']>;
  sumCount?: Maybe<Scalars['Float']>;
  sumCountActual?: Maybe<Scalars['Float']>;
}

export interface PlotNodeConnection {
  __typename?: 'PlotNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlotNodeEdge>>;
}

/** A Relay edge containing a `PlotNode` and its cursor. */
export interface PlotNodeEdge {
  __typename?: 'PlotNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlotNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** This node provides information about Status */
export interface PlotStatusNode extends Node {
  __typename?: 'PlotStatusNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
}

export interface PlotStatusNodeConnection {
  __typename?: 'PlotStatusNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlotStatusNodeEdge>>;
}

/** A Relay edge containing a `PlotStatusNode` and its cursor. */
export interface PlotStatusNodeEdge {
  __typename?: 'PlotStatusNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlotStatusNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum QualityTypeChoice {
  O = 'O',
  In = 'IN',
  If = 'IF',
  Ik = 'IK',
  Fk = 'FK',
  Nf = 'NF',
  Nfk = 'NFK',
  B = 'B',
  Bk = 'BK',
  C = 'C',
  D = 'D',
  Oa = 'OA',
  Bc = 'BC',
  Cd = 'CD',
  Bcd = 'BCD',
  AFu = 'A_FU',
  AIs = 'A_IS',
  AIf = 'A_IF',
  AI2 = 'A_I2',
  AId = 'A_ID',
  AIm = 'A_IM',
  ASp = 'A_SP',
  AY = 'A_Y',
  AX = 'A_X',
  AZ = 'A_Z',
  ABh = 'A_BH',
  ChK = 'CH_K',
  ChAb = 'CH_AB',
  ChBc = 'CH_BC',
  ChCd = 'CH_CD',
  ChAbc = 'CH_ABC',
  ChR = 'CH_R',
  Ch_1 = 'CH_1',
  Ch_2 = 'CH_2',
  ChBk = 'CH_BK',
  ChSk = 'CH_SK',
  A = 'A',
  Ak = 'AK'
}

/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface Query {
  __typename?: 'Query';
  routes?: Maybe<RouteNodeConnection>;
  logLengths?: Maybe<StringPaginationType>;
  supplier?: Maybe<StringPaginationType>;
  qualityTypes?: Maybe<StringPaginationType>;
  timbers?: Maybe<StringPaginationType>;
  pileClients?: Maybe<StringPaginationType>;
  usageSorts?: Maybe<StringPaginationType>;
  timeSheets?: Maybe<TimeSheetNodeConnection>;
  deliveryStatuses?: Maybe<DeliveryStatusNodeConnection>;
  deliveryStatus?: Maybe<DeliveryStatusNode>;
  deliveryStatusUnitSelection?: Maybe<Array<Maybe<Scalars['String']>>>;
  deliveryStatusTypeSelection?: Maybe<Array<Maybe<Scalars['String']>>>;
  inventoriesByDeliveryStatusTowardsWagon?: Maybe<Array<Maybe<InventoriesByDeliveryStatusNode>>>;
  inventoryPlanings?: Maybe<InventoryPlaningNodeConnection>;
  inventories?: Maybe<InventoryNodeConnection>;
  pileCategories?: Maybe<PileNodeConnection>;
  pileDistricts?: Maybe<PileNodeConnection>;
  pileTypes?: Maybe<PileNodeConnection>;
  pileSorts?: Maybe<PileNodeConnection>;
  pileLocations?: Maybe<PileLocationNodeConnection>;
  pileStatus?: Maybe<PileNodeConnection>;
  woodtypes?: Maybe<PileNodeConnection>;
  vehicles?: Maybe<VehicleNodeConnection>;
  companyLocations?: Maybe<CompanyLocationNodeConnection>;
  company?: Maybe<CompanyNode>;
  companies?: Maybe<CompanyNodeConnection>;
  groups?: Maybe<GroupNodeConnection>;
  times?: Maybe<TimeNodeConnection>;
  orderTypes?: Maybe<OrderNodeConnection>;
  orderStatus?: Maybe<OrderStatusNodeConnection>;
  order?: Maybe<OrderNode>;
  orders?: Maybe<OrderNodeConnection>;
  prefetchOrders?: Maybe<OrderNodeConnection>;
  piles?: Maybe<PileNodeConnection>;
  pilesNoLimit?: Maybe<PileNodeConnection>;
  pile?: Maybe<PileNode>;
  plotStatus?: Maybe<PlotStatusNodeConnection>;
  plots?: Maybe<PlotNodeConnection>;
  plot?: Maybe<PlotNode>;
  emails?: Maybe<StringPaginationType>;
  firstNames?: Maybe<StringPaginationType>;
  lastNames?: Maybe<StringPaginationType>;
  accounts?: Maybe<AccountNodeConnection>;
  me?: Maybe<AccountNode>;
  account?: Maybe<AccountNode>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryRoutesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryLogLengthsArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QuerySupplierArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryQualityTypesArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryTimbersArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileClientsArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryUsageSortsArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryTimeSheetsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryDeliveryStatusesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  fullDescription?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  singlePlanOnly?: Maybe<Scalars['Boolean']>;
  unitMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryDeliveryStatusArgs {
  deliveryStatusId?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryInventoriesByDeliveryStatusTowardsWagonArgs {
  deliveryStatusId?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryInventoryPlaningsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryInventoriesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileCategoriesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileDistrictsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileTypesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileSortsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileLocationsArgs {
  searchString?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileStatusArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryWoodtypesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryVehiclesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryCompanyLocationsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  company?: Maybe<Scalars['ID']>;
  company_Isnull?: Maybe<Scalars['Boolean']>;
  fullAddress?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryCompanyArgs {
  companyId?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryCompaniesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Scalars['Boolean']>;
  supplier_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryGroupsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name_Contains?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryTimesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryOrderTypesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['ID']>;
  supplier?: Maybe<Scalars['ID']>;
  supplier_Isnull?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['ID']>;
  vehicle_Isnull?: Maybe<Scalars['Boolean']>;
  driver?: Maybe<Scalars['ID']>;
  driver_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  number_Contains?: Maybe<Scalars['Int']>;
  collectedFromOtherSystem?: Maybe<Scalars['Boolean']>;
  isPlausible?: Maybe<Scalars['Boolean']>;
  addablePiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderByActiveStatus?: Maybe<Scalars['Boolean']>;
  usesPiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryOrderStatusArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryOrderArgs {
  orderId?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryOrdersArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['ID']>;
  supplier?: Maybe<Scalars['ID']>;
  supplier_Isnull?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['ID']>;
  vehicle_Isnull?: Maybe<Scalars['Boolean']>;
  driver?: Maybe<Scalars['ID']>;
  driver_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  number_Contains?: Maybe<Scalars['Int']>;
  collectedFromOtherSystem?: Maybe<Scalars['Boolean']>;
  isPlausible?: Maybe<Scalars['Boolean']>;
  addablePiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderByActiveStatus?: Maybe<Scalars['Boolean']>;
  usesPiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPrefetchOrdersArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['ID']>;
  supplier?: Maybe<Scalars['ID']>;
  supplier_Isnull?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['ID']>;
  vehicle_Isnull?: Maybe<Scalars['Boolean']>;
  driver?: Maybe<Scalars['ID']>;
  driver_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  activeOnly?: Maybe<Scalars['Boolean']>;
  number_Contains?: Maybe<Scalars['Int']>;
  collectedFromOtherSystem?: Maybe<Scalars['Boolean']>;
  isPlausible?: Maybe<Scalars['Boolean']>;
  addablePiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderByActiveStatus?: Maybe<Scalars['Boolean']>;
  usesPiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPilesArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPilesNoLimitArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category_Icontains?: Maybe<Scalars['String']>;
  client_Name_Icontains?: Maybe<Scalars['String']>;
  district?: Maybe<Array<Maybe<Scalars['String']>>>;
  district_Isnull?: Maybe<Scalars['Boolean']>;
  district_Icontains?: Maybe<Scalars['String']>;
  includeBroached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Icontains?: Maybe<Scalars['String']>;
  logLength_Gte?: Maybe<Scalars['Float']>;
  logLength_Lte?: Maybe<Scalars['Float']>;
  number_Contains?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plot_Number_Contains?: Maybe<Scalars['Int']>;
  plot_Number?: Maybe<Scalars['Int']>;
  plot_Company_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort_Icontains?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  timber?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  usageSort?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityType?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtype_Icontains?: Maybe<Scalars['String']>;
  isBroached?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<Scalars['String']>;
  plotId?: Maybe<Scalars['String']>;
  logLength?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_Name?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  woodtypeMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  plot_Company_Name_Multi?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
  logLengthMulti?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPileArgs {
  pileId?: Maybe<Scalars['ID']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPlotStatusArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPlotsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  company?: Maybe<Scalars['ID']>;
  number_Contains?: Maybe<Scalars['Int']>;
  includeAllStatus?: Maybe<Scalars['Boolean']>;
  selection?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryPlotArgs {
  plotId: Scalars['ID'];
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryEmailsArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryFirstNamesArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryLastNamesArgs {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  filterValue?: Maybe<Scalars['String']>;
  filterMultipleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryAccountsArgs {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  includeInactive?: Maybe<Scalars['Boolean']>;
}


/** This class will inherit from multiple Queries as we begin to add more apps to our project. */
export interface QueryAccountArgs {
  accountId?: Maybe<Scalars['ID']>;
}

export interface Refresh {
  __typename?: 'Refresh';
  token?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['GenericScalar']>;
}

export interface RollbackOrderStatusChange {
  __typename?: 'RollbackOrderStatusChange';
  order?: Maybe<OrderNode>;
}

export interface RouteMutation {
  __typename?: 'RouteMutation';
  ok?: Maybe<Scalars['Boolean']>;
}

export interface RouteNode extends Node {
  __typename?: 'RouteNode';
  index: Scalars['Int'];
  longitude: Scalars['Decimal'];
  latitude: Scalars['Decimal'];
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface RouteNodeConnection {
  __typename?: 'RouteNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RouteNodeEdge>>;
}

/** A Relay edge containing a `RouteNode` and its cursor. */
export interface RouteNodeEdge {
  __typename?: 'RouteNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RouteNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface RouteNodeInput {
  index: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

/** An enumeration. */
export enum StatusChangeNewStatus {
  /** erstellt */
  Created = 'CREATED',
  /** zugewiesen */
  Allocated = 'ALLOCATED',
  /** übermittelt */
  Transmitted = 'TRANSMITTED',
  /** begonnen */
  Commenced = 'COMMENCED',
  /** geladen */
  Loaded = 'LOADED',
  /** übergeben */
  Delivered = 'DELIVERED',
  /** beendet */
  Finished = 'FINISHED',
  /** abgebrochen */
  Aborted = 'ABORTED'
}

export interface StatusChangeNode extends Node {
  __typename?: 'StatusChangeNode';
  createdBy?: Maybe<AccountNode>;
  lastmodifiedBy?: Maybe<AccountNode>;
  createdAt: Scalars['DateTime'];
  lastmodifiedAt: Scalars['DateTime'];
  oldStatus?: Maybe<StatusChangeOldStatus>;
  newStatus: StatusChangeNewStatus;
  order: OrderStatusNode;
  dispatchedAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
}

/** An enumeration. */
export enum StatusChangeOldStatus {
  /** erstellt */
  Created = 'CREATED',
  /** zugewiesen */
  Allocated = 'ALLOCATED',
  /** übermittelt */
  Transmitted = 'TRANSMITTED',
  /** begonnen */
  Commenced = 'COMMENCED',
  /** geladen */
  Loaded = 'LOADED',
  /** übergeben */
  Delivered = 'DELIVERED',
  /** beendet */
  Finished = 'FINISHED',
  /** abgebrochen */
  Aborted = 'ABORTED'
}

/** An enumeration. */
export enum StatusChoice {
  Created = 'CREATED',
  Allocated = 'ALLOCATED',
  Transmitted = 'TRANSMITTED',
  Commenced = 'COMMENCED',
  Loaded = 'LOADED',
  Delivered = 'DELIVERED',
  Finished = 'FINISHED',
  Aborted = 'ABORTED'
}

/**
 * This type holds the information for a pagination of strings.
 *
 * page = current page
 * pages = total number of pages
 * has_next = is there a next page?
 * has_previous = is there a previous page?
 * strings = list of strings; this is the data
 */
export interface StringPaginationType {
  __typename?: 'StringPaginationType';
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
  strings?: Maybe<Array<Scalars['String']>>;
}

/** This node provides information about Time */
export interface TimeNode extends Node {
  __typename?: 'TimeNode';
  order: OrderStatusNode;
  begin: Scalars['DateTime'];
  end: Scalars['DateTime'];
  vehicle?: Maybe<VehicleNode>;
  user: AccountNode;
  kmBegin?: Maybe<Scalars['Int']>;
  kmEnd?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface TimeNodeConnection {
  __typename?: 'TimeNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeNodeEdge>>;
}

/** A Relay edge containing a `TimeNode` and its cursor. */
export interface TimeNodeEdge {
  __typename?: 'TimeNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TimeNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** This node provides information on the timesheets of a user */
export interface TimeSheetNode extends Node {
  __typename?: 'TimeSheetNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdBy?: Maybe<AccountNode>;
  lastmodifiedBy?: Maybe<AccountNode>;
  checkInCreatedAt: Scalars['DateTime'];
  checkInDispatchedAt: Scalars['DateTime'];
  checkOutCreatedAt?: Maybe<Scalars['DateTime']>;
  checkOutDispatchedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AccountNode>;
}

export interface TimeSheetNodeConnection {
  __typename?: 'TimeSheetNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeSheetNodeEdge>>;
}

/** A Relay edge containing a `TimeSheetNode` and its cursor. */
export interface TimeSheetNodeEdge {
  __typename?: 'TimeSheetNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TimeSheetNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** An enumeration. */
export enum TypeChoice {
  ContainerLoading = 'CONTAINER_LOADING',
  WagonShip = 'WAGON_SHIP',
  FreeFacility = 'FREE_FACILITY'
}

/** An enumeration. */
export enum UnitChoice {
  Fm = 'FM',
  Rm = 'RM',
  Atro = 'ATRO',
  Lutro = 'LUTRO',
  Srm = 'SRM'
}


export interface UploadPilesMutation {
  __typename?: 'UploadPilesMutation';
  ok?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
}

/** An enumeration. */
export enum UsageSortChoice {
  Xy = 'XY',
  F = 'F',
  Tf = 'TF',
  Ss = 'SS',
  Sg = 'SG',
  Wh = 'WH',
  Pz = 'PZ',
  Sf = 'SF',
  Rh = 'RH',
  Hs = 'HS',
  Eh = 'EH',
  Ru = 'RU',
  Ih = 'IH',
  P = 'P',
  M = 'M',
  R = 'R',
  Sw = 'SW',
  Ov = 'OV',
  Pa = 'PA',
  Pk = 'PK',
  St = 'ST',
  Osb = 'OSB',
  Mdf = 'MDF',
  Cb = 'CB',
  La = 'LA',
  Sb = 'SB',
  Vp = 'VP',
  Ph = 'PH',
  Sl = 'SL',
  Nl = 'NL',
  Gl = 'GL',
  Ns = 'NS',
  Au = 'AU',
  ChSw = 'CH_SW',
  ChKst = 'CH_KST',
  ChKbu = 'CH_KBU',
  ChZ = 'CH_Z',
  ChP = 'CH_P',
  ChH = 'CH_H',
  ChHs = 'CH_HS',
  ChHhap = 'CH_HHAP',
  ChHheb = 'CH_HHEB',
  ChBrhl = 'CH_BRHL',
  ChBrhk = 'CH_BRHK',
  ChSp = 'CH_SP',
  ChKsp = 'CH_KSP',
  ChRu = 'CH_RU',
  ChLang = 'CH_LANG'
}

/** This node provides information of vehicles. */
export interface VehicleNode extends Node {
  __typename?: 'VehicleNode';
  registrationNumber: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
}

export interface VehicleNodeConnection {
  __typename?: 'VehicleNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VehicleNodeEdge>>;
}

/** A Relay edge containing a `VehicleNode` and its cursor. */
export interface VehicleNodeEdge {
  __typename?: 'VehicleNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<VehicleNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface Verify {
  __typename?: 'Verify';
  payload?: Maybe<Scalars['GenericScalar']>;
}
