import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MultiSelect, MultiSelectProps } from '@src/components/Elements/MultiSelect/MultiSelect';

export const noCategory = 'noCategory';

export const CategorySelect: FC<Pick<MultiSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ pileCategories: PileNodeConnection }>(
    Pile.getPileCategories,
    {
      variables: {
        first: 10,
      },
      fetchPolicy: 'network-only',
    },
  );
  const { pileCategories: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 10,
        category_Icontains: value,
      });
    }, 100),
    [],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.pileCategories.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newEdges = fetchMoreResult.pileCategories.edges;
          const pageInfo = fetchMoreResult.pileCategories.pageInfo;

          return newEdges.length
            ? {
                pileCategories: {
                  pageInfo,
                  __typename: prev.pileCategories.__typename,
                  edges: [...prev.pileCategories.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <MultiSelect
      {...props}
      label={t('desktop.pages.piles.list.columns.category')}
      options={edges.map(({ node: { category } }) => ({
        title: category || t('desktop.pages.piles.filter.withoutCategory'),
        value: category || noCategory,
      }))}
      name='category'
      loading={loading}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
    />
  );
};
