import gql from 'graphql-tag';

export const createPlot = gql`
  mutation createPlot(
    $category: CategoryTypeChoice!
    $customer: ID
    $mustBeRemovedSeparately: Boolean
    $done: Boolean
    $number: Int!
  ) {
    createPlot(
      category: $category
      customer: $customer
      mustBeRemovedSeparately: $mustBeRemovedSeparately
      done: $done
      number: $number
    ) {
      ok
    }
  }
`;

export const changePlot = gql`
  mutation changePlot(
    $plotId: ID!
    $category: CategoryTypeChoice!
    $customer: ID
    $done: Boolean
    $mustBeRemovedSeparately: Boolean
    $number: Int!
  ) {
    changePlot(
      plotId: $plotId
      category: $category
      customer: $customer
      done: $done
      mustBeRemovedSeparately: $mustBeRemovedSeparately
      number: $number
    ) {
      ok
    }
  }
`;

export const deletePlot = gql`
  mutation deletePlot($plotId: ID!) {
    deletePlot(plotId: $plotId) {
      ok
    }
  }
`;
