import React, { FC, memo, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormItemProps, SelectProps } from 'formik-antd';
import { IonContent, IonItem, IonModal, IonSearchbar } from '@ionic/react';
import { Input } from '@src/components/Desktop/Containers/Input';
import { useFormikContext } from 'formik';
import {
  SearchTitleWrapper,
  SelectedMultiOptionsWrapper,
  StyledIonList,
  StyledLoadingOutlined,
} from '@src/components/Mobile/Elements/SearchSelect/SearchSelect.styles';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export interface SearchSelectProps extends Omit<SelectProps, 'options'>, Pick<FormItemProps, 'label'> {
  options?: Array<{ title: string; value: string }>;
  required?: boolean;
}

export interface Option {
  id: string;
  title: string;
  value: string;
}

export interface MobileSelectProps extends Pick<SearchSelectProps, 'required'> {
  allowNoSelection?: boolean;
  label: string;
  loading?: boolean;
  multi?: boolean;
  name: string;
  onLoadMore?: (e: any) => void;
  onSearch?: (value: string) => void;
  options?: Option[];
  prefilledValue?: string;
  prefilledValueMulti?: string[];
}

export interface SearchSelectBarProps extends Pick<SearchSelectProps, 'required'> {
  onSearch?: (value: string) => void;
}

/**
 * The search must not re-render if there are new options. So we use a memo function here.
 */
export const SearchSelectBar: FC<SearchSelectBarProps> = memo(({ onSearch }) => {
  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = useCallback(e => {
    setSearchText(e.detail.value);
    if (onSearch) {
      onSearch(e.detail.value);
    }
  }, []);

  return <IonSearchbar value={searchText} onIonChange={handleSearch} debounce={100} />;
});

export const MobileSearchSelect: FC<MobileSelectProps> = ({
  allowNoSelection,
  prefilledValue,
  name,
  label,
  options,
  loading,
  onSearch,
  onLoadMore,
  required,
  multi,
  prefilledValueMulti,
}) => {
  const { t } = useTranslation();
  const scrollDetailRef = useRef<any>();
  const myRef = useRef<any>();
  const { setFieldValue, values } = useFormikContext<any>();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(prefilledValue);
  const [selectedValueMulti, setSelectedValueMulti] = useState<Array<string | undefined>>(prefilledValueMulti || []);

  const cleanOptions = options || [];
  const optionBase = allowNoSelection
    ? [{ title: t('general.noSelection'), value: undefined, id: '-999' }, ...cleanOptions]
    : cleanOptions;

  /**
   * Returns the list of options that are displayed in this component.
   */
  const getCleanOptions = () => {
    if (!multi) return optionBase;

    return optionBase.filter(
      option => option.id === '-999' || (option.value && !selectedValueMulti?.includes(option.value)),
    );
  };

  /**
   * Reset the search whenever the drawer opens again.
   */
  useEffect(() => {
    if (onSearch && drawerVisible) {
      onSearch('');
    }
  }, [onSearch, drawerVisible]);

  /**
   * Returns the current value of the field.
   */
  const getFieldValue = () => {
    const value = values[name];

    return Array.isArray(value) ? value.join(', ') : value;
  };

  /**
   * Called when the input field is clicked.
   */
  const onInputClick = (event: SyntheticEvent) => {
    event.preventDefault();
    setDrawerVisible(true);
  };

  /**
   * Called when an entry is selected from all the options.
   */
  const onSelect = (title: string, value?: string) => {
    if (multi) {
      const currentList = values[name] || [];

      setFieldValue(name, value ? [...currentList, value] : []);
    } else {
      setFieldValue(name, value);
      setDrawerVisible(false);
    }
  };

  /**
   * Save the value that is passed from formik in `values` to the state.
   */
  useEffect(() => {
    if (!multi) {
      setSelectedValue(values[name]);
    } else {
      setSelectedValueMulti(values[name] || []);
    }
  }, [multi, setSelectedValueMulti, setSelectedValue, values, name]);

  /**
   * Called when in multi mode a value is removed.
   */
  const onRemoveMultiSelected = value => {
    setFieldValue(
      name,
      (values[name] || []).filter(v => v && v !== value),
    );
  };

  /**
   * Called whenever the user scrolls in the list.
   */
  const onScroll = (e: any) => {
    // save the details about scrolling in the list
    scrollDetailRef.current = e.detail;
  };

  /**
   * Is called whenever a scroll has ended
   */
  const onScrollEnd = (e: any) => {
    // check if scrolled to the end and if yes, call onLoadMore
    if (onLoadMore && myRef.current) {
      // the height for scrolling, this will change whenever the number of list items change
      const fullHeight: number = myRef.current.scrollHeight;
      // how far the user scrolled
      const currentScrolled: number = scrollDetailRef.current?.currentY;
      // screen height
      const screenHeight: number = e.target.clientHeight;

      if ((screenHeight + currentScrolled) / fullHeight > 0.85) {
        onLoadMore(e);
      }
    }
  };

  return (
    <>
      <Input
        readOnly
        label={label}
        type='text'
        name={name}
        required={required}
        onClick={onInputClick}
        value={getFieldValue()}
      />
      {drawerVisible && (
        <IonModal isOpen={drawerVisible}>
          <IonContent scrollEvents onIonScroll={onScroll} onIonScrollEnd={onScrollEnd}>
            <SearchTitleWrapper>
              <h3>{label}</h3>
              <Button icon={<CloseOutlined />} type='text' onClick={() => setDrawerVisible(false)} />
            </SearchTitleWrapper>
            <SearchSelectBar onSearch={onSearch} />
            <SelectedMultiOptionsWrapper>
              {multi &&
                optionBase
                  .filter(option => selectedValueMulti.includes(option.value))
                  .map(option => (
                    <Button
                      shape='round'
                      key={option.value}
                      icon={<CloseOutlined />}
                      type='primary'
                      onClick={() => onRemoveMultiSelected(option.value)}
                    >
                      {option.title}
                    </Button>
                  ))}
            </SelectedMultiOptionsWrapper>
            <StyledIonList ref={myRef}>
              {getCleanOptions().map(option => (
                <IonItem
                  color={selectedValue && option.value && option.value === selectedValue ? 'primary' : undefined}
                  onClick={() => onSelect(option.title, option.value)}
                  key={option.id}
                  // add a ripple effect when selected
                  className='ion-activatable'
                >
                  {option.title}
                </IonItem>
              ))}
            </StyledIonList>
            {loading && <StyledLoadingOutlined spin />}
          </IonContent>
        </IonModal>
      )}
    </>
  );
};
