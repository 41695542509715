import React, { FC, memo, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonIcon, isPlatform } from '@ionic/react';
import { albums } from 'ionicons/icons';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import L, { LatLngTuple } from 'leaflet';
import { TileLayer, useMapEvents, Marker, Polyline } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useTranslation } from 'react-i18next';
import { RoutingMachine } from '@src/components/Containers/Map/Routing';

import { default as leafletMarkerIcon2x } from 'leaflet/dist/images/marker-icon-2x.png';
import { default as leafletMarkerIcon } from 'leaflet/dist/images/marker-icon.png';
import { default as leafletMarkerIconShadow } from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'font-awesome/css/font-awesome.min.css';

import { Routes } from '@src/global';
import { BREAKPOINTS } from '@src/theme';
import { usePrevState } from '@src/hooks';
import { MeContext, Permissions, ResizeContext, ToastContext } from '@src/components/Providers';
import {
  LocationContext,
  OfflineHandlerContext,
  OfflineMapTilesHandlerContext,
} from '@src/components/Mobile/Providers';
import { LocationMarker } from '@src/components/Containers/Map/LocationMarker';
import { PileNode, RouteNodeInput } from '@src/generated/schema';
import {
  DefaultMapCenter,
  DefaultMarkerHeight,
  DownloadMinZoom,
  MapMaxZoom,
  MapMinZoom,
  MapTotalZoom,
  MarkerOpacity,
  MarkerOpacityBroached,
  MarkerToolTipOffsetX,
  MarkerToolTipOffsetY,
  MarkerToolTipOpacity,
} from '@src/global/Map';

import { StyledClusteringButton, StyledErrorWrapper, StyledMapContainer, StyledTooltip } from './Map.styles';
import { Marker as CustomMarker, RouteMarker } from './Marker';
import TileLayerOffline from '@src/components/Mobile/Providers/OfflineMapTilesHandler/TileLayerOffline';
import { ModalDataState } from '@src/components/Desktop/Pages/Private/Piles/List/List';
import { MapControl, POSITION_CLASSES, SCREEN_ORIENTATIONS } from './MapControl';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { renderToString } from 'react-dom/server';
import { widthHeightFactor } from '@src/components/Containers/Map/Marker/Marker';
import { RoutePathCacheHandlerContext } from '@src/components/Mobile/Providers/RoutePathsCache';

// This deletion and merge fixes a problem with the leaflet core files which react-leaflet is depending on
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: leafletMarkerIcon2x,
  iconUrl: leafletMarkerIcon,
  shadowUrl: leafletMarkerIconShadow,
});

export interface MapProps {
  addRouteMarker?: (latlng: { lat: number; lng: number }) => void;
  allowClusteringToggle?: boolean;
  allowDownload?: boolean;
  allowNavigationToOfflineMaps?: boolean;
  mapCenterReset?: boolean;
  markerFilterIsActive?: boolean;
  markerHeight: number;
  markers: ModalDataState;
  onDownloadClick?: () => void;
  onMarkerClick?: (marker: PileNode) => void;
  onToggleMarkerFilter?: () => void;
  orderId?: string | null;
  routeMarkers?: RouteNodeInput[];
  showActionBar?: boolean;
  showRoute?: boolean;
  showZoomActionBar?: boolean;
  toggleShowRoute?: (b: boolean) => void;
}

export interface MapSettings {
  center: LatLngTuple;
  zoom: number;
}

export interface LeafletMapProps {
  height: number;
  markerHeight: number;
  width: number;
}

interface MarkersProps {
  disableCluster: boolean;
  markerHeight?: number;
  markersList: PileNode[] | undefined;
  onMarkerClick?: (marker: PileNode) => void;
}

interface RouteMarkersProps {
  markersList: RouteNodeInput[] | undefined;
  showRouteMarkers: boolean;
}

const ChangeView = ({ center, zoom, handleClick }) => {
  const options = handleClick
    ? {
        // eslint-disable-next-line
        click(e) {
          handleClick(e.latlng);
        },
      }
    : {};
  const map = useMapEvents(options);

  useEffect(() => {
    map.setView(center, zoom);
  }, [map, center, zoom]);

  setTimeout(() => {
    if (map) map.invalidateSize();
  }, 1000);

  return null;
};

const markerIcon = (marker, markerHeight) => {
  const height = markerHeight || DefaultMarkerHeight;
  const width = height / widthHeightFactor;

  return L.divIcon({
    className: 'custom-icon',
    iconSize: [width, height],
    html: renderToString(<CustomMarker height={height} text={marker.rest.toString()} />),
    iconAnchor: [height, height],
  });
};

const RouteMarkers: FC<RouteMarkersProps> = memo(({ showRouteMarkers, markersList }) => (
  <>
    {showRouteMarkers &&
      markersList?.map(marker => (
        <RouteMarker key={marker.index} initialPosition={[marker.latitude, marker.longitude]} />
      ))}
  </>
));

// Since we don't want to re-render the markers everytime the location of the user is updated, the markers should only
// re-render whenever its data changes
const Markers: FC<MarkersProps> = memo(({ disableCluster, markersList, onMarkerClick, markerHeight }) => (
  <>
    {!disableCluster ? (
      // add a key on every render because markers are not cleanly removed from the cluster group, memo will handle
      // too many renders anyway. also refer to:
      // https://github.com/yuzhva/react-leaflet-markercluster/issues/149#issuecomment-854920761
      // @ts-ignore
      <MarkerClusterGroup key={Date.now()}>
        {markersList?.map(marker => (
          <Marker
            icon={markerIcon(marker, markerHeight)}
            key={marker.id}
            opacity={marker.isBroached ? MarkerOpacityBroached : MarkerOpacity}
            position={[marker.latitude, marker.longitude]}
            eventHandlers={{
              click: () => onMarkerClick && onMarkerClick(marker),
            }}
          >
            <StyledTooltip
              direction='right'
              offset={[MarkerToolTipOffsetX, MarkerToolTipOffsetY]}
              opacity={MarkerToolTipOpacity}
              permanent
            >
              {marker.number}
            </StyledTooltip>
          </Marker>
        ))}
      </MarkerClusterGroup>
    ) : (
      markersList?.map(marker => (
        <Marker
          icon={markerIcon(marker, markerHeight)}
          key={marker.id}
          opacity={marker.isBroached ? MarkerOpacityBroached : MarkerOpacity}
          position={[marker.latitude, marker.longitude]}
          eventHandlers={{
            click: () => onMarkerClick && onMarkerClick(marker),
          }}
        >
          <StyledTooltip
            direction='right'
            offset={[MarkerToolTipOffsetX, MarkerToolTipOffsetY]}
            opacity={MarkerToolTipOpacity}
            permanent
          >
            {marker.number}
          </StyledTooltip>
        </Marker>
      ))
    )}
  </>
));

// eslint-disable-next-line complexity
export const Map: FC<MapProps> = ({
  children,
  mapCenterReset,
  markerFilterIsActive,
  markerHeight,
  markers,
  onDownloadClick,
  onMarkerClick,
  onToggleMarkerFilter,
  addRouteMarker,
  showRoute,
  toggleShowRoute,
  routeMarkers = [],
  allowClusteringToggle = true,
  allowNavigationToOfflineMaps = true,
  allowDownload = true,
  showActionBar = isPlatform('hybrid'),
  showZoomActionBar = !isPlatform('mobile'),
  orderId,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { me, userHasPermissions } = useContext(MeContext);
  const { innerWidth, innerHeight } = useContext(ResizeContext);
  const { setIonToast } = useContext(ToastContext);
  const { isOnline } = useContext(NetworkContext);
  const { internetConnectionType } = useContext(OfflineHandlerContext);
  const { addRoutePath, getRoutePath, pathCoordinates } = useContext(RoutePathCacheHandlerContext);
  const { activeDownloadId, setMapInstance, setOfflineLayer, loadTilesForDownload } = useContext(
    OfflineMapTilesHandlerContext,
  );
  const location = useContext(LocationContext);

  const markersList: PileNode[] | undefined = !Array.isArray(markers) && markers ? [markers] : markers;

  const [leafletMapElement, setLeafletMapElement] = useState<any>(null);
  const [mapSettings, setMapSettings] = useState<MapSettings>({ center: [0, 0], zoom: 13 });
  const [disableCluster, setDisableCluster] = useState(false);
  const initialLeafletMapProps: LeafletMapProps = { height: 0, markerHeight: 0, width: 0 };
  const [leafletMapProps, setLeafletMapProps] = useState<LeafletMapProps>(initialLeafletMapProps);
  const [routeAlreadyCached, setRouteAlreadyCached] = useState<boolean>(false);
  const prevLeafletMapProps: any = usePrevState(leafletMapProps);

  const MAP_TILESERVER_URL = me?.client?.tileServerUrl || '';
  const GRAPHHOPPER_API_KEY = me?.client?.graphhopperApiKey || '';

  const userCanEditRoutes = () => userHasPermissions([Permissions.CAN_CHANGE_ROUTE, Permissions.CAN_ADD_ROUTE]);

  /**
   * @description Calculates optimal center & zoom for the map, so that all markers are visible
   */
  const calculateMapSettings = (): MapSettings => {
    if (!markersList?.length) return { center: DefaultMapCenter as LatLngTuple, zoom: MapMaxZoom };

    // We cast lat and long to Number to be used in Markerprops.
    const markerstListNumbers = markersList.map(marker => ({
      longitude: Number(marker.longitude),
      latitude: Number(marker.latitude),
    }));

    let minLon = markerstListNumbers[0].longitude;
    let maxLon = markerstListNumbers[0].longitude;
    let minLat = markerstListNumbers[0].latitude;
    let maxLat = markerstListNumbers[0].latitude;

    markerstListNumbers.forEach(marker => {
      if (marker.longitude < minLon) minLon = marker.longitude;
      if (marker.longitude > maxLon) maxLon = marker.longitude;
      if (marker.latitude < minLat) minLat = marker.latitude;
      if (marker.latitude > maxLat) maxLat = marker.latitude;
    });

    const latDistance = maxLat - minLat;
    const lonDistance = maxLon - minLon;

    let zoom = MapTotalZoom;

    for (let i = 1; i < MapTotalZoom; i += 1) {
      const osmHeightDistanceFactor = 0.0000026;
      const osmWidthDistanceFactor = 0.0000046;

      if (
        (leafletMapProps.height &&
          leafletMapProps.height * osmHeightDistanceFactor * Math.pow(2, i - 1) < latDistance) ||
        (leafletMapProps.width && leafletMapProps.width * osmWidthDistanceFactor * Math.pow(2, i - 1) < lonDistance)
      ) {
        zoom -= 1;
      } else {
        // Set the final zoom to the maximal zoom level, if it is too high otherwise.
        zoom = zoom > MapMaxZoom ? MapMaxZoom : zoom;

        break;
      }
    }

    const center: LatLngTuple = [minLat + latDistance / 2, minLon + lonDistance / 2];

    return { center, zoom };
  };

  /**
   * @description Centers the map
   */
  const centerLocation = (event: SyntheticEvent) => {
    event.preventDefault();
    if (location?.latitude && location?.longitude) {
      setMapSettings({ center: [location?.latitude, location?.longitude], zoom: MapMaxZoom });
    }
  };

  const handleDownloadClick = (event: SyntheticEvent) => {
    event.preventDefault();
    if (activeDownloadId) {
      setIonToast({
        show: true,
        message: t('containers.map.downloadAlreadyRunning'),
      });

      return;
    }
    if (mapSettings && mapSettings.zoom && mapSettings.zoom < DownloadMinZoom) {
      setIonToast({
        show: true,
        message: t('containers.map.zoomToSmall'),
      });

      return;
    }
    if (!isOnline || internetConnectionType === 'cellular') {
      setIonToast({
        show: true,
        message: t('containers.map.wifiRequired'),
      });

      return;
    }
    loadTilesForDownload();
    if (onDownloadClick) onDownloadClick();
  };

  const handleSettingsClick = (event: SyntheticEvent) => {
    event.preventDefault();
    push(Routes.OFFLINE_MAPS);
  };

  /**
   * @description Toggles the grouping of map markers.
   */
  const handleClusteringClick = (event: SyntheticEvent) => {
    event.preventDefault();
    setDisableCluster(!disableCluster);
  };

  /**
   * @description Toggles the marker filter.
   */
  const toggleMarkerFilter = (event: SyntheticEvent) => {
    event.preventDefault();

    if (onToggleMarkerFilter) {
      onToggleMarkerFilter();
    }
  };

  useEffect(() => {
    setRouteAlreadyCached(
      JSON.stringify(pathCoordinates?.waypoints) ===
        JSON.stringify(routeMarkers.map(({ latitude, longitude }) => [latitude, longitude])),
    );
  }, [pathCoordinates, routeMarkers]);

  /**
   * @description Pulls path description for route paths display from cache
   */
  useEffect(() => {
    if (orderId) {
      getRoutePath(orderId);
    }
  }, [orderId]);

  /**
   * @description Needed for the mobile app. Forces the leaflet map to re-validate its size on every re-render.
   * Map-tiles were fetched too late or not at all when moving or zooming the map.
   * Using "invalidateSize" definitely helps speeding up the fetching of tiles.
   * Using a minor timeout improves the fetching even more. No idea why.
   * If this is too resource hungry consider adding a debounce.
   */
  useEffect(() => {
    setTimeout(() => {
      leafletMapElement?.current?.leafletElement?.invalidateSize();
    }, 100);
  });

  /**
   * @description Calculates the map center and zoom settings after the leaflet map properties have been initialized.
   */
  useEffect(() => {
    // After initializing the map properties they will never have the initialLeafletMapProps values again.
    // The map is not supposed to re-center on leafletMapProps changes, except for initialization.
    // Comparing the previous leafletMapProps state to the initial values will confirm, that this is the first change
    // made to the initial properties.
    // NOTE: String comparison requires the object key-values to be in the same order.
    if (JSON.stringify(initialLeafletMapProps) === JSON.stringify(prevLeafletMapProps)) {
      setMapSettings(calculateMapSettings());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafletMapProps]);

  /**
   * @description Recalculates the map center and zoom settings whenever 'mapCenterReset' updates.
   * 'mapCenterReset' is an empty array here. Updating the 'mapCenterReset' state-value from the parent
   * component with a new empty array will trigger this side-effect.
   */
  useEffect(() => {
    if (!!markersList?.length && mapCenterReset) {
      setMapSettings(calculateMapSettings());
    }

    // Adding "calculateMapSettings" to the dependencies would cause re-render, whenever the map dimensions change,
    // because "leafletMapProps" would be a dependency on the "useCallback" hook wrapper for "calculateMapSettings".
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCenterReset]); // Todo have a look if this can be tweaked

  /**
   * @description Resets the map properties on window resize.
   */
  useEffect(() => {
    const maxWidth = BREAKPOINTS.xxlarge as number;
    const height = innerHeight || 0;
    const width = innerWidth ? (innerWidth > maxWidth ? maxWidth : innerWidth) : 0;

    setLeafletMapProps({
      height,
      markerHeight,
      width,
    });
  }, [markerHeight, innerWidth, innerHeight, ScreenOrientation.type]);

  useEffect(() => {
    const leafletElement = leafletMapElement;

    const offlineLayer = TileLayerOffline(MAP_TILESERVER_URL, {
      minZoom: MapMinZoom,
      maxZoom: MapMaxZoom,
    });

    // setup instances for OfflineMapTilesHandler to work with
    if (leafletElement) {
      setMapInstance(leafletElement);
      setOfflineLayer(offlineLayer);

      offlineLayer.addTo(leafletElement);
      offlineLayer.bringToFront();
    }
  }, [setMapInstance, setOfflineLayer, MAP_TILESERVER_URL, leafletMapElement]);

  const toggleRoute = e => {
    e.preventDefault();
    if (toggleShowRoute) {
      toggleShowRoute(!showRoute);
    }
  };

  return (
    <StyledMapContainer
      className={ScreenOrientation?.type}
      center={mapSettings?.center}
      zoom={mapSettings?.zoom}
      zoomControl={showZoomActionBar}
      whenCreated={mapInstance => {
        setLeafletMapElement(mapInstance);
      }}
      {...(leafletMapProps || {})}
    >
      <ChangeView
        center={mapSettings?.center}
        zoom={mapSettings?.zoom}
        handleClick={showRoute && userCanEditRoutes() && isPlatform('desktop') && addRouteMarker}
      />

      {showActionBar && (
        <>
          <MapControl
            position={
              ScreenOrientation.type === SCREEN_ORIENTATIONS.LANDSCAPE_PRIMARY
                ? POSITION_CLASSES.BOTTOM_RIGHT
                : POSITION_CLASSES.BOTTOM_LEFT
            }
            onCenterLocation={centerLocation}
            markerFilterIsActive={markerFilterIsActive}
            onToggleMarkerFilter={onToggleMarkerFilter && toggleMarkerFilter}
            onDownload={allowDownload ? handleDownloadClick : undefined}
            onSettingsClick={allowNavigationToOfflineMaps ? handleSettingsClick : undefined}
            onClusteringClick={allowClusteringToggle ? handleClusteringClick : undefined}
            clusterEnabled={!disableCluster}
            onRouteClick={toggleShowRoute ? toggleRoute : undefined}
          />
        </>
      )}

      {children}

      {!MAP_TILESERVER_URL && (
        <StyledErrorWrapper>
          <p>{t('containers.map.wrongServer')}</p>
        </StyledErrorWrapper>
      )}

      <TileLayer url={MAP_TILESERVER_URL} />

      {isPlatform('hybrid') && <LocationMarker />}
      {(isPlatform('desktop') || isPlatform('mobileweb')) && (
        <StyledClusteringButton onClick={handleClusteringClick} disabled={false}>
          <IonIcon slot='icon-only' icon={albums} color={disableCluster ? '' : 'primary'} />
        </StyledClusteringButton>
      )}

      <Markers
        markersList={markersList}
        disableCluster={disableCluster}
        onMarkerClick={onMarkerClick}
        markerHeight={markerHeight}
      />

      {showRoute && routeMarkers && isPlatform('desktop') && (
        <>
          {userCanEditRoutes() && <RouteMarkers markersList={routeMarkers} showRouteMarkers />}
          {routeMarkers.map(
            (mark, _, array) =>
              array[mark.index + 1] && (
                <RoutingMachine
                  key={mark.index}
                  routeCoordinates={[mark, array[mark.index + 1]]}
                  graphhopperApiKey={GRAPHHOPPER_API_KEY}
                />
              ),
          )}
        </>
      )}
      {showRoute && routeMarkers && isPlatform('hybrid') && (
        <>
          {pathCoordinates && routeAlreadyCached ? (
            <Polyline positions={pathCoordinates.coordinates} pathOptions={{ color: 'red', stroke: true }} />
          ) : (
            <RoutingMachine
              orderId={orderId}
              graphhopperApiKey={GRAPHHOPPER_API_KEY}
              routeCoordinates={routeMarkers}
              addRoutePath={addRoutePath}
            />
          )}
        </>
      )}
    </StyledMapContainer>
  );
};
