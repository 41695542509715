import React, { FC, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PileNode, StringPaginationType } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MobileSearchSelect } from '@src/components/Mobile/Elements/SearchSelect';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { MobileSelectProps } from '@src/components/Mobile/Elements/SearchSelect/SearchSelect';
import { getUniqueList } from '@src/helpers/Logic/Logic';

export interface MobileQualityTypeSelectProps extends Omit<MobileSelectProps, 'options'> {
  piles?: PileNode[];
}

export const MobileQualityTypeSelect: FC<MobileQualityTypeSelectProps> = ({ piles, ...props }) => {
  const { isOnline } = useContext(NetworkContext);
  const { data, loading, fetchMore, refetch } = useQuery<{ qualityTypes: StringPaginationType }>(Pile.qualityTypes, {
    variables: {
      pageSize: 20,
    },
  });
  const { qualityTypes: { page, strings, hasNext } = { page: 0, strings: [], hasNext: false } } = data || {};

  const handleSearch = useCallback(
    debounce((filterValue: string) => {
      refetch({ filterValue, pageSize: 20 });
    }, 500),
    [],
  );

  const handlePopupScroll = (e: any) => {
    const previousPage: number = page || 0;

    if (!hasNext) return;

    fetchMore({
      variables: {
        page: previousPage + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newPage = fetchMoreResult?.qualityTypes?.page || 0;
        const oldPage = prev?.qualityTypes?.page || 0;

        if (!fetchMoreResult || newPage <= oldPage) return prev;

        const newEdges = fetchMoreResult.qualityTypes.strings;
        const previousEdges = prev?.qualityTypes?.strings || [];

        return newEdges?.length
          ? {
              qualityTypes: {
                page: fetchMoreResult.qualityTypes.page,
                pages: fetchMoreResult.qualityTypes.pages,
                hasNext: fetchMoreResult.qualityTypes.hasNext,
                hasPrevious: fetchMoreResult.qualityTypes.hasPrevious,
                __typename: prev.qualityTypes.__typename,
                strings: [...previousEdges, ...newEdges],
              },
            }
          : prev;
      },
    });
  };

  return (
    <MobileSearchSelect
      {...props}
      options={
        isOnline
          ? strings?.map(qualityType => ({
              id: qualityType,
              title: qualityType,
              value: qualityType,
            }))
          : getUniqueList(piles?.map(p => p.qualityType)).map(qualityType => ({
              id: qualityType,
              value: qualityType,
              title: qualityType,
            }))
      }
      loading={loading}
      onSearch={handleSearch}
      multi
      onLoadMore={handlePopupScroll}
      allowNoSelection
    />
  );
};
