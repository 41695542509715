import React, { FC } from 'react';
import { StyledIonInput, StyledIonItem, StyledIonLabel } from './Input.styles';
import { TextFieldTypes } from '@ionic/core';

interface InputProps extends Pick<HTMLIonInputElement, 'value' | 'maxlength'> {
  className?: string;
  disabled?: boolean;
  label: string;
  name?: string;
  onChange: any;
  type?: TextFieldTypes;
}

export const Input: FC<InputProps> = ({ className, label, onChange, ...props }) => (
  <StyledIonItem className={className}>
    <StyledIonLabel position='stacked'>{label}</StyledIonLabel>
    <StyledIonInput onIonChange={onChange} {...props} />
  </StyledIonItem>
);
