import { gql } from 'apollo-boost';

export const authenticate = gql`
  mutation tokenAuth($clientKey: String!, $email: String!, $password: String!) {
    tokenAuth(clientKey: $clientKey, email: $email, password: $password) {
      token
    }
  }
`;

export const changePassword = gql`
  mutation ChangeUserPassword($newPassword1: String!, $newPassword2: String!, $oldPassword: String!) {
    changePassword(newPassword1: $newPassword1, newPassword2: $newPassword2, oldPassword: $oldPassword) {
      ok
      errors {
        field
        messages {
          code
          message
        }
      }
    }
  }
`;

export const checkIn = gql`
  mutation CheckIn($checkInDispatchedAt: DateTime!) {
    checkIn(checkInDispatchedAt: $checkInDispatchedAt) {
      ok
    }
  }
`;

export const checkOut = gql`
  mutation CheckOut($checkOutDispatchedAt: DateTime!) {
    checkOut(checkOutDispatchedAt: $checkOutDispatchedAt) {
      ok
    }
  }
`;

export const editTimeSheet = gql`
  mutation EditTimesheet($timesheet: ID!, $checkInDispatchedAt: DateTime, $checkOutDispatchedAt: DateTime) {
    editTimesheet(
      timesheet: $timesheet
      checkInDispatchedAt: $checkInDispatchedAt
      checkOutDispatchedAt: $checkOutDispatchedAt
    ) {
      ok
    }
  }
`;

export const deleteTimeSheet = gql`
  mutation DeleteTimeSheet($timesheet: ID!) {
    deleteTimesheet(timesheet: $timesheet) {
      ok
    }
  }
`;

export const createAccount = gql`
  mutation CreateAccount(
    $password: String!
    $passwordRepeat: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $groups: [String]
  ) {
    createAccount(
      password: $password
      passwordRepeat: $passwordRepeat
      firstName: $firstName
      lastName: $lastName
      email: $email
      groups: $groups
    ) {
      ok
      account {
        id
      }
    }
  }
`;

export const editAccount = gql`
  mutation EditAccount(
    $id: ID!
    $password: String
    $passwordRepeat: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $groups: [String]
  ) {
    editAccount(
      id: $id
      password: $password
      passwordRepeat: $passwordRepeat
      firstName: $firstName
      lastName: $lastName
      email: $email
      groups: $groups
    ) {
      ok
      account {
        id
      }
    }
  }
`;
