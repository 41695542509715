import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledIonText,
  StyledIonTextError,
  StyledIonRow,
  StyledIonButtonRow,
  StyledIonCol,
  StyledIonColBroachedToggle,
  StyledIonInput,
  StyledIonToggle,
  StyledForm,
  StyledTitle,
} from '../InventoryForm.styles';
import { ValidationError } from '../InventoryForm';
import { isPlatform } from '@ionic/core';
import { WagonInventoryState } from '@src/components/Containers/InventoryForm/WagonInventoryForm';
import { HorizontalRadioGroup } from '@src/components/Elements/HorizontalRadioGroup';

interface FormProps {
  handleDeleteSubmit: (e: any) => void;
  handleSaveSubmit: (e: any) => void;
  handleUpdateInventory: (e: any, field: any) => void;
  inventory: WagonInventoryState;
  isCount?: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  setIsCount: (bool: boolean | undefined) => void;
  showDelete: boolean;
  showSave: boolean;
  validationErrors: ValidationError[] | undefined;
}

export enum WagonFormFilters {
  LOADEVERYTHING = 'loadEverything',
  COUNT = 'count',
  MASS = 'mass',
}

export const WagonForm: FC<FormProps> = ({
  handleSaveSubmit,
  handleDeleteSubmit,
  inventory,
  handleUpdateInventory,
  validationErrors,
  isCount,
  setIsCount,
}) => {
  const { t } = useTranslation();

  const [showIsLoaded, setShowIsLoaded] = useState<boolean>();

  const onFilterChange = event => {
    const newValue = event.target.value;

    switch (newValue) {
      case WagonFormFilters.LOADEVERYTHING:
        setShowIsLoaded(true);
        setIsCount(undefined);
        break;
      case WagonFormFilters.COUNT:
        setIsCount(true);
        setShowIsLoaded(false);
        break;
      case WagonFormFilters.MASS:
        setIsCount(false);
        setShowIsLoaded(false);
        break;
      default:
        throw Error('Not handled.');
    }
  };

  return (
    <StyledForm onSubmit={handleSaveSubmit}>
      <StyledTitle>{t('containers.inventoryForm.form.header')}</StyledTitle>

      <HorizontalRadioGroup
        style={{ justifyContent: 'space-between', marginTop: '3rem', marginBottom: '1rem' }}
        disabled={inventory.loadEverything ? true : false}
        buttonStyle='solid'
        onChange={onFilterChange}
        buttonValues={[
          { value: WagonFormFilters.LOADEVERYTHING, text: t('containers.inventoryForm.form.labels.loadEverything') },
          { value: WagonFormFilters.COUNT, text: t('containers.inventoryForm.form.labels.quantity') },
          { value: WagonFormFilters.MASS, text: t('containers.inventoryForm.form.labels.mass') },
        ]}
      />

      {showIsLoaded && (
        <StyledIonRow>
          <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
            <StyledIonText>{t('containers.inventoryForm.form.labels.loadEverything')}:</StyledIonText>
          </StyledIonCol>

          <StyledIonColBroachedToggle>
            <StyledIonToggle
              value='false'
              checked={inventory.loadEverything ? inventory.loadEverything : false}
              color='success'
              onIonChange={e => handleUpdateInventory(e, 'loadEverything')}
            />
          </StyledIonColBroachedToggle>
        </StyledIonRow>
      )}

      {isCount === true && (
        // count = Stückzahl
        <StyledIonRow>
          <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
            <StyledIonText>{t('containers.inventoryForm.form.labels.quantity')}:</StyledIonText>
          </StyledIonCol>

          <StyledIonCol size={isPlatform('hybrid') ? '3' : '2.5'}>
            <StyledIonInput
              type='number'
              value={inventory.count ? inventory.count.toString() : null}
              onIonChange={e => handleUpdateInventory(e, 'count')}
              disabled={inventory.loadEverything}
            />
          </StyledIonCol>
        </StyledIonRow>
      )}

      {isCount === false && (
        // amount = Masse = "Summe"
        <StyledIonRow>
          <StyledIonCol size={isPlatform('hybrid') ? '3' : '2.5'} push={isPlatform('hybrid') ? '0' : undefined}>
            <StyledIonText color='dark'>{t('containers.inventoryForm.form.labels.sum')}</StyledIonText>
          </StyledIonCol>

          <StyledIonCol size={isPlatform('hybrid') ? '4' : '2.5'} push={isPlatform('hybrid') ? '2' : undefined}>
            <StyledIonText color='dark'>
              {Number(inventory.amount)
                .toFixed(2)
                .replace('.', ',')}
            </StyledIonText>
          </StyledIonCol>
        </StyledIonRow>
      )}

      {/* If loadEverythin is true */}
      {(inventory.loadEverything || isCount !== undefined) && (
        <>
          <StyledIonRow>
            <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
              <StyledIonText>{t('containers.inventoryForm.form.labels.isWagonReady')}:</StyledIonText>
            </StyledIonCol>

            <StyledIonColBroachedToggle>
              <StyledIonToggle
                value='false'
                checked={inventory.isWagonReady ? inventory.isWagonReady : false}
                color='success'
                onIonChange={e => handleUpdateInventory(e, 'isWagonReady')}
              />
            </StyledIonColBroachedToggle>
          </StyledIonRow>
          <StyledIonRow>
            <StyledIonCol size={isPlatform('hybrid') ? '5' : '2.5'}>
              <StyledIonText>{t('containers.inventoryForm.form.labels.wagonNumber')}:</StyledIonText>
            </StyledIonCol>

            <StyledIonCol size={isPlatform('hybrid') ? '3' : '2.5'}>
              <StyledIonInput
                type='number'
                value={inventory.wagonNumber}
                onIonChange={e => handleUpdateInventory(e, 'wagonNumber')}
              />
            </StyledIonCol>
          </StyledIonRow>
          {validationErrors && validationErrors.length > 0 && (
            <StyledIonButtonRow>
              <StyledIonCol size='12'>
                {validationErrors
                  .filter(item => item.field !== 'amount')
                  .map(item => (
                    <StyledIonTextError key={item.field} color='danger'>
                      {item.message}
                    </StyledIonTextError>
                  ))}
              </StyledIonCol>
            </StyledIonButtonRow>
          )}
        </>
      )}
    </StyledForm>
  );
};
