import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MultiSelect } from '@src/components/Elements/MultiSelect';
import { MultiSelectProps } from '@src/components/Elements/MultiSelect/MultiSelect';

export const SortSelect: FC<Pick<MultiSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ pileSorts: PileNodeConnection }>(Pile.getPileSorts, {
    variables: {
      first: 10,
    },
    fetchPolicy: 'network-only',
  });
  const { pileSorts: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 10,
        sort_Icontains: value,
      });
    }, 100),
    [],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.pileSorts.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newEdges = fetchMoreResult.pileSorts.edges;
          const pageInfo = fetchMoreResult.pileSorts.pageInfo;

          return newEdges.length
            ? {
                pileSorts: {
                  pageInfo,
                  __typename: prev.pileSorts.__typename,
                  edges: [...prev.pileSorts.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <MultiSelect
      {...props}
      label={t('desktop.pages.piles.filter.sort')}
      options={edges.map(({ node: { sort } }) => ({
        title: sort,
        value: sort,
      }))}
      name='sort'
      loading={loading}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
    />
  );
};
