import gql from 'graphql-tag';

export const CompanyNodeFragment = gql`
  fragment companyNode on CompanyNode {
    id
    name
    matchcode
    note
    info
  }
`;

export const CompanyLocationNodeFragment = gql`
  fragment companyLocationNode on CompanyLocationNode {
    id
    housenumber
    postcode
    street
    country
    partOfVillage
  }
`;
