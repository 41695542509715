import gql from 'graphql-tag';
import { CompanyNodeFragment } from '@src/graphql/Company/Fragments';

export const PlotNodeFragment = gql`
  fragment plotNode on PlotNode {
    id
    number
    done
  }
`;

export const PlotNodeFullFragment = gql`
  fragment fullPlotNode on PlotNode {
    id
    number
    status
    strSelection
    numberOfPiles
    numberOfBroachedPiles
    sumCount
    sumCountActual
    sumCbkSolid
    sumCubikActual
    sumCubikSolidActual
    sumCbkNet
    sumCubikSolidActual
    company {
      ...companyNode
    }
    done
    category
    mustBeRemovedSeparately
  }
  ${CompanyNodeFragment}
`;

export const PlotStatusNodeFragment = gql`
  fragment plotStatusNode on PlotStatusNode {
    status
  }
`;
