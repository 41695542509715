import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CompanyLocationNodeConnection } from '@src/generated/schema';
import { Company } from '@src/graphql/Company';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

interface SupplierSelectProps extends Pick<SearchSelectProps, 'required'> {
  company?: string;
  prefilledValue?: string;
}

export const CompanyLocationSelect: FC<SupplierSelectProps> = ({ company, prefilledValue, ...props }) => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ companyLocations: CompanyLocationNodeConnection }>(
    Company.companyLocations,
    {
      variables: {
        company,
        first: 10,
        fullAddress: prefilledValue,
      },
      fetchPolicy: 'network-only',
    },
  );
  const { companyLocations: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        company,
        first: 10,
        fullAddress: value,
      });
    }, 100),
    [company],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.companyLocations.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const newEdges = fetchMoreResult.companyLocations.edges;
          const pageInfo = fetchMoreResult.companyLocations.pageInfo;

          return newEdges.length
            ? {
                companyLocations: {
                  pageInfo,
                  __typename: prev.companyLocations.__typename,
                  edges: [...prev.companyLocations.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <SearchSelect
      {...props}
      allowClear
      label={t('general.deliveryAddress')}
      options={edges.map(({ node: { street, postcode, country, id } }) => ({
        title: `${street} ${postcode} ${country}`,
        value: id,
      }))}
      name='deliveryAddress'
      loading={loading}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
    />
  );
};
