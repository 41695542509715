import { CompanyNodeFragment } from '@src/graphql/Company/Fragments';
import { FileUploadNodeFragment } from '@src/graphql/FileUpload/Fragments';
import { DriverInventoryNodeFragment } from '@src/graphql/Inventory/Fragments';
import { DriverPileNodeFragment } from '@src/graphql/Pile/Fragments';
import gql from 'graphql-tag';

// DESKTOP

export const OrderAllFieldsNodeFragment = gql`
  fragment orderAllFieldsNode on OrderNode {
    id
    createdBy {
      firstName
      lastName
    }
    createdAt
    lastmodifiedBy {
      firstName
      lastName
    }
    lastmodifiedAt
    number
    status
    planDate
    deliveryStatus {
      id
      shortDescription
      number
    }
    amount
    repeat
    repeatEndDate
    note
    noteDriver
    deliveryType
    type
    collectedFromOtherSystem
    canBeAborted
    finishedAt
    isPlausible
  }
`;

export const OrderStatusNodeFragment = gql`
  fragment orderStatusNode on OrderStatusNode {
    id
    status
  }
`;

export const OrderTypeNodeFragment = gql`
  fragment orderTypeNode on OrderNode {
    type
  }
`;

export const TimeNodeFragment = gql`
  fragment timeNode on TimeNode {
    id
    begin
    end
    kmBegin
    kmEnd
  }
`;

export const OrderNodeFragment = gql`
  fragment orderNode on OrderNode {
    id
    number
    status
    amount
    repeat
    planDate
    finishedAt
    repeatEndDate
    note
    noteDriver
    collectedFromOtherSystem
    isPlausible
  }
`;

// MOBILE

export const DriverOrderNodeFragment = gql`
  fragment driverOrderNode on OrderNode {
    id
    number
    status
    amount
    repeat
    planDate
    note
    noteDriver
    driver {
      id
    }
    customer {
      ...companyNode
    }
    supplier {
      ...companyNode
    }
    piles {
      edges {
        node {
          ...driverPileNode
        }
      }
    }
    inventories {
      edges {
        node {
          ...driverInventoryNode
        }
      }
    }
    route {
      edges {
        node {
          index
          latitude
          longitude
        }
      }
    }
    transportMedia {
      ...fileUploadNode
    }
    deliveryNoteCustomer {
      ...fileUploadNode
    }
    deliveryNoteSupplier {
      ...fileUploadNode
    }
  }
  ${CompanyNodeFragment}
  ${DriverPileNodeFragment}
  ${DriverInventoryNodeFragment}
  ${FileUploadNodeFragment}
`;
