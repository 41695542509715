import React, { FC } from 'react';
import { OrderNode, StatusChoice } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverRow.styles';
import { closePopups } from '@src/helpers/DomManipulations';
import { ModalEnum } from '../List';

export interface PopoverRowProps {
  handleOrderStatusChange: (orderIds: string[], status: StatusChoice) => Promise<void>;
  order: OrderNode;
  toggleModal: (modalType: ModalEnum, order: OrderNode) => void;
}

export const PopoverRow: FC<PopoverRowProps> = ({ handleOrderStatusChange, toggleModal, order }) => {
  const { t } = useTranslation();

  const setOrderStatusAllocated = () => {
    handleOrderStatusChange([order.id], StatusChoice.Allocated);
    closePopups();
  };

  return (
    <StyledPopover>
      <StyledPopoverOption onClick={() => toggleModal(ModalEnum.SHOW, order)}>
        {t('desktop.pages.orders.list.popover.show')}
      </StyledPopoverOption>
      <StyledPopoverOption onClick={setOrderStatusAllocated}>
        {t('desktop.pages.orders.list.popover.allocate')}
      </StyledPopoverOption>
      <StyledPopoverOption onClick={() => toggleModal(ModalEnum.EDIT, order)}>
        {t('desktop.pages.orders.list.popover.edit')}
      </StyledPopoverOption>
      <StyledPopoverOption onClick={() => toggleModal(ModalEnum.DUPLICATE, order)}>
        {t('desktop.pages.orders.list.popover.duplicate')}
      </StyledPopoverOption>
    </StyledPopover>
  );
};
