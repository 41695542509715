import React, { FC, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PrivateRoutes, Routes } from '@src/global';
import { Login, Logout } from '@src/components/Pages/Public';
import { MeContext } from '@src/components/Providers';
import { Private } from './Private';

export const Pages: FC = () => {
  const { START, LOGIN, LOGOUT, HOME, ORDERS } = Routes;
  const { pathname } = useLocation();
  const { me: loggedIn } = useContext(MeContext);

  return (
    <Switch>
      {!loggedIn && pathname === START && <Redirect to={LOGIN} />}
      {loggedIn && (pathname === START || pathname === LOGIN) && <Redirect to={`${HOME}${ORDERS}`} />}
      <Route exact path={LOGIN} component={Login} />
      {loggedIn ? (
        PrivateRoutes.map(route => <Route key={`private-${route.toLowerCase()}`} path={route} component={Private} />)
      ) : (
        <Redirect to={LOGIN} />
      )}
      <Route exact path={LOGOUT} component={Logout} />
    </Switch>
  );
};
