import { Formik } from 'formik';
import React, { FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form as FormWrapper } from '@src/components/Elements/Wrapper/Form';
import { Input } from '@src/components/Desktop/Containers/Input';
import { Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { WoodtypeSelect } from './WoodtypeSelect';
import { DistrictSelect, noDistrict } from './DisctrictSelect';
import { StatusSelect } from './StatusSelect';
import { FormItem, Switch } from 'formik-antd';
import { SortSelect } from './SortSelect';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import { SupplierSelect } from '@src/components/Desktop/Containers/SearchSelects/SupplierSelect';
import { LocationSelect } from './LocationSelect';
import { CategorySelect, noCategory } from './CategorySelect';
import { TypeSelect } from './TypeSelect';
import { PileNode } from '@src/generated/schema';
import { LogLengthSelect } from '@src/components/Desktop/Pages/Private/Piles/List/FilterForm/LogLengthSelect';

interface FilterFormValues {
  category?: string;
  district?: string;
  includeBroached?: boolean;
  isBroached?: boolean;
  location?: string;
  logLength?: [number?, number?];
  pileNumber?: number;
  plotNumber?: number;
  sort?: string;
  status?: string;
  supplier?: string;
  type?: string;
  woodtype?: string;
}

const validationSchema = Yup.object().shape({
  category: Yup.string(),
  district: Yup.string(),
  includeBroached: Yup.boolean(),
  location: Yup.string(),
  logLength: Yup.array().of(Yup.number()),
  pileNumber: Yup.number(),
  plotNumber: Yup.number(),
  sort: Yup.string(),
  status: Yup.string(),
  supplier: Yup.string(),
  type: Yup.string(),
  woodtype: Yup.string(),
  isBroached: Yup.boolean(),
});

const initialValues: FilterFormValues = {
  category: undefined,
  district: undefined,
  includeBroached: false,
  location: undefined,
  logLength: undefined,
  pileNumber: undefined,
  plotNumber: undefined,
  sort: undefined,
  status: undefined,
  supplier: undefined,
  type: undefined,
  woodtype: undefined,
  isBroached: undefined,
};

interface FilterFormProps {
  itemsPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setQueryVariables: Dispatch<SetStateAction<any>>;
  setSelectedRows: Dispatch<SetStateAction<PileNode[]>>;
}

export const FilterForm: FC<FilterFormProps> = ({ setPage, setQueryVariables, setSelectedRows, itemsPerPage }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      initialErrors={{}}
      onSubmit={values => {
        const {
          includeBroached,
          supplier,
          location,
          district,
          logLength,
          plotNumber,
          pileNumber,
          sort,
          status,
          woodtype,
          category,
          type,
          isBroached,
        } = values;

        setSelectedRows([]);
        setPage(1);
        setQueryVariables(previous => ({
          includeBroached,
          type,
          location,
          sort,
          woodtype,
          orderBy: previous?.orderBy,
          first: itemsPerPage,
          isBroached: isBroached ? isBroached : undefined,
          plot_Number_Contains: plotNumber ? plotNumber : undefined,
          number_Contains: pileNumber ? pileNumber : undefined,
          category_Isnull: category === noCategory ? true : null,
          district_Isnull: district === noDistrict ? true : null,
          plot_Company_Name: supplier,
          category: category === noCategory ? '' : category,
          status: status || [],
          logLength: logLength || [],
          district: district === noDistrict ? '' : district,
        }));
      }}
    >
      <FormWrapper>
        <AutoSaveForm />
        <Row>
          <FormItem
            labelCol={{ span: 24 }}
            label={t('desktop.pages.piles.filter.includeBroached')}
            name='includeBroached'
          >
            <Switch name='includeBroached' />
          </FormItem>
        </Row>
        <Row>
          <Input label={t('desktop.pages.piles.filter.plotNumber')} type='text' name='plotNumber' />
        </Row>
        <Row>
          <Input label={t('desktop.pages.piles.filter.pileNumber')} type='text' name='pileNumber' />
        </Row>
        <Row>
          <SupplierSelect multiSelect setNameAsValue />
        </Row>
        <Row>
          <WoodtypeSelect />
        </Row>
        <Row>
          <SortSelect />
        </Row>
        <Row>
          <LogLengthSelect />
        </Row>
        <Row>
          <StatusSelect />
        </Row>
        <Row>
          <LocationSelect />
        </Row>
        <Row>
          <DistrictSelect />
        </Row>
        <Row>
          <CategorySelect />
        </Row>
        <Row>
          <TypeSelect />
        </Row>
        <Row>
          <FormItem labelCol={{ span: 24 }} label={t('desktop.pages.piles.filter.isBroached')} name='isBroached'>
            <Switch name='isBroached' />
          </FormItem>
        </Row>
      </FormWrapper>
    </Formik>
  );
};
