import { ColumnProps } from 'antd/lib/table';
import React from 'react';
/**
 * This function adds a onclick function to every cell of a table.
 * This can be used to e.g. open up a "show"-modal.
 * @param columns a columns object that will be passed to a table
 * @param onRowClicked a function that should executed when a row is clicked
 * @returns a columns object
 */
export const addRowClickHandler = (
  columns: Array<ColumnProps<any>>,
  onRowClicked: (e: React.MouseEvent, record: any, index?: number) => void,
) =>
  columns.map(column => {
    if (column.dataIndex !== 'action') {
      column.onCell = (record, index) => ({
        onClick: e => onRowClicked(e, record, index),
      });
    }

    return column;
  });
