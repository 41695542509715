import {
  getPaginatedOrders,
  getOrderStatus,
  getOrderDetail,
  getOrderStatusChanges,
  getDriverOrders,
  getOrderTypes,
  getDriverOrdersDetail,
} from './Queries';
import {
  createOrder,
  setOrderStatus,
  setDeliveryNoteCustomer,
  setDeliveryNoteSupplier,
  editOrder,
  appendPilesToExistingOrder,
  deletePilesFromExistingOrder,
  rollbackOrderStatusChange,
  changeToNextStatus,
  saveRoute,
} from './Mutations';
import { DriverOrderNodeFragment } from './Fragments';

export const Order = {
  appendPilesToExistingOrder,
  createOrder,
  DriverOrderNodeFragment,
  deletePilesFromExistingOrder,
  editOrder,
  getDriverOrders,
  getDriverOrdersDetail,
  getOrderDetail,
  getOrderStatusChanges,
  getOrderStatus,
  getOrderTypes,
  getPaginatedOrders,
  setDeliveryNoteCustomer,
  setDeliveryNoteSupplier,
  setOrderStatus,
  rollbackOrderStatusChange,
  saveRoute,
  changeToNextStatus,
};
