// Collect testIds for findings etc. here
export const TestIds = {
  platforms: {
    desktop: 'platforms-desktop',
    mobile: 'platforms-mobile',
  },
  pages: {
    piles: {
      list: {
        identifier: 'pages-piles-list-identifier',
        table: {
          popOverTitle: 'pages-piles-list-table-popOverTitle',
          popOverRow: 'pages-piles-list-table-popOverRow',
        },
        map: {
          container: 'pages-piles-list-map-container',
        },
        marker: {
          container: 'pages-piles-list-marker-container',
          label: 'pages-piles-list-marker-label',
        },
        popOverRow: {
          openModal: 'pages-piles-list-popOverRow-openModal',
        },
      },
    },
    orders: {
      add: {
        identifier: 'pages-orders-add-identifier',
      },
      show: {
        identifier: 'pages-orders-show-identifier',
      },
      list: {
        identifier: 'pages-orders-list-identifier',
      },
      detail: {
        identifier: 'pages-orders-detail-identifier',
      },
    },
    login: {
      identifier: 'pages-login-identifier',
    },
    PasswordChangePage: {
      identifier: 'pages-passwordChangePage-identifier',
    },
    home: {
      identifier: 'pages-home-identifier',
    },
  },
  containers: {},
  links: {},
  ui: {
    buttons: {
      createOrder: {
        submit: 'ui-buttons-create-order-submit',
      },
    },
    inputs: {
      createOrder: {
        numberInput: 'ui-inputs-create-order-number-input',
        noteInput: 'ui-inputs-create-order-note-input',
        supplierInput: 'ui-inputs-create-order-supplier-input',
        customerInput: 'ui-inputs-create-order-customer-input',
        amountInput: 'ui-inputs-create-order-amount-input',
        driverInput: 'ui-inputs-create-order-driver-input',
        vehicleInput: 'ui-inputs-create-order-vehicle-input',
        dateInput: 'ui-inputs-create-order-date-input',
        repeatInput: 'ui-inputs-create-order-repeat-input',
        submitButton: 'ui-inputs-create-order-submit-button',
      },
    },
  },
  ux: {
    response: {},
    headings: {},
  },
  forms: {
    login: 'forms-login',
    PasswordChangePage: 'password-change',
  },
  elements: {},
  sections: {},
};
