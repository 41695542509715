import React, { FC, useContext } from 'react';
import { TestIds } from '@src/global';
import { InventoryNode } from '@src/generated/schema';
import { MeContext, Permissions } from '@src/components/Providers';
import { InventoryForm } from '@src/components/Containers/InventoryForm';

export enum InventoryModalEnum {
  CHANGE_INVENTORY = 'changeInventory',
}

export interface ShowProps {
  closeModal: () => void;
  inventory: InventoryNode | undefined;
}

export const InventoryEdit: FC<ShowProps> = ({ inventory, closeModal }) => {
  const { userHasPermission, userHasPermissions } = useContext(MeContext);

  return (
    <div data-testid={TestIds.pages.orders.show.identifier}>
      {inventory && (
        <>
          <InventoryForm
            alertModalLevel={3}
            inventory={inventory}
            order={inventory.order || undefined}
            pile={inventory.pile || undefined}
            createEnabled={userHasPermissions([
              Permissions.CAN_CHANGE_INVENTORY,
              Permissions.CAN_CHANGE_INVENTORY_DETAILS,
            ])}
            editEnabled={userHasPermissions([
              Permissions.CAN_CHANGE_INVENTORY,
              Permissions.CAN_CHANGE_INVENTORY_DETAILS,
            ])}
            deleteEnabled={userHasPermission(Permissions.CAN_DELETE_INVENTORY)}
            onSubmit={closeModal}
          />
        </>
      )}
    </div>
  );
};
