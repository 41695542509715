import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TestIds } from '@src/global';
import { OrderNode, PileNode, RouteNodeInput } from '@src/generated/schema/index';
import { closePopups } from '@src/helpers/DomManipulations';
import { Container, Wrapper } from '@src/components/Elements';
import { PageHeadline } from '@src/components/Elements/PageHeadline';
import { ItemsPerPagePicker } from '@src/components/Desktop/Containers/ItemsPerPagePicker';
import { Add } from '@src/components/Desktop/Pages/Private/Orders/Edit/Add';
import { Alter } from '@src/components/Desktop/Pages/Private/Orders/Edit/Alter';

import { Show } from '../Show';
import { Table } from './Table';
import { StyledList } from './List.styles';
import { Duplicate } from '../Edit/Duplicate/Duplicate';
import { Modal, TabComponentsType } from '@src/components/Elements/Modal/Modal';
import { PilesMap } from '@src/components/Desktop/Pages/Private/Orders/Show/PilesMap';

export enum ModalEnum {
  ADD = 'add',
  EDIT = 'edit',
  SHOW = 'show',
  DUPLICATE = 'duplicate',
  MAP = 'map',
}

const modalTitles = {
  [ModalEnum.ADD]: 'desktop.pages.orders.add.title',
  [ModalEnum.EDIT]: 'desktop.pages.orders.list.popover.edit',
  [ModalEnum.SHOW]: 'desktop.pages.orders.show.title',
  [ModalEnum.DUPLICATE]: 'desktop.pages.orders.list.popover.duplicate',
  [ModalEnum.MAP]: 'desktop.pages.piles.list.mapModal.title',
};

export const List: FC = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<OrderNode[]>([]);
  const [showModal, setShowModal] = useState<string>('');
  const [showPopover, setShowPopover] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [clickedOrder, setClickedOrder] = useState<OrderNode | undefined>(undefined);

  const [piles, setPiles] = useState<PileNode[]>([]);
  const [route, setRoute] = useState<RouteNodeInput[]>([]);
  const [mapMarker, setMapMarker] = useState<PileNode[]>([]);

  useEffect(() => {
    setMapMarker(piles || []);
  }, [piles]);

  const closeModal = () => {
    setShowModal('');
    setClickedOrder(undefined);
  };

  const openModal = (modalType?: ModalEnum, orderNode?: any) => {
    closePopups();
    setShowModal(modalType || '');
    setClickedOrder(orderNode);
  };

  const toggleModal: () => void = (modalType?: ModalEnum, orderNode?: any) => {
    if (showModal) {
      closeModal();
    } else {
      openModal(modalType, orderNode);
    }
  };

  const onItemClicked = (e, order) => {
    openModal(ModalEnum.SHOW, order);
  };

  const makeModal = (tabComponents: TabComponentsType) => (
    <Modal
      title={`${t(modalTitles[showModal])}: ${clickedOrder?.number}`}
      visible={!!showModal}
      onCancel={toggleModal}
      tabComponents={tabComponents}
    />
  );

  return (
    <div data-testid={TestIds.pages.orders.list.identifier}>
      {showModal === ModalEnum.ADD &&
        makeModal([
          {
            tabName: t(modalTitles.add),
            component: <Add closeModal={closeModal} />,
          },
        ])}

      {showModal === ModalEnum.EDIT &&
        makeModal([
          {
            tabName: t(modalTitles.edit),
            component: <Alter toggleModal={toggleModal} currentOrderId={clickedOrder?.id} />,
          },
        ])}
      {showModal === ModalEnum.DUPLICATE &&
        makeModal([
          {
            component: <Duplicate toggleModal={toggleModal} orderIdToDuplicate={clickedOrder?.id} />,
            tabName: t(modalTitles.duplicate),
          },
        ])}
      {showModal === ModalEnum.SHOW &&
        makeModal([
          {
            component: (
              <Show
                toggleModal={toggleModal}
                order={clickedOrder}
                piles={piles}
                setPiles={setPiles}
                setRoute={setRoute}
              />
            ),
            tabName: t(modalTitles.show),
          },
          {
            component: (
              <PilesMap order={clickedOrder} piles={piles} route={route} onSearch={setMapMarker} markers={mapMarker} />
            ),
            tabName: t(modalTitles.map),
            isMap: true,
          },
        ])}

      <Container>
        <Wrapper>
          <PageHeadline>{t('desktop.pages.orders.headline')}</PageHeadline>
          <ItemsPerPagePicker setItemsPerPage={setItemsPerPage} itemsPerPage={itemsPerPage} />
          <StyledList>
            <Table
              onItemClick={onItemClicked}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              toggleModal={toggleModal}
              showPopover={showPopover}
              setShowPopover={setShowPopover}
            />
          </StyledList>
        </Wrapper>
      </Container>
    </div>
  );
};
