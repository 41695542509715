import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form, FormValues } from '../Form';
import { useQuery, useMutation } from 'react-apollo';
import { PlotNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { IonCol, IonSpinner, IonRow } from '@ionic/react';
import { Plot } from '@src/graphql/Plot';

export interface AlterProps {
  plot?: PlotNode;
  toggleModal: () => void;
}

export const Alter: FC<AlterProps> = ({ toggleModal, plot }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [changePlot, { error: changeError }] = useMutation(Plot.changePlot);
  const [deletePlot, { error: deleteError, loading: deleteLoading }] = useMutation(Plot.deletePlot);
  const { data, loading } = useQuery<{
    plot: PlotNode;
  }>(Plot.getPlotDetail, {
    variables: { plotId: plot?.id },
  });

  const initialValues: FormValues = {
    number: data?.plot.number || undefined,
    customer: data?.plot?.company?.id || undefined,
    category: data?.plot.category || undefined,
    mustBeRemovedSeparately: data?.plot.mustBeRemovedSeparately || false,
    done: data?.plot.done || false,
  };

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: data_ } = await changePlot({
        variables: {
          ...variables,
          plotId: plot?.id,
        },
        refetchQueries: ['plots'],
      });

      if (data_) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.plots.alter.form.submit.success.message'),
          description: t('desktop.pages.plots.alter.form.submit.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  const handleDelete = async () => {
    try {
      const { data: dataDelete } = await deletePlot({
        variables: {
          plotId: plot?.id,
        },
        refetchQueries: ['plots'],
      });
      if (dataDelete) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.plots.delete.form.submit.success.message'),
          description: t('desktop.pages.plots.delete.form.submit.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}
      {data && (
        <Wrapper>
          <Form
            initialFormValues={initialValues}
            prefilledValues={{ customer: plot?.company?.name || '' }}
            onSubmit={onSubmit}
            onDelete={handleDelete}
            error={changeError || deleteError}
            deleteLoading={deleteLoading}
          />
        </Wrapper>
      )}
    </div>
  );
};
