import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { OrderNodeConnection } from '@src/generated/schema';
import { Order } from '@src/graphql/Order';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';

export const TypeSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ orderTypes: OrderNodeConnection }>(Order.getOrderTypes);
  const { orderTypes: { edges } = { edges: [] as any } } = data || {};

  return (
    <SearchSelect
      {...props}
      label={t('desktop.pages.orders.filter.type.title')}
      options={edges.map(({ node: { type } }) => ({
        title: t(`desktop.pages.orders.filter.type.${type}`),
        value: type.toLowerCase(),
      }))}
      name='type'
      loading={loading}
    />
  );
};
