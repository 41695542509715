import gql from 'graphql-tag';
import { PlotNodeFragment, PlotNodeFullFragment, PlotStatusNodeFragment } from './Fragments';

export const plots = gql`
  query plots(
    $activeOnly: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $plotNumber: Int
    $selection: String
    $status: String
    $supplier: ID
  ) {
    plots(
      includeAllStatus: $activeOnly
      after: $after
      before: $before
      first: $first
      last: $last
      number_Contains: $plotNumber
      selection: $selection
      status: $status
      company: $supplier
    ) {
      edges {
        node {
          ...fullPlotNode
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${PlotNodeFullFragment}
`;

export const searchPlots = gql`
  query plots(
    $activeOnly: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $plotNumber: Int
    $selection: String
    $status: String
    $supplier: ID
  ) {
    plots(
      includeAllStatus: $activeOnly
      after: $after
      before: $before
      first: $first
      last: $last
      number_Contains: $plotNumber
      selection: $selection
      status: $status
      company: $supplier
    ) {
      edges {
        node {
          ...plotNode
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${PlotNodeFragment}
`;

export const getPlotDetail = gql`
  query plot($plotId: ID!) {
    plot(plotId: $plotId) {
      ...fullPlotNode
    }
  }
  ${PlotNodeFullFragment}
`;

export const getPlotStatus = gql`
  query {
    plotStatus {
      edges {
        node {
          ...plotStatusNode
        }
      }
    }
  }
  ${PlotStatusNodeFragment}
`;
