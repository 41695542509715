import gql from 'graphql-tag';

export const InventoryNodeFragment = gql`
  fragment inventoryNode on InventoryNode {
    id
    amount
    count
    createdAt
    isMutable
    createdBy {
      firstName
      lastName
    }
    lastmodifiedBy {
      firstName
      lastName
    }
    isBroached
    rest
    order {
      id
      number
    }
    pile {
      id
      logLength
      number
    }
    time
    wagonNumber
    isWagonReady
    isCalculated
  }
`;

export const DriverInventoryNodeFragment = gql`
  fragment driverInventoryNode on InventoryNode {
    id
    amount
    count
    isBroached
    wagonNumber
    isWagonReady
    isCalculated
    pile {
      number
    }
  }
`;

export const InventorySummaryNodeFragment = gql`
  fragment inventorySummaryNode on InventorySummaryNode {
    combinedHeight
    stackNumber
    stackWidth
    totalAmount
    woodLength
  }
`;
