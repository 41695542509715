import gql from 'graphql-tag';
import { CompanyNodeFragment } from '../Company/Fragments';
import { AccountNameNodeFragment } from '../Account/Fragments';
import { VehicleNodeFragment } from '../Vehicle/Fragments';
import { InventoryNodeFragment, InventorySummaryNodeFragment } from '../Inventory/Fragments';
import { FileUploadNodeFragment } from '../FileUpload/Fragments';
import { PileNodeFragment } from '../Pile/Fragments';
import {
  DriverOrderNodeFragment,
  OrderStatusNodeFragment,
  OrderAllFieldsNodeFragment,
  TimeNodeFragment,
  OrderNodeFragment,
  OrderTypeNodeFragment,
} from './Fragments';
import { PlotNodeFragment } from '@src/graphql/Plot/Fragments';

// DESKTOP

export const getPaginatedOrders = gql`
  query orders(
    $activeOnly: Boolean
    $isPlausible: Boolean
    $addablePiles: [ID]
    $after: String
    $before: String
    $customer: ID
    $driver_Isnull: Boolean
    $driver: ID
    $first: Int
    $last: Int
    $number_Contains: Int
    $orderBy: String
    $orderByActiveStatus: Boolean
    $status: String
    $supplier_Isnull: Boolean
    $supplier: ID
    $type: String
    $usesPiles: [ID]
    $vehicle_Isnull: Boolean
    $vehicle: ID
    $collectedFromOtherSystem: Boolean
  ) {
    orders(
      activeOnly: $activeOnly
      isPlausible: $isPlausible
      addablePiles: $addablePiles
      after: $after
      before: $before
      customer: $customer
      driver_Isnull: $driver_Isnull
      driver: $driver
      first: $first
      last: $last
      number_Contains: $number_Contains
      orderBy: $orderBy
      orderByActiveStatus: $orderByActiveStatus
      status: $status
      supplier_Isnull: $supplier_Isnull
      supplier: $supplier
      type: $type
      usesPiles: $usesPiles
      vehicle_Isnull: $vehicle_Isnull
      vehicle: $vehicle
      collectedFromOtherSystem: $collectedFromOtherSystem
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...orderNode
          customer {
            ...companyNode
          }
          supplier {
            ...companyNode
          }
          driver {
            ...accountNameNode
          }
          vehicle {
            ...vehicleNode
          }
        }
      }
    }
  }
  ${OrderNodeFragment}
  ${CompanyNodeFragment}
  ${AccountNameNodeFragment}
  ${VehicleNodeFragment}
`;

export const getOrderStatusChanges = gql`
  query getOrderStatusChanges($orderId: ID) {
    order(orderId: $orderId) {
      id
      statusChanges {
        id
        createdAt
        createdBy {
          firstName
          lastName
        }
        oldStatus
        newStatus
        dispatchedAt
      }
    }
  }
`;

export const getOrderDetail = gql`
  query order($orderId: ID) {
    order(orderId: $orderId) {
      ...orderAllFieldsNode
      inventorySummary {
        ...inventorySummaryNode
      }
      customer {
        ...companyNode
      }
      supplier {
        ...companyNode
      }
      vehicle {
        ...vehicleNode
      }
      transportMedia {
        ...fileUploadNode
      }
      deliveryNoteCustomer {
        ...fileUploadNode
      }
      deliveryNoteSupplier {
        ...fileUploadNode
      }
      driver {
        ...accountNameNode
      }
      piles {
        edges {
          node {
            ...pileNode
            plot {
              ...plotNode
            }
          }
        }
      }
      statusChanges {
        id
        createdAt
        createdBy {
          firstName
          lastName
        }
        oldStatus
        newStatus
        dispatchedAt
      }
      route {
        edges {
          node {
            id
            latitude
            longitude
            index
          }
        }
      }
    }
    inventories(order: $orderId) {
      edges {
        node {
          ...inventoryNode
          inventoryDetails {
            stackNumber
            woodLength
            stackHeight
            stackWidth
          }
        }
      }
    }
    times(order: $orderId) {
      edges {
        node {
          ...timeNode
          user {
            ...accountNameNode
          }
          vehicle {
            ...vehicleNode
          }
        }
      }
    }
  }
  ${OrderAllFieldsNodeFragment}
  ${CompanyNodeFragment}
  ${VehicleNodeFragment}
  ${TimeNodeFragment}
  ${AccountNameNodeFragment}
  ${InventoryNodeFragment}
  ${FileUploadNodeFragment}
  ${PileNodeFragment}
  ${PlotNodeFragment}
  ${InventorySummaryNodeFragment}
`;

export const getOrderStatus = gql`
  query {
    orderStatus {
      edges {
        node {
          ...orderStatusNode
        }
      }
    }
  }
  ${OrderStatusNodeFragment}
`;

export const getOrderTypes = gql`
  query {
    orderTypes {
      edges {
        node {
          ...orderTypeNode
        }
      }
    }
  }
  ${OrderTypeNodeFragment}
`;

// MOBILE

export const getDriverOrders = gql`
  query prefetchOrders(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $status: String
    $activeOnly: Boolean
  ) {
    prefetchOrders(
      first: $first
      after: $after
      before: $before
      last: $last
      status: $status
      activeOnly: $activeOnly
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...driverOrderNode
        }
      }
    }
  }
  ${DriverOrderNodeFragment}
`;

export const getCachedDriverOrders = gql`
  query getCachedDriverOrder($id: ID!) {
    getCachedDriverOrder(id: $id) {
      id
      piles {
        edges {
          node {
            ...pileNode
          }
        }
      }
    }
  }
  ${PileNodeFragment}
`;

export const getDriverOrdersDetail = gql`
  query($orderId: ID) {
    order(orderId: $orderId) {
      ...driverOrderNode
      inventorySummary {
        ...inventorySummaryNode
      }
    }
  }
  ${DriverOrderNodeFragment}
  ${InventorySummaryNodeFragment}
`;
