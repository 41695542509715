import gql from 'graphql-tag';

export const PileNodeFragment = gql`
  fragment pileNode on PileNode {
    number
    date
    location
    woodtype
    woodtype2
    sort
    logLength
    cbkNet
    factorCbkNetCbkSolid
    markedAsBroached
    cbkSolid
    rest
    cbkSolidActual
    cbkNetActual
    locationPlan
    longitude
    statusOldSystem
    latitude
    pileNumberFurnisher
    status
    count
    note
    pkOtherSystem
    type
    category
    district
    id
    cubikSolidActual
    cubikActual
    countActual
    isBroached
    canBeAddedToAnyOrder
    amountUnit
    locationType
    usageSort
    qualityType
    timber
    mustBeAccepted
  }
`;

export const DriverPileNodeFragment = gql`
  fragment driverPileNode on PileNode {
    number
    date
    location
    woodtype
    woodtype2
    sort
    logLength
    rest
    locationPlan
    longitude
    latitude
    pileNumberFurnisher
    type
    id
    cubikSolidActual
    cubikActual
    countActual
    isBroached
    statusOldSystem
    locationType
    plot {
      number
    }
    cbkNet
    cbkSolid
    amountUnit
    qualityType
    category
    usageSort
  }
`;

export const DriverPileNoLimitNodeFragment = gql`
  fragment driverPileNoLimitNode on PileNode {
    isBroached
    number
    category
    location
    qualityType
    sort
    client {
      name
    }
    timber
    type
    usageSort
    plot {
      number
      company {
        name
      }
    }
    date
    woodtype
    woodtype2
    logLength
    rest
    locationPlan
    longitude
    latitude
    pileNumberFurnisher
    id
    cubikSolidActual
    cubikActual
    countActual
  }
`;

export const PileWoodtypeNodeFragment = gql`
  fragment pileWoodtypeNode on PileNode {
    id
    woodtype
  }
`;

export const PileLocationNodeFragment = gql`
  fragment pileLocationNode on PileLocationNode {
    id
    name
  }
`;

export const PileStatusNodeFragment = gql`
  fragment pileStatusNode on PileNode {
    id
    status
  }
`;

export const PileSortNodeFragment = gql`
  fragment pileSortNode on PileNode {
    id
    sort
  }
`;

export const PileTypeNodeFragment = gql`
  fragment pileTypeNode on PileNode {
    type
  }
`;

export const PileCategoryNodeFragment = gql`
  fragment pileCategoryNode on PileNode {
    category
  }
`;

export const PileDistrictNodeFragment = gql`
  fragment pileDistrictNode on PileNode {
    district
  }
`;
