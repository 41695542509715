import React, { useContext } from 'react';
import { PileNode } from '@src/generated/schema';
import { Button, List } from 'antd';
import { useMutation } from 'react-apollo';
import { Pile } from '@src/graphql/Pile';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { useTranslation } from 'react-i18next';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';

export interface IExportPiles {
  closeModal: () => void;

  piles: Array<Pick<PileNode, 'id' | 'number'>>;
}

const ListFooter = ({
  error,
  loading,
  onClick,
}: {
  error: ApolloError | undefined;
  loading: boolean;
  onClick: () => Promise<void>;
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column-reverse' }}>
      {error && <APIError errors={error} />}
      <Button loading={loading} type='primary' disabled={loading} onClick={onClick}>
        {t('desktop.pages.piles.exportPiles.title')}
      </Button>
    </div>
  );
};

export const ExportPiles: React.FC<IExportPiles> = ({ piles, closeModal }) => {
  const { setToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const [exportPilesFun, { error, loading }] = useMutation(Pile.exportPiles);

  const dataSource = piles.map(x => ({ title: x.number }));
  const pilesIds = piles.map(x => x.id);

  const handleDownloadPiles = async () => {
    setToast({
      type: TOAST_TYPES.INFO,
      message: 'Info',
      description: t('general.pile.import.toast.info.takestime'),
    });
    await exportPilesFun({ variables: { pilesIds } }).then(
      ({
        data: {
          exportPiles: { url },
        },
      }) => {
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.piles.exportPiles.toast.message'),
          description: t('desktop.pages.piles.exportPiles.toast.description'),
        });
        closeModal();
        window.location.assign(url);
      },
    );
  };

  return (
    <List
      itemLayout={'horizontal'}
      dataSource={dataSource}
      renderItem={item => <List.Item>{item.title}</List.Item>}
      header={pilesIds.length > 0 && <ListFooter loading={loading} error={error} onClick={handleDownloadPiles} />}
    />
  );
};
