import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { useMutation, useQuery } from 'react-apollo';
import { CompanyNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { IonCol, IonSpinner, IonRow } from '@ionic/react';
import { Company } from '@src/graphql/Company';
import { Form, FormValues } from '../Form';

export interface AlterProps {
  currentCompanyId?: string;
  toggleModal: () => void;
}

export const Alter: FC<AlterProps> = ({ toggleModal, currentCompanyId }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [changeCompany, { error: changeError }] = useMutation(Company.changeCompany, {
    refetchQueries: ['companies'],
  });
  const { data, loading } = useQuery<{
    company: CompanyNode;
  }>(Company.getCompanyDetail, {
    variables: { companyId: currentCompanyId },
  });

  const initialValues: FormValues = {
    name: data?.company?.name || undefined,
    matchcode: data?.company?.matchcode || undefined,
    note: data?.company?.note || undefined,
    information: data?.company?.info || undefined,
    customer: data?.company?.customer || undefined,
    supplier: data?.company?.supplier || undefined,
    carrier: data?.company?.carrier || undefined,
    billingAddressStreet: data?.company?.billingAddress?.street || undefined,
    billingAddressPostcode: data?.company?.billingAddress?.postcode || undefined,
    billingAddressPartOfVillage: data?.company?.billingAddress?.partOfVillage || undefined,
    billingAddressCountry: data?.company?.billingAddress?.country || undefined,
    billingAddressHousenumber: data?.company?.billingAddress?.housenumber || undefined,
  };

  const onSubmit = async (variables: FormValues) => {
    await changeCompany({
      variables: {
        ...variables,
        companyId: currentCompanyId,
      },
      refetchQueries: ['companies'],
    })
      .then(() => {
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.companies.edit.success.message'),
          description: t('desktop.pages.companies.edit.success.description'),
        });
      })
      .finally(toggleModal);
  };

  return (
    <div>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}
      {data && (
        <Wrapper>
          <Form initialFormValues={initialValues} onSubmit={onSubmit} error={changeError} />
        </Wrapper>
      )}
    </div>
  );
};
