import styled from 'styled-components';
import { TagProps, TagType } from './Tag';
import { transparentize } from 'polished';

const getBackgroundColor = (theme, type) => {
  if (type === TagType.PRIMARY) return transparentize(0.8, theme.color.emerald);
  if (type === TagType.ERROR) return transparentize(0.8, theme.color.cinnabar);

  return theme.color.mercury;
};

const getColor = (theme, type) => {
  if (type === TagType.PRIMARY) return theme.color.emerald;
  if (type === TagType.ERROR) return theme.color.cinnabar;

  return theme.color.silverChalice;
};

export const StyledTag = styled.div<Omit<TagProps, 'value' | 'text'>>`
  padding: 0.3em 0.6em;
  text-align: center;
  border-radius: ${({ theme }) => theme.global.borderRadius}px;
  color: ${({ theme, type }) => getColor(theme, type)};
  background-color: ${({ theme, type }) => getBackgroundColor(theme, type)};
`;
