import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';

export const TypeSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ pileTypes: PileNodeConnection }>(Pile.getPileTypes);
  const { pileTypes: { edges } = { edges: [] as any } } = data || {};

  return (
    <SearchSelect
      {...props}
      label={t('desktop.pages.piles.filter.type.title')}
      options={edges.map(({ node: { type } }) => ({
        title: t(`desktop.pages.piles.filter.type.${type}`),
        value: type.toLowerCase(),
      }))}
      name='type'
      loading={loading}
    />
  );
};
