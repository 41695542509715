import { Permissions } from '@src/components/Providers';

export enum Routes {
  ADD = '/add',
  ADD_WAGON = '/add-wagon',
  EDIT = '/edit',
  HOME = '/home',
  ID = '/:id',
  LOGIN = '/login',
  LOGOUT = '/logout',
  OFFLINE_MAPS = '/offline-maps',
  ORDERS = '/orders',
  DELIVERY_STATUS = '/delivery-status',
  ACCOUNTS = '/accounts',
  COMPANIES = '/companies',
  ORDER_ID = '/:orderId',
  PASSWORD = '/password',
  PILES = '/piles',
  PILE_ID = '/:pileId',
  PLOTS = '/plots',
  START = '/',
  UPLOAD = '/api/v1/fileupload',
}

export enum FilesystemRoutes {
  DELIVERY_NOTES = 'deliverNotes',
  TRANSPORT_MEDIA = 'transportMedia',
  OFFLINE_TILES = 'offlineTiles',
}

export const PrivateRoutes: Routes[] = [
  Routes.HOME,
  Routes.OFFLINE_MAPS,
  Routes.ORDERS,
  Routes.PASSWORD,
  Routes.PILES,
  Routes.PLOTS,
  Routes.COMPANIES,
];

export type RoutesPermissionConf = {
  [route in Routes]?: Permissions[];
};

/**
 * Routes that can only be accessed if logged in and while having a certain permission.
 */
export const PrivateRoutesWithPermission: RoutesPermissionConf = {
  [Routes.DELIVERY_STATUS]: [Permissions.CAN_VIEW_DELIVERY_STATUS],
  [Routes.ACCOUNTS]: [Permissions.CAN_VIEW_ACCOUNTS],
  [Routes.COMPANIES]: [Permissions.CAN_VIEW_COMPANY],
};

export const RoutesWithBackButton: string[] = [`${Routes.ORDERS}/`, `${Routes.PILES}/`];
