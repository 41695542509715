import React, { FC, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PileNode, StringPaginationType } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MobileSearchSelect } from '@src/components/Mobile/Elements/SearchSelect';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { MobileSelectProps } from '@src/components/Mobile/Elements/SearchSelect/SearchSelect';
import { getUniqueList } from '@src/helpers/Logic/Logic';

export interface MobileSupplierSelectProps extends Omit<MobileSelectProps, 'options'> {
  piles?: PileNode[];
}

export const MobileSupplierSelect: FC<MobileSupplierSelectProps> = ({ piles, ...props }) => {
  const { isOnline } = useContext(NetworkContext);
  const { data, loading, fetchMore, refetch } = useQuery<{ supplier: StringPaginationType }>(Pile.suppliers, {
    variables: {
      pageSize: 20,
    },
  });
  const { supplier: { page, strings, hasNext } = { page: 0, strings: [], hasNext: false } } = data || {};

  const handleSearch = useCallback(
    debounce((filterValue: string) => {
      refetch({ filterValue, pageSize: 20 });
    }, 500),
    [],
  );

  const handlePopupScroll = (e: any) => {
    const previousPage: number = page || 0;

    if (!hasNext) return;

    fetchMore({
      variables: {
        page: previousPage + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newPage = fetchMoreResult?.supplier?.page || 0;
        const oldPage = prev?.supplier?.page || 0;

        if (!fetchMoreResult || newPage <= oldPage) return prev;

        const newEdges = fetchMoreResult.supplier.strings;
        const previousEdges = prev?.supplier?.strings || [];

        return newEdges?.length
          ? {
              supplier: {
                page: fetchMoreResult.supplier.page,
                pages: fetchMoreResult.supplier.pages,
                hasNext: fetchMoreResult.supplier.hasNext,
                hasPrevious: fetchMoreResult.supplier.hasPrevious,
                __typename: prev.supplier.__typename,
                strings: [...previousEdges, ...newEdges],
              },
            }
          : prev;
      },
    });
  };

  return (
    <MobileSearchSelect
      {...props}
      options={
        isOnline
          ? strings?.map(companyName => ({
              id: companyName,
              title: companyName,
              value: companyName,
            }))
          : getUniqueList(piles?.map(p => p.plot?.company?.name)).map(companyName => ({
              id: companyName,
              value: companyName,
              title: companyName,
            }))
      }
      loading={loading}
      onSearch={handleSearch}
      multi
      onLoadMore={handlePopupScroll}
      allowNoSelection
    />
  );
};
