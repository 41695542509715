import gql from 'graphql-tag';
import { OrderAllFieldsNodeFragment } from '@src/graphql/Order/Fragments';

export const createOrder = gql`
  mutation createOrder(
    $amount: Int!
    $customer: ID!
    $driver: ID
    $note: String
    $piles: [String]
    $routes: [String]
    $planDate: DateTime
    $repeat: Boolean
    $supplier: ID
    $vehicle: ID
    $transportMedia: ID
    $deliveryStatus: ID
    $repeatEndDate: DateTime
  ) {
    createOrder(
      amount: $amount
      customer: $customer
      driver: $driver
      routes: $routes
      note: $note
      piles: $piles
      planDate: $planDate
      repeat: $repeat
      supplier: $supplier
      vehicle: $vehicle
      deliveryStatus: $deliveryStatus
      transportMedia: $transportMedia
      repeatEndDate: $repeatEndDate
    ) {
      ok
    }
  }
`;

export const editOrder = gql`
  mutation editOrder(
    $amount: Int!
    $customer: ID!
    $driver: ID
    $note: String
    $noteDriver: String
    $order: ID!
    $planDate: DateTime
    $repeat: Boolean
    $isPlausible: Boolean
    $supplier: ID
    $transportMedia: ID
    $vehicle: ID
    $deliveryStatus: ID
    $repeatEndDate: DateTime
  ) {
    editOrder(
      order: $order
      amount: $amount
      customer: $customer
      driver: $driver
      note: $note
      noteDriver: $noteDriver
      planDate: $planDate
      deliveryStatus: $deliveryStatus
      repeat: $repeat
      isPlausible: $isPlausible
      supplier: $supplier
      transportMedia: $transportMedia
      vehicle: $vehicle
      repeatEndDate: $repeatEndDate
    ) {
      ok
    }
  }
`;

export const appendPilesToExistingOrder = gql`
  mutation appendPilesToExistingOrder($orderId: ID!, $pileIdList: [ID]!) {
    appendPilesToExistingOrder(orderId: $orderId, pileIdList: $pileIdList) {
      ok
    }
  }
`;

export const deletePilesFromExistingOrder = gql`
  mutation deletePilesFromExistingOrder($orderId: ID!, $pileIdList: [ID]!) {
    deletePilesFromExistingOrder(orderId: $orderId, pileIdList: $pileIdList) {
      ok
    }
  }
`;

export const setOrderStatus = gql`
  mutation setOrderStatus(
    $idList: [ID]!
    $status: StatusChoice!
    $dispatchedAt: DateTime
    $repeatOnFinished: Boolean
    $accuracy: Decimal
    $longitude: Decimal
    $latitude: Decimal
  ) {
    setOrderStatus(
      idList: $idList
      status: $status
      dispatchedAt: $dispatchedAt
      repeatOnFinished: $repeatOnFinished
      accuracy: $accuracy
      longitude: $longitude
      latitude: $latitude
    ) {
      ok
      orders {
        ...orderAllFieldsNode
      }
    }
  }
  ${OrderAllFieldsNodeFragment}
`;

export const setDeliveryNoteCustomer = gql`
  mutation setDeliveryNoteCustomer($orderId: ID!, $deliveryNoteCustomerId: ID!) {
    setDeliveryNoteCustomer(orderId: $orderId, deliveryNoteCustomerId: $deliveryNoteCustomerId) {
      ok
    }
  }
`;

export const setDeliveryNoteSupplier = gql`
  mutation setDeliveryNoteSupplier($orderId: ID!, $deliveryNoteSupplierId: ID!) {
    setDeliveryNoteSupplier(orderId: $orderId, deliveryNoteSupplierId: $deliveryNoteSupplierId) {
      ok
    }
  }
`;

export const saveRoute = gql`
  mutation changeRoute($orderId: ID!, $routes: [RouteNodeInput]!) {
    changeRoute(orderId: $orderId, routes: $routes) {
      ok
    }
  }
`;

export const rollbackOrderStatusChange = gql`
  mutation rollbackOrderStatusChange($orderId: ID!) {
    rollbackOrderStatusChange(orderId: $orderId) {
      order {
        statusChanges {
          id
          createdAt
          createdBy {
            firstName
            lastName
          }
          oldStatus
          newStatus
          dispatchedAt
        }
      }
    }
  }
`;

export const changeToNextStatus = gql`
  mutation changeToNextStatus($orderId: ID!) {
    changeToNextStatus(orderId: $orderId) {
      ok
    }
  }
`;
