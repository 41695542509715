import React, { FC } from 'react';
import { StyledButtonLink } from './ButtonLink.styles';
import { LinkProps } from 'react-router-dom';

export enum ButtonType {
  DEFAULT = 'Default',
  SELECTED = 'Selected',
}

export const ButtonLink: FC<LinkProps> = ({ children, ...props }) => (
  <StyledButtonLink {...props}>{children}</StyledButtonLink>
);
