import React, { createContext, FC } from 'react';
import { FilesystemRoutes, Routes } from '@src/global';
import { isPlatform } from '@ionic/react';
import { FileOpener } from '@ionic-native/file-opener';
import { DocumentViewer } from '@ionic-native/document-viewer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { decode } from 'base64-arraybuffer';

export const FILESYSTEM_DIRECTORY = Directory.External;

interface FileContextProps {
  checkFileExists: (folderName: FilesystemRoutes, fileName: string) => Promise<boolean>;
  deleteFile: (folderName: FilesystemRoutes, fileName: string) => Promise<void>;
  downloadFile: (url: string, location: FilesystemRoutes, fileName: string) => Promise<void>;
  getFileAsBase64: (folderName: FilesystemRoutes, fileName: string) => Promise<string>;
  getFileSha256: (folderName: FilesystemRoutes, fileName: string) => Promise<string>;
  getFileUri: (folderName: FilesystemRoutes, fileName: string) => Promise<string>;
  listDirectoryFiles: (folderName: FilesystemRoutes) => Promise<string[]>;
  openPdf: (folderName: FilesystemRoutes, fileName: string, mimeType: string) => void;
  saveFile: (file: Blob | File, folderName: FilesystemRoutes, fileName: string) => Promise<string>;
  uploadFile: (file: File) => Promise<Response>;
}

export const FileContext = createContext<FileContextProps>({
  checkFileExists: (_args) => Promise.resolve(false),
  deleteFile: (_args) => Promise.resolve({} as any),
  downloadFile: (_args) => Promise.resolve(),
  getFileAsBase64: (_args) => Promise.resolve() as any,
  getFileSha256: (_args) => Promise.resolve() as any,
  getFileUri: (_args) => Promise.resolve() as any,
  listDirectoryFiles: (_args) => Promise.resolve([]),
  openPdf: (_args) => null,
  saveFile: (_args) => Promise.resolve() as any,
  uploadFile: (file: File) => Promise.resolve() as any,
});

/**
 * Equal Signs should not be part of the filename
 */
export const sanitizeFileName = (fileName: string): string => fileName.replace(/=/g, 'EQUAL');

/**
 * Checks a given folder by automatically resolving the base-path and creates the folder if it's not already there.
 */
export const checkOrCreateDirectory = async (folderName: FilesystemRoutes) => {
  try {
    await Filesystem.readdir({ path: folderName, directory: FILESYSTEM_DIRECTORY });
  } catch (e) {
    await Filesystem.mkdir({ path: folderName, directory: FILESYSTEM_DIRECTORY });
  }
};

/**
 * Wrapper for File.checkFile that automatically sets the base-path
 */
export const checkFileExists = async (folderName: FilesystemRoutes, fileName: string): Promise<boolean> => {
  await checkOrCreateDirectory(folderName);
  // eslint-disable-next-line no-useless-catch
  try {
    const fileList = await listDirectoryFiles(folderName);

    return fileList.find((element) => element === sanitizeFileName(fileName)) !== undefined;
  } catch (error) {
    throw error;
  }
};

export const getFileUri = async (folderName: FilesystemRoutes, fileName: string): Promise<string> => {
  const fileSystemRoute = await Filesystem.getUri({
    directory: FILESYSTEM_DIRECTORY,
    path: `${folderName}/${sanitizeFileName(fileName)}`,
  });

  return fileSystemRoute.uri;
};

/**
 * Wrapper for File.listdir that automatically sets the base-path
 */
export const listDirectoryFiles = async (folderName: FilesystemRoutes): Promise<string[]> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const filesList = await Filesystem.readdir({ path: `${folderName}/`, directory: FILESYSTEM_DIRECTORY });

    return filesList.files.map((fileInfo) => fileInfo.name);
  } catch (error) {
    throw error;
  }
};

/**
 * Wrapper for File.removeFile that automatically sets the base-path
 */
export const deleteFile = (folderName: FilesystemRoutes, fileName: string): Promise<void> =>
  Filesystem.deleteFile({
    path: `${folderName}/${sanitizeFileName(fileName)}`,
    directory: FILESYSTEM_DIRECTORY,
  });

/**
 * Saves a file to the filesystem
 */
export const saveFile = async (file: File | Blob, folderName: FilesystemRoutes, fileName: string): Promise<string> => {
  await checkOrCreateDirectory(folderName);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const sanitizedFileName = sanitizeFileName(fileName);

    reader.readAsDataURL(file);
    reader.onloadend = async function () {
      if (typeof reader.result === 'string') {
        await Filesystem.writeFile({
          directory: FILESYSTEM_DIRECTORY,
          path: `${folderName}/${sanitizedFileName}`,
          data: reader.result,
        });

        resolve(reader.result);
      }

      reject('Unexpected result');
    };
  });
};

export const getFileAsBase64 = async (folderName: FilesystemRoutes, fileName: string): Promise<string> => {
  const sanitizedFileName = sanitizeFileName(fileName);
  const filesList = await listDirectoryFiles(folderName);
  const existingFile = filesList.find((_fileName) => _fileName.includes(sanitizedFileName));
  if (!existingFile) throw Error(`File ${folderName}/${existingFile} does not exist`);
  // eslint-disable-next-line no-useless-catch
  try {
    const file = await Filesystem.readFile({
      directory: FILESYSTEM_DIRECTORY,
      path: `${folderName}/${existingFile}`,
    });

    return `data:image/jpeg;base64,${file.data}`;
  } catch (e) {
    throw e;
  }
};

/**
 * Replacement of File.download with correct CORS-Implementation, which downloads a file as a blob and then saving it
 * to the desired file and folder enhancing the correct base-path with write access.
 */
export const downloadFile = async (url: string, folderName: FilesystemRoutes, fileName: string): Promise<void> => {
  await checkOrCreateDirectory(folderName);

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    await saveFile(blob, folderName, fileName);
  } catch (error) {
    throw error;
  }
};

/**
 * Opens a pdf from the filesystem
 */
export const openPdf = async (folderName: FilesystemRoutes, fileName: string, mimeType: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const fileSystemRoute = await getFileUri(folderName, fileName);

    if (isPlatform('ios')) {
      await FileOpener.open(fileSystemRoute, mimeType);
    } else {
      DocumentViewer.viewDocument(fileSystemRoute, mimeType, {});
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Returns the SHA-256 hash of a given file
 */
export const getFileSha256 = async (folderName: FilesystemRoutes, fileName: string): Promise<string> => {
  const sanitizedFileName = sanitizeFileName(fileName);
  const filesList = await listDirectoryFiles(folderName);
  const existingFile = filesList.find((_fileName) => _fileName.includes(sanitizedFileName));
  const file = await Filesystem.readFile({
    directory: FILESYSTEM_DIRECTORY,
    path: `${folderName}/${existingFile}`,
  });
  const data = typeof file.data === 'string' ? file.data : await file.data.text();
  const hashBuffer = await crypto.subtle.digest('SHA-256', decode(data));
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

export const uploadFile = async (file: File): Promise<Response> => {
  const jwt = await Preferences.get({ key: 'token' });
  const headers = jwt ? new Headers({ Authorization: `JWT ${jwt.value}` }) : new Headers({});

  const data = new FormData()
  data.append('file', file)

  return fetch(`${window.env.LOGISTIK_BACKEND_URL}${Routes.UPLOAD}`, {
    method: 'POST',
    body: data,
    headers: new Headers(headers)
  })
};
export const FileProvider: FC = ({ children }) => (
  <FileContext.Provider
    value={{
      downloadFile,
      checkFileExists,
      getFileAsBase64,
      getFileUri,
      listDirectoryFiles,
      deleteFile,
      openPdf,
      saveFile,
      uploadFile,
      getFileSha256,
    }}
  >
    {children}
  </FileContext.Provider>
);
