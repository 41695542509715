import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledIonButtonRow, StyledIonCol, StyledButton } from './InventoryForm.styles';

interface WagonInventoryFormButtonProps {
  handleDeleteSubmit: (e: any) => void;
  handleSaveSubmit: (e: any) => void;
  handleUpdateInventory: (e: any, field: any) => void;
  isSubmitting: boolean;
  isValid: boolean;
  showDelete: boolean;
  showSave: boolean;
  // validationErrors: ValidationError[] | undefined;
}

export const WagonInventoryFormButton: FC<WagonInventoryFormButtonProps> = ({
  handleSaveSubmit,
  handleDeleteSubmit,
  showDelete,
  showSave,
  isSubmitting,
  isValid = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {(showDelete || showSave) && (
        <StyledIonButtonRow>
          {showSave && (
            <StyledIonCol size={showDelete ? '6' : '12'}>
              <StyledButton
                type='submit'
                onClick={handleSaveSubmit}
                color='primary'
                fill='solid'
                expand='block'
                disabled={isSubmitting || !isValid}
              >
                {t('containers.inventoryForm.form.submit')}
              </StyledButton>
            </StyledIonCol>
          )}

          {showDelete && (
            <StyledIonCol size={showSave ? '6' : '12'}>
              <StyledButton
                type='button'
                onClick={handleDeleteSubmit}
                color='primary'
                fill='solid'
                expand='block'
                disabled={isSubmitting}
              >
                {t('containers.inventoryForm.form.delete')}
              </StyledButton>
            </StyledIonCol>
          )}
        </StyledIonButtonRow>
      )}
    </>
  );
};
