import styled from 'styled-components';
import { Button } from 'antd';
import { IonIcon } from '@ionic/react';

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.gallery} !important;
  color: ${({ theme }) => theme.global.color};
  border: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const StyledUserIcon = styled(IonIcon)`
  font-size: 1.3em;
  margin-right: 0.4em;
`;

export const StyledChevronIcon = styled(IonIcon)`
  font-size: 1em;
`;
