import {
  piles,
  getPaginatedPiles,
  getPileWoodtypes,
  getPileDistricts,
  getPileStatus,
  getPileSorts,
  getPileLocations,
  getPileCategories,
  getPileTypes,
  getDetailPile,
  logLengths,
  getPilesNoLimit,
  suppliers,
  qualityTypes,
  timbers,
  clients,
  usageSorts,
} from './Queries';
import { changePile, createPile, deletePile, deletePiles, exportPiles, importPiles } from '@src/graphql/Pile/Mutations';

export const Pile = {
  piles,
  clients,
  usageSorts,
  getPaginatedPiles,
  getPilesNoLimit,
  getPileWoodtypes,
  getPileDistricts,
  getPileStatus,
  getPileSorts,
  getPileLocations,
  getPileCategories,
  getPileTypes,
  getDetailPile,
  createPile,
  logLengths,
  suppliers,
  changePile,
  deletePile,
  deletePiles,
  exportPiles,
  importPiles,
  qualityTypes,
  timbers,
};
