import React, { FC, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import { OrderNode, PileNode } from '@src/generated/schema';
import { Order } from '@src/graphql/Order';
import { DriverPileNodeFragment } from '@src/graphql/Pile/Fragments';
import { Page } from '@src/components/Mobile/Sections/Page';

import { StyledTitle } from './Add.styles';
import { InventoryForm } from '@src/components/Containers/InventoryForm';
import { LocationContext } from '@src/components/Mobile/Providers';

export const Add: FC = () => {
  const client = useApolloClient();
  const { goBack } = useHistory();
  const { orderId, pileId } = useParams<{ orderId: string; pileId: string }>();
  const [order, setOrder] = useState<OrderNode | null | undefined>();
  const [pile, setPile] = useState<PileNode | null | undefined>();
  const location = useContext(LocationContext);
  /**
   * Sets the pile via given pileId.
   */
  useEffect(() => {
    let nextPile: PileNode | null = null;

    if (pileId) {
      nextPile = client.readFragment({ id: pileId, fragment: DriverPileNodeFragment, fragmentName: 'driverPileNode' });
      setPile(nextPile);
    }
  }, [pileId, client, setPile]);

  /**
   * Sets the order via given orderId.
   */
  useEffect(() => {
    if (orderId) {
      setOrder(
        client.readFragment({
          id: orderId,
          fragment: Order.DriverOrderNodeFragment,
          fragmentName: 'driverOrderNode',
        }),
      );
    }
  }, [orderId, client, setOrder]);

  return (
    <Page>
      <StyledTitle />

      <InventoryForm
        deleteEnabled={false}
        createEnabled
        editEnabled={false}
        offlineHandling
        order={order || undefined}
        pile={pile || undefined}
        onSubmit={goBack}
        locationData={location}
      />
    </Page>
  );
};
