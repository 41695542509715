import styled from 'styled-components';
import { StyledWrapper } from '@src/components/Elements/Wrapper';
import img from '@src/assets/img/login-bg.png';
import { BREAKPOINTS } from '@src/theme';

export const StyledPasswordPage = styled.div`
  display: flex;
  height: 100%;

  > ${StyledWrapper} {
    display: flex;
    width: 15vw;
    flex-direction: column;
    flex-grow: 1;
    min-width: 300px;
    margin-top: 3em;

    @media (max-height: ${BREAKPOINTS.medium}px) {
      margin-top: 0;
    }

    @media ${({ theme }) => theme.media.medium} {
      margin-top: 1em;
    }
  }
`;

export const StyledPasswordPageVisual = styled.div`
  background-image: url(${img});
  background-size: cover;
  background-position: center;
  width: 60vw;
  flex-direction: column-reverse;
  align-items: flex-end;
  display: none;

  @media ${({ theme }) => theme.media.medium} {
    display: flex;
  }
`;

export const StyledSubline = styled.p`
  margin-bottom: 3em;
`;
