import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { CompanyNodeConnection } from '@src/generated/schema';
import { Company } from '@src/graphql/Company';
import { SearchSelect, SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import uniqBy from 'lodash.uniqby';
import debounce from 'lodash.debounce';
import { MultiSelect } from '@src/components/Elements/MultiSelect';

interface SupplierSelectProps extends Pick<SearchSelectProps, 'required'> {
  multiSelect?: boolean;
  prefilledValue?: string;
  setNameAsValue?: boolean;
  showBlankSupplierOption?: boolean;
}

export const noSupplier = 'noSupplier';

export const SupplierSelect: FC<SupplierSelectProps> = ({
  setNameAsValue,
  showBlankSupplierOption,
  prefilledValue,
  multiSelect,
  ...props
}) => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ companies: CompanyNodeConnection }>(Company.suppliers, {
    variables: {
      first: 10,
      name_Icontains: prefilledValue,
    },
    fetchPolicy: 'network-only',
  });
  const { companies: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 10,
        name_Icontains: value,
      });
    }, 100),
    [],
  );

  const handleDelete = useCallback(
    debounce(() => {
      refetch({
        first: 10,
      });
    }, 100),
    [],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.companies.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newEdges = fetchMoreResult.companies.edges;
          const pageInfo = fetchMoreResult.companies.pageInfo;

          return newEdges.length
            ? {
                companies: {
                  pageInfo,
                  __typename: prev.companies.__typename,
                  edges: [...prev.companies.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  const searchSelectOptions = uniqBy(
    edges.map(({ node: { name, id } }) => ({
      title: name,
      value: setNameAsValue ? name : id,
    })),
    'value',
  );

  if (showBlankSupplierOption) {
    searchSelectOptions.unshift({ title: t('desktop.pages.general.filter.withoutSupplier'), value: noSupplier });
  }

  return (
    <>
      {multiSelect ? (
        <MultiSelect
          {...props}
          label={t('general.supplier')}
          options={searchSelectOptions}
          onDeselect={handleDelete}
          name='supplier'
          loading={loading}
          onSearch={handleSearch}
          onPopupScroll={handlePopupScroll}
        />
      ) : (
        <SearchSelect
          {...props}
          label={t('general.supplier')}
          options={searchSelectOptions}
          onDeselect={handleDelete}
          name='supplier'
          loading={loading}
          onSearch={handleSearch}
          onPopupScroll={handlePopupScroll}
        />
      )}
    </>
  );
};
