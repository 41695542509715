import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { UploadFile } from 'antd/es/upload/interface';
import { Input } from '@src/components/Desktop/Containers/Input';
import { TextArea } from '@src/components/Desktop/Containers/TextArea';
import { Form as FormWrapper, Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import {
  DriverSelect,
  noDriver,
  VehicleSelect,
  CompanySelect,
  noSupplier,
} from '@src/components/Desktop/Containers/SearchSelects';
import { Column } from '@src/components/Desktop/Elements/Wrapper/Form/Column';
import { SubmitButton } from 'formik-antd';
import { isFieldRequired } from '@src/helpers';
import { DatePicker } from '@src/components/Desktop/Containers/DatePicker';
import { FileUpload } from '@src/components/Desktop/Containers/FileUpload';
import { Switch } from '@src/components/Desktop/Containers/Switch/Switch';
import { TestIds } from '@src/global';
import moment, { Moment } from 'moment';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';
import { SupplierSelect } from '@src/components/Desktop/Containers/SearchSelects/SupplierSelect';
import { Button } from 'antd';
import { DeliveryStatusSelect } from '@src/components/Desktop/Containers/SearchSelects/DeliveryStatusSelect';
export interface FormValues {
  amount: number | undefined;
  customer: string | undefined;
  deliveryStatus: string | undefined;
  driver: string | undefined;
  isPlausible?: boolean;
  note: string | undefined;
  planDate: Moment | undefined;
  repeat: boolean;
  repeatEndDate: Moment | undefined;
  supplier: string | undefined;
  transportMedia: UploadFile | undefined;
  vehicle: string | undefined;
}

export interface FormProps {
  error?: ApolloError;
  initialOrderValues?: FormValues;
  onAbortOrder?: () => Promise<any>;
  onSubmit: (variables: FormValues) => Promise<false | undefined>;
  orderId?: string;
  prefilledValues?: PrefilledFormValues;
  showSupplier: boolean;
}

export interface PrefilledFormValues {
  customer: string;
  deliveryStatus: string;
  supplier: string;
}

export const Form: FC<FormProps> = ({
  error,
  initialOrderValues,
  onAbortOrder,
  onSubmit,
  prefilledValues,
  showSupplier,
  orderId,
}) => {
  const { t } = useTranslation();

  const initialValues: FormValues = initialOrderValues || {
    amount: undefined,
    customer: undefined,
    driver: undefined,
    isPlausible: false,
    note: undefined,
    planDate: moment(),
    repeat: false,
    repeatEndDate: undefined,
    deliveryStatus: undefined,
    supplier: undefined,
    transportMedia: undefined,
    vehicle: undefined,
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(0)
      .required(t(i18nFormFieldRequired, { field: t('general.amount') })),
    customer: Yup.string().required(t(i18nFormFieldRequired, { field: t('general.customer') })),
    dischargeOrder: Yup.string(),
    driver: Yup.string().nullable(),
    note: Yup.string().nullable(),
    planDate: Yup.date().nullable(),
    deliveryStatus: Yup.string().notRequired(),
    repeat: Yup.boolean(),
    repeatEndDate: Yup.date().when('repeat', {
      is: true,
      then: Yup.date()
        .min(moment().toDate(), t('forms.validation.errors.timeNotInTheFuture'))
        .required(t(i18nFormFieldRequired, { field: t('general.repeatEndDate') })),
      else: Yup.date(),
    }),
    isPlausible: Yup.boolean().notRequired(),
    supplier: Yup.string(),
    vehicle: Yup.string().nullable(),
  });

  const handleSubmit = async (values: FormValues) =>
    onSubmit({
      ...values,
      driver: values.driver === noDriver ? undefined : values.driver,
      supplier: values.supplier === noSupplier ? undefined : values.supplier,
      // in case of duplicating a file, we don't have a response but can use the uid of that file instead
      transportMedia: values?.transportMedia?.response?.id || values?.transportMedia?.uid,
    });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, values, setFieldValue, getFieldMeta }) => (
        <FormWrapper>
          <Row>
            {showSupplier && (
              <Column>
                <SupplierSelect
                  prefilledValue={prefilledValues?.supplier}
                  required={isFieldRequired('supplier', validationSchema)}
                  data-testid={TestIds.ui.inputs.createOrder.supplierInput}
                />
              </Column>
            )}
            <Column>
              <CompanySelect
                prefilledValue={prefilledValues?.customer}
                required={isFieldRequired('customer', validationSchema)}
                data-testid={TestIds.ui.inputs.createOrder.customerInput}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Input
                label={t('general.amount')}
                type='number'
                name='amount'
                required={isFieldRequired('amount', validationSchema)}
                data-testid={TestIds.ui.inputs.createOrder.amountInput}
              />
            </Column>
            <Column>
              <DriverSelect
                required={isFieldRequired('driver', validationSchema)}
                data-testid={TestIds.ui.inputs.createOrder.driverInput}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <VehicleSelect
                required={isFieldRequired('vehicle', validationSchema)}
                data-testid={TestIds.ui.inputs.createOrder.vehicleInput}
              />
            </Column>
            <Column>
              <DatePicker
                name='planDate'
                required={isFieldRequired('planDate', validationSchema)}
                label={t('general.planDate')}
                data-testid={TestIds.ui.inputs.createOrder.dateInput}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <DeliveryStatusSelect
                getFieldMeta={getFieldMeta}
                setFieldValue={setFieldValue}
                prefilledValue={prefilledValues?.deliveryStatus}
                resetValuePreFetch
                disabled={!values.customer || !values.planDate}
                customer={values.customer}
                singlePlanOnly
                date={values.planDate}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Switch
                name='repeat'
                required={isFieldRequired('repeat', validationSchema)}
                label={t('general.repeat')}
                data-testid={TestIds.ui.inputs.createOrder.repeatInput}
              />
            </Column>
            {values.repeat && (
              <Column>
                <DatePicker
                  showTime={{ defaultValue: moment('18:00:00', 'HH:mm:ss') }}
                  name='repeatEndDate'
                  required={values.repeat}
                  format='DD.MM.YYYY HH:mm:ss'
                  label={t('general.repeatEndDate')}
                />
              </Column>
            )}
          </Row>

          <Row>
            <Column>
              <FileUpload
                name='transportMedia'
                required={isFieldRequired('transportMedia', validationSchema)}
                label={t('general.transportMedia')}
              />
            </Column>
            <Column>
              <TextArea
                label={t('general.note')}
                type='text'
                name='note'
                required={isFieldRequired('note', validationSchema)}
                data-testid={TestIds.ui.inputs.createOrder.noteInput}
              />
            </Column>
          </Row>
          {orderId && (
            <Row>
              <Switch
                name='isPlausible'
                required={isFieldRequired('isPlausible', validationSchema)}
                label={t('general.isPlausible')}
              />
            </Row>
          )}

          <Row justify='space-between'>
            <SubmitButton type='primary' data-testid={TestIds.ui.inputs.createOrder.submitButton} disabled={!isValid}>
              {t('desktop.pages.orders.add.form.submit')}
            </SubmitButton>
            {onAbortOrder && (
              <Button onClick={onAbortOrder}>{t('desktop.pages.orders.alter.form.abort.button')}</Button>
            )}
          </Row>

          {error && <APIError errors={error} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
