import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form, FormValues } from '../Form';
import { TestIds } from '@src/global';
import { useQuery, useMutation } from 'react-apollo';
import { Order } from '@src/graphql/Order';
import { OrderNode, StatusChoice } from '@src/generated/schema';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { UploadFile } from 'antd/es/upload/interface';
import { IonCol, IonSpinner, IonRow } from '@ionic/react';
import { PrefilledFormValues } from '../Form/Form';
import { getDeliveryStatusSelectText } from '@src/components/Desktop/Containers/SearchSelects/DeliveryStatusSelect/DeliveryStatusSelect';

export interface AlterProps {
  currentOrderId?: string;
  toggleModal: () => void;
}

export const Alter: FC<AlterProps> = ({ toggleModal, currentOrderId }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [editOrder, { error }] = useMutation(Order.editOrder);
  const { data, loading } = useQuery<{
    order: OrderNode;
  }>(Order.getOrderDetail, {
    variables: { orderId: currentOrderId },
  });

  const [setOrderStatus] = useMutation(Order.setOrderStatus);
  const handleAbortOrderStatusChange = async () => {
    try {
      await setOrderStatus({ variables: { status: StatusChoice.Aborted, idList: [currentOrderId] } });
      setToast({
        type: TOAST_TYPES.SUCCESS,
        message: t('desktop.pages.orders.alter.form.abort.success.message'),
        description: t('desktop.pages.orders.alter.form.abort.success.description'),
      });
    } catch (error) {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.orders.alter.form.abort.error.message'),
        description: t('desktop.pages.orders.alter.form.abort.error.description'),
      });
    }
    toggleModal();
  };

  const uploadedFile: UploadFile = {
    uid: data?.order?.transportMedia?.id || '',
    size: 1,
    type: 'pdf',
    name: 'Zuletzt hochgeladen',
    fileName: 'Zuletzt hochgeladen',
    url: data?.order?.transportMedia?.s3Path || '',
  };

  const initialValues: FormValues = {
    amount: data?.order?.amount || undefined,
    customer: data?.order?.customer?.id || undefined,
    deliveryStatus: data?.order?.deliveryStatus?.id || undefined,
    transportMedia: data?.order?.transportMedia ? uploadedFile : undefined,
    driver: data?.order?.driver?.id || undefined,
    note: data?.order?.note || undefined,
    planDate: data?.order?.planDate || moment(),
    repeat: data?.order?.repeat || false,
    isPlausible: data?.order?.isPlausible || false,
    repeatEndDate: data?.order?.repeatEndDate || undefined,
    supplier: data?.order?.supplier?.id || undefined,
    vehicle: data?.order?.vehicle?.id || undefined,
  };

  const prefilledValues: PrefilledFormValues = {
    customer: data?.order?.customer?.name || '',
    supplier: data?.order?.supplier?.name || '',
    deliveryStatus: getDeliveryStatusSelectText(data?.order?.deliveryStatus),
  };

  const orderPiles = data?.order?.piles?.edges || [];

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: data_ } = await editOrder({
        variables: {
          ...variables,
          order: currentOrderId,
          transportMedia: variables.transportMedia || uploadedFile.uid,
        },
        refetchQueries: ['orders', { query: Order.getOrderDetail, variables: { orderId: currentOrderId } }],
      });

      if (data_) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.orders.alter.form.submit.success.message'),
          description: t('desktop.pages.orders.alter.form.submit.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div data-testid={TestIds.pages.orders.add.identifier}>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}
      {data && (
        <Wrapper>
          <Form
            prefilledValues={prefilledValues}
            initialOrderValues={initialValues}
            onSubmit={onSubmit}
            error={error}
            showSupplier={orderPiles.length === 0}
            onAbortOrder={data.order?.canBeAborted ? handleAbortOrderStatusChange : undefined}
            orderId={data?.order?.id}
          />
        </Wrapper>
      )}
    </div>
  );
};
