import {
  createInventory,
  createWagonInventory,
  deleteInventory,
  updateInventory,
  exportInventoriesTowardsWagon,
} from './Mutations';
import { getInventoriesByDeliveryStatusTowardsWagon } from './Queries';

export const Inventory = {
  createInventory,
  updateInventory,
  deleteInventory,
  createWagonInventory,
  getInventoriesByDeliveryStatusTowardsWagon,
  exportInventoriesTowardsWagon,
};
