import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TestIds } from '@src/global';
import { Container, Wrapper } from '@src/components/Elements';
import { closePopups } from '@src/helpers/DomManipulations';
import { PageHeadline } from '@src/components/Elements/PageHeadline';
import { ItemsPerPagePicker } from '@src/components/Desktop/Containers/ItemsPerPagePicker';

import { Table } from './Table';
import { InventoriesTowardsWagonTable } from './InventoriesTowardsWagonTable';
import { StyledList } from './List.styles';
import { Modal } from '@src/components/Elements/Modal/Modal';
import { Add } from '@src/components/Desktop/Pages/Private/DeliveryStatus/Edit/Add';
import { DeliveryStatusNode } from '@src/generated/schema';
import { Show } from '@src/components/Desktop/Pages/Private/DeliveryStatus/Show';
import { Alter as DeliveryStatusAlter } from '../Edit/Alter';

export enum ModalEnum {
  ADD = 'add',
  SHOW = 'show',
  CHANGE = 'change',
}

const modalTitles = {
  [ModalEnum.ADD]: 'desktop.pages.deliveryStatus.add.title',
  [ModalEnum.CHANGE]: 'desktop.pages.deliveryStatus.edit.title',
};

export type ModalDataState = DeliveryStatusNode | undefined;

export const List: FC = () => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState({ id: '' });
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [showModal, setShowModal] = useState<string>('');
  const [modalData, setModalData] = useState<ModalDataState>(undefined);

  const closeModal = () => {
    setShowModal('');
    setModalData(undefined);
  };

  const openModal = (modalType?: ModalEnum, deliveryStatusNode?: any) => {
    closePopups();
    setShowModal(modalType || '');
    setModalData(deliveryStatusNode);
  };

  const toggleModal: () => void = (modalType?: ModalEnum, deliveryStatusNode?: any) => {
    if (showModal) {
      closeModal();
    } else {
      openModal(modalType, deliveryStatusNode);
    }
  };

  return (
    <div data-testid={TestIds.pages.piles.list.identifier}>
      {showModal === ModalEnum.ADD && (
        <Modal
          title={`${t('desktop.pages.deliveryStatus.add.title')}`}
          visible={showModal === ModalEnum.ADD}
          onCancel={toggleModal}
          tabComponents={[{ component: <Add closeModal={closeModal} />, tabName: t(modalTitles.add) }]}
        />
      )}

      {showModal === ModalEnum.SHOW && (
        <Modal
          title={t('desktop.pages.deliveryStatus.show.title', { number: modalData && modalData.number })}
          visible={showModal === ModalEnum.SHOW}
          onCancel={toggleModal}
          tabComponents={[
            {
              component: <Show deliveryStatus={modalData} />,
              tabName: t('desktop.pages.deliveryStatus.show.overview.tabs.default'),
            },
            {
              component: <InventoriesTowardsWagonTable deliveryStatusId={modalData && modalData.id} />,
              tabName: t('desktop.pages.deliveryStatus.show.overview.tabs.table'),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.CHANGE && (
        <Modal
          title={`${t('desktop.pages.deliveryStatus.edit.numberdTitle', { number: modalData && modalData.number })}`}
          visible={showModal === ModalEnum.CHANGE}
          onCancel={toggleModal}
          tabComponents={[
            {
              component: <DeliveryStatusAlter toggleModal={toggleModal} deliveryStatus={modalData} />,
              tabName: t('desktop.pages.deliveryStatus.edit.title'),
            },
          ]}
        />
      )}

      <Container>
        <Wrapper>
          <PageHeadline>{t('desktop.pages.deliveryStatus.headline')}</PageHeadline>
          <ItemsPerPagePicker setItemsPerPage={setItemsPerPage} itemsPerPage={itemsPerPage} />
          <StyledList>
            <Table
              onItemClick={(e, row) => openModal(ModalEnum.SHOW, row)}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setShowPopover={setShowPopover}
              showPopover={showPopover}
              toggleModal={toggleModal}
            />
          </StyledList>
        </Wrapper>
      </Container>
    </div>
  );
};
