import { Preferences } from '@capacitor/preferences';
import React, { useState } from 'react';
import { LatLng } from 'leaflet';

interface RoutePathCacheHandlerContextProps {
  addRoutePath: (orderId: string, coordinates: { coordinates: LatLng[]; waypoints: LatLng[] }) => void;
  errors: string[];
  getRoutePath: (orderId: string) => void;
  isLoading: boolean;
  pathCoordinates: { coordinates: LatLng[]; waypoints: LatLng[] };
}

export const RoutePathCacheHandlerContext = React.createContext<RoutePathCacheHandlerContextProps>({
  addRoutePath: () => null,
  getRoutePath: () => null,
  errors: [],
  isLoading: false,
  pathCoordinates: { coordinates: [], waypoints: [] },
});

export const RoutePathCacheHandlerProvider = ({ children }) => {
  const [pathCoordinates, setPathCoordinates] = useState<{ coordinates: LatLng[]; waypoints: LatLng[] }>({
    coordinates: [],
    waypoints: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const onStorageReturn = storageReturn => {
    if (storageReturn) {
      setPathCoordinates(JSON.parse(storageReturn.value));
    }
  };

  const handleErrors = e => {
    setErrors(e);
  };

  const addRoutePath = (orderId: string, waypointObj: { coordinates: LatLng[]; waypoints: LatLng[] }) => {
    Preferences.set({ key: orderId, value: JSON.stringify(waypointObj) })
      .then(() => setPathCoordinates(waypointObj))
      .catch(handleErrors);
  };

  const getRoutePath = (orderId: string) => {
    setIsLoading(true);
    Preferences.get({ key: orderId })
      .then(onStorageReturn)
      .catch(handleErrors)
      .finally(() => setIsLoading(false));
  };

  return (
    <RoutePathCacheHandlerContext.Provider
      value={{
        addRoutePath,
        getRoutePath,
        pathCoordinates,
        isLoading,
        errors,
      }}
    >
      {children}
    </RoutePathCacheHandlerContext.Provider>
  );
};
