import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';
import { THEME } from '@src/theme';
import { default as LanguageDetector } from 'i18next-browser-languagedetector';
import { default as JSON5 } from 'json5';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { default as Backend } from 'i18next-xhr-backend';
import { Environment } from '@src/global';
import * as Sentry from '@sentry/react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import moment from 'moment';
import { Overlay } from './components/Elements/Loading';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
// Import CSS for Antdesign Components
import 'antd/dist/antd.less';
import './index.css';

export const bootstrap = () => {
  // LOAD ENV FROM WINDOW
  Environment();

  const deviceLanguage = window.navigator.language.split('-')[0];
  moment.locale('de');

  // LOAD SENTRY ON GIVEN KEY
  if (window.env.LOGISTIK_SENTRY_KEY) {
    Sentry.init({
      dsn: window.env.LOGISTIK_SENTRY_KEY,
      environment: window.env.LOGISTIK_SENTRY_ENVIRONMENT,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  i18next
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      lng: deviceLanguage,
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        parse: (data: string) => JSON5.parse(data),
      },
      ns: ['translation'],
      defaultNS: 'translation',
      debug: window.env.LOGISTIK_I18N_DEBUG === 'true' || false,
      fallbackLng: 'en',
    });

  ReactDOM.render(
    <Suspense fallback={<Overlay />}>
      <App theme={THEME} />
    </Suspense>,
    document.getElementById('root'),
  );
};

bootstrap();
defineCustomElements(window);
