import React, { FC } from 'react';
import { StyledError } from './APIError.styles';
import { useTranslation } from 'react-i18next';
import { ApolloError } from 'apollo-client';

interface ErrorProps {
  errors: ApolloError;
}

export const APIError: FC<ErrorProps> = ({ errors }) => {
  const { t } = useTranslation();

  if (errors.graphQLErrors && errors.graphQLErrors.length > 0) {
    return (
      <>
        {errors.graphQLErrors.map(({ message }, index) => (
          <StyledError key={`gqle_${index}`}>{message}</StyledError>
        ))}
      </>
    );
  }

  if (errors.networkError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <StyledError>{errors.networkError.bodyText || errors.networkError.message}</StyledError>;
  }

  return <StyledError>{t('pages.login.errors.generic')}</StyledError>;
};
