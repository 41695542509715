import { Col } from 'antd';
import styled from 'styled-components';

export const ButtonCol = styled(Col)`
  margin-top: 2em;
  
  &:nth-child(2n) {
    margin-top: 1em;
  }

  > button {
    width: 100%;
  }
`;
