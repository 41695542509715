import React, { FC, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PileNode, StringPaginationType } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MobileSearchSelect } from '@src/components/Mobile/Elements/SearchSelect';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { MobileSelectProps } from '@src/components/Mobile/Elements/SearchSelect/SearchSelect';
import { getUniqueList } from '@src/helpers/Logic/Logic';

export interface MobileClientSelectProps extends Omit<MobileSelectProps, 'options'> {
  piles?: PileNode[];
}

export const MobileClientSelect: FC<MobileClientSelectProps> = ({ piles, ...props }) => {
  const { isOnline } = useContext(NetworkContext);
  const { data, loading, fetchMore, refetch } = useQuery<{ pileClients: StringPaginationType }>(Pile.clients, {
    variables: {
      pageSize: 20,
    },
  });
  const { pileClients: { page, strings, hasNext } = { page: 0, strings: [], hasNext: false } } = data || {};

  const handleSearch = useCallback(
    debounce((filterValue: string) => {
      refetch({ filterValue, pageSize: 20 });
    }, 500),
    [],
  );

  const handlePopupScroll = (e: any) => {
    const previousPage: number = page || 0;

    if (!hasNext) return;

    fetchMore({
      variables: {
        page: previousPage + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newPage = fetchMoreResult?.pileClients?.page || 0;
        const oldPage = prev?.pileClients?.page || 0;

        if (!fetchMoreResult || newPage <= oldPage) return prev;

        const newEdges = fetchMoreResult.pileClients.strings;
        const previousEdges = prev?.pileClients?.strings || [];

        return newEdges?.length
          ? {
              pileClients: {
                page: fetchMoreResult.pileClients.page,
                pages: fetchMoreResult.pileClients.pages,
                hasNext: fetchMoreResult.pileClients.hasNext,
                hasPrevious: fetchMoreResult.pileClients.hasPrevious,
                __typename: prev.pileClients.__typename,
                strings: [...previousEdges, ...newEdges],
              },
            }
          : prev;
      },
    });
  };

  return (
    <MobileSearchSelect
      {...props}
      options={
        isOnline
          ? strings?.map(client => ({
              id: client,
              title: client,
              value: client,
            }))
          : getUniqueList(piles?.map(p => p.client?.name)).map(clientName => ({
              id: clientName,
              value: clientName,
              title: clientName,
            }))
      }
      loading={loading}
      multi
      onSearch={handleSearch}
      onLoadMore={handlePopupScroll}
      allowNoSelection
    />
  );
};
