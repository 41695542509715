import { Formik } from 'formik';
import React, { FC, Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { Form as FormWrapper } from '@src/components/Elements/Wrapper/Form';
import { Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import { Input } from '@src/components/Desktop/Containers/Input';
import { FormItem, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { UnitSelect } from './UnitSelect';
import { TypeSelect } from './TypeSelect';

interface FilterFormValues {
  fullDescription?: string;
  showInactive?: boolean;
  types?: string;
  units?: string;
}

const validationSchema = Yup.object().shape({
  fullDescription: Yup.string(),
  showInactive: Yup.boolean(),
  types: Yup.string(),
  units: Yup.string(),
});

const initialValues: FilterFormValues = {
  fullDescription: undefined,
  showInactive: false,
  types: undefined,
  units: undefined,
};

interface FilterFormProps {
  itemsPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setQueryVariables: Dispatch<SetStateAction<any>>;
}

export const FilterForm: FC<FilterFormProps> = ({ setPage, setQueryVariables, itemsPerPage }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const { fullDescription, showInactive, types, units } = values;
        setPage(1);
        setQueryVariables(previous => ({
          fullDescription,
          showInactive,
          types,
          units,
          activeOnly: !showInactive,
          first: itemsPerPage,
          orderBy: previous?.orderBy,
        }));
      }}
    >
      <FormWrapper>
        <AutoSaveForm />
        <Row>
          <FormItem
            labelCol={{ span: 24 }}
            label={t('desktop.pages.deliveryStatus.filter.showInactive')}
            name='showInactive'
          >
            <Switch defaultChecked name='showInactive' />
          </FormItem>
        </Row>
        <Row>
          <Input label={t('desktop.pages.deliveryStatus.filter.shortDescription')} type='text' name='fullDescription' />
        </Row>
        <Row>
          <UnitSelect />
        </Row>
        <Row>
          <TypeSelect />
        </Row>
      </FormWrapper>
    </Formik>
  );
};
