import gql from 'graphql-tag';

export const DeliveryStatusFragment = gql`
  fragment deliveryStatusNode on DeliveryStatusNode {
    id
    customer {
      id
      name
    }
    deliveryAddress {
      id
      street
      country
      postcode
    }
    number
    allowTemporaryStorage
    shortDescription
    dateBegin
    dateEnd
    amount
    unit
    type
  }
`;

export const DeliveryStatusDetailFragment = gql`
  fragment deliveryStatusDetailNode on DeliveryStatusNode {
    id
    number
    shortDescription
    deliveryAddress {
      id
      street
      country
      postcode
    }
    dateBegin
    dateEnd
    amount
    unit
    type
    planType
    allowTemporaryStorage
    createdBy {
      firstName
      lastName
    }
    createdAt
    lastmodifiedBy {
      firstName
      lastName
    }
    lastmodifiedAt
    customer {
      id
      name
    }
  }
`;
