import styled from 'styled-components';

export const AggregationsSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
    
  button {
    width: 100%;
    height: 7vh;
    border-radius: 3px;
    
    .ant-switch-handle {
      display: none;
    }
    
    &:not(.ant-switch-checked) {
      background-color: rgba(191, 140, 96, 0.8);
    }
    
    .ant-checkbox-wrapper {
      flex: 2;
    
      .ant-checkbox {
        margin-left: auto;
        margin-right: auto;
        border: 1px solid rgba(255, 255, 255, 0.4);
      }
    }
    
    .ant-switch-inner {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const AggregationSwitchWrapper = styled.div`
  width: 100%;
  margin-bottom: 2%;
`;

export const AggregationTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const AggregationWoodType = styled.span`
  flex: 2;
  font-size: 1.2em;
`;

export const AggregationSort = styled.span`
  flex: 4;
  font-size: 1.2em;
`;

export const AggregationLength = styled.span`
  flex: 2;
  font-size: 1.2em;
`;
