import { Checkbox, SubmitButton } from 'formik-antd';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 1em;
`;

export const StylesSubmitButton = styled(SubmitButton)`
  margin-top: 1em;
`;
