import { IonButton, IonList } from '@ionic/react';
import styled from 'styled-components';

export const StyledList = styled(IonList)`
  margin-left: -15px;
  margin-right: -15px;
`;

export const StyledButton = styled(IonButton)`
  margin: 30px auto;
  max-width: 500px;
`;
