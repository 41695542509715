import React, { FC } from 'react';
import { IonPage } from '@ionic/react';
import { StyledIonContent } from './Page.styles';
import { Header } from '@src/components/Desktop/Sections/Header';

export const Page: FC = ({ children }) => (
  <IonPage>
    <Header />
    <StyledIonContent>{children}</StyledIonContent>
  </IonPage>
);
