import React, { FC, useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PlotNodeConnection } from '@src/generated/schema';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import { Plot } from '@src/graphql/Plot';
import { IonContent, IonItem, IonList, IonSearchbar } from '@ionic/react';

interface PlotSelectProps extends Pick<SearchSelectProps, 'required'> {
  handleOnClick: (e: any) => void;
  prefilledValue?: number;
}

export const StyledPlotSelect: FC<PlotSelectProps> = ({ prefilledValue, handleOnClick, ...props }) => {
  const { data, refetch } = useQuery<{ plots: PlotNodeConnection }>(Plot.searchPlots, {
    variables: {
      first: 25,
      plotNumber: prefilledValue,
    },
    fetchPolicy: 'network-only',
  });
  const { plots: { edges } = { edges: [] as any } } = data || {};
  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = useCallback(e => {
    setSearchText(e.detail.value);
    refetch({
      limit: 25,
      plotNumber: e.detail.value,
    });
  }, []);

  return (
    <IonContent fullscreen>
      <IonSearchbar value={searchText} onIonChange={handleSearch} debounce={100} />
      <IonList>
        {edges.map(({ node: { number } }) => (
          <IonItem onClick={handleOnClick} key={number}>
            {number}
          </IonItem>
        ))}
      </IonList>
    </IonContent>
  );
};
