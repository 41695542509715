import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import { PileNode } from '@src/generated/schema';
import { DriverPileNodeFragment } from '@src/graphql/Pile/Fragments';
import { Page } from '@src/components/Mobile/Sections/Page';

import { EditForm } from './EditForm';
import { ValidationError } from '@src/components/Containers/InventoryForm/InventoryForm';
import { Pile } from '@src/graphql/Pile';
import { useMutation } from 'react-apollo';
import { OfflineHandlerContext } from '@src/components/Mobile/Providers';
import { getPileValidationSchema } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form/validationSchema';
import { useTranslation } from 'react-i18next';
import { FormValues } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';

export interface EditProps {
  offlineHandling: boolean;
}

type PileMutationType = 'create' | 'update' | 'delete';

export const Edit: FC<EditProps> = () => {
  const client = useApolloClient();
  const { goBack } = useHistory();
  const { pileId } = useParams<{ pileId: string }>();
  const { addJob } = useContext(OfflineHandlerContext);
  const { isOnline } = useContext(NetworkContext);
  const [pileState, setPileState] = useState<FormValues>();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [updatePile] = useMutation(Pile.changePile);
  const { t } = useTranslation();

  const clearErrors = () => {
    setValidationErrors([]);
  };

  const throwError = err => {
    throw Error(err);
  };

  /**
   * Sets the pile via given pileId.
   */
  // eslint-disable-next-line complexity
  useEffect(() => {
    let pile: PileNode | null = null;

    if (pileId) {
      pile = client.readFragment({ id: pileId, fragment: DriverPileNodeFragment, fragmentName: 'driverPileNode' });
      if (pile) {
        setPileState({
          cbkNet: pile?.cbkNet || undefined,
          cbkSolid: pile?.cbkSolid || undefined,
          count: pile?.count ?? undefined,
          category: pile?.category || undefined,
          date: pile?.date ? new Date(pile.date) : undefined,
          district: pile?.district || undefined,
          factorCbkNetCbkSolid: pile?.factorCbkNetCbkSolid || '0.6',
          latitude: pile?.latitude || undefined,
          location: pile?.location || undefined,
          locationPlan: pile?.locationPlan || undefined,
          logLength: pile?.logLength || undefined,
          longitude: pile?.longitude || undefined,
          markedAsBroached: pile?.markedAsBroached || undefined,
          note: pile?.note || undefined,
          number: pile?.number,
          pileNumberFurnisher: pile?.pileNumberFurnisher || undefined,
          plotNumber: pile?.plot?.number || undefined,
          sort: pile?.sort || undefined,
          status: pile?.statusOldSystem || undefined,
          type: pile?.type || undefined,
          woodtype: pile?.woodtype || undefined,
          woodtype2: pile?.woodtype2 || undefined,
          usageSort: pile?.usageSort || undefined,
          amountUnit: pile?.amountUnit || undefined,
          locationType: pile?.locationType || undefined,
          qualityType: pile?.qualityType || undefined,
          timber: pile?.timber || undefined,
          mustBeAccepted: pile?.mustBeAccepted || undefined,
        });
      }
    }
  }, [pileId, client, setPileState]);

  useEffect(() => {
    validateForm();
  }, [pileState]);

  /**
   * Performs the mutation in the end. This function considers if the Offline module should be used or not.
   */
  const performMutation = async (type: PileMutationType, variables, refId: string | null = null) => {
    if (isOnline) {
      await updatePile({ variables }).catch(throwError);
    } else {
      if (type === 'update') {
        addJob({
          refId,
          variables,
          mutation: Pile.changePile,
          refetchOrders: true,
        });
      } else {
        throw Error('This was not configured yet.');
      }
    }
  };

  /**
   * Validates the form.
   */
  const validateForm = useCallback((): void => {
    const validationSchema = getPileValidationSchema(t);
    validationSchema
      .validate(pileState, { abortEarly: false })
      .then(clearErrors)
      .catch(err => setValidationErrors(err.inner.map(error => ({ field: error.path, message: error.message }))));
  }, [t, pileState]);

  const onUpdatePile = (event: CustomEvent, field: string) => {
    // Boolean fields that are represented with a switch component are handle differently
    // eslint-disable-next-line unicorn/prefer-switch
    if (field === 'isBroached' || field === 'mustBeAccepted') {
      setPileState(prevState => (prevState ? { ...prevState, [field]: event.detail.checked } : undefined));
    } else if (field === 'date') {
      setPileState(prevState =>
        prevState
          ? {
              ...prevState,
              [field]: new Date(event.detail.value),
            }
          : undefined,
      );
    } else {
      setPileState(prevState =>
        prevState
          ? {
              ...prevState,
              [field]: (event.target as HTMLInputElement)?.value,
            }
          : undefined,
      );
    }
  };

  const onSaveSubmit = (event?: SyntheticEvent) => {
    event?.preventDefault();
    validateForm();
    performMutation('update', { ...pileState, pileId })
      .then(goBack)
      .catch(err => setValidationErrors([err]));
  };

  return (
    <Page>
      <EditForm
        showSave
        pile={pileState || undefined}
        handleSaveSubmit={onSaveSubmit}
        validationErrors={validationErrors}
        handleUpdatePile={onUpdatePile}
      />
    </Page>
  );
};
