import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledPaginationList = styled.ul`
  padding-left: 0;
  margin: 2em 0;
  list-style: none;
  text-align: center;
`;

export const StyledPaginationItem = styled.li`
  display: inline-block;
  margin: 0 0.2em;
`;

export const StyledPaginationButton = styled.button`
  padding: 0.322em 0.8em;
  background-color: ${({ theme }) => theme.color.white};

  &:disabled {
    color: ${({ theme }) => theme.color.mercury};
  }

  &:focus:not([disabled]),
  &:hover:not([disabled]),
  &:active:not([disabled]) {
    color: ${({ theme }) => lighten(0.3, theme.color.tundora)};
    outline: none;
  }
`;

export const StyledPaginationIndicator = styled.span`
  display: inline-block;
  padding: 0.322em 0.8em;
  border-radius: ${({ theme }) => theme.global.borderRadius}px;
  background-color: ${({ theme }) => theme.color.mercury};
`;