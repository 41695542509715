import React, { FC } from 'react';
import { StyledTag } from './Tag.styles';

export enum TagType {
  PRIMARY = 'primary',
  DEFAULT = 'default',
  ERROR = 'error',
}

export interface TagProps {
  text: string;
  type: TagType;
  value: string;
}

export const Tag: FC<TagProps> = ({ text, value, ...props }) => <StyledTag {...props}>{text}</StyledTag>;
