import React, { createContext, FC, useEffect, useState, useContext } from 'react';
import { Preferences } from '@capacitor/preferences';
import { OrderNode, InventoryNode } from '@src/generated/schema';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';

const INVENTORIES_STORAGE_KEY = 'inventories-storage-key';

interface InventoriesState {
  [key: string]: InventoryNode[];
}

interface InventoriesContextProps {
  addInventory: (orderId: string, inventory: InventoryNode) => void;
  deleteInventory: (orderId: string, inventoryId: string) => void;
  orderInventories: InventoriesState;
  parseFetchedOrders: (orders: OrderNode[]) => void;
}

export const InventoriesContext = createContext<InventoriesContextProps>({
  addInventory: () => null,
  deleteInventory: () => null,
  parseFetchedOrders: () => null,
  orderInventories: {},
});

/*
  The inventoriesProvider is used to solve the difficulties of dealing with apollo offline cache updates.
  It is used to handle the saving and managing of the inventories independent from the apollo cache.
*/
export const InventoriesProvider: FC = ({ children }) => {
  const [orderInventories, setOrderInventories] = useState<InventoriesState>({});
  const [ordersToParse, setOrdersToParse] = useState<OrderNode[]>([]);
  const { isOnline } = useContext(NetworkContext);

  /*
  In the past there have been issues with the parsed inventories while offline. This useEffect will consider every
  aspect of the parsing orders -> inventories and will reset the `ordersToParse` afterwards.
   */
  useEffect(() => {
    if (!ordersToParse.length) {
      return undefined;
    }

    setOrderInventories(previousOrderInventories => {
      const inventoriesForOrders = ordersToParse.reduce((collection: InventoriesState, order: OrderNode) => {
        const inventoriesFromOrder = order.inventories.edges.map(edge => edge?.node).filter(node => !!node) as any[];
        const existingInventories = previousOrderInventories[order.id] || [];

        collection[order.id] = isOnline ? inventoriesFromOrder : [...existingInventories, ...inventoriesFromOrder];

        return collection;
      }, {});

      return { ...previousOrderInventories, ...inventoriesForOrders };
    });

    setOrdersToParse([]);
  }, [isOnline, ordersToParse, setOrdersToParse]);

  /*
    Function that takes orders fetched from the api and parses the inventories
    to save them in the provider state and therefore also in the persistent storage
  */
  const parseFetchedOrders = (orders: OrderNode[]) => {
    setOrdersToParse(orders);
  };

  // Function to add an inventory to an order locally
  const addInventory = (orderId: string, inventory: InventoryNode) => {
    setOrderInventories({
      ...orderInventories,
      [orderId]: [...orderInventories[orderId], inventory],
    });
  };

  // Function to delete an inventory from an order locally
  const deleteInventory = (orderId: string, inventoryId: string) => {
    setOrderInventories({
      ...orderInventories,
      [orderId]: orderInventories[orderId].filter(inventory => inventoryId !== inventory.id),
    });
  };

  // Side Effect: initially load orderInventories from storage
  useEffect(() => {
    Preferences.get({ key: INVENTORIES_STORAGE_KEY }).then(({ value }) => {
      if (value) setOrderInventories(JSON.parse(value));
    });
  }, []);

  // Side Effect: persit orderInventories to storage when they update
  useEffect(() => {
    Preferences.set({ key: INVENTORIES_STORAGE_KEY, value: JSON.stringify(orderInventories) });
  }, [orderInventories]);

  return (
    <InventoriesContext.Provider
      value={{
        addInventory,
        deleteInventory,
        parseFetchedOrders,
        orderInventories,
      }}
    >
      {children}
    </InventoriesContext.Provider>
  );
};
