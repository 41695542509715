import React, { FC, useContext, useEffect } from 'react';
import { InventoriesByDeliveryStatusNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { StyledButton, StyledIonRow, StyledTable } from './InventoriesTowardsWagonTable.styles';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Alert, Tooltip } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { ColumnProps } from 'antd/lib/table/Column';
import { Inventory } from '@src/graphql/Inventory';
import {
  StyledResponsiveTableHeaderButton,
  StyledTableHeaderActions,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { codeDownloadOutline } from 'ionicons/icons';

interface TableProps extends AntdTableProps<any> {
  deliveryStatusId: string | undefined;
}

export const InventoriesTowardsWagonTable: FC<TableProps> = ({ deliveryStatusId, ...props }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [getDeliveryInventories, { loading, error, data, refetch }] = useLazyQuery<{
    inventoriesByDeliveryStatusTowardsWagon: InventoriesByDeliveryStatusNode[];
  }>(Inventory.getInventoriesByDeliveryStatusTowardsWagon, {
    variables: {
      delivery_status_id: deliveryStatusId,
    },
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onError: () => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.deliveryStatus.list.error.message'),
        description: t('desktop.pages.deliveryStatus.list.error.description'),
      });
    },
  });

  const inventories = data?.inventoriesByDeliveryStatusTowardsWagon || [];

  useEffect(() => {
    getDeliveryInventories();
  }, [getDeliveryInventories]);

  useEffect(() => {
    const el = document;
    const event = document.createEvent('HTMLEvents');

    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  });

  const [exportInventoriesFun] = useMutation(Inventory.exportInventoriesTowardsWagon);
  const handleDownloadInventories = async () => {
    await exportInventoriesFun({ variables: { delivery_status_id: deliveryStatusId } }).then(
      ({
        data: {
          downloadWagonInventory: { url },
        },
      }) => {
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.deliveryStatus.list.inventoryTable.export.message'),
          description: t('desktop.pages.deliveryStatus.list.inventoryTable.export.description'),
        });
        window.location.assign(url);
      },
    );
  };

  const columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.wagonNumber'),
      dataIndex: 'wagonNumber',
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.plotNumber'),
      dataIndex: 'plotNumber',
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.orderID'),
      dataIndex: 'orderId',
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.orderNumber'),
      dataIndex: 'orderNumber',
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.allAmount'),
      dataIndex: 'allAmount',
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.allCount'),
      dataIndex: 'allCount',
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.isWagonReady'),
      dataIndex: 'isWagonReady',
      render: (text, record: any) => <p> {text ? t('general.yes') : t('general.no')}</p>,
    },
    {
      title: t('desktop.pages.deliveryStatus.list.inventoryTable.salesNumber'),
      dataIndex: 'salesNumber',
      render: (text, record: any) => <p> {text ? text : '-'}</p>,
    },
  ];

  return (
    <>
      {error && !data && (
        <Alert
          message={t('desktop.pages.deliveryStatus.list.error.message')}
          description={t('desktop.pages.deliveryStatus.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <StyledIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </StyledIonRow>
      )}

      {data &&
        (inventories?.length > 0 ? (
          <>
            <StyledTableHeaderActions>
              <StyledResponsiveTableHeaderButton size='large' type='link' onClick={handleDownloadInventories}>
                <Tooltip title={t('desktop.pages.piles.list.popover.showOnMap')}>
                  <IonIcon icon={codeDownloadOutline} />
                </Tooltip>
                {t('desktop.pages.deliveryStatus.list.inventoryTable.downloadInventory')}
              </StyledResponsiveTableHeaderButton>
            </StyledTableHeaderActions>
            <StyledTable
              {...props}
              columns={columns}
              dataSource={inventories}
              rowKey={val => Object.values(val).join('-')}
              pagination={false}
            />

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>
            {t('desktop.pages.deliveryStatus.list.inventoryTable.noDeliveryStatusInventoriesAvailable')}
          </IonTitle>
        ))}
    </>
  );
};
