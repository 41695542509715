import React, { useContext } from 'react';
import { PileNode } from '@src/generated/schema';
import { Button, List } from 'antd';
import { useMutation } from 'react-apollo';
import { Pile } from '@src/graphql/Pile';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { useTranslation } from 'react-i18next';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';

export interface IDeletePiles {
  closeModal: () => void;

  piles: Array<Pick<PileNode, 'id' | 'number'>>;
}

const ListFooter = ({
  error,
  loading,
  onClick,
}: {
  error: ApolloError | undefined;
  loading: boolean;
  onClick: () => Promise<void>;
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column-reverse' }}>
      {error && <APIError errors={error} />}
      <Button loading={loading} type='primary' disabled={loading} onClick={onClick}>
        {t('desktop.pages.piles.delete.title')}
      </Button>
    </div>
  );
};

export const DeletePiles: React.FC<IDeletePiles> = ({ piles, closeModal }) => {
  const { setToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const [deletePilesFun, { error, loading }] = useMutation(Pile.deletePiles, { refetchQueries: ['piles'] });

  const data = piles.map(x => ({ title: x.number }));
  const pilesIds = piles.map(x => x.id);

  const handleDeletePiles = async () => {
    await deletePilesFun({ variables: { pilesIds } }).then(() => {
      setToast({
        type: TOAST_TYPES.SUCCESS,
        message: t('desktop.pages.piles.delete.list.toast.success.message'),
        description: t('desktop.pages.piles.delete.list.toast.success.description', { count: pilesIds.length }),
      });
      closeModal();
    });
  };

  return (
    <List
      itemLayout={'horizontal'}
      dataSource={data}
      renderItem={item => <List.Item>{item.title}</List.Item>}
      header={pilesIds.length > 0 && <ListFooter loading={loading} error={error} onClick={handleDeletePiles} />}
    />
  );
};
