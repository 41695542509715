import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Routes } from '@src/global';
import { MapWrapper } from '@src/components/Mobile/Containers/MapWrapper';
import { PasswordChangePage } from '@src/components/Pages/Private/PasswordChangePage/PasswordChangePage';

import { Add, AddWagon } from './Inventories/Add';
import { Detail } from './Orders/Detail';
import { OfflineMaps } from './OfflineMaps';
import { Home } from '@src/components/Mobile/Pages/Private/Home/Home';
import { Edit } from '@src/components/Mobile/Pages/Private/Piles/Edit';

export const Private: FC = () => {
  const { ADD, EDIT, ADD_WAGON, HOME, ID, OFFLINE_MAPS, ORDERS, ORDER_ID, PASSWORD, PILES, PILE_ID } = Routes;

  return (
    <Switch>
      {/* This route adds an inventory to an order, it does not add a pile to an order */}
      <Route exact path={`${ORDERS}${ORDER_ID}${PILES}${PILE_ID}${ADD}`} component={Add} />
      <Route exact path={`${PILES}${PILE_ID}${EDIT}`} component={Edit} />
      <Route exact path={`${ORDERS}${ORDER_ID}${ADD_WAGON}`} component={AddWagon} />
      <Route path={`${ORDERS}${ID}${PILES}`} component={MapWrapper} />
      <Route path={`${ORDERS}${ID}`} component={Detail} />
      <Route path={HOME} component={Home} />
      <Route exact path={OFFLINE_MAPS} component={OfflineMaps} />
      <Route exact path={PASSWORD} component={PasswordChangePage} />
      <Redirect exact path={ORDERS} to={`${HOME}${ORDERS}`} />
    </Switch>
  );
};
