import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from '@src/global';
import { StyledMenu, StyledMenuItem } from './Content.styles';
import { IonRouterLink } from '@ionic/react';

export const Content: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledMenu>
      <StyledMenuItem key='0'>
        <IonRouterLink href={Routes.LOGOUT}>{t('desktop.elements.header.userDropdown.logout')}</IonRouterLink>
      </StyledMenuItem>
      <StyledMenuItem key='1'>
        <IonRouterLink href={Routes.PASSWORD}>{t('desktop.elements.header.userDropdown.changePassword')}</IonRouterLink>
      </StyledMenuItem>
    </StyledMenu>
  );
};
