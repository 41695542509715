import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@src/components/Elements/ButtonLink';
import { Routes } from '@src/global';
import { ButtonType } from '@src/components/Elements/ButtonLink/ButtonLink';
import { Container, Wrapper } from '@src/components/Elements';

export const Unauthorized: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <p>{t('pages.response.http.unauthorized.title')}</p>
        <ButtonLink type={ButtonType.SELECTED} to={Routes.LOGIN}>
          {t('pages.response.http.unauthorized.callToAction')}
        </ButtonLink>
      </Wrapper>
    </Container>
  );
};
