import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { PasswordPage } from '@src/components/Sections/PasswordPage';
import { MeContext } from '@src/components/Providers';
import { useApolloClient } from '@apollo/react-hooks';
import { QUEUE_STORAGE_KEY } from '@src/components/Mobile/Providers/OfflineHandler/OfflineHandler';
import { Preferences } from '@capacitor/preferences';
import { isPlatform } from '@ionic/react';

export const Login: FC = () => {
  const { t } = useTranslation();
  const { setSession } = useContext(MeContext);
  const { cache } = useApolloClient();

  // We need to clean up the token because it might have expired (not on mobile though)
  // we can not just clear it, because we want to keep map downloads
  useEffect(() => {
    const cleanupToken = async () => {
      await Promise.all([Preferences.remove({ key: 'token' }), Preferences.remove({ key: QUEUE_STORAGE_KEY })]);
      setSession({ token: '' });
      await cache.reset();
    };

    // dont cleanup on mobile
    if (!isPlatform('hybrid')) {
      cleanupToken();
    }
  });

  return (
    <PasswordPage header={t('pages.login.header')} subHeader={t('pages.login.subHeader')}>
      <Form />
    </PasswordPage>
  );
};
