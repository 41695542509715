import styled from 'styled-components';
import { IonCardContent } from '@ionic/react';

export const StyledIonCardContent = styled(IonCardContent)`
  margin-top: 0;
  margin-left: -0.22em;
  padding-top: 0;
`;

export const StyledOrderNotePreview = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
