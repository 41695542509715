import React, { FC } from 'react';
import {
  StyledBubblePath,
  StyledEllipse,
  StyledMarkerPath,
  StyledRouteEllipse,
  StyledSVG,
  StyledText,
  StyledTspan,
} from './Marker.styles';

import { Marker as LeafletMarker } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import L, { LatLngExpression } from 'leaflet';

export interface MarkerProps {
  height: number;
  text: string | null | undefined;
}

export const widthHeightFactor = 1.5;

export interface RoutMarkerProps {
  initialPosition: LatLngExpression;
}

export interface StyledRouterMarkerProps {
  height: number;
}

export const Marker: FC<MarkerProps> = ({ height, text }) => {
  const textAsInt = parseInt(String(text), 10);
  const textLength = textAsInt.toString().length;

  return (
    <StyledSVG
      height={`${height}px`}
      width={`${height / widthHeightFactor}px`}
      viewBox='0 0 77.3 115.8'
      enableBackground={`new 0 0 ${height / widthHeightFactor} ${height}`}
      xmlSpace='preserve'
      x={0}
      y={0}
    >
      <defs>
        <linearGradient
          id='SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1='-65.3028'
          y1='-385.0086'
          x2='-64.3029'
          y2='-385.0086'
          gradientTransform='matrix(7.090706e-15 115.8 115.8 -7.090706e-15 44622.625 7561.6304)'
        >
          <stop offset='0' stopColor='#D9B391' />
          <stop offset='0.12' stopColor='#CEA888' />
          <stop offset='0.32' stopColor='#B18C70' />
          <stop offset='0.59' stopColor='#825E49' />
          <stop offset='0.79' stopColor='#593627' />
          <stop offset='0.99' stopColor='#0C0C0C' />
          <stop offset='1' stopColor='#0C0C0C' />
        </linearGradient>
      </defs>
      <StyledMarkerPath
        d='M38.8,0C17.4,0,0.1,17.2,0,38.5c0,21.8,38.8,77.3,38.8,77.3s38.5-55.4,38.5-77.3C77.2,17.3,60,0.1,38.8,0z
     M38.6,72.9c-18.3,0-33.2-14.9-33.2-33.2c0,0,0,0,0,0c0-18.3,14.9-33.2,33.2-33.2c18.3,0,33.2,14.9,33.2,33.2
    C71.9,58.1,57,72.9,38.6,72.9z'
      />
      <StyledEllipse
        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -16.7665 38.9222)'
        cx='38.6'
        cy='39.7'
        rx='33.2'
        ry='33.2'
      />
      <StyledBubblePath
        d='M33.5,77.8c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9S30.8,77.8,33.5,77.8L33.5,77.8
     M43.8,77.8c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9S41.1,77.8,43.8,77.8L43.8,77.8 M38.6,68.8
    c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9c0,0,0,0,0,0C33.8,66.7,35.9,68.8,38.6,68.8 M28.3,86.8
    c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9l0,0C23.5,84.6,25.6,86.8,28.3,86.8 M38.6,86.8
    c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9l0,0C33.8,84.6,35.9,86.8,38.6,86.8 M48.9,86.8
    c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9l0,0C44.1,84.6,46.3,86.8,48.9,86.8'
      />
      <StyledText transform='translate(6.39 49.92)'>
        <StyledTspan x={(3 - textLength) * 11} y='0'>
          {textAsInt}
        </StyledTspan>
      </StyledText>
    </StyledSVG>
  );
};

export const StyledRouteMarker: FC<StyledRouterMarkerProps> = ({ height }) => (
  <StyledSVG
    height={`${height}px`}
    width={`${height}px`}
    viewBox='30 15 50 50'
    enableBackground={`new 0 0 ${50} ${50}`}
    xmlSpace='preserve'
    x={0}
    y={0}
  >
    <StyledRouteEllipse
      transform='matrix(0.7071 -0.7071 0.7071 0.7071 -16.7665 38.9222)'
      cx='50'
      cy='50'
      rx='20'
      ry='20'
    />
  </StyledSVG>
);

const routeMarkerIcon = markerHeight => {
  const height = markerHeight;
  const width = height * 2;

  return L.divIcon({
    className: 'custom-icon',
    iconSize: [width, height],
    html: renderToString(<StyledRouteMarker height={height} />),
    iconAnchor: [width, height / 2],
  });
};

export const RouteMarker: FC<RoutMarkerProps> = ({ initialPosition }) => (
  <LeafletMarker icon={routeMarkerIcon(20)} position={initialPosition} />
);
