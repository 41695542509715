import { Formik } from 'formik';
import React, { FC, Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { Form as FormWrapper } from '@src/components/Elements/Wrapper/Form';
import { Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { StatusSelect } from './StatusSelect';
import { AutoSaveForm } from '@src/components/Elements/AutoSaveForm';
import {
  CompanySelect,
  DriverSelect,
  noDriver,
  noSupplier,
  noVehicle,
  VehicleSelect,
} from '@src/components/Desktop/Containers/SearchSelects';
import { OrderNode } from '@src/generated/schema';
import { FormItem, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { TypeSelect } from './TypeSelect';
import { SupplierSelect } from '@src/components/Desktop/Containers/SearchSelects/SupplierSelect';
import { Input } from '@src/components/Desktop/Containers/Input';
import { CollectedFromOtherSystemSelect } from './CollectedFromOtherSystemSelect';
import { CollectedOptions } from './CollectedFromOtherSystemSelect/CollectedFromOtherSystemSelect';

interface FilterFormValues {
  collectedFromOtherSystem?: string | null;
  customer?: string;
  driver?: string;
  isPlausible?: boolean;
  number?: number;
  showInactive?: boolean;
  status?: string;
  supplier?: string;
  type?: string;
  vehicle?: string;
}

const validationSchema = Yup.object().shape({
  customer: Yup.string(),
  driver: Yup.string(),
  number: Yup.number(),
  showInactive: Yup.boolean(),
  isPlausible: Yup.boolean(),
  status: Yup.string(),
  supplier: Yup.string(),
  vehicle: Yup.string(),
  type: Yup.string(),
  collectedFromOtherSystem: Yup.string(),
});

const initialValues: FilterFormValues = {
  customer: undefined,
  number: undefined,
  driver: undefined,
  showInactive: undefined,
  isPlausible: undefined,
  status: undefined,
  supplier: undefined,
  vehicle: undefined,
  type: undefined,
  collectedFromOtherSystem: CollectedOptions.ALL,
};

interface FilterFormProps {
  itemsPerPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setQueryVariables: Dispatch<SetStateAction<any>>;
  setSelectedRows: Dispatch<SetStateAction<OrderNode[]>>;
}

export const FilterForm: FC<FilterFormProps> = ({ setPage, setQueryVariables, setSelectedRows, itemsPerPage }) => {
  const { t } = useTranslation();

  /**
   * Translate collected_from_other_system values into queries
   */
  const getQueryCollectedFromOtherSystem = (collected?: string | null) => {
    if (collected === CollectedOptions.YES) return true;

    if (collected === CollectedOptions.NO) return false;

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const {
          customer,
          driver,
          status,
          supplier,
          vehicle,
          type,
          showInactive,
          isPlausible,
          number,
          collectedFromOtherSystem,
        } = values;
        setSelectedRows([]);
        setPage(1);
        setQueryVariables(previous => ({
          customer,
          status,
          type,
          isPlausible,
          collectedFromOtherSystem: getQueryCollectedFromOtherSystem(collectedFromOtherSystem),
          number_Contains: number || null,
          orderBy: previous?.orderBy,
          activeOnly: !showInactive,
          driver_Isnull: driver === noDriver ? true : null,
          driver: driver === noDriver ? '' : driver,
          first: itemsPerPage,
          supplier_Isnull: supplier === noSupplier ? true : null,
          supplier: supplier === noSupplier ? '' : supplier,
          vehicle_Isnull: vehicle === noVehicle ? true : null,
          vehicle: vehicle === noVehicle ? '' : vehicle,
        }));
      }}
    >
      <FormWrapper>
        <AutoSaveForm />
        <Row>
          <FormItem labelCol={{ span: 24 }} label={t('desktop.pages.orders.filter.showInactive')} name='showInactive'>
            <Switch defaultChecked name='showInactive' />
          </FormItem>
        </Row>
        <Row>
          <Input label={t('desktop.pages.piles.filter.orderNumber')} type='number' name='number' />
        </Row>
        <Row>
          <CompanySelect />
        </Row>
        <Row>
          <SupplierSelect showBlankSupplierOption />
        </Row>
        <Row>
          <StatusSelect />
        </Row>
        <Row>
          <VehicleSelect />
        </Row>
        <Row>
          <DriverSelect />
        </Row>
        <Row>
          <TypeSelect />
        </Row>
        <Row>
          <FormItem
            style={{ width: '100%' }}
            labelCol={{ span: 24 }}
            label={t('desktop.pages.orders.filter.collectedFromOtherSystem.title')}
            name='collectedFromOtherSystem'
          >
            <CollectedFromOtherSystemSelect name='collectedFromOtherSystem' />
          </FormItem>
        </Row>
        <Row>
          <FormItem labelCol={{ span: 24 }} label={t('desktop.pages.orders.filter.isPlausible')} name='isPlausible'>
            <Switch name='isPlausible' />
          </FormItem>
        </Row>
      </FormWrapper>
    </Formik>
  );
};
