import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';
import { MultiSelect } from '@src/components/Elements/MultiSelect';

export const TypeSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ deliveryStatusTypeSelection: any }>(
    DeliveryStatus.getDeliveryStatusTypeSelectOptions,
  );
  const { deliveryStatusTypeSelection } = data || {};

  const typeOptions = deliveryStatusTypeSelection?.map(option => ({ value: option, title: option })) || [];

  return <MultiSelect {...props} label={t('general.type')} options={typeOptions} name='types' loading={loading} />;
};
