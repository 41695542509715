import React, { createContext, FC, useState, Dispatch, SetStateAction } from 'react';

interface NetworkContextProps {
  isOnline: boolean;
  setIsOnline: Dispatch<SetStateAction<boolean>>;
}

export const NetworkContext = createContext<NetworkContextProps>({
  isOnline: true,
  setIsOnline: () => undefined,
});

export const NetworkProvider: FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  return <NetworkContext.Provider value={{ setIsOnline, isOnline }}>{children}</NetworkContext.Provider>;
};
