import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';

export const AutoSaveForm = () => {
  const formik = useFormikContext();
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), 500),
    [formik.submitForm],
  );
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false);

  useEffect(() => {
    // only enable auto save once any field was touched
    if (Object.keys(formik.touched).length > 0) {
      setAutoSaveEnabled(true);
    }
  }, [formik.touched, setAutoSaveEnabled]);

  useEffect(() => {
    if (autoSaveEnabled) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, formik.values, autoSaveEnabled]);

  return <></>;
};
