import gql from 'graphql-tag';

export const createDeliveryStatus = gql`
  mutation createDeliveryStatus(
    $shortDescription: String
    $customer: ID!
    $deliveryAddress: ID
    $dateBegin: DateTime!
    $dateEnd: DateTime!
    $amount: Int
    $unit: UnitChoice!
    $type: TypeChoice!
    $allowTemporaryStorage: Boolean!
    $planType: PlanTypeChoice!
  ) {
    createDeliveryStatus(
      shortDescription: $shortDescription
      customer: $customer
      deliveryAddress: $deliveryAddress
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      amount: $amount
      unit: $unit
      type: $type
      allowTemporaryStorage: $allowTemporaryStorage
      planType: $planType
    ) {
      ok
    }
  }
`;

export const changeDeliveryStatus = gql`
  mutation changeDeliveryStatus(
    $deliveryStatusId: ID!
    $shortDescription: String
    $customer: ID!
    $deliveryAddress: ID
    $dateBegin: DateTime!
    $dateEnd: DateTime!
    $amount: Int
    $unit: UnitChoice!
    $type: TypeChoice!
    $allowTemporaryStorage: Boolean!
    $planType: PlanTypeChoice!
  ) {
    changeDeliveryStatus(
      deliveryStatusId: $deliveryStatusId
      shortDescription: $shortDescription
      customer: $customer
      deliveryAddress: $deliveryAddress
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      amount: $amount
      unit: $unit
      type: $type
      allowTemporaryStorage: $allowTemporaryStorage
      planType: $planType
    ) {
      ok
    }
  }
`;
