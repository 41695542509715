import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonAlert } from '@ionic/react';
import { useApolloClient } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';

import { InventoryNode, OrderNode } from '@src/generated/schema';
import { Inventory } from '@src/graphql/Inventory';
import { InventoriesContext, OfflineHandlerContext } from '@src/components/Mobile/Providers';

import { Stacks } from './Stacks';
import { WagonForm } from './Form';
import {
  StyledButton,
  StyledIonButtonRow,
  StyledIonCol,
  StyledIonGrid,
  StyledIonText,
  StyledIonTextError,
} from './InventoryForm.styles';
import { useMutation } from 'react-apollo';
import { Modal } from '@src/components/Elements/Modal';
import { ModalLevel } from '@src/components/Elements/Modal/Modal';
import { WagonInventoryFormButton } from './WagonInventoryFormButton';

export interface StackState {
  stackHeight: number;
  stackNumber: number;
  stackWidth: number;
  woodLength: number;
}

export interface WagonInventoryState {
  amount: number;
  count: number;
  isBroached: boolean;
  isWagonReady: boolean;
  loadEverything: boolean;
  wagonNumber: number;
}

export interface ValidationError {
  field: string;
  message: string;
}

type InventoryMutationType = 'create' | 'delete';

export interface InventoryFormProps {
  alertModalLevel?: ModalLevel;
  createEnabled?: boolean;
  deleteEnabled?: boolean;
  inventory?: InventoryNode;
  locationData?: {
    accuracy: number | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
  };
  offlineHandling?: boolean;
  onSubmit?: (inventory?: InventoryNode) => void;
  order?: OrderNode;
}

export const WagonInventoryForm: FC<InventoryFormProps> = ({
  alertModalLevel,
  order,
  deleteEnabled,
  createEnabled,
  onSubmit,
  inventory,
  offlineHandling,
  locationData,
}) => {
  // general data
  const client = useApolloClient();
  const { t } = useTranslation();

  // data for form
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
  const [nextKey, setNextKey] = useState<number>(5);
  const { addJob } = useContext(OfflineHandlerContext);
  const [showCountAlert, setShowCountAlert] = useState<boolean>(false);
  const [isCount, setIsCount] = useState<boolean>();

  // data to fill and submit form
  const { addInventory } = useContext(InventoriesContext);
  const [stacks, setStacks] = useState<StackState[]>([]);
  const [inventoryState, setInventoryState] = useState<WagonInventoryState>({
    amount: inventory?.amount || 0,
    count: inventory?.count || 0,
    isWagonReady: inventory?.isWagonReady || false,
    wagonNumber: inventory?.wagonNumber || 0,
    isBroached: true,
    loadEverything: false,
  });

  const [deleteInventory] = useMutation(Inventory.deleteInventory);
  const [createWagonInventory] = useMutation(Inventory.createWagonInventory);

  const prevInventory = useRef<WagonInventoryState>(inventoryState);

  /**
   * Counts the number of decimals behind `.`
   *
   * 2.00 -> 2
   * 1.2 -> 1
   * 3 -> 0
   */
  const countDecimals = (value: number): number => {
    if (!value || Math.floor(value) === value) return 0;

    return value.toString().split('.')[1]?.length || 0;
  };

  /**
   * Handles adding a stack entry.
   */
  const handleAddStack = () => {
    setStacks((prev: StackState[]) => [
      ...prev,
      { stackNumber: nextKey, woodLength: 0, stackWidth: 2.6, stackHeight: 0 },
    ]);
  };

  /**
   * Handles an update to the stack.
   */
  const handleUpdateStacks = (stackKey: number, column: string, target: HTMLInputElement) => {
    // Input value is a number, as the event targets are number-inputs.
    let value = (target.value as unknown) as number;

    // Width must be at least 2m, but can't be greater than 2.90m
    if (column === 'width' && value > 2.9) {
      value = 2.9;
    }

    if (column === 'width' && value < 2) {
      value = 2;
    }

    if ((column === 'length' || 'width') && countDecimals(value) > 2) {
      value = Math.floor(value * 100) / 100;
    }

    setStacks(prevStacks =>
      prevStacks.map(stack => {
        if (stack.stackNumber === stackKey) {
          stack[column] = value;
        }

        return stack;
      }),
    );
  };

  /**
   * Handles delete from stack.
   */
  const handleDeleteStack = (stackKey: number) => {
    setStacks(prevStacks => prevStacks.filter(stack => stack.stackNumber !== stackKey));
  };

  useEffect(() => {
    if (stacks.length === 0) {
      setNextKey(1);
    } else {
      setNextKey(Number(stacks.slice(-1)[0].stackNumber) + 1);
    }
  }, [stacks, setNextKey]);

  /**
   * Handles an update in the inventory form.
   */
  const handleUpdateInventory = (event: CustomEvent, field: string) => {
    if (field === 'isWagonReady') {
      setInventoryState(previousInventory => ({ ...previousInventory, isWagonReady: event.detail.checked }));
    } else if (field === 'loadEverything') {
      setInventoryState(previousInventory => ({ ...previousInventory, loadEverything: event.detail.checked }));
    } else {
      setInventoryState(previousInventory => ({
        ...previousInventory,
        [field]: (event.target as HTMLInputElement)?.value,
      }));
    }
  };

  /**
   * Validates the form.
   */
  const validateForm = useCallback(
    (update): boolean => {
      const errors: ValidationError[] = [];

      // Höhe muss mindestens einmal größer 0 eingegeben sein und kann nicht höher als 2,90 sein
      if (inventoryState.amount === 0) {
        errors.push({ field: 'amount', message: t('containers.inventoryForm.form.errors.amount') });
      }

      if (inventoryState.wagonNumber.toString().length !== 6) {
        errors.push({ field: 'wagonNumber', message: t('containers.inventoryForm.form.errors.wagonNumber') });
      }

      if (!order || !order?.id) {
        errors.push({ field: 'nonField', message: t('containers.inventoryForm.form.errors.oops') });
      }

      setValidationErrors(errors);

      return !errors.length;
    },
    [inventoryState, t, order],
  );

  /**
   * Handles the form submit of deleting the inventory.
   */
  const handleDeleteSubmit = () => {
    if (deleteEnabled && inventory) {
      performMutation('delete', { inventoryId: inventory.id }, inventory.id);
    }

    if (onSubmit) {
      onSubmit();
    }
  };

  /**
   * Catches the submit for the create and update event and checks if these actions are valid.
   */
  const handleUpdateOrCreateSubmit = (event?: SyntheticEvent) => {
    if (createEnabled) {
      performUpdateAndCreate(event);
    }
  };

  /**
   * Performs the mutation in the end. This function considers if the Offline module should be used or not.
   */
  const performMutation = async (type: InventoryMutationType, variables, refId: string | null = null) => {
    if (offlineHandling) {
      if (type === 'create') {
        addJob({
          variables,
          refId,
          mutation: Inventory.createWagonInventory,
          refetchOrders: true,
        });
      } else {
        throw Error('This was not configured yet.');
      }
    } else {
      switch (type) {
        case 'create':
          await createWagonInventory({ variables });
          break;
        case 'delete':
          await deleteInventory({ variables });
          break;
        default:
          throw Error('This was not configured yet.');
      }
    }
  };

  /**
   * Performs everything around updating and creating an inventory. It also checks if everything is valid before that.
   */
  const performUpdateAndCreate = async (event?: SyntheticEvent) => {
    event?.preventDefault();

    if (!order?.id || !order) {
      return;
    }
    // Todo: Handle case where count can be null but amount not
    if (
      inventoryState.amount === 0 &&
      Number(inventoryState.count) === 0 &&
      !inventoryState.loadEverything &&
      !!event
    ) {
      setShowCountAlert(true);

      return;
    }

    setIsSubmitting(true);

    const nextInventoryId = `temp-${uuidv4()}`;

    const nextInventory: Omit<InventoryNode, 'id'> = {
      ...inventoryState,
      amount: inventoryState.amount ? Number(inventoryState.amount) : 0,
      count: inventoryState.count ? Number(inventoryState.count) : 0,
      time: new Date().toISOString(),
      // if you are having problems with the type: if the schema was generated, the default type is:
      // inventoryDetails?: Maybe<Array<Maybe<InventoryDetailNode>>>,
      // the type is correct but the type InventoryNode above is wrong. Instead MutationCreateInventoryArgs should
      // be used because the id for inventoryDetails is not required. For now, the line above was changed to:
      //
      // inventoryDetails?: Maybe<Array<Maybe<InventoryDetailType>>>,
      //
      // if you generate the code again, you might have to add that line again
      // Also you might have problems with the createdAt Type. The Schema generation makes it required, which it is
      // really not. To fix that simply put.
      //
      // createdAt?: Scalars['DateTime'],
      //
      inventoryDetails: inventoryState.amount
        ? stacks.map(stack => ({
            stackHeight: stack.stackHeight,
            stackNumber: stack.stackNumber,
            stackWidth: stack.stackWidth,
            woodLength: stack.woodLength,
          }))
        : [],
      accuracy: locationData?.accuracy,
      longitude: locationData?.longitude,
      latitude: locationData?.latitude,
      // isBroached will be set by backend depending on parent inventory
      isBroached: false,
    };

    addInventory(order?.id, { ...nextInventory, id: nextInventoryId });

    try {
      await performMutation('create', { ...nextInventory, order: order.id }, nextInventoryId);
    } catch (e) {
      setValidationErrors([{ field: 'nonField', message: e.message }]);
      setIsSubmitting(false);

      return;
    }

    if (onSubmit) {
      onSubmit({ ...nextInventory, id: nextInventoryId });
    }
  };

  useEffect(() => {
    setInventoryState(previousInventory => {
      const amount = parseFloat(
        stacks.reduce((prev, cur) => prev + (cur.woodLength || 0) * cur.stackWidth * cur.stackHeight, 0).toFixed(3),
      );

      return { ...previousInventory, amount };
    });
  }, [stacks]);

  useEffect(() => {
    if (
      prevInventory.current.count !== inventoryState.count ||
      prevInventory.current.isWagonReady !== inventoryState.isWagonReady ||
      prevInventory.current.loadEverything !== inventoryState.loadEverything ||
      // eslint-disable-next-line
      prevInventory.current.wagonNumber !== inventoryState.wagonNumber ||
      prevInventory.current.amount !== inventoryState.amount
    ) {
      validateForm(!!inventory);
    }
    prevInventory.current = inventoryState;
  }, [inventoryState, validateForm, inventory]);

  useEffect(() => {
    if (Number(inventoryState.count) === 0 && !inventoryState.loadEverything && inventoryState.amount === 0) {
      setIsValid(false);
    } else setIsValid(true);
  }, [inventoryState]);

  useEffect(() => {
    /**
     * Returns a default entry for a stack.
     */
    const getDefaultStack: (stackNumber: number) => StackState = stackNumber => ({
      stackNumber,
      woodLength: 0,
      stackWidth: 2.6,
      stackHeight: 0,
    });

    if (inventory && inventory.inventoryDetails) {
      // if an inventory is given, we need to fill in missing entries in order to keep the logic:
      // stackNumber of an entry === index + 1
      const result: StackState[] = inventory.inventoryDetails.reduce((accumulator: StackState[], value) => {
        // the entry that was provided in the inventoryDetails
        const newEntry: StackState = {
          woodLength: value?.woodLength || 0,
          stackNumber: value?.stackNumber || 0,
          stackWidth: value?.stackWidth || 0,
          stackHeight: value?.stackHeight || 0,
        };
        // the stackNumber that the accumulator should have next
        const requiredStackNumber = accumulator.length + 1;

        // if the stackNumber of the entry is correct, simply add it to the accumulator
        if (newEntry.stackNumber === requiredStackNumber) {
          accumulator.push(newEntry);

          return accumulator;
        }

        // if the stackNumber is incorrect, there are entries missing in between
        // add an entry for each missing entry in between each number
        [...Array(newEntry.stackNumber - requiredStackNumber)].forEach((entry, i) => {
          accumulator.push(getDefaultStack(requiredStackNumber + i));
        });

        // after each inBetween entry, add the original one
        accumulator.push(newEntry);

        return accumulator;
      }, []);

      setStacks(result);
    } else {
      setStacks([getDefaultStack(1), getDefaultStack(2), getDefaultStack(3), getDefaultStack(4)]);
    }
  }, [client, setStacks, setNextKey, inventory]);

  return (
    <StyledIonGrid>
      <WagonForm
        showSave={!!createEnabled}
        showDelete={!!deleteEnabled}
        handleSaveSubmit={handleUpdateOrCreateSubmit}
        handleDeleteSubmit={handleDeleteSubmit}
        handleUpdateInventory={handleUpdateInventory}
        inventory={inventoryState}
        isSubmitting={isSubmitting}
        validationErrors={validationErrors}
        isValid={isValid}
        isCount={isCount}
        setIsCount={setIsCount}
      />
      {!inventoryState.loadEverything
        ? isCount === false && (
            <>
              <Stacks
                handleAddStack={handleAddStack}
                handleDeleteStack={handleDeleteStack}
                handleUpdateStacks={handleUpdateStacks}
                stacks={stacks}
                inputDisabled={!createEnabled}
              />
              {validationErrors && validationErrors.length > 0 && (
                <StyledIonButtonRow>
                  <StyledIonCol size='12'>
                    {validationErrors
                      .filter(item => item.field === 'amount')
                      .map(item => (
                        <StyledIonTextError key={item.field} color='danger'>
                          {item.message}
                        </StyledIonTextError>
                      ))}
                  </StyledIonCol>
                </StyledIonButtonRow>
              )}
            </>
          )
        : null}

      <WagonInventoryFormButton
        showSave={!!createEnabled}
        showDelete={!!deleteEnabled}
        handleSaveSubmit={handleUpdateOrCreateSubmit}
        handleDeleteSubmit={handleDeleteSubmit}
        handleUpdateInventory={handleUpdateInventory}
        isSubmitting={isSubmitting}
        isValid={isValid}
      />

      {alertModalLevel === undefined ? (
        <IonAlert
          buttons={[
            {
              text: t('containers.inventoryForm.form.message.cancel'),
              role: 'cancel',
            },
            {
              text: t('containers.inventoryForm.form.message.confirm'),
              handler: () => handleUpdateOrCreateSubmit(undefined),
            },
          ]}
          isOpen={showCountAlert}
          message={t('containers.inventoryForm.form.message.body')}
          onDidDismiss={() => setShowCountAlert(false)}
        />
      ) : (
        <Modal
          visible={showCountAlert}
          level={alertModalLevel}
          tabComponents={[
            {
              tabName: '',
              component: (
                <>
                  <StyledIonCol size='3' push='5'>
                    <StyledIonText color='dark'>{t('containers.inventoryForm.form.message.body')}</StyledIonText>
                  </StyledIonCol>
                  <StyledIonButtonRow>
                    <StyledIonCol size='6'>
                      <StyledButton
                        type='button'
                        onClick={() => setShowCountAlert(false)}
                        color='primary'
                        fill='solid'
                        expand='block'
                        disabled={isSubmitting}
                      >
                        {t('containers.inventoryForm.form.message.cancel')}
                      </StyledButton>
                    </StyledIonCol>
                    <StyledIonCol size='6'>
                      <StyledButton
                        type='button'
                        onClick={() => handleUpdateOrCreateSubmit(undefined)}
                        color='primary'
                        fill='solid'
                        expand='block'
                        disabled={isSubmitting}
                      >
                        {t('containers.inventoryForm.form.message.confirm')}
                      </StyledButton>
                    </StyledIonCol>
                  </StyledIonButtonRow>
                </>
              ),
            },
          ]}
          onCancel={() => setShowCountAlert(false)}
          title={t('containers.inventoryForm.form.message.title')}
        />
      )}
    </StyledIonGrid>
  );
};
