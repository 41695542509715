import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { RefresherEventDetail } from '@ionic/core';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonLoading,
  IonCard,
} from '@ionic/react';
import moment from 'moment';

import { Order } from '@src/graphql/Order';
import { FileUploadNode, OrderNode, OrderNodeConnection, PageInfo } from '@src/generated/schema';
import { FilesystemRoutes, FileType, FILETYPE_PDF, RelayItemsPerPageMobile, Routes, TestIds } from '@src/global';
import { ToastContext } from '@src/components/Providers';
import { FileContext, InventoriesContext, OfflineHandlerContext } from '@src/components/Mobile/Providers';
import { Page } from '@src/components/Mobile/Sections/Page';
import { StyledInfoText } from '@src/components/Mobile/Pages/Private/Orders/Detail/Detail.styles';

import { StyledIonCardContent, StyledOrderNotePreview } from './List.styles';
import { HorizontalRadioGroup } from '@src/components/Elements/HorizontalRadioGroup/HorizontalRadioGroup';
import {
  ORDERS_QUERY_ACTIVE_ONLY,
  ORDERS_QUERY_ALL,
  ORDERS_QUERY_FINISHED_ONLY,
} from '@src/components/Mobile/Providers/OfflineHandler/OfflineHandler';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';

const { TRANSPORT_MEDIA, DELIVERY_NOTES } = FilesystemRoutes;

export enum OrderFilters {
  ALL = 'all',
  FINISHED = 'finished',
  OPEN = 'open',
}

export const List: FC = () => {
  const [queryVariables, setQueryVariables] = useState<any>({
    first: RelayItemsPerPageMobile,
    activeOnly: true,
  });
  const { data, error, loading, fetchMore } = useQuery<{ prefetchOrders: OrderNodeConnection }>(Order.getDriverOrders, {
    variables: queryVariables,
    fetchPolicy: 'cache-only',
  });
  const { t } = useTranslation();
  const { setIonToast } = useContext(ToastContext);
  const { isOnline } = useContext(NetworkContext);
  const { jobs, lastOrdersFetch, refetchOrders } = useContext(OfflineHandlerContext);
  const { downloadFile, checkFileExists, deleteFile, getFileSha256 } = useContext(FileContext);
  const { orderInventories, parseFetchedOrders } = useContext(InventoriesContext);
  const [isRefetching, setIsRefetching] = useState<boolean>();

  const { prefetchOrders: { edges, pageInfo } = { edges: [] as any, pageInfo: undefined as unknown as PageInfo } } =
    data || {};

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    if (!isOnline) {
      setIonToast({
        show: true,
        message: t('general.offlineQuery'),
      });

      event.detail.complete();

      return;
    }

    if (isOnline && !!jobs.length) {
      setIonToast({
        show: true,
        message: t('general.offlineProcessing'),
      });

      event.detail.complete();

      return;
    }

    setIsRefetching(true);
    refetchOrders(queryVariables);

    // hacky hack for none lagging ui
    await new Promise((resolve) => setTimeout(resolve, 1000));

    setIsRefetching(false);
    event.detail.complete();
  };

  const searchNext = async (event: CustomEvent<void>) => {
    if (pageInfo?.hasNextPage) {
      await fetchMore({
        variables: { first: RelayItemsPerPageMobile, after: pageInfo?.endCursor },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (edges?.length && fetchMoreResult?.prefetchOrders?.pageInfo) {
            const prefetchOrders: OrderNodeConnection = {
              __typename: previousResult.prefetchOrders.__typename,
              pageInfo: fetchMoreResult.prefetchOrders.pageInfo,
              edges: [...previousResult.prefetchOrders.edges, ...fetchMoreResult.prefetchOrders.edges],
            };

            return { prefetchOrders };
          }

          return previousResult;
        },
      });
    }
    (event?.target as HTMLIonInfiniteScrollElement).complete();
  };

  const downloadOrRemoveFile = useCallback(
    async (
      fileUploadNode: FileUploadNode | null | undefined,
      fileFolder: FilesystemRoutes,
      fileName: string,
    ): Promise<void> => {
      /*
       * There is a total of five cases that could occur with regards to a file's existence on the
       * device and a file retrieved from the API.
       * The following notation is used to describe these cases.
       *
       * F: A file with the provided name does exists on the device.
       * ~F: A file with the provided name does not exist on the device.
       * N: The order has a (new) file in the backend.
       * ~N: The order has no file in the backend.
       * E: The file hash provided by the backend and the hash of the local file match.
       * ~E: The file hash provided by the backend and the hash of the local file do not match.
       *
       * A combination of the letters describes the different cases. For example: ~F,N means that
       * a file doesn't exist on the local device, but a file was provided by the API.
       *
       * With this notation, the following cases exist:
       *   F,N,E:   Do nothing (1)
       *   F,N,~E:  Delete old file, download new file (2)
       *   ~F,N:    Download new file (3)
       *   F,~N:    Delete old file (4)
       *   ~F,~N:   Do nothing (5)
       */
      const newFileURL = fileUploadNode?.s3Path;
      const newFileHash = fileUploadNode?.hashSum;
      const fileOnDevice = await checkFileExists(fileFolder, fileName);

      try {
        if (!fileOnDevice && newFileURL) {
          // Case (3)
          await downloadFile(newFileURL, fileFolder, fileName);
        } else if (fileOnDevice && !newFileURL) {
          // Case (4)
          await deleteFile(fileFolder, fileName);
        } else if (fileOnDevice && newFileURL) {
          // Case (2)
          // We do the hash calculation in here so that we do not do any unnecessary computations.
          const currentFileHash = await getFileSha256(fileFolder, fileName);

          if (newFileHash !== currentFileHash) {
            await deleteFile(fileFolder, fileName);
            await downloadFile(newFileURL, fileFolder, fileName);
          }
        }
      } catch (error) {
        setIonToast({
          show: true,
          message: t('mobile.pages.orders.list.downloadFailed'),
        });
      }
    },
    [checkFileExists, downloadFile, getFileSha256, deleteFile, setIonToast, t],
  );

  const checkOrdersForFiles = useCallback(async () => {
    const orders: OrderNode[] = edges.map((node) => node?.node).filter((node) => !!node);

    if (!orders?.length) return;

    for (const order of orders) {
      await downloadOrRemoveFile(order.transportMedia, TRANSPORT_MEDIA, `${order.id}${FILETYPE_PDF}`);
      await downloadOrRemoveFile(order.deliveryNoteCustomer, DELIVERY_NOTES, `${order.id}-${FileType.CUSTOMER}`);
      await downloadOrRemoveFile(order.deliveryNoteSupplier, DELIVERY_NOTES, `${order.id}-${FileType.SUPPLIER}`);
    }
  }, [edges, downloadOrRemoveFile]);

  useEffect(() => {
    checkOrdersForFiles();
  }, [data, checkOrdersForFiles]);

  useEffect(() => {
    if ((!lastOrdersFetch || moment().diff(moment(lastOrdersFetch), 'minutes') >= 5) && isOnline) {
      refetchOrders(queryVariables);
    }
  }, [lastOrdersFetch, refetchOrders, isOnline, queryVariables]);

  // initial request if there is no data
  useEffect(() => {
    if (!data && isOnline) {
      refetchOrders(queryVariables);
    }
  }, [refetchOrders, queryVariables, data, isOnline]);

  // Side Effect: in addition to updating our own caches on refetch
  // we need to initially check for unparsed orders
  useEffect(() => {
    const orders: OrderNode[] = edges.map((node) => node?.node).filter((node) => !!node);
    const unparsedOrders = orders.filter((order) => !orderInventories[order.id]);

    if (unparsedOrders.length) {
      parseFetchedOrders(orders);
    }
  }, [edges, orderInventories, parseFetchedOrders]);

  useEffect(() => {
    if (error && isOnline) {
      setIonToast({
        show: true,
        message: t('mobile.pages.orders.list.getOrdersFailed'),
      });
    }
  }, [error, setIonToast, t, isOnline]);

  const onFilterChange = (event) => {
    const newValue = event.target.value;

    switch (newValue) {
      case OrderFilters.OPEN:
        setQueryVariables((previous) => ({ ...previous, ...ORDERS_QUERY_ACTIVE_ONLY }));
        break;
      case OrderFilters.FINISHED:
        setQueryVariables((previous) => ({ ...previous, ...ORDERS_QUERY_FINISHED_ONLY }));
        break;
      case OrderFilters.ALL:
        setQueryVariables((previous) => ({ ...previous, ...ORDERS_QUERY_ALL }));
        break;
      default:
        throw Error('Not handled.');
    }
  };

  return (
    <Page
      inTab
      renderContentHeader={() => (
        <HorizontalRadioGroup
          defaultValue={OrderFilters.OPEN}
          buttonStyle='solid'
          onChange={onFilterChange}
          buttonValues={[
            { value: OrderFilters.OPEN, text: t('mobile.pages.orders.list.filters.open') },
            { value: OrderFilters.FINISHED, text: t('mobile.pages.orders.list.filters.finished') },
            { value: OrderFilters.ALL, text: t('mobile.pages.orders.list.filters.all') },
          ]}
        />
      )}
    >
      <div data-testid={TestIds.pages.orders.list.identifier}>
        {(loading || !lastOrdersFetch) && !isRefetching && (
          <IonLoading isOpen message={t('general.loading')} duration={5000} />
        )}

        <IonRefresher slot='fixed' closeDuration='500ms' onIonRefresh={doRefresh}>
          <IonRefresherContent pullingIcon={null} />
        </IonRefresher>

        <div style={{ marginTop: isRefetching ? '50px' : '0' }}>
          {edges.map(({ node }: { node: OrderNode }, index) => (
            <IonCard key={`order_${index}`} routerLink={`${Routes.ORDERS}/${node.id}`}>
              <IonCardHeader>
                <IonCardTitle>{node.number}</IonCardTitle>

                <IonCardSubtitle>{t(`general.status.${node.status}`)}</IonCardSubtitle>
              </IonCardHeader>

              <StyledIonCardContent>
                <IonRow>
                  <IonCol>
                    <StyledOrderNotePreview>{node?.note}</StyledOrderNotePreview>
                  </IonCol>
                </IonRow>

                <IonRow>
                  {node?.supplier?.name ? <IonCol size='6'>{node.supplier.name}</IonCol> : <></>}

                  {node?.customer?.name ? <IonCol size='6'>{node.customer.name}</IonCol> : <></>}
                </IonRow>
              </StyledIonCardContent>
            </IonCard>
          ))}

          {!edges.length && <StyledInfoText>{t('mobile.pages.orders.list.noOrders')}</StyledInfoText>}
        </div>

        <IonInfiniteScroll threshold='100px' onIonInfinite={searchNext}>
          <IonInfiniteScrollContent loadingText={t('mobile.pages.orders.list.loadMoreText')} />
        </IonInfiniteScroll>
      </div>
    </Page>
  );
};
