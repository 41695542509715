import React, { createContext, FC } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { BREAKPOINTS } from '@src/theme';

interface ResizeContextProps {
  innerHeight?: number;
  innerWidth?: number;
  isSmallScreen?: boolean;
}

export const ResizeContext = createContext<ResizeContextProps>({
  innerHeight: undefined,
  innerWidth: undefined,
  isSmallScreen: undefined,
});

export const ResizeProvider: FC = ({ children }) => {
  const [width, height] = useWindowSize(undefined, undefined, { wait: 100 });
  const isSmallScreen = !!width && width < (BREAKPOINTS.small as number);

  return (
    <ResizeContext.Provider
      value={{
        isSmallScreen,
        innerWidth: width || window.innerWidth,
        innerHeight: height || window.innerHeight,
      }}
    >
      {children}
    </ResizeContext.Provider>
  );
};
