import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { DeliveryStatusNode, DeliveryStatusNodeConnection } from '@src/generated/schema';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import debounce from 'lodash.debounce';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { FieldMetaProps } from 'formik/dist/types';
import { usePrevState } from '@src/hooks';

interface SupplierSelectProps extends Pick<SearchSelectProps, 'required'> {
  customer?: string;
  date?: Moment;
  disabled?: boolean;
  getFieldMeta: (name: string) => FieldMetaProps<any>;
  prefilledValue?: string;
  // should the value of the form be reset everytime company, singlePlanOnly or date changes?
  resetValuePreFetch?: boolean;
  setFieldValue?: (field: string, value: string | undefined) => void;
  singlePlanOnly?: boolean;
}

/**
 * Returns a text that is used in format of the delivery status select field to identify an entry.
 */
export const getDeliveryStatusSelectText = (deliveryStatus: DeliveryStatusNode | undefined | null) =>
  deliveryStatus
    ? `${deliveryStatus.number} ${deliveryStatus.shortDescription ? `- ${deliveryStatus.shortDescription}` : ''}`
    : '';

export const DeliveryStatusSelect: FC<SupplierSelectProps> = ({
  prefilledValue,
  resetValuePreFetch,
  getFieldMeta,
  setFieldValue,
  disabled,
  date,
  customer,
  singlePlanOnly,
  ...props
}) => {
  const { t } = useTranslation();
  const prevCustomer = usePrevState(customer);
  const prevSinglePlanOnly = usePrevState(singlePlanOnly);
  const prevDate = usePrevState(date);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  const { data, loading, fetchMore, refetch } = useQuery<{ deliveryStatuses: DeliveryStatusNodeConnection }>(
    DeliveryStatus.getDeliveryStatuses,
    {
      variables: {
        customer,
        date,
        singlePlanOnly,
        first: 10,
        fullDescription: prefilledValue,
      },
      fetchPolicy: 'network-only',
    },
  );
  const { deliveryStatuses: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        customer,
        date,
        singlePlanOnly,
        first: 10,
        fullDescription: value,
      });
    }, 100),
    [customer, date, singlePlanOnly],
  );

  /**
   * Reset the value if customer, singlePlan or date changes. If the component is not mounted yet, the prevX contains
   * undefined. For that reason, it is caught here. Else: check for any changes from the previous and current
   * values.
   */
  useEffect(() => {
    if (
      mounted &&
      customer &&
      singlePlanOnly &&
      (prevCustomer !== customer || prevSinglePlanOnly !== singlePlanOnly || prevDate !== date) &&
      setFieldValue &&
      resetValuePreFetch
    ) {
      setFieldValue('deliveryStatus', undefined);
    }
  }, [
    customer,
    prevCustomer,
    date,
    prevDate,
    singlePlanOnly,
    prevSinglePlanOnly,
    resetValuePreFetch,
    setFieldValue,
    mounted,
  ]);

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.deliveryStatuses.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newEdges = fetchMoreResult.deliveryStatuses.edges;
          const pageInfo = fetchMoreResult.deliveryStatuses.pageInfo;

          return newEdges.length
            ? {
                deliveryStatuses: {
                  pageInfo,
                  __typename: prev.deliveryStatuses.__typename,
                  edges: [...prev.deliveryStatuses.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <SearchSelect
      {...props}
      allowClear
      disabled={disabled}
      label={t('general.deliveryStatus')}
      options={edges.map(({ node: deliveryStatus }) => ({
        title: getDeliveryStatusSelectText(deliveryStatus),
        value: deliveryStatus.id,
      }))}
      name='deliveryStatus'
      loading={loading}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
    />
  );
};
