import { account, accounts, groups, emails, firstNames, lastNames, me, timeSheets } from './Queries';
import {
  authenticate,
  changePassword,
  checkIn,
  checkOut,
  createAccount,
  editAccount,
  editTimeSheet,
  deleteTimeSheet,
} from './Mutations';

export const Account = {
  account,
  accounts,
  authenticate,
  changePassword,
  checkIn,
  checkOut,
  editTimeSheet,
  deleteTimeSheet,
  me,
  timeSheets,
  emails,
  firstNames,
  lastNames,
  createAccount,
  editAccount,
  groups,
};
