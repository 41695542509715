import styled from 'styled-components';

export const StyledBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIconWrapper = styled.div`
  margin-top: -4em;
  font-size: 3rem;
`;
