import React, { FC } from 'react';
import { IonApp, isPlatform } from '@ionic/react';
import { Mobile } from './Mobile';

import { Desktop } from './Desktop';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { AppStyles } from './App.styles';
import { ResizeProvider, ToastProvider } from '@src/components/Providers';

export interface AppProps {
  theme: DefaultTheme;
}

export const App: FC<AppProps> = ({ theme }) => (
  <ThemeProvider theme={theme}>
    <AppStyles />
    <ResizeProvider>
      <ToastProvider>
        <IonApp>{isPlatform('hybrid') ? <Mobile /> : <Desktop />}</IonApp>
      </ToastProvider>
    </ResizeProvider>
  </ThemeProvider>
);
