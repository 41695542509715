import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TestIds } from '@src/global';
import { Container, Wrapper } from '@src/components/Elements';
import { PageHeadline } from '@src/components/Elements/PageHeadline';
import { ItemsPerPagePicker } from '@src/components/Desktop/Containers/ItemsPerPagePicker';

import { Table } from './Table';
import { StyledList } from './List.styles';
import { AccountNode, DeliveryStatusNode } from '@src/generated/schema';
import { closePopups } from '@src/helpers/DomManipulations';
import { Modal } from '@src/components/Elements/Modal';
import { TabComponentsType } from '@src/components/Elements/Modal/Modal';
import { Show } from '../Show';
import { Add } from '@src/components/Desktop/Pages/Private/Accounts/Edit/Add';
import { Alter } from '@src/components/Desktop/Pages/Private/Accounts/Edit/Alter';

export enum ModalEnum {
  SHOW = 'show',
  ADD = 'add',
  ALTER = 'alter',
}

const modalTitles = {
  [ModalEnum.SHOW]: 'desktop.pages.accounts.show.title',
  [ModalEnum.ADD]: 'desktop.pages.accounts.add.title',
  [ModalEnum.ALTER]: 'desktop.pages.accounts.alter.title',
};

export type ModalDataState = DeliveryStatusNode | undefined;

export const List: FC = () => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState({ id: '' });
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [showModal, setShowModal] = useState<string>('');
  const [clickedAccount, setClickedAccount] = useState<AccountNode | undefined>(undefined);

  const closeModal = () => {
    setShowModal('');
    setClickedAccount(undefined);
  };

  const openModal = (modalType?: ModalEnum, accountNode?: any) => {
    closePopups();
    setShowModal(modalType || '');
    setClickedAccount(accountNode);
  };

  const toggleModal: () => void = (modalType?: ModalEnum, accountNode?: any) => {
    if (showModal) {
      closeModal();
    } else {
      openModal(modalType, accountNode);
    }
  };

  const onItemClicked = (e, order) => {
    openModal(ModalEnum.SHOW, order);
  };

  const makeModal = (tabComponents: TabComponentsType) => (
    <Modal
      title={
        clickedAccount
          ? `${t(modalTitles[showModal])}: ${clickedAccount?.firstName} ${clickedAccount?.lastName}`
          : t(modalTitles[showModal])
      }
      visible={!!showModal}
      onCancel={toggleModal}
      tabComponents={tabComponents}
    />
  );

  return (
    <div data-testid={TestIds.pages.piles.list.identifier}>
      {showModal === ModalEnum.SHOW &&
        makeModal([
          { component: <Show account={clickedAccount} toggleModal={toggleModal} />, tabName: t(modalTitles.show) },
        ])}
      {showModal === ModalEnum.ALTER &&
        makeModal([
          { component: <Alter account={clickedAccount} toggleModal={toggleModal} />, tabName: t(modalTitles.show) },
        ])}
      {showModal === ModalEnum.ADD &&
        makeModal([{ component: <Add closeModal={toggleModal} />, tabName: t(modalTitles.show) }])}
      <Container>
        <Wrapper>
          <PageHeadline>{t('desktop.pages.accounts.headline')}</PageHeadline>
          <ItemsPerPagePicker setItemsPerPage={setItemsPerPage} itemsPerPage={itemsPerPage} />
          <StyledList>
            <Table
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setShowPopover={setShowPopover}
              showPopover={showPopover}
              toggleModal={toggleModal}
              onItemClick={onItemClicked}
            />
          </StyledList>
        </Wrapper>
      </Container>
    </div>
  );
};
