import gql from 'graphql-tag';

export const createPile = gql`
  mutation createPile(
    $plotNumber: Int!
    $number: String!
    $location: String!
    $woodtype: String!
    $sort: String!
    $logLength: CustomDecimal!
    $cbkNet: CustomDecimal!
    $cbkSolid: CustomDecimal!
    $longitude: CustomDecimal!
    $latitude: CustomDecimal!
    $status: OldSystemStatusChoice!
    $type: PileTypeChoice!
    $count: Int
    $category: String
    $district: String
    $note: String
    $date: DateTime
    $markedAsBroached: Boolean
    $woodtype2: String
    $locationPlan: String
    $pileNumberFurnisher: String
    $factorCbkNetCbkSolid: CustomDecimal
    $amountUnit: AmountUnitChoice!
    $locationType: LocationTypeChoice!
    $usageSort: UsageSortChoice!
    $qualityType: QualityTypeChoice!
    $timber: String
    $mustBeAccepted: Boolean
  ) {
    createPile(
      plotNumber: $plotNumber
      number: $number
      location: $location
      woodtype: $woodtype
      sort: $sort
      logLength: $logLength
      cbkNet: $cbkNet
      cbkSolid: $cbkSolid
      longitude: $longitude
      latitude: $latitude
      status: $status
      type: $type
      count: $count
      category: $category
      district: $district
      note: $note
      date: $date
      markedAsBroached: $markedAsBroached
      woodtype2: $woodtype2
      locationPlan: $locationPlan
      pileNumberFurnisher: $pileNumberFurnisher
      factorCbkNetCbkSolid: $factorCbkNetCbkSolid
      amountUnit: $amountUnit
      locationType: $locationType
      usageSort: $usageSort
      qualityType: $qualityType
      timber: $timber
      mustBeAccepted: $mustBeAccepted
    ) {
      ok
    }
  }
`;

export const changePile = gql`
  mutation changePile(
    $pileId: ID!
    $plotNumber: Int!
    $number: String!
    $location: String!
    $woodtype: String!
    $sort: String!
    $logLength: CustomDecimal!
    $cbkNet: CustomDecimal!
    $cbkSolid: CustomDecimal!
    $longitude: CustomDecimal!
    $latitude: CustomDecimal!
    $status: OldSystemStatusChoice!
    $type: PileTypeChoice!
    $count: Int
    $category: String
    $district: String
    $note: String
    $date: DateTime
    $markedAsBroached: Boolean
    $woodtype2: String
    $locationPlan: String
    $pileNumberFurnisher: String
    $factorCbkNetCbkSolid: CustomDecimal
    $amountUnit: AmountUnitChoice!
    $locationType: LocationTypeChoice!
    $usageSort: UsageSortChoice!
    $qualityType: QualityTypeChoice!
    $timber: String
    $mustBeAccepted: Boolean
  ) {
    changePile(
      pileId: $pileId
      plotNumber: $plotNumber
      number: $number
      location: $location
      woodtype: $woodtype
      sort: $sort
      logLength: $logLength
      cbkNet: $cbkNet
      cbkSolid: $cbkSolid
      longitude: $longitude
      latitude: $latitude
      status: $status
      type: $type
      count: $count
      category: $category
      district: $district
      note: $note
      date: $date
      markedAsBroached: $markedAsBroached
      woodtype2: $woodtype2
      locationPlan: $locationPlan
      pileNumberFurnisher: $pileNumberFurnisher
      factorCbkNetCbkSolid: $factorCbkNetCbkSolid
      amountUnit: $amountUnit
      locationType: $locationType
      usageSort: $usageSort
      qualityType: $qualityType
      timber: $timber
      mustBeAccepted: $mustBeAccepted
    ) {
      ok
    }
  }
`;

export const deletePile = gql`
  mutation deletePile($pileId: ID!) {
    deletePile(pileId: $pileId) {
      ok
    }
  }
`;

export const importPiles = gql`
  mutation importPiles($plotNumber: Int!, $pilesFile: Upload!) {
    importPiles(plotNumber: $plotNumber, pilesFile: $pilesFile) {
      ok
      count
    }
  }
`;

export const deletePiles = gql`
  mutation deletePilesList($pilesIds: [ID!]) {
    deletePilesList(pilesIds: $pilesIds) {
      ok
    }
  }
`;

export const exportPiles = gql`
  mutation exportPiles($pilesIds: [ID!]) {
    exportPiles(pilesIds: $pilesIds) {
      ok
      url
    }
  }
`;
