import gql from 'graphql-tag';

export const FileUploadNodeFragment = gql`
  fragment fileUploadNode on FileUploadNode {
    id
    name
    file
    s3Path
    hashSum
  }
`;
