import { plots, getPlotStatus, searchPlots, getPlotDetail } from './Queries';
import { createPlot, changePlot, deletePlot } from './Mutations';

export const Plot = {
  plots,
  getPlotStatus,
  getPlotDetail,
  searchPlots,
  createPlot,
  changePlot,
  deletePlot,
};
