import gql from 'graphql-tag';
import { DeliveryStatusFragment, DeliveryStatusDetailFragment } from './Fragments';

export const getDeliveryStatuses = gql`
  query deliveryStatuses(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $activeOnly: Boolean
    $customer: ID
    $date: DateTime
    $fullDescription: String
    $singlePlanOnly: Boolean
    $types: [String]
    $units: [String]
  ) {
    deliveryStatuses(
      after: $after
      before: $before
      first: $first
      last: $last
      activeOnly: $activeOnly
      customer: $customer
      date: $date
      fullDescription: $fullDescription
      singlePlanOnly: $singlePlanOnly
      typeMulti: $types
      unitMulti: $units
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...deliveryStatusNode
        }
      }
    }
  }
  ${DeliveryStatusFragment}
`;

export const getDeliveryStatusDetail = gql`
  query deliveryStatus($deliveryStatusId: ID) {
    deliveryStatus(deliveryStatusId: $deliveryStatusId) {
      ...deliveryStatusDetailNode
    }
  }
  ${DeliveryStatusDetailFragment}
`;

export const getDeliveryStatusUnitSelectOptions = gql`
  query {
    deliveryStatusUnitSelection
  }
`;

export const getDeliveryStatusTypeSelectOptions = gql`
  query {
    deliveryStatusTypeSelection
  }
`;
