import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledHorizontalScrollRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
     display: none;
  }
  
  > label {
    border-radius: 15px !important;
    flex: 0 0 auto;
    
    &::before {
      border-radius: 15px !important;
      right: 0;
      width: 100% !important;
      z-index: -1;
    }
    
    &:not(:last-child) {
      margin-right: 10px;
    }
    
    &:focus{
      border-radius: 0;
    }
  }

`;
