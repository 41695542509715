import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { AccountNode, AccountNodeConnection } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { LoadingSpinnerIonRow, StyledButton } from './Table.styles';
import { StyledTable } from '@src/components/Elements/Table';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Pagination } from '@src/components/Desktop/Elements';
import { Alert, Popover } from 'antd';
import { Tag, TagType } from '@src/components/Elements/Tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { ColumnProps } from 'antd/lib/table/Column';
import { Account } from '@src/graphql/Account';
import { PopoverRow } from '@src/components/Desktop/Pages/Private/Accounts/List/PopoverRow';
import { add, ellipsisVertical, funnel } from 'ionicons/icons';
import { ModalEnum } from '../List';
import { addRowClickHandler } from '@src/helpers/Table';
import {
  StyledTableHeaderActions,
  StyledTableHeaderButton,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { DrawerButton } from '@src/components/Elements/DrawerButton';
import { FilterForm } from '@src/components/Desktop/Pages/Private/Accounts/List/FilterForm';

interface TableProps extends AntdTableProps<any> {
  itemsPerPage: number;
  onItemClick?: (event: React.MouseEvent, row: AccountNode, rowIndex: number) => void;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setShowPopover: ({ id }: { id: string }) => void;
  showPopover: { id: string };
  toggleModal: (modalType: ModalEnum) => void;
}

export const Table: FC<TableProps> = ({
  itemsPerPage,
  onItemClick,
  setItemsPerPage,
  setShowPopover,
  showPopover,
  toggleModal,
  ...props
}) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const { userHasPermission } = useContext(MeContext);
  const [queryVariables, setQueryVariables] = useState<any>({
    first: itemsPerPage,
    includeInactive: false,
  });
  const [page, setPage] = useState<number>(1);
  const [getAccounts, { loading, error, data, refetch }] = useLazyQuery<{
    accounts: AccountNodeConnection;
  }>(Account.accounts, {
    variables: queryVariables,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onError: () => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.accounts.list.error.message'),
        description: t('desktop.pages.accounts.list.error.description'),
      });
    },
  });
  const { accounts: { edges } = { edges: [] as any } } = data || {};
  const accounts = edges.map(({ node }) => node);

  useEffect(() => {
    setQueryVariables(currentQueryVariables => ({ ...currentQueryVariables, first: itemsPerPage }));
    setPage(1);
  }, [itemsPerPage, setQueryVariables, setPage]);

  useEffect(() => {
    getAccounts();
  }, [queryVariables, getAccounts]);

  useEffect(() => {
    const el = document;
    const event = document.createEvent('HTMLEvents');

    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  });

  const onCellClicked = (e: React.MouseEvent, record: AccountNode, index?: number | null) => {
    if (onItemClick && index !== null && index !== undefined) {
      onItemClick(e, record, index);
    }
  };

  let columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.accounts.list.columns.firstName'),
      dataIndex: 'firstName',
    },
    {
      title: t('desktop.pages.accounts.list.columns.lastName'),
      dataIndex: 'lastName',
    },
    {
      title: t('desktop.pages.accounts.list.columns.activeOrdersCount'),
      dataIndex: 'activeOrdersCount',
    },
    {
      title: t('desktop.pages.accounts.list.columns.isActive'),
      dataIndex: 'isActive',
      render: active => (
        <Tag type={active ? TagType.PRIMARY : TagType.DEFAULT} value={active} text={t(`general.isActive.${active}`)} />
      ),
    },
    {
      title: t('desktop.pages.accounts.list.columns.email'),
      dataIndex: 'email',
    },
    {
      title: t('desktop.pages.accounts.list.columns.userGroups'),
      dataIndex: 'groups',
      render: groups => {
        const groupSting = groups?.map(group => group.name)?.join(', ');

        return groupSting ? groupSting : '-';
      },
    },
    {
      title: () => <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />,
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record: any) => (
        <Popover
          placement='leftTop'
          content={<PopoverRow toggleModal={toggleModal} user={record} />}
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  // add onCell click handler to each column except the action column
  columns = addRowClickHandler(columns, onCellClicked);

  return (
    <>
      {error && !data && (
        <Alert
          message={t('desktop.pages.accounts.list.error.message')}
          description={t('desktop.pages.accounts.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <LoadingSpinnerIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </LoadingSpinnerIonRow>
      )}

      <StyledTableHeaderActions>
        {userHasPermission(Permissions.CAN_ADD_ACCOUNT) && (
          <StyledTableHeaderButton size='large' type='link' onClick={() => toggleModal(ModalEnum.ADD)}>
            <IonIcon icon={add} />
            {t('desktop.pages.accounts.list.createAccount')}
          </StyledTableHeaderButton>
        )}
        <DrawerButton icon={funnel} buttonText={t('desktop.pages.general.filter.button')}>
          <FilterForm setQueryVariables={setQueryVariables} setPage={setPage} itemsPerPage={itemsPerPage} />
        </DrawerButton>
      </StyledTableHeaderActions>

      {data &&
        (edges?.length > 0 ? (
          <>
            <StyledTable {...props} columns={columns} dataSource={accounts} pagination={false} rowKey='id' />

            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.accounts && data.accounts.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.accounts.list.noAccountsAvailable')}</IonTitle>
        ))}
    </>
  );
};
