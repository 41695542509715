import React, { FC, Dispatch, SetStateAction } from 'react';
import { StyledSelect } from './ItemsPerPagePicker.styles';

interface ItemsPerPagePickerProps {
  itemsPerPage: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
}

export const ItemsPerPagePicker: FC<ItemsPerPagePickerProps> = ({ itemsPerPage, setItemsPerPage }) => (
  <StyledSelect onChange={setItemsPerPage} value={itemsPerPage}>
    <StyledSelect.Option value={10}>10</StyledSelect.Option>
    <StyledSelect.Option value={20}>20</StyledSelect.Option>
    <StyledSelect.Option value={50}>50</StyledSelect.Option>
    <StyledSelect.Option value={100}>100</StyledSelect.Option>
    <StyledSelect.Option value={250}>250</StyledSelect.Option>
    <StyledSelect.Option value={500}>500</StyledSelect.Option>
  </StyledSelect>
);
