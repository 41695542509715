import styled from 'styled-components';

export const StyledPopoverOption = styled.button`
  display: block;
  font-size: 1.05rem;
  margin-bottom: 0.75em;
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledPopover = styled.div`
  margin: 1em;
`;
