import React, { FC, useContext } from 'react';
import { ButtonLink, ButtonType } from '@src/components/Elements/ButtonLink/ButtonLink';
import { Routes } from '@src/global';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { MeContext, Permissions } from '@src/components/Providers';

export const Navigation: FC = () => {
  const { pathname } = useLocation();
  const { userHasPermission } = useContext(MeContext);
  const { t } = useTranslation();

  return (
    <div className='navigation-links'>
      <ButtonLink type={pathname === Routes.PLOTS ? ButtonType.SELECTED : ButtonType.DEFAULT} to={Routes.PLOTS}>
        {t('desktop.elements.header.navigation.plotOverview')}
      </ButtonLink>
      <ButtonLink type={pathname === Routes.PILES ? ButtonType.SELECTED : ButtonType.DEFAULT} to={Routes.PILES}>
        {t('desktop.elements.header.navigation.polterOverview')}
      </ButtonLink>
      <ButtonLink type={pathname === Routes.ORDERS ? ButtonType.SELECTED : ButtonType.DEFAULT} to={Routes.ORDERS}>
        {t('desktop.elements.header.navigation.orderOverview')}
      </ButtonLink>
      {userHasPermission(Permissions.CAN_VIEW_DELIVERY_STATUS) && (
        <ButtonLink
          type={pathname === Routes.DELIVERY_STATUS ? ButtonType.SELECTED : ButtonType.DEFAULT}
          to={Routes.DELIVERY_STATUS}
        >
          {t('desktop.elements.header.navigation.deliveryStatusOverview')}
        </ButtonLink>
      )}
      {userHasPermission(Permissions.CAN_VIEW_ACCOUNTS) && (
        <ButtonLink type={pathname === Routes.ACCOUNTS ? ButtonType.SELECTED : ButtonType.DEFAULT} to={Routes.ACCOUNTS}>
          {t('desktop.elements.header.navigation.accountOverview')}
        </ButtonLink>
      )}
      {userHasPermission(Permissions.CAN_VIEW_COMPANY) && (
        <ButtonLink
          type={pathname === Routes.COMPANIES ? ButtonType.SELECTED : ButtonType.DEFAULT}
          to={Routes.COMPANIES}
        >
          {t('desktop.elements.header.navigation.companyOverview')}
        </ButtonLink>
      )}
    </div>
  );
};
