import React, { FC } from 'react';
import { DatePickerProps as AntDatePickerProps } from 'formik-antd/lib/date-picker';
import { FormItem, FormItemProps } from 'formik-antd';
import { StyledDatePicker } from '@src/components/Desktop/Containers/DatePicker/DatePicker.styles';

type DatePickerProps = AntDatePickerProps &
  Pick<FormItemProps, 'label' | 'name'> & {
    format?: string;
    required?: boolean;
  };

export const DatePicker: FC<DatePickerProps> = ({ label, name, required, format, ...props }) => (
  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={label} name={name} required={required} hasFeedback>
    <StyledDatePicker format={format || 'DD.MM.YYYY'} name={name} {...props} />
  </FormItem>
);
