import React, { FC } from 'react';
import { OrderNode, StatusChoice } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverTitle.styles';
import { closePopups } from '@src/helpers/DomManipulations';

export interface PopoverTitleProps {
  handleOrderStatusChange: (orderIds: string[], status: StatusChoice) => Promise<void>;
  orders: OrderNode[];
}

export const PopoverTitle: FC<PopoverTitleProps> = ({ handleOrderStatusChange, orders }) => {
  const { t } = useTranslation();

  const setOrderStatusAllocated = () => {
    handleOrderStatusChange(
      orders.map(order => order.id),
      StatusChoice.Allocated,
    );
    closePopups();
  };

  return (
    <StyledPopover>
      <StyledPopoverOption onClick={setOrderStatusAllocated}>
        {t('desktop.pages.orders.list.popover.allocateMultiple')}
      </StyledPopoverOption>
    </StyledPopover>
  );
};
