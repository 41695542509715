import React, { FC, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PileNode, PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import debounce from 'lodash.debounce';
import { MobileSearchSelect } from '@src/components/Mobile/Elements/SearchSelect';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { MobileSelectProps } from '@src/components/Mobile/Elements/SearchSelect/SearchSelect';
import { getUniqueList } from '@src/helpers/Logic/Logic';

export interface MobileCategorySelectProps extends Omit<MobileSelectProps, 'options'> {
  piles?: PileNode[];
}

export const MobileCategorySelect: FC<MobileCategorySelectProps> = ({ piles, ...props }) => {
  const { isOnline } = useContext(NetworkContext);
  const { data, loading, fetchMore, refetch } = useQuery<{ pileCategories: PileNodeConnection }>(
    Pile.getPileCategories,
    {
      variables: {
        first: 20,
      },
      fetchPolicy: 'network-only',
    },
  );
  const { pileCategories: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 20,
        category_Icontains: value,
      });
    }, 500),
    [],
  );

  const handlePopupScroll = () => {
    fetchMore({
      variables: {
        after: data?.pileCategories.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newEdges = fetchMoreResult.pileCategories.edges;
        const pageInfo = fetchMoreResult.pileCategories.pageInfo;

        return newEdges.length
          ? {
              pileCategories: {
                pageInfo,
                __typename: prev.pileCategories.__typename,
                edges: [...prev.pileCategories.edges, ...newEdges],
              },
            }
          : prev;
      },
    });
  };

  return (
    <MobileSearchSelect
      {...props}
      options={
        isOnline
          ? edges.map(({ node: { category, id } }) => ({
              id,
              title: category,
              value: category,
            }))
          : getUniqueList(piles?.map(p => p.category)).map(category => ({
              id: category,
              value: category,
              title: category,
            }))
      }
      loading={loading}
      multi
      onSearch={handleSearch}
      onLoadMore={handlePopupScroll}
      allowNoSelection
    />
  );
};
