import styled from 'styled-components';
import {
  IonIcon,
  IonList,
  IonNote,
  IonSpinner,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
} from '@ionic/react';
import { Modal } from '@src/components/Elements/Modal';
import { Upload } from 'antd';
import { TextArea } from '@src/components/Desktop/Containers/TextArea';

export const StyledTitle = styled.h5`
  text-align: center;
  margin-top: 10%;
  color: ${({ theme }) => theme.color.twine};
`;

export const StyledUploadContainer = styled.div`
  margin-bottom: 10%;
`;

export const StyledUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;

export const StyledSpan = styled.span`
  margin-left: 39%;
  color: ${({ theme }) => theme.color.twine};
`;

export const StyledIcon = styled(IonIcon)`
  margin-left: 35%;
  color: ${({ theme }) => theme.color.twine};
`;

export const StyledInfoText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.color.doveGray};
  font-weight: ${({ theme }) => theme.fontWeight.secondary.regular};
`;

export const StyledAddIonButtonDiv = styled.div`
  text-align: center;
`;

export const StyledNote = styled(IonNote)`
  &.md {
    font-size: ${({ theme }) => theme.global.fontSize}px;
  }
`;

export const StyledList = styled(IonList)`
  margin-top: 5%;
`;

export const StyledSpinner = styled(IonSpinner)`
  margin: 0 auto;
  width: 100%;
`;

export const StyledIonText = styled(IonText)`
  font-size: 15px;
`;

export const StyledIonTextError = styled(IonText)`
  display: block;
  font-size: 15px;
`;

export const StyledIonGrid = styled(IonGrid)`
  --ion-grid-padding: 0;
`;

export const StyledForm = styled.form`
  margin-top: 30px;
`;

export const StyledIonRow = styled(IonRow)`
  align-items: center;
`;

export const StyledIonButtonRow = styled(IonRow)`
  margin-top: 1em;
`;

export const StyledIonCol = styled(IonCol)`
  --ion-grid-column-padding: 0.6em;
`;

export const StyledIonColBroachedToggle = styled(IonCol)`
  display: flex;
  --ion-grid-column-padding: 0.6em;
`;

export const StyledIonInput = styled(IonInput)`
  --background: ${({ theme }) => theme.color.gallery};
  --padding-top: 0.2em;
  --padding-bottom: 0.2em;
  --padding-start: 0.5em;
  font-size: 15px;

  input:invalid {
    border: 1px solid #f04141;
  }
`;

export const StyledIonButton = styled(IonButton)`
  margin: 0;
  --padding-top: 0;
  --padding-end: 0.5em;
  --padding-bottom: 0;
  --padding-start: 0.5em;
`;

export const StyledButton = styled(IonButton)`
  margin: 30px auto;
  max-width: 500px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    margin: 30px;
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  height: 8rem;
`;
