import React, { FC } from 'react';
import { IonRow, IonSelectOption, IonIcon, isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  StyledIonText,
  StyledIonRow,
  StyledIonCol,
  StyledIonInput,
  StyledIonSelect,
  StyledIonButton,
  StyledIonButtonDelete,
} from '../InventoryForm.styles';
import { add, closeCircleOutline } from 'ionicons/icons';
import { InventoryDetailType } from '@src/generated/schema';

const stepsHeight: number[] = Array.from(Array(30), (x, index) => index / 10);

interface StacksProps {
  handleAddStack: () => void;
  handleDeleteStack: (stackKey: number) => void;
  handleUpdateStacks: (stackKey: number, column: string, target: any) => void;
  inputDisabled?: boolean;
  stacks: InventoryDetailType[];
}

export const Stacks: FC<StacksProps> = ({
  handleAddStack,
  handleDeleteStack,
  handleUpdateStacks,
  stacks,
  inputDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <IonRow>
        <StyledIonCol size='2.5'>
          <StyledIonText>{t('containers.inventoryForm.form.stack.columns.stack')}</StyledIonText>
        </StyledIonCol>
        <StyledIonCol size='2.5'>
          <StyledIonText>{t('containers.inventoryForm.form.stack.columns.length')}</StyledIonText>
        </StyledIonCol>
        <StyledIonCol size='2.6'>
          <StyledIonText>{t('containers.inventoryForm.form.stack.columns.width')}</StyledIonText>
        </StyledIonCol>
        <StyledIonCol size='2.6'>
          <StyledIonText>{t('containers.inventoryForm.form.stack.columns.height')}</StyledIonText>
        </StyledIonCol>
      </IonRow>

      {stacks.map((stack) => (
        <StyledIonRow key={stack.stackNumber || 0}>
          <StyledIonCol size='2.5'>
            <StyledIonText>{stack.stackNumber}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size='2.5'>
            <StyledIonInput
              type='number'
              value={stack.woodLength?.toString()}
              min='0'
              step='0.01'
              disabled={inputDisabled}
              onIonChange={(e) => handleUpdateStacks(stack.stackNumber || 0, 'woodLength', e.target)}
            />
          </StyledIonCol>
          <StyledIonCol size='2.6'>
            <StyledIonInput
              type='number'
              value={stack.stackWidth?.toString()}
              min='2.00'
              max='3.00'
              disabled={inputDisabled}
              step='0.01'
              onIonChange={(e) => handleUpdateStacks(stack.stackNumber || 0, 'stackWidth', e.target)}
              debounce={200}
            />
          </StyledIonCol>
          <StyledIonCol size='2.6'>
            {isPlatform('hybrid') ? (
              <StyledIonSelect
                interface='popover'
                interfaceOptions={{ cssClass: 'stacks-popover' }}
                value={stack.stackHeight}
                disabled={inputDisabled}
                onIonChange={(e) => handleUpdateStacks(stack.stackNumber || 0, 'stackHeight', e.target)}
              >
                {stepsHeight.map((height) => (
                  <IonSelectOption key={height} value={height}>
                    {height}
                  </IonSelectOption>
                ))}
              </StyledIonSelect>
            ) : (
              <StyledIonInput
                type='number'
                value={stack.stackHeight?.toString()}
                min='0.00'
                disabled={inputDisabled}
                max='3.00'
                step='0.01'
                onIonChange={(e) => handleUpdateStacks(stack.stackNumber || 0, 'stackHeight', e.target)}
                debounce={200}
              />
            )}
          </StyledIonCol>
          {stacks.length > 1 && !inputDisabled && (
            <StyledIonCol size='1.8'>
              <StyledIonButtonDelete
                type='button'
                size='default'
                fill='clear'
                color='medium'
                onClick={() => handleDeleteStack(stack.stackNumber || 0)}
              >
                <IonIcon slot='icon-only' icon={closeCircleOutline} />
              </StyledIonButtonDelete>
            </StyledIonCol>
          )}
        </StyledIonRow>
      ))}

      {!inputDisabled && (
        <StyledIonRow>
          <StyledIonCol size='2'>
            <StyledIonButton type='button' size='small' color='success' onClick={handleAddStack}>
              <IonIcon icon={add} />
            </StyledIonButton>
          </StyledIonCol>
        </StyledIonRow>
      )}
    </>
  );
};
