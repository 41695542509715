import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Wrapper } from '@src/components/Elements';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyNode, CompanyNodeConnection } from '@src/generated/schema';
import { IonRow, IonCol, IonSpinner } from '@ionic/react';
import { Company } from '@src/graphql/Company';
import { Detail } from './Detail';

export interface ShowProps {
  company?: CompanyNode;
}

export const Show: FC<ShowProps> = ({ company }) => {
  const { t } = useTranslation();

  // initial data
  const { loading, error, data } = useQuery<{
    companies: CompanyNodeConnection;
  }>(Company.companies, {
    variables: { companyId: company && company.id },
    fetchPolicy: 'network-only',
  });

  return (
    <div>
      <Wrapper>
        {loading && (
          <IonRow>
            <IonCol className='ion-text-center'>
              <IonSpinner name='crescent' />
            </IonCol>
          </IonRow>
        )}

        {data && data.companies && (
          <>
            <Detail company={company} />
          </>
        )}

        {error && (
          <Alert
            message={t('desktop.pages.companies.show.overview.error.message')}
            description={t('desktop.pages.companies.show.overview.error.description')}
            type='error'
            showIcon
          />
        )}
      </Wrapper>
    </div>
  );
};
