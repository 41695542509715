import { Pages } from './Pages/Pages';
import React, { FC } from 'react';
import { TestIds } from '@src/global';
import { IonReactRouter } from '@ionic/react-router';
import { IonRouterOutlet } from '@ionic/react';
import { ConfigProvider } from 'antd';
import { default as de } from 'antd/lib/locale/de_DE';
import { ApolloProvider } from '@src/components/Desktop/Providers';
import { MeProvider } from '@src/components/Providers';

export const Desktop: FC = () => (
  <div data-testid={TestIds.platforms.desktop}>
    <ApolloProvider>
      <MeProvider>
        <ConfigProvider locale={de}>
          <IonRouterOutlet>
            <IonReactRouter>
              <Pages />
            </IonReactRouter>
          </IonRouterOutlet>
        </ConfigProvider>
      </MeProvider>
    </ApolloProvider>
  </div>
);
