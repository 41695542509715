import React, { FC, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { AccountNode, CustomObtainJsonWebToken } from '@src/generated/schema';
import { useQuery } from '@apollo/react-hooks';
import { Account } from '@src/graphql/Account';
import { Overlay } from '@src/components/Elements/Loading';

export enum Permissions {
  CAN_ADD_PILES_TO_EXISTING_ORDER = 'order.can_add_piles_to_existing_order',
  CAN_ABORT_ORDER = 'order.can_abort_order',
  CAN_VIEW_DELIVERY_STATUS = 'delivery_status.view_deliverystatus',
  CAN_VIEW_ACCOUNTS = 'account.view_account',
  CAN_ADD_DELIVERY_STATUS = 'delivery_status.add_deliverystatus',
  CAN_ADD_INVENTORY = 'inventory.add_inventory',
  CAN_CHANGE_INVENTORY = 'inventory.change_inventory',
  CAN_DELETE_INVENTORY = 'inventory.delete_inventory',
  CAN_ADD_INVENTORY_DETAILS = 'inventory.add_inventorydetails',
  CAN_CHANGE_INVENTORY_DETAILS = 'inventory.change_inventorydetails',
  CAN_CHANGE_ACCOUNT = 'account.change_account',
  CAN_ADD_ACCOUNT = 'account.add_account',
  CAN_ADD_PILE = 'pile.add_pile',
  CAN_ADD_PLOT = 'pile.add_plot',
  CAN_CHANGE_PILE = 'pile.change_pile',
  CAN_CHANGE_PLOT = 'pile.change_plot',
  CAN_ADD_ROUTE = 'order.add_route',
  CAN_CHANGE_ROUTE = 'order.change_route',
  CAN_VIEW_COMPANY = 'company.view_company',
  CAN_ADD_COMPANY = 'company.add_company',
  CAN_CHANGE_COMPANY = 'company.change_company',
}

export interface UserAuth {
  isLoggedIn: () => boolean;
  me?: AccountNode;
  refetchMe: () => Promise<void>;
  session: CustomObtainJsonWebToken;
  setSession: (session: CustomObtainJsonWebToken) => any;
  userHasAnyPermission: (permissions: Permissions[]) => boolean;
  userHasPermission: (permission: Permissions) => boolean;
  userHasPermissions: (permissions: Permissions[]) => boolean;
}

export const noUser: UserAuth = {
  isLoggedIn: () => false,
  session: { token: '' },
  setSession: () => undefined,
  refetchMe: async () => new Promise<void>(() => undefined),
  userHasAnyPermission: () => false,
  userHasPermissions: () => false,
  userHasPermission: () => false,
};

export const MeContext = React.createContext(noUser);

export const MeProvider: FC = ({ children }) => {
  const [sessionState, setSessionState] = useState(noUser.session);

  if (!sessionState.token) {
    Preferences.get({ key: 'token' }).then(({ value: tokenValue }) => {
      const newToken: CustomObtainJsonWebToken = { token: tokenValue ? tokenValue : noUser.session.token };
      if (newToken.token) setSessionState(newToken);
    });
  }

  const setSession = (session: CustomObtainJsonWebToken) => {
    if (session.token !== sessionState.token) {
      Preferences.set({ key: 'token', value: session.token }).then(() => {
        setSessionState(session);
      });
    }
  };

  const isLoggedIn = () => sessionState.token !== '';

  const { data, loading, refetch } = useQuery(Account.me, {
    // This is needed to let the Query refetch after cache reset.
    variables: { token: sessionState.token },
    skip: !sessionState.token,
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) return <Overlay />;

  const refetchMe = async () => {
    await refetch();
  };

  /**
   * Checks if the logged in user has ALL given permissions.
   */
  const userHasPermissions = (permissions: Permissions[]) =>
    data && data.me && permissions.filter(p => data.me.permissions?.includes(p)).length === permissions.length;

  /**
   * Checks if the logged in user has ANY of the given permissions.
   */
  const userHasAnyPermission = (permissions: Permissions[]) =>
    data && data.me && permissions.filter(p => data.me.permissions?.includes(p)).length > 0;

  /**
   * Checks if the logged in user has a certain permission.
   */
  const userHasPermission = (permission: Permissions) => data && data.me && data.me.permissions?.includes(permission);

  return (
    <MeContext.Provider
      value={{
        userHasAnyPermission,
        userHasPermission,
        isLoggedIn,
        userHasPermissions,
        refetchMe,
        setSession,
        session: sessionState,
        me: data && data.me,
      }}
    >
      {children}
    </MeContext.Provider>
  );
};
