import L from 'leaflet';
import { Filesystem } from '@capacitor/filesystem';
import { FilesystemRoutes } from '@src/global';
import { FILESYSTEM_DIRECTORY } from '../File/File';

export async function getTile({ z, x, y }: any) {
  const fileName = `${z}-${x}-${y}.png`;

  try {
    const file = await Filesystem.readFile({
      directory: FILESYSTEM_DIRECTORY,
      path: `${FilesystemRoutes.OFFLINE_TILES}/${fileName}`,
    });

    return file;
  } catch (error) {
    // Does not have tile
  }
}

export function getTileUrl(urlTemplate: any, data: any) {
  return L.Util.template(urlTemplate, {
    ...data,
    r: L.Browser.retina ? '@2x' : '',
  });
}

export function getTileUrls(layer: any, bounds: any, zoom: any) {
  const tiles: any = [];
  const tileBounds: any = L.bounds(
    bounds.min.divideBy(layer.getTileSize().x).floor(),
    bounds.max.divideBy(layer.getTileSize().x).floor(),
  );
  for (let j = tileBounds.min.y; j <= tileBounds.max.y; j += 1) {
    for (let i = tileBounds.min.x; i <= tileBounds.max.x; i += 1) {
      const tilePoint = new L.Point(i, j);
      const data = {
        ...layer.options,
        x: i,
        y: j,
        z: zoom,
      };
      tiles.push({
        key: getTileUrl(layer._url, {
          ...data,
          s: layer.options.subdomains['0'],
        }),
        url: getTileUrl(layer._url, {
          ...data,
          s: layer._getSubdomain(tilePoint),
        }),
        z: zoom,
        x: i,
        y: j,
        urlTemplate: layer._url,
      });
    }
  }

  return tiles;
}

const OfflineLayer: any = L.TileLayer.extend({
  createTile(coords: any, done: any) {
    // @ts-ignore
    const tile: any = L.TileLayer.prototype.createTile.call(this, coords, done);
    tile.src = '';

    getTile(coords).then(file => {
      if (file?.data) {
        tile.src = `data:image/jpeg;base64,${file.data}`;
      }

      done(null, tile);
    });

    return tile;
  },

  getTileUrls(bounds: any, zoom: any) {
    return getTileUrls(this, bounds, zoom);
  },
});

const TileLayerOffline = (url, options) => new OfflineLayer(url, options);

export default TileLayerOffline;
