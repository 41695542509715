// import { Form } from '@src/components/Elements/Wrapper/Form';
import styled from 'styled-components';
import { Form } from 'formik-antd';

export const StyledForm = styled(Form)`
  .ant-calendar-picker,
  .ant-upload {
    width: 100%;
  }

  .ant-upload button {
    width: 100%;
    line-height: 1.6em;
  }
`;
