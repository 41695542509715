import gql from 'graphql-tag';

export const AccountMeNodeFragment = gql`
  fragment accountMeNode on AccountNode {
    id
    activeOrdersCount
    email
    firstName
    groups {
      name
    }
    isActive
    isStaff
    checkedIn
    isSuperuser
    lastName
    permissions
    client {
      tileServerUrl
      graphhopperApiKey
      canUseTimeRecording
      canChangePlotPile
      canExportPiles
    }
  }
`;

export const GroupNode = gql`
  fragment groupNode on GroupNode {
    id
    name
  }
`;

export const AccountNodeFragment = gql`
  fragment accountNode on AccountNode {
    id
    firstName
    lastName
    email
    groups {
      name
    }
    isStaff
    isActive
    isSuperuser
    activeOrdersCount
  }
`;

export const AccountNameNodeFragment = gql`
  fragment accountNameNode on AccountNode {
    firstName
    lastName
    id
  }
`;
