import { Menu } from '@src/components/Mobile/Sections/Page/Menu';
import { IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { RoutesWithBackButton } from '@src/global';
import { arrowBack } from 'ionicons/icons';
import { StyledIonContent, StyledIonContentHeader } from '@src/components/Mobile/Sections/Page/Page.styles';
import React, { FC, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastContext } from '@src/components/Providers';

interface PageContentProps {
  renderContentHeader?: () => JSX.Element;
  renderFooter?: JSX.Element;
}

const TOAST_TIME_MOBILE = 3000;

export const PageContent: FC<PageContentProps> = ({ renderFooter, children, renderContentHeader }) => {
  const { t } = useTranslation();
  const { goBack, location } = useHistory();
  const { ionToast, setIonToast } = useContext(ToastContext);

  return (
    <Fragment>
      <Menu withFooter={!!renderFooter} />

      <IonHeader>
        <IonToolbar>
          {RoutesWithBackButton.some(route => location.pathname.includes(route)) && (
            <IonButtons slot='start'>
              <IonButton fill='clear' onClick={goBack} router-direction='back'>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
          )}

          <IonTitle>{t('mobile.header')}</IonTitle>

          <IonButtons slot='end'>
            <IonMenuButton menu='main-menu' />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {renderContentHeader && <StyledIonContentHeader>{renderContentHeader()}</StyledIonContentHeader>}
      <StyledIonContent className='ion-padding' id='main-content'>
        {children}

        <IonToast
          isOpen={ionToast.show}
          onDidDismiss={() => setIonToast({ show: false })}
          message={ionToast.message}
          duration={TOAST_TIME_MOBILE}
        />
      </StyledIonContent>

      {renderFooter}
    </Fragment>
  );
};
