import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form, FormValues } from '../Form';
import { useMutation, useQuery } from 'react-apollo';
import { DeliveryStatusNode, DeliveryStatusPlanType } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';
import { TestIds } from '@src/global';

export interface AlterProps {
  deliveryStatus: DeliveryStatusNode | undefined;
  toggleModal: () => void;
}

export const Alter: FC<AlterProps> = ({ toggleModal, deliveryStatus }) => {
  const deliveryStatusId = deliveryStatus && deliveryStatus.id;
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [changeDeliveryStatus, { error: changeError }] = useMutation(DeliveryStatus.changeDeliveryStatus, {
    refetchQueries: ['deliveryStatuses'],
  });

  const { data } = useQuery<{
    deliveryStatus: DeliveryStatusNode;
  }>(DeliveryStatus.getDeliveryStatusDetail, {
    variables: { deliveryStatusId },
  });

  const initialValues: FormValues = {
    allowTemporaryStorage: data?.deliveryStatus.allowTemporaryStorage,
    amount: data?.deliveryStatus?.amount || undefined,
    customer: data?.deliveryStatus.customer.id,
    dateBegin: data?.deliveryStatus.dateBegin ? new Date(data?.deliveryStatus.dateBegin) : undefined,
    dateEnd: data?.deliveryStatus.dateEnd ? new Date(data?.deliveryStatus.dateEnd) : undefined,
    deliveryAddress: data?.deliveryStatus?.deliveryAddress?.id || undefined,
    shortDescription: data?.deliveryStatus?.shortDescription || undefined,
    planType: data?.deliveryStatus.planType || DeliveryStatusPlanType.Single,
    type: data?.deliveryStatus.type,
    unit: data?.deliveryStatus.unit,
  };

  const onSubmit = async (variables: FormValues) => {
    await changeDeliveryStatus({
      variables: {
        ...variables,
        deliveryStatusId,
      },
      refetchQueries: ['deliveryStatuses'],
    })
      .then(() => {
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.deliveryStatus.edit.success.message'),
          description: t('desktop.pages.deliveryStatus.edit.success.description'),
        });
      })
      .finally(toggleModal);
  };

  const prefilledValues = {
    customer: data?.deliveryStatus?.customer.name || '',
    fullAddress: data?.deliveryStatus?.deliveryAddress
      ? `${data?.deliveryStatus?.deliveryAddress.street} ${data?.deliveryStatus?.deliveryAddress.postcode} ${data?.deliveryStatus?.deliveryAddress.country}`
      : '',
  };

  return (
    <div data-testid={TestIds.pages.orders.add.identifier}>
      <Wrapper>
        <Form
          onSubmit={onSubmit}
          error={changeError}
          initialFormValues={initialValues}
          prefilledValues={prefilledValues}
        />
      </Wrapper>
    </div>
  );
};
