import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form, FormValues } from '../Form';
import { TestIds } from '@src/global';
import { useQuery, useMutation } from 'react-apollo';
import { AccountNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { IonCol, IonSpinner, IonRow } from '@ionic/react';
import { Account } from '@src/graphql/Account';

export interface AlterProps {
  account?: AccountNode;
  toggleModal: () => void;
}

export const Alter: FC<AlterProps> = ({ toggleModal, account }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [editAccount, { error }] = useMutation(Account.editAccount);
  const { data, loading } = useQuery<{
    account: AccountNode;
  }>(Account.account, {
    variables: { accountId: account?.id },
  });

  const initialValues: FormValues = {
    email: data?.account?.email || undefined,
    groups: data?.account?.groups?.map(group => group?.name || '') || [],
    firstName: data?.account?.firstName || undefined,
    lastName: data?.account?.lastName || undefined,
    password: undefined,
    passwordRepeat: undefined,
  };

  const onSubmit = async (variables: FormValues) => {
    try {
      const { data: data_ } = await editAccount({
        variables: {
          ...variables,
          id: account?.id,
        },
        refetchQueries: ['accounts', { query: Account.account, variables: { accountId: account?.id } }],
      });

      if (data_) {
        toggleModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.accounts.alter.form.submit.success.message'),
          description: t('desktop.pages.accounts.alter.form.submit.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div data-testid={TestIds.pages.orders.add.identifier}>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}
      {data && (
        <Wrapper>
          <Form initialFormValues={initialValues} passwordOptional onSubmit={onSubmit} error={error} />
        </Wrapper>
      )}
    </div>
  );
};
