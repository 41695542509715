import React, { createContext, FC, useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { PersistentStorage } from 'apollo-cache-persist/types';
import { CachePersistor } from 'apollo-cache-persist';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider as ApolloClientProvider } from 'react-apollo';

import { authLink, cache, httpLink } from '@src/global';
import { Overlay } from '@src/components/Elements/Loading';

interface ApolloContextProps {
  client?: ApolloClient<any>;
  persistor: CachePersistor<any>;
}

export const ApolloContext = createContext<ApolloContextProps>({
  client: new ApolloClient({ cache, link: authLink }),
  persistor: (() => Promise.reject('CachePersistor not initialized')) as any,
});

export const ApolloProvider: FC = ({ children }) => {
  const [isCachedInitialized, setIsCachedInitialized] = useState<boolean>();

  // Capacitor Storage
  const storage: PersistentStorage<any> = {
    getItem: async key => Preferences.get({ key }),
    setItem: async (key, value) => Preferences.set({ key, value }),
    removeItem: async key => Preferences.remove({ key }),
  };

  // Cache-Persistor
  const persistorKey = 'apollo-cache-persist';
  const persistor = new CachePersistor({ cache, storage, key: persistorKey, debug: false });

  const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    queryDeduplication: !isPlatform('hybrid'), // Disable on hybrid platforms.
  });

  // Manual cache restorage as the persistor.restore() is restoring just empty content. Queries will be refetched
  // automatically afterwards.
  useEffect(() => {
    if (!isCachedInitialized) {
      Preferences.get({ key: persistorKey }).then(({ value }) => {
        if (value) {
          const myCache: NormalizedCacheObject = JSON.parse(value);
          cache.restore(myCache);
        }

        setIsCachedInitialized(true);
      });
    }
  }, [isCachedInitialized]);

  if (isCachedInitialized) {
    return (
      <ApolloContext.Provider value={{ client, persistor }}>
        <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
      </ApolloContext.Provider>
    );
  }

  return <Overlay />;
};
