import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import { OrderStatus } from '@src/graphql/Order/Types';

export const StatusSelect: FC<Pick<SearchSelectProps, 'required'>> = (props) => {
  const { t } = useTranslation();

  // -- TODO --
  // Fix bug #438 if dump data was created, then remove FE fix below
  // const { data, loading } = useQuery<{ orderStatus: OrderStatusNodeConnection }>(Order.getOrderStatus);
  // const { orderStatus: { edges } = { edges: [] as any } } = data || {};

  // return (
  //   <SearchSelect
  //     {...props}
  //     label={t('desktop.pages.orders.filter.status.title')}
  //     options={edges.map(({ node: { status } }) => ({
  //       title: t(`general.status.${status}`),
  //       value: status.toLowerCase(),
  //     }))}
  //     name='status'
  //     loading={loading}
  //   />
  // );

  return (
    <SearchSelect
      {...props}
      label={t('desktop.pages.orders.filter.status.title')}
      options={Object.values(OrderStatus).map((status) => ({
        title: t(`general.status.${status}`),
        value: status.toLowerCase(),
      }))}
      name='status'
    />
  );
};
