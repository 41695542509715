import { IonItem, IonList, IonMenu, IonNote, IonSpinner } from '@ionic/react';
import { MenuProps } from '@src/components/Mobile/Sections/Page/Menu/Menu';
import { StyledActiveInactiveStatus } from '@src/components/Mobile/Sections/Page/Menu/NetworkStatus/ActiveInactiveStatus.styles';
import styled from 'styled-components';

export const StyledIonMenu = styled(IonMenu)<MenuProps>`
  ${StyledActiveInactiveStatus} {
    bottom: ${({ withFooter }) => (withFooter ? '90px' : '0')};
  }
`;
export const StyledNote = styled(IonNote)`
  &.md {
    font-size: ${({ theme }) => theme.global.fontSize}px;
  }
`;

export const MenuIconStyles =  { marginRight: 10 };

export const IonItemHeader = styled(IonItem)`
  color: #999;
  padding-top: 1.2em;
  text-transform: uppercase;
  font-size: 0.9em;
`;

export const StyledIonList = styled(IonList)`
  margin-bottom: 10vh;
`;

export const BottomInformationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 50;
  width: 100%;
  display: flex;

  > div {
    &:not(:first-child) {
      border-left: 1px solid #fff;
    }
  }
`;

export const CheckInIonItem = styled(IonItem)`
  position: relative;
`;

export const CheckInIonSpinner = styled(IonSpinner)`
  position: absolute;
  right: 1em;
`;
