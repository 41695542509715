import { SearchSelect } from '@src/components/Elements/SearchSelect';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { CompanyNodeConnection } from '@src/generated/schema';
import { Company } from '@src/graphql/Company';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import debounce from 'lodash.debounce';

interface SupplierSelectProps extends Pick<SearchSelectProps, 'required'> {
  prefilledValue?: string;
}

export const CompanySelect: FC<SupplierSelectProps> = ({ prefilledValue, ...props }) => {
  const { t } = useTranslation();
  const { data, loading, fetchMore, refetch } = useQuery<{ companies: CompanyNodeConnection }>(Company.companies, {
    variables: {
      first: 10,
      name_Icontains: prefilledValue,
    },
    fetchPolicy: 'network-only',
  });
  const { companies: { edges } = { edges: [] as any } } = data || {};

  const handleSearch = useCallback(
    debounce((value: string) => {
      refetch({
        first: 10,
        name_Icontains: value,
      });
    }, 100),
    [],
  );

  const handlePopupScroll = (e: any) => {
    e.persist();
    const scrollTop = e.target.scrollTop as number;
    const offsetHeight = e.target.offsetHeight as number;

    if (scrollTop + offsetHeight === e.target.scrollHeight) {
      fetchMore({
        variables: {
          after: data?.companies.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newEdges = fetchMoreResult.companies.edges;
          const pageInfo = fetchMoreResult.companies.pageInfo;

          return newEdges.length
            ? {
                companies: {
                  pageInfo,
                  __typename: prev.companies.__typename,
                  edges: [...prev.companies.edges, ...newEdges],
                },
              }
            : prev;
        },
      });
    }
  };

  return (
    <SearchSelect
      {...props}
      allowClear
      label={t('general.customer')}
      options={edges.map(({ node: { name, id } }) => ({
        title: name,
        value: id,
      }))}
      name='customer'
      loading={loading}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
    />
  );
};
