import styled from 'styled-components';
import { IonItemGroup } from '@ionic/react';

export const StyledUpload = styled.div`
  padding: 1em;
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const StyledIonItemGroup = styled(IonItemGroup)`
  padding: 1em;
`;
