import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Filesystem } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { v4 as uuidv4 } from 'uuid';
import L from 'leaflet';

import { ESTIMATED_TILE_SIZE_KB, MAP_DOWNLOADS_STORAGE_KEY, ZOOM_LEVELS_TO_DOWNLOAD } from '@src/global/Map';
import { promisedTimeout } from '@src/helpers/Async';
import { saveFile, checkOrCreateDirectory, FILESYSTEM_DIRECTORY } from '../File/File';
import { FilesystemRoutes } from '@src/global';

interface MapDownload {
  date: Date;
  id: string;
  name: string;
  tilesLoaded: number;
  tilesToLoad: number;
}

async function downloadTile(tileUrl: any) {
  try {
    const response = await fetch(tileUrl);

    if (!response.ok) {
      throw new Error();
    }

    return response.blob();
  } catch (error) {
    return error;
  }
}

export async function saveTile(tileInfo: any, blob: any) {
  const { z, x, y } = tileInfo;
  const fileName = `${z}-${x}-${y}.png`;

  await saveFile(blob, FilesystemRoutes.OFFLINE_TILES, fileName);
}

function getTilesForSaving(mapInstance: any, baseLayer: any) {
  let bounds;
  let tiles = [];

  const latlngBounds = mapInstance.getBounds();

  for (const zoomlevel of ZOOM_LEVELS_TO_DOWNLOAD) {
    bounds = L.bounds(
      mapInstance.project(latlngBounds.getNorthWest(), zoomlevel),
      mapInstance.project(latlngBounds.getSouthEast(), zoomlevel),
    );
    tiles = tiles.concat(baseLayer.getTileUrls(bounds, zoomlevel));
  }

  return tiles;
}

interface OfflineTilesHandlerContextProps {
  activeDownloadId: string | null;
  deleteDownloads: () => void;
  downloads: MapDownload[];
  downloadTiles: (name: string) => void;
  estimatedSize: string | null;
  loadTilesForDownload: () => void;
  setMapInstance: (map: any) => void;
  setOfflineLayer: (layer: any) => void;
}

export const OfflineMapTilesHandlerContext = React.createContext<OfflineTilesHandlerContextProps>({
  activeDownloadId: null,
  deleteDownloads: () => null,
  downloads: [],
  downloadTiles: () => null,
  estimatedSize: null,
  loadTilesForDownload: () => null,
  setMapInstance: () => null,
  setOfflineLayer: () => null,
});

export const OfflineMapTilesHandlerProvider = ({ children }) => {
  const mapInstance = useRef<any>(null);
  const offlineLayer = useRef<any>(null);
  const [downloads, setDownloads] = useState<MapDownload[]>([]);
  const [tilesForDownload, setTilesForDownload] = useState([]);
  const [activeDownloadId, setActiveDownLoadId] = useState<string | null>(null);

  async function clearTilesDB() {
    setActiveDownLoadId(null);
    await Filesystem.rmdir({
      directory: FILESYSTEM_DIRECTORY,
      path: FilesystemRoutes.OFFLINE_TILES,
      recursive: true,
    });
  }

  // SideEffect: initialize downloads from storage
  useEffect(() => {
    Preferences.get({ key: MAP_DOWNLOADS_STORAGE_KEY }).then(({ value }) => {
      if (value) setDownloads(JSON.parse(value));
    });
  }, []);

  // SideEffect: update storage on downloads change
  useEffect(() => {
    Preferences.set({ key: MAP_DOWNLOADS_STORAGE_KEY, value: JSON.stringify(downloads) });
  }, [downloads]);

  function deleteDownloads() {
    clearTilesDB();
    setDownloads([]);
  }

  async function saveTiles(tiles: any[], downloadId: string) {
    // We split the tiles into chunks to avoid blocking other parts of the app
    await checkOrCreateDirectory(FilesystemRoutes.OFFLINE_TILES);

    const chunks: any[][] = [];

    while (tiles.length) {
      const chunk: any[] = tiles.splice(0, 100);

      if (chunk.length) {
        chunks.push(chunk);
      }
    }

    for (const chunk of chunks) {
      const tilesInChunk = chunk.length;

      await promisedTimeout(100);

      // @ts-ignore
      await Promise.allSettled(
        chunk.map(async tile => {
          await loadTile(tile);
        }),
      );

      setDownloads(downloadsState =>
        downloadsState.map(download => {
          if (download.id !== downloadId) return download;

          return {
            ...download,
            tilesLoaded: download.tilesLoaded + tilesInChunk,
          };
        }),
      );
    }

    setActiveDownLoadId(null);
  }

  async function loadTile(tile: any) {
    return downloadTile(tile.url).then(async blob => {
      await saveTile(tile, blob);
    });
  }

  const setMapInstance = (map: any) => {
    mapInstance.current = map;
  };

  const setOfflineLayer = (layer: any) => {
    offlineLayer.current = layer;
  };

  const loadTilesForDownload = () => {
    const tiles = getTilesForSaving(mapInstance.current, offlineLayer.current);

    setTilesForDownload(tiles);
  };

  const downloadTiles = (name: string) => {
    const id = uuidv4();

    setDownloads([
      ...downloads,
      {
        id,
        name,
        date: new Date(),
        tilesToLoad: tilesForDownload.length,
        tilesLoaded: 0,
      },
    ]);
    setActiveDownLoadId(id);
    saveTiles(tilesForDownload, id);
  };

  const estimatedSize = useMemo(() => {
    const sizeInKb = tilesForDownload.length * ESTIMATED_TILE_SIZE_KB;
    const roundedSizeInMb = Math.ceil(sizeInKb / 1000 / 10) * 10;

    return `${roundedSizeInMb}MB`;
  }, [tilesForDownload]);

  return (
    <OfflineMapTilesHandlerContext.Provider
      value={{
        activeDownloadId,
        setMapInstance,
        setOfflineLayer,
        estimatedSize,
        loadTilesForDownload,
        downloadTiles,
        downloads,
        deleteDownloads,
      }}
    >
      {children}
    </OfflineMapTilesHandlerContext.Provider>
  );
};
