import React, { FC, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PrivateRoutes, Routes, PrivateRoutesWithPermission } from '@src/global';
import { MeContext } from '@src/components/Providers';
import { NotFound } from './Response/Http';
import { Login } from '@src/components/Pages/Public/Login';
import { Private } from '../Pages/Private';
import { Logout } from '@src/components/Pages/Public/Logout';
import { Unauthorized } from '@src/components/Pages/Response';

export const Pages: FC = () => {
  const { START, LOGIN, LOGOUT, PILES } = Routes;
  const { pathname } = useLocation();
  const { me: loggedIn, userHasPermissions } = useContext(MeContext);
  const privateRoutesPath = `(${PrivateRoutes.join('|')})`;

  return (
    <Switch>
      {!loggedIn && pathname === START && <Redirect to={LOGIN} />}
      {loggedIn && (pathname === START || pathname === LOGIN) && <Redirect to={PILES} />}
      <Route exact path={LOGIN} component={Login} />
      {loggedIn ? (
        PrivateRoutes.map(route => <Route key={`private-${route.toLowerCase()}`} path={route} component={Private} />)
      ) : (
        <Route key='private' path={privateRoutesPath} component={Unauthorized} />
      )}
      {loggedIn &&
        Object.keys(PrivateRoutesWithPermission)
          .filter(route => userHasPermissions(PrivateRoutesWithPermission[route]))
          .map(route => <Route key={`private-perm-${route.toLowerCase()}`} path={route} component={Private} />)}
      <Route exact path={LOGOUT} component={Logout} />
      <Route component={NotFound} />
    </Switch>
  );
};
