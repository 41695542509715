import styled from 'styled-components';
import { IonList } from '@ionic/react';
import { LoadingOutlined } from '@ant-design/icons';

export const SearchTitleWrapper = styled.div`
  padding-top: 10vh;
  display: flex;
  justify-content: space-between;
  padding-left: 3vw;
  padding-right: 3vw;
  
  h3 {
    display: inline-block;
  }
`;


export const StyledIonList = styled(IonList)`
  padding-bottom: 2em;
`;

export const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 2em;
  text-align: center;
  width: 100%;
`;

export const SelectedMultiOptionsWrapper = styled.div`
  margin: 1em;
  display: flex;
  flex-wrap: wrap;

  > button {
    margin-bottom: 0.5em;
    max-width: 100%;
    height: initial;
    align-items: center;
    display: flex;
    
    &:not(:last-child) {
      margin-right: 1em;
    }
   
    // first span is the X-button, so ignore it
    span:nth-child(2) {
      white-space: initial;
      text-align: left;
    }
  }
`;
