import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { PileNodeConnection } from '@src/generated/schema';
import { Pile } from '@src/graphql/Pile';
import { SearchSelectProps } from '@src/components/Elements/SearchSelect/SearchSelect';
import { MultiSelect } from '@src/components/Elements/MultiSelect';

export const StatusSelect: FC<Pick<SearchSelectProps, 'required'>> = props => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ pileStatus: PileNodeConnection }>(Pile.getPileStatus);
  const { pileStatus: { edges } = { edges: [] as any } } = data || {};

  return (
    <MultiSelect
      {...props}
      label={t('desktop.pages.piles.filter.status.title')}
      options={edges.map(({ node: { status } }) => ({
        title: t(`desktop.pages.piles.filter.status.${status}`),
        value: status.toLowerCase(),
      }))}
      name='status'
      loading={loading}
    />
  );
};
