import styled from 'styled-components';
import { StyledTable } from '../List/Table/Table.styles';

export const BROACHED_PILE_CELL_CLASS = 'broached-pile-cell';


export const StyledPlotDetailTable = styled(StyledTable)`
  .ant-table-title {
      font-size: 16px;
      font-weight: bold;
  }
  
  .${BROACHED_PILE_CELL_CLASS} {
    opacity: 0.9;
    background-color: #f0f0f0;
  }
`;
