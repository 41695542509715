import React, { FC, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Alert, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  InventoryNodeConnection,
  OrderNode,
  TimeNodeConnection,
  AccountNode,
  TimeSheetNodeConnection,
} from '@src/generated/schema/index';
import Moment from 'react-moment';
import { Pagination } from '@src/components/Desktop/Elements';
import { IonCol, IonSpinner, IonIcon, IonTitle } from '@ionic/react';
import { Modal } from '@src/components/Elements/Modal';
import { Account } from '@src/graphql/Account';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { ellipsisVertical } from 'ionicons/icons';
import { LoadingSpinnerIonRow } from '../List/Table/Table.styles';
import { StyledTable } from '@src/components/Elements/Table';
import { addRowClickHandler } from '@src/helpers/Table';
import { TimeSheetEdit } from './Edit';
import { PopoverRow } from './PopoverRow';
import { ColumnProps } from 'antd/es/table';

export enum TimeSheetModalEnum {
  TIME_SHEET_EDIT = 'timeSheetEdit',
}

export const TIME_SHEETS_PER_PAGE = 40;
export interface ShowProps {
  account: AccountNode | undefined;
  toggleModal: () => void;
}

export interface AccountTimeSheetResponse {
  inventories: InventoryNodeConnection;
  order: OrderNode;
  times: TimeNodeConnection;
}

export const Show: FC<ShowProps> = ({ account, ...props }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [queryVariables, setQueryVariables] = useState<any>({
    first: TIME_SHEETS_PER_PAGE,
  });
  const [selectedTimeSheet, setSelectedTimeSheet] = useState(undefined);
  const [showModal, setShowModal] = useState('');
  const [page, setPage] = useState<number>(1);

  const [getTimeSheets, { loading, error, data, refetch }] = useLazyQuery<{
    timeSheets: TimeSheetNodeConnection;
  }>(Account.timeSheets, {
    variables: { user: account?.id, ...queryVariables },
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    onError: () => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.timeSheets.show.error.message'),
        description: t('desktop.pages.timeSheets.show.error.description'),
      });
    },
  });

  useEffect(() => {
    getTimeSheets();
  }, [queryVariables, getTimeSheets]);

  const { timeSheets: { edges } = { edges: [] as any } } = data || {};
  const timeSheets = edges.map(({ node }) => node);

  const toggleModal = (modalType, timeSheet) => {
    setSelectedTimeSheet(timeSheet);
    setShowModal(modalType);
  };

  const onDismissTimeSheetModal = () => {
    setShowModal('');
    setSelectedTimeSheet(undefined);
  };

  const onCellClicked = (e, record, index?: number | null) => {
    if (index !== null && index !== undefined) {
      setSelectedTimeSheet(timeSheets[index]);
      setShowModal(TimeSheetModalEnum.TIME_SHEET_EDIT);
    }
  };

  // delete timesheets
  const [deleteTimeSheet, { error: deleteTimeSheetError }] = useMutation(Account.deleteTimeSheet, {
    refetchQueries: ['timeSheets'],
  });

  const handleDeleteTimeSheet = async (timesheet: string) => {
    try {
      const { data: dataDelete } = await deleteTimeSheet({
        variables: { timesheet },
      });
      if (dataDelete) {
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.timeSheets.delete.form.submit.success.message'),
          description: t('desktop.pages.timeSheets.delete.form.submit.success.description'),
        });
      }
    } catch (error) {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.timeSheets.show.error.message'),
        description: t('desktop.pages.timeSheets.show.error.description'),
      });
    }
  };

  let columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.accounts.show.columns.checkInCreatedAt'),
      dataIndex: 'checkInCreatedAt',
      render: text => <Moment format='DD.MM.YYYY, hh:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.accounts.show.columns.checkInDispatchedAt'),
      dataIndex: 'checkInDispatchedAt',
      render: text => <Moment format='DD.MM.YYYY, hh:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.accounts.show.columns.checkOutCreatedAt'),
      dataIndex: 'checkOutCreatedAt',
      render: text =>
        text ? (
          <Moment format='DD.MM.YYYY, HH:mm'>{text}</Moment>
        ) : (
          t('desktop.pages.accounts.show.columns.noCheckoutYet')
        ),
    },
    {
      title: t('desktop.pages.accounts.show.columns.checkOutDispatchedAt'),
      dataIndex: 'checkOutDispatchedAt',
      render: text =>
        text ? (
          <Moment format='DD.MM.YYYY, HH:mm'>{text}</Moment>
        ) : (
          t('desktop.pages.accounts.show.columns.noCheckoutYet')
        ),
    },
    {
      title: t('general.createdBy'),
      dataIndex: 'createdBy',
      render: createdBy => `${createdBy.firstName} ${createdBy.lastName}`,
    },
    {
      title: t('general.lastModifiedBy'),
      dataIndex: 'lastmodifiedBy',
      render: lastModifiedBy => `${lastModifiedBy.firstName} ${lastModifiedBy.lastName}`,
    },
    {
      title: () => <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />,
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record: any) => (
        <Popover
          placement='leftTop'
          content={
            <PopoverRow
              toggleModal={toggleModal}
              timesheet={record}
              handleDeleteTimeSheet={handleDeleteTimeSheet}
              error={deleteTimeSheetError}
            />
          }
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  // add onCell click handler to each column except the action column
  columns = addRowClickHandler(columns, onCellClicked);

  return (
    <>
      <Modal
        level={2}
        title={t('desktop.pages.account.show.timeSheetEdit.title')}
        visible={showModal === TimeSheetModalEnum.TIME_SHEET_EDIT}
        onCancel={onDismissTimeSheetModal}
        tabComponents={[
          {
            tabName: t('desktop.pages.orders.show.inventoryEdit.title'),
            component: <TimeSheetEdit closeModal={onDismissTimeSheetModal} timeSheet={selectedTimeSheet} />,
          },
        ]}
      />

      {error && !data && (
        <Alert
          message={t('desktop.pages.accounts.list.error.message')}
          description={t('desktop.pages.accounts.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <LoadingSpinnerIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </LoadingSpinnerIonRow>
      )}

      {data &&
        (edges?.length > 0 ? (
          <>
            <StyledTable
              title={() => t('desktop.pages.accounts.show.timeSheets.title')}
              {...props}
              columns={columns}
              dataSource={timeSheets}
              rowKey='id'
              pagination={false}
            />

            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.timeSheets && data.timeSheets.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <button onClick={() => refetch()}>{t('general.buttonRefetch')}</button>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.accounts.show.noTimesheetsAvailable')}</IonTitle>
        ))}
    </>
  );
};
