/* WARNING: use with caution as imperative dom manipulations are an anti pattern in react
Due to some difficulties/bugs with a combination of antd components */

/* A hack to close antd popups that are uncontrolled and can not be controlled
due to the usage inside a fixed column table that renders itself multiple times 
Ask Kai Nordmann for more information */
export const closePopups = () => {
  const popovers = document.querySelectorAll('.popover-actions');
  popovers.forEach(el => {
    el.classList.add('ant-popover-hidden');
  });
};
