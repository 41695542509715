import styled from 'styled-components';
import { Typography } from 'antd';

export const StyledAddPileToOrderHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAddPileToOrderHeadingLabel = styled(Typography.Text)`
  margin-bottom: 1rem;
`;
