import { gql } from 'apollo-boost';

export const exportInventoriesTowardsWagon = gql`
  mutation downloadWagonInventory($delivery_status_id: ID!) {
    downloadWagonInventory(deliveryStatusId: $delivery_status_id) {
      ok
      url
    }
  }
`;

export const createInventory = gql`
  mutation createInventory(
    $order: ID!
    $pile: ID!
    $amount: Float!
    $isBroached: Boolean!
    $rest: Int
    $count: Int
    $time: String!
    $inventoryDetails: [InventoryDetailType]
    $accuracy: Decimal
    $longitude: Decimal
    $latitude: Decimal
  ) {
    createInventory(
      order: $order
      pile: $pile
      amount: $amount
      isBroached: $isBroached
      rest: $rest
      count: $count
      time: $time
      inventoryDetails: $inventoryDetails
      accuracy: $accuracy
      longitude: $longitude
      latitude: $latitude
    ) {
      inventory {
        id
      }
    }
  }
`;

export const updateInventory = gql`
  mutation editInventory(
    $inventoryId: ID!
    $amount: Float!
    $isBroached: Boolean!
    $rest: Int
    $count: Int
    $inventoryDetails: [InventoryDetailType]
    $accuracy: Float
    $longitude: Float
    $latitude: Float
  ) {
    editInventory(
      inventoryId: $inventoryId
      amount: $amount
      isBroached: $isBroached
      rest: $rest
      count: $count
      inventoryDetails: $inventoryDetails
      accuracy: $accuracy
      longitude: $longitude
      latitude: $latitude
    ) {
      inventory {
        id
      }
    }
  }
`;

export const deleteInventory = gql`
  mutation deleteInventory($inventoryId: ID!) {
    deleteInventory(inventoryId: $inventoryId) {
      ok
    }
  }
`;

export const createWagonInventory = gql`
  mutation createWagonInventory(
    $order: ID!
    $wagonNumber: Int!
    $isWagonReady: Boolean!
    $amount: Float!
    $count: Int!
    $time: String!
    $inventoryDetails: [InventoryDetailType]
    $accuracy: Decimal
    $longitude: Decimal
    $latitude: Decimal
    $loadEverything: Boolean
  ) {
    createWagonInventory(
      order: $order
      amount: $amount
      count: $count
      time: $time
      wagonNumber: $wagonNumber
      isWagonReady: $isWagonReady
      inventoryDetails: $inventoryDetails
      accuracy: $accuracy
      longitude: $longitude
      latitude: $latitude
      loadEverything: $loadEverything
    ) {
      inventories {
        id
      }
    }
  }
`;
