// @ts-nocheck
import L from 'leaflet';

// eslint-disable-next-line import/no-extraneous-dependencies
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';
import 'lrm-graphhopper';
import { isPlatform } from '@ionic/react';

const createRoutineMachineLayer = props => {
  const waypoints = props.routeCoordinates.map(coordinate => [coordinate.latitude, coordinate.longitude]);
  const graphHopperRoute = new L.Routing.graphHopper(props.graphhopperApiKey, {
    urlParameters: { vehicle: 'bike' },
    serviceUrl: 'https://graphhopper.com/api/1/route',
  });

  return L.Routing.control({
    waypoints,
    router: graphHopperRoute,
    createMarker: () => null,
    show: false,
    addWaypoints: false,
    routeWhileDragging: false,
    draggableWaypoints: false,
    fitSelectedRoutes: false,
    showAlternatives: false,
  }).on('routesfound', e => {
    if (isPlatform('hybrid')) {
      props.addRoutePath(props.orderId, { waypoints, coordinates: e.routes[0].coordinates });
    }
  });
};

export const RoutingMachine = createControlComponent(createRoutineMachineLayer);
