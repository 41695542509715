import styled from 'styled-components';
import { IonButton } from '@ionic/react';

export const StyledIonButton = styled(IonButton)`
  --padding-start: 2.8rem;
  --padding-end: 2.8rem;
  --padding-top: 2rem;
  --padding-bottom: 2em;
  --border-radius: ${({ theme }) => theme.global.borderRadius * 2}px;
  height: 3.8em;
  font-size: 1rem;
  margin: 0;
  text-transform: initial;
`;
