import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Input } from '@src/components/Desktop/Containers/Input';
import { Form as FormWrapper, Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { Column } from '@src/components/Desktop/Elements/Wrapper/Form/Column';
import { SubmitButton } from 'formik-antd';
import { isFieldRequired } from '@src/helpers';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';
import {
  AmountUnitChoice,
  LocationTypeChoice,
  OldSystemStatusChoice,
  PileTypeChoice,
  QualityTypeChoice,
  UsageSortChoice,
} from '@src/generated/schema';
import { PlotSelect } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form/PlotSelect';
import { SearchSelect } from '@src/components/Elements/SearchSelect';
import { TextArea } from '@src/components/Desktop/Containers/TextArea';
import { DatePicker } from '@src/components/Desktop/Containers/DatePicker';
import { StyledCheckbox } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form/Form.styles';
import { Button } from 'antd';
import { getPileValidationSchema } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form/validationSchema';

export interface FormValues {
  amountUnit: AmountUnitChoice | string | undefined;
  category: string | undefined;
  cbkNet: string | undefined;
  cbkSolid: string | undefined;
  count: number | undefined;
  date: Date | undefined;
  district: string | undefined;
  factorCbkNetCbkSolid: string | undefined;
  latitude: string | undefined;
  location: string | undefined;
  locationPlan: string | undefined;
  locationType: LocationTypeChoice | string | undefined;
  logLength: string | undefined;
  longitude: string | undefined;
  markedAsBroached: boolean | undefined;
  mustBeAccepted: boolean | undefined;
  note: string | undefined;
  number: string | undefined;
  pileNumberFurnisher: string | undefined;
  plotNumber: number | undefined;
  qualityType: QualityTypeChoice | string | undefined;
  sort: string | undefined;
  status: OldSystemStatusChoice | string | undefined;
  timber: string | undefined;
  type: PileTypeChoice | string | undefined;
  usageSort: UsageSortChoice | string | undefined;
  woodtype: string | undefined;
  woodtype2: string | undefined;
}

export interface FormProps {
  deleteLoading?: boolean;
  error?: ApolloError;
  initialFormValues?: FormValues;
  onDelete?: () => Promise<false | undefined>;
  onSubmit: (variables: FormValues) => Promise<false | undefined>;
  passwordOptional?: boolean;
}

export const Form: FC<FormProps> = ({ error, initialFormValues, onSubmit, onDelete, deleteLoading }) => {
  const { t } = useTranslation();

  const initialValues: FormValues = initialFormValues || {
    cbkNet: undefined,
    cbkSolid: undefined,
    category: undefined,
    logLength: undefined,
    longitude: undefined,
    latitude: undefined,
    factorCbkNetCbkSolid: '0.6',
    count: undefined,
    date: undefined,
    district: undefined,
    location: undefined,
    locationPlan: undefined,
    markedAsBroached: undefined,
    note: undefined,
    number: undefined,
    pileNumberFurnisher: undefined,
    plotNumber: undefined,
    sort: undefined,
    status: undefined,
    type: undefined,
    woodtype: undefined,
    woodtype2: undefined,
    usageSort: undefined,
    amountUnit: undefined,
    locationType: undefined,
    qualityType: undefined,
    timber: undefined,
    mustBeAccepted: undefined,
  };

  const validationSchema = getPileValidationSchema(t);
  const handleSubmit = async (values: FormValues) => onSubmit(values);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      validateOnBlur
    >
      {({ isValid }) => (
        <FormWrapper>
          <h3>{t('desktop.pages.piles.add.form.title.general')}</h3>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.number')}
                type='text'
                name='number'
                required={isFieldRequired('number', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.pileNumberFurnisher')}
                type='text'
                name='pileNumberFurnisher'
                maxLength={50}
                required={isFieldRequired('pileNumberFurnisher', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <StyledCheckbox name='markedAsBroached'>{t('general.pile.markedAsBroached')}</StyledCheckbox>
              <StyledCheckbox name='mustBeAccepted'>{t('general.pile.mustBeAccepted')}</StyledCheckbox>
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <SearchSelect
                name='status'
                options={Object.values(OldSystemStatusChoice).map(status => ({
                  value: status,
                  title: t(`general.pile.status.${status}`),
                }))}
                label={t('general.pile.status.title')}
                required={isFieldRequired('status', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <SearchSelect
                name='type'
                required={isFieldRequired('type', validationSchema)}
                options={Object.values(PileTypeChoice).map(type => ({
                  value: type,
                  title: t(`general.pile.type.${type}`),
                }))}
                label={t('general.pile.type.title')}
              />
            </Column>
            <Column lg={8}>
              <PlotSelect
                required={isFieldRequired('plotNumber', validationSchema)}
                prefilledValue={initialFormValues?.plotNumber}
              />
            </Column>
            <Column lg={8}>
              <SearchSelect
                name='locationType'
                options={Object.values(LocationTypeChoice).map(locationType => ({
                  value: locationType,
                  title: t(`general.pile.locationType.${locationType}`),
                }))}
                label={t('general.pile.locationType.title')}
                required={isFieldRequired('locationType', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.sort')}
                type='text'
                name='sort'
                maxLength={15}
                required={isFieldRequired('sort', validationSchema)}
              />
            </Column>
          </Row>
          <h3>{t('desktop.pages.piles.add.form.title.location')}</h3>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.location')}
                type='text'
                name='location'
                maxLength={50}
                required={isFieldRequired('location', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.latitude')}
                type='text'
                name='latitude'
                required={isFieldRequired('latitude', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.longitude')}
                type='text'
                name='longitude'
                required={isFieldRequired('longitude', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.district')}
                type='text'
                name='district'
                maxLength={50}
                required={isFieldRequired('district', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.locationPlan')}
                type='text'
                name='locationPlan'
                required={isFieldRequired('locationPlan', validationSchema)}
              />
            </Column>
          </Row>
          <h3>{t('desktop.pages.piles.add.form.title.wood')}</h3>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.cbkNet')}
                type='text'
                name='cbkNet'
                required={isFieldRequired('cbkNet', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.cbkSolid')}
                type='text'
                name='cbkSolid'
                required={isFieldRequired('cbkSolid', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.factorCbkNetCbkSolid')}
                type='text'
                name='factorCbkNetCbkSolid'
                required={isFieldRequired('factorCbkNetCbkSolid', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.logLength')}
                type='text'
                name='logLength'
                required={isFieldRequired('logLength', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.woodtype')}
                type='text'
                name='woodtype'
                maxLength={15}
                required={isFieldRequired('woodtype', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.woodtype2')}
                type='text'
                name='woodtype2'
                maxLength={15}
                required={isFieldRequired('woodtype2', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <SearchSelect
                name='amountUnit'
                options={Object.values(AmountUnitChoice).map(amountUnit => ({
                  value: amountUnit,
                  title: t(`general.pile.amountUnit.${amountUnit}`),
                }))}
                label={t('general.pile.amountUnit.title')}
                required={isFieldRequired('amountUnit', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <SearchSelect
                name='usageSort'
                options={Object.values(UsageSortChoice).map(usageSort => ({
                  value: usageSort,
                  title: usageSort,
                }))}
                label={t('general.pile.usageSort.title')}
                required={isFieldRequired('usageSort', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <SearchSelect
                name='qualityType'
                options={Object.values(QualityTypeChoice).map(qualityType => ({
                  value: qualityType,
                  title: qualityType,
                }))}
                label={t('general.pile.qualityType.title')}
                required={isFieldRequired('qualityType', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.count')}
                type='number'
                name='count'
                required={isFieldRequired('count', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <Input
                label={t('general.pile.category')}
                type='text'
                name='category'
                maxLength={50}
                required={isFieldRequired('category', validationSchema)}
              />
            </Column>
            <Column lg={8}>
              <DatePicker
                showTime={false}
                name='date'
                required={isFieldRequired('date', validationSchema)}
                label={t('general.pile.date')}
              />
            </Column>
          </Row>
          <Row>
            <Column lg={8}>
              <Input
                label={t('general.pile.timber')}
                type='text'
                name='timber'
                required={isFieldRequired('timber', validationSchema)}
              />
            </Column>
          </Row>
          <Row>
            <TextArea
              type='text'
              label={t('general.pile.note')}
              name='note'
              required={isFieldRequired('note', validationSchema)}
            />
          </Row>

          <Row justify='space-between'>
            <SubmitButton type='primary' disabled={!isValid}>
              {t('desktop.pages.accounts.form.submit')}
            </SubmitButton>
            <Button loading={deleteLoading} onClick={onDelete} type='primary' danger disabled={false}>
              {t('desktop.pages.piles.delete.form.delete')}
            </Button>
          </Row>
          {error && <APIError errors={error} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
