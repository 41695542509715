import styled from 'styled-components';
import { Table, Button } from 'antd';
import { IonRow } from '@ionic/react';
import { CompaniesSearch } from '@src/components/Elements/CompaniesSearch';

export const StyledTable = styled(Table)`
  .ant-pagination {
    float: initial !important;
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  display: block !important;
  margin: 2em auto;
`;

export const LoadingSpinnerIonRow = styled(IonRow)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%), translateY(-50%);
`;

export const StyledCompaniesSearch = styled(CompaniesSearch)`
  max-width: 300px;
  margin-bottom: 2rem;
`;
