import styled, { css } from 'styled-components';
import { Modal } from 'antd';
import { BREAKPOINTS } from '@src/theme';
import { ButtonLinkStyle, SelectedButtonLinkStyle } from '@src/components/Elements/ButtonLink/ButtonLink.styles';

export const mapModalStyles = css`
  .ant-modal-body {
    padding: 0;
    overflow: hidden !important;
    position: relative;
  }
`;

const level1ModalStyles = css`
  max-width: ${BREAKPOINTS.xxlarge}px;
`;

const level2ModalStyles = css`
  max-width: ${BREAKPOINTS.xlarge}px;
`;

const level3ModalStyles = css`
  max-width: ${BREAKPOINTS.large}px;
`;

export const StyledModal = styled(Modal)<{ isMap: boolean, level: 1 | 2 | 3 }>`
  && {
    ${({ level }) => level === 1 && level1ModalStyles}
    ${({ level }) => level === 2 && level2ModalStyles}
    ${({ level }) => level === 3 && level3ModalStyles}
    
    width: calc(100% - 4rem) !important;
    padding-bottom: 0;
    overflow: hidden;
    top: 4rem;

    .ant-modal-wrap {
      overflow: hidden;
    }

    .ant-modal-body {
      max-height: calc(100vh - 15rem);
      overflow: auto;
    }

    @media (max-width: ${BREAKPOINTS.medium}px) {
      width: 100% !important;
      height: 100%;
      top: 0 !important;
      margin: 0 !important;

      .ant-modal-body {
        max-height: calc(100vh - 3rem);
      }
    }
  }
  ${({ isMap }) => isMap && mapModalStyles}
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const StyledModalTab = styled.button<{ selected: boolean }>`
  ${ButtonLinkStyle}
  ${({ selected }) => selected && SelectedButtonLinkStyle}
`;

export const StyledModalHeadline = styled.h3`
  margin: 0;
  flex: 1;
`;

export const EmptyStyleElement = styled.div`
  flex: 1;
`;
