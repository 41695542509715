import gql from 'graphql-tag';

export const getInventoriesByDeliveryStatusTowardsWagon = gql`
  query inventoriesByDeliveryStatusTowardsWagon($delivery_status_id: ID) {
    inventoriesByDeliveryStatusTowardsWagon(deliveryStatusId: $delivery_status_id) {
      plotNumber
      wagonNumber
      orderId
      orderNumber
      allAmount
      allCount
      isWagonReady
      salesNumber
    }
  }
`;
