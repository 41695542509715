import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Input } from '@src/components/Desktop/Containers/Input';
import { Form as FormWrapper, Row } from '@src/components/Desktop/Elements/Wrapper/Form';
import { Column } from '@src/components/Desktop/Elements/Wrapper/Form/Column';
import { SubmitButton } from 'formik-antd';
import { isFieldRequired } from '@src/helpers';
import { MAXIMUM_PASSWORD_LENGTH, MINIMUM_PASSWORD_LENGTH, MINIMUM_PASSWORD_STRENGTH } from '@src/global';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';
import { GroupsSelect } from '@src/components/Desktop/Pages/Private/Accounts/Edit/Form/GroupsSelect';

export interface FormValues {
  email: string | undefined;
  firstName: string | undefined;
  groups: string[] | undefined;
  lastName: string | undefined;
  password: string | undefined;
  passwordRepeat: string | undefined;
}

export interface FormProps {
  error?: ApolloError;
  initialFormValues?: FormValues;
  onSubmit: (variables: FormValues) => Promise<false | undefined>;
  passwordOptional?: boolean;
}

export interface PrefilledFormValues {
  email: string;
  firstName: string;
  groups: string[];
  lastName: string;
}

export const Form: FC<FormProps> = ({ error, initialFormValues, onSubmit, passwordOptional }) => {
  const { t } = useTranslation();

  const initialValues: FormValues = initialFormValues || {
    password: undefined,
    passwordRepeat: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    groups: [],
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';
  const passwordValidation = passwordOptional
    ? {
        password: Yup.string()
          .min(MINIMUM_PASSWORD_LENGTH, t('pages.accounts.form.passwordLength'))
          .max(MAXIMUM_PASSWORD_LENGTH, t('pages.accounts.form.passwordLength'))
          .matches(MINIMUM_PASSWORD_STRENGTH, t('pages.accounts.form.passwordTooWeak')),
        passwordRepeat: Yup.string().when(['password'], {
          is: val => val === '' || !val,
          then: Yup.string(),
          otherwise: Yup.string()
            .oneOf([Yup.ref('password')], t('pages.changePassword.input.newPasswordConfirm.errors.notEqual'))
            .required(t(i18nFormFieldRequired, { field: t('general.passwordRepeat') })),
        }),
      }
    : {
        password: Yup.string()
          .required(t(i18nFormFieldRequired, { field: t('general.password') }))
          .min(MINIMUM_PASSWORD_LENGTH, t('pages.accounts.form.passwordLength'))
          .max(MAXIMUM_PASSWORD_LENGTH, t('pages.accounts.form.passwordLength'))
          .matches(MINIMUM_PASSWORD_STRENGTH, t('pages.accounts.form.passwordTooWeak')),
        passwordRepeat: Yup.string()
          .required(t(i18nFormFieldRequired, { field: t('general.passwordRepeat') }))
          .when(['password'], {
            is: val => val === '' || !val,
            then: Yup.string()
              .oneOf([Yup.ref('password')], t('pages.changePassword.input.newPasswordConfirm.errors.notEqual'))
              .notRequired(),
            otherwise: Yup.string()
              .oneOf([Yup.ref('password')], t('pages.changePassword.input.newPasswordConfirm.errors.notEqual'))
              .required(t(i18nFormFieldRequired, { field: t('general.passwordRepeat') })),
          }),
      };

  const validationSchema = Yup.object().shape({
    ...passwordValidation,
    firstName: Yup.string().required(t(i18nFormFieldRequired, { field: t('general.firstName') })),
    lastName: Yup.string().required(t(i18nFormFieldRequired, { field: t('general.lastName') })),
    email: Yup.string()
      .email(t('pages.accounts.form.invalidEmail'))
      .required(t(i18nFormFieldRequired, { field: t('general.email') })),
    groups: Yup.array().of(Yup.string()),
  });

  const handleSubmit = async (values: FormValues) => onSubmit(values);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      validateOnBlur
    >
      {({ isValid, values }) => (
        <FormWrapper>
          <Row>
            <Column>
              <Input
                autoComplete='email'
                label={t('general.email')}
                type='email'
                name='email'
                required={isFieldRequired('email', validationSchema)}
              />
            </Column>
            <Column>
              <GroupsSelect required={isFieldRequired('groups', validationSchema)} />
            </Column>
          </Row>

          <Row>
            <Column>
              <Input
                autoComplete='cc-given-name'
                label={t('general.firstName')}
                type='text'
                name='firstName'
                required={isFieldRequired('firstName', validationSchema)}
              />
            </Column>
            <Column>
              <Input
                autoComplete='cc-family-name'
                label={t('general.lastName')}
                type='text'
                name='lastName'
                required={isFieldRequired('lastName', validationSchema)}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Input
                autoComplete='new-password'
                label={t('general.password')}
                type='password'
                name='password'
                required={isFieldRequired('password', validationSchema)}
                helpText={passwordOptional ? t('desktop.pages.accounts.form.passwordOptional.helpText') : undefined}
              />
            </Column>
            <Column>
              <Input
                autoComplete='new-password'
                label={t('general.passwordRepeat')}
                type='password'
                name='passwordRepeat'
                required={
                  isFieldRequired('passwordRepeat', validationSchema) || !!(values.password && passwordOptional)
                }
              />
            </Column>
          </Row>

          <Row justify='space-between'>
            <SubmitButton type='primary' disabled={!isValid}>
              {t('desktop.pages.accounts.form.submit')}
            </SubmitButton>
          </Row>
          {error && <APIError errors={error} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
