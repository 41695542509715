import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form } from '../Form';
import { FormValues } from '../Form/Form';
import { TestIds } from '@src/global';
import { useMutation } from 'react-apollo';
import { Order } from '@src/graphql/Order';
import { PileNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { noVehicle } from '@src/components/Desktop/Containers/SearchSelects';

export interface AddProps {
  closeModal: () => void;
  piles?: PileNode[];
}

export const Add: FC<AddProps> = ({ piles, closeModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [createOrder, { error }] = useMutation(Order.createOrder);

  const selectedPileIds = piles?.map(pile => pile.id);

  const onSubmit = async (variables: FormValues) => {
    try {
      const { vehicle } = variables;
      const { data: data_ } = await createOrder({
        variables: {
          ...variables,
          piles: selectedPileIds,
          vehicle_Isnull: vehicle === noVehicle ? true : null,
          vehicle: vehicle === noVehicle ? '' : vehicle,
        },
        refetchQueries: ['orders'],
      });

      if (data_) {
        closeModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.orders.add.form.success.message'),
          description: t('desktop.pages.orders.add.form.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  return (
    <div data-testid={TestIds.pages.orders.add.identifier}>
      <Wrapper>
        <Form showSupplier={!selectedPileIds} initialOrderValues={undefined} onSubmit={onSubmit} error={error} />
      </Wrapper>
    </div>
  );
};
