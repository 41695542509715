import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form as FormWrapper, Row, Column } from '@src/components/Desktop/Elements/Wrapper/Form';
import { SubmitButton } from 'formik-antd';
import { isFieldRequired } from '@src/helpers';
import { Switch } from '@src/components/Desktop/Containers/Switch/Switch';
import moment from 'moment';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import { ApolloError } from 'apollo-client';
import { SearchSelect } from '@src/components/Elements/SearchSelect';
import { DeliveryStatusPlanType, DeliveryStatusType, DeliveryStatusUnit } from '@src/generated/schema';
import { DatePicker } from '@src/components/Desktop/Containers/DatePicker';
import { Input } from '@src/components/Desktop/Containers/Input';
import { CompanySelect } from '@src/components/Desktop/Containers/SearchSelects';
import { CompanyLocationSelect } from '@src/components/Desktop/Containers/SearchSelects/CompanyLocationSelect/CompanyLocationSelect';

export interface FormValues {
  allowTemporaryStorage?: boolean;
  amount?: number;
  customer?: string;
  dateBegin?: Date | string;
  dateEnd?: Date | string;
  deliveryAddress?: string;
  planType: DeliveryStatusPlanType;
  shortDescription?: string;
  type?: DeliveryStatusType;
  unit?: DeliveryStatusUnit;
}

export interface FormProps {
  error?: ApolloError;
  initialFormValues?: FormValues;
  onSubmit: (variables: FormValues) => Promise<void>;
  prefilledValues?: PrefilledFormValues;
}

export interface PrefilledFormValues {
  customer: string;
  fullAddress: string;
}

export const Form: FC<FormProps> = ({ error, onSubmit, prefilledValues, initialFormValues }) => {
  const { t } = useTranslation();

  const initialValues: FormValues = initialFormValues || {
    allowTemporaryStorage: false,
    amount: undefined,
    customer: undefined,
    dateBegin: undefined,
    dateEnd: undefined,
    deliveryAddress: undefined,
    shortDescription: undefined,
    type: undefined,
    unit: undefined,
    planType: DeliveryStatusPlanType.Single,
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';
  const validationSchema = Yup.object().shape({
    allowTemporaryStorage: Yup.boolean().required(
      t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.allowTemporaryStorage') }),
    ),
    amount: Yup.number().required(
      t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.amount') }),
    ),
    customer: Yup.string().required(
      t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.customer') }),
    ),
    dateBegin: Yup.date()
      .default(new Date())
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.dateBegin') })),
    dateEnd: Yup.date()
      .default(new Date())
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.dateEnd') }))
      .when(
        'dateBegin',
        (dateBegin, schema) =>
          dateBegin &&
          schema.min(
            moment(dateBegin).add(1, 'day'),
            t('desktop.pages.deliveryStatus.add.validation.dateEndAfterDateStart'),
          ),
      ),
    deliveryAddress: Yup.string(),

    shortDescription: Yup.string().test(
      'len',
      t('desktop.pages.deliveryStatus.add.validation.shortDescriptionLength'),
      val => !val || (val && val.length <= 30),
    ),
    type: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.type') }))
      .oneOf(Object.values(DeliveryStatusType)),
    unit: Yup.string()
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.unit') }))
      .oneOf(Object.values(DeliveryStatusUnit)),
  });

  const handleSubmit = async (values: FormValues) =>
    onSubmit({
      ...values,
      planType: DeliveryStatusPlanType.Single,
    });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, values }) => (
        <FormWrapper>
          <Row>
            <Column>
              <SearchSelect
                label={t('desktop.pages.deliveryStatus.list.columns.unit')}
                options={Object.keys(DeliveryStatusUnit).map(unitVal => ({
                  title: t(`general.deliveryStatusUnit.${DeliveryStatusUnit[unitVal]}`),
                  value: DeliveryStatusUnit[unitVal],
                }))}
                name='unit'
                required={isFieldRequired('unit', validationSchema)}
                loading={false}
              />
            </Column>
            <Column>
              <SearchSelect
                label={t('desktop.pages.deliveryStatus.list.columns.type')}
                options={Object.values(DeliveryStatusType).map(typeVal => ({
                  title: t(`general.deliveryStatusType.${typeVal}`),
                  value: typeVal,
                }))}
                required={isFieldRequired('type', validationSchema)}
                name='type'
                loading={false}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <DatePicker
                showTime={false}
                name='dateBegin'
                required={isFieldRequired('dateBegin', validationSchema)}
                label={t('desktop.pages.deliveryStatus.list.columns.dateBegin')}
              />
            </Column>
            <Column>
              <DatePicker
                showTime={false}
                name='dateEnd'
                required={isFieldRequired('dateEnd', validationSchema)}
                label={t('desktop.pages.deliveryStatus.list.columns.dateEnd')}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                maxLength={30}
                name='shortDescription'
                required={isFieldRequired('shortDescription', validationSchema)}
                label={t('desktop.pages.deliveryStatus.list.columns.shortDescription')}
                type='text'
              />
            </Column>
            <Column>
              <Input
                name='amount'
                required={isFieldRequired('amount', validationSchema)}
                label={t('desktop.pages.deliveryStatus.list.columns.amount')}
                type='number'
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <CompanySelect
                required={isFieldRequired('customer', validationSchema)}
                prefilledValue={prefilledValues?.customer}
              />
            </Column>
            <Column>
              {values.customer && (
                <CompanyLocationSelect
                  company={values.customer}
                  required={isFieldRequired('deliveryAddress', validationSchema)}
                  prefilledValue={prefilledValues?.fullAddress}
                />
              )}
            </Column>
          </Row>
          <Row>
            <Column>
              <Switch
                name='allowTemporaryStorage'
                required={isFieldRequired('allowTemporaryStorage', validationSchema)}
                label={t('desktop.pages.deliveryStatus.list.columns.allowTemporaryStorage')}
              />
            </Column>
          </Row>

          <Row justify='space-between'>
            <SubmitButton type='primary' disabled={!isValid}>
              {t('desktop.pages.deliveryStatus.add.submit')}
            </SubmitButton>
          </Row>
          {error && <APIError errors={error} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
