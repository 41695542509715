import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import i18next from 'i18next';

export enum TOAST_TYPES {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  IONIC = 'ionic',
  IONIC_HIDE = 'ionicHidden',
}

interface ToastConfig extends Pick<ArgsProps, 'description' | 'message'> {
  type: TOAST_TYPES;
}

interface IonToastConfig {
  message?: string;
  show: boolean;
}

const defaultToast: ToastConfig = {
  description: i18next.t('toasts.defaults.error.description'),
  message: i18next.t('toasts.defaults.error.message'),
  type: TOAST_TYPES.ERROR,
};

const ionDefaultToast: IonToastConfig = {
  show: false,
};

export interface ToastContextProps {
  ionToast: IonToastConfig;
  setIonToast: Dispatch<SetStateAction<IonToastConfig>>;
  setToast: Dispatch<SetStateAction<ToastConfig>>;
  toast: ToastConfig;
}

export const ToastContext = React.createContext<ToastContextProps>({
  ionToast: ionDefaultToast,
  setIonToast: () => undefined,
  setToast: () => undefined,
  toast: defaultToast,
});

const displayToast = ({ description, message, type }: ToastConfig) => {
  notification[type]({
    message,
    description,
  });
};

export const ToastProvider: FC = ({ children }) => {
  const [toast, setToast] = useState<ToastConfig>(defaultToast);
  const [ionToast, setIonToast] = useState<IonToastConfig>(ionDefaultToast);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return undefined;
    }
    displayToast(toast);
  }, [toast]);

  return <ToastContext.Provider value={{ setToast, toast, ionToast, setIonToast }}>{children}</ToastContext.Provider>;
};
