import styled from 'styled-components';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { transparentize } from 'polished';

export const StyledIonItem = styled(IonItem)`
  --background-focused: ${({ theme }) => theme.color.white};
  --highlight-height: 0;
  --border-radius: ${({ theme }) => theme.global.borderRadius}px;
  --border-style: solid;
  --border-width: ${({ theme }) => theme.global.borderWidth}px;
  --border-color: ${({ theme }) => transparentize(0.5, theme.color.doveGray)};
  --inner-border-width: 0;
  height: 3.8em;
  overflow: visible;

  &.ion-focused {
    --border-color: ${({ theme }) => theme.global.brandColor};
  }
`;

export const StyledIonLabel = styled(IonLabel)`
  position: absolute;
  top: -1.46rem;
  padding: 0 0.2em;
  font-size: 1.15rem !important;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.global.color} !important;
`;

export const StyledIonInput = styled(IonInput)`
  --color: ${({ theme }) => theme.global.color};
  font-size: 1rem;
`;
