import React, { FC } from 'react';
import { FormItem, FormItemProps, Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'formik-antd';

interface SwitchProps extends Omit<AntSwitchProps, 'name'>, Pick<FormItemProps, 'label' | 'name'> {
  required: boolean;
}

export const Switch: FC<SwitchProps> = ({ required, name, label, ...props }) => (
  <FormItem labelCol={{ span: 24 }} label={label} name={name} required={required}>
    <AntSwitch name={name} {...props} />
  </FormItem>
);
