import {
  StyledCustomMapControls,
  StyledPilesSearch,
} from '@src/components/Desktop/Pages/Private/Piles/List/List.styles';
import { Map } from '@src/components/Containers/Map';
import { DefaultMarkerHeight } from '@src/global/Map';
import React, { FC, useContext, useState } from 'react';
import { OrderNode, PileNode, RouteNodeInput } from '@src/generated/schema';
import { ModalDataState } from '@src/components/Desktop/Pages/Private/Piles/List/List';
import { Modal } from '@src/components/Elements/Modal';
import { useTranslation } from 'react-i18next';
import { InventoryForm } from '@src/components/Containers/InventoryForm';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { IonIcon, isPlatform } from '@ionic/react';
import { analyticsOutline, saveOutline, closeCircleOutline } from 'ionicons/icons';
import { MapControl, POSITION_CLASSES } from '@src/components/Containers/Map/MapControl';
import { StyledCustomControl } from '@src/components/Containers/Map/Map.styles';
import { useMutation } from 'react-apollo';
import { Order } from '@src/graphql/Order';

export interface PilesMapProps {
  markers: ModalDataState;
  onSearch: (piles: PileNode[]) => void;
  order: OrderNode | undefined;
  piles: PileNode[];
  route: RouteNodeInput[];
}

export enum InventoryModalEnum {
  INVENTORY_CREATE = 'createInventory',
}

export const PilesMap: FC<PilesMapProps> = ({ piles, order, route, onSearch, markers }) => {
  const { t } = useTranslation();
  const { userHasPermissions, me } = useContext(MeContext);
  const [showModal, setShowModal] = useState<string>('');
  const [selectedPile, setSelectedPile] = useState<PileNode | undefined>(undefined);
  const [showRoute, setShowRoute] = useState<boolean>(false);
  const [routeMarkers, setRouteMarkers] = useState<RouteNodeInput[]>(route);

  const [saveRouteData] = useMutation(Order.saveRoute);

  const GRAPHHOPPER_API_KEY = me?.client?.graphhopperApiKey || '';

  const { setToast, setIonToast } = useContext(ToastContext);

  /**
   * Fired when the inventory modal is closed. Cleans up the state.
   */
  const onDismissInventoryModal = () => {
    setShowModal('');
    setSelectedPile(undefined);
  };

  /**
   * Check if the user is allowed to create an inventory.
   */
  const userCanCreateInventory = () =>
    userHasPermissions([Permissions.CAN_ADD_INVENTORY, Permissions.CAN_ADD_INVENTORY_DETAILS]);

  /**
   * Check if the user is allowed to create or change routes.
   */
  const userCanEditRoutes = () => userHasPermissions([Permissions.CAN_CHANGE_ROUTE, Permissions.CAN_ADD_ROUTE]);

  /**
   * Whenever a marker is clicked, check if the user can create an inventory and the conditions are correct. If yes,
   * open the modal to create an inventory.
   */
  const onMarkerClick = (marker: PileNode) => {
    if (!marker.plot?.done && !order?.collectedFromOtherSystem && userCanCreateInventory()) {
      setSelectedPile(marker);
      setShowModal(InventoryModalEnum.INVENTORY_CREATE);
    }
  };

  const toggleShowRoute = e => {
    e.preventDefault();
    if (GRAPHHOPPER_API_KEY) {
      setShowRoute(!showRoute);
    } else {
      isPlatform('hybrid')
        ? setIonToast({
            show: true,
            message: t('mobile.pages.orders.detail.map.toast.error.noGraphhopperApiKey.description'),
          })
        : setToast({
            type: TOAST_TYPES.ERROR,
            message: t('mobile.pages.orders.detail.map.toast.error.noGraphhopperApiKey.message'),
            description: t('mobile.pages.orders.detail.map.toast.error.noGraphhopperApiKey.description'),
          });
    }
  };

  const deleteLastRouteCoordinate = e => {
    e.preventDefault();
    const markersArray = [...routeMarkers];
    markersArray.pop();
    setRouteMarkers(markersArray);
  };

  const saveRoute = e => {
    e.preventDefault();
    if (order) {
      saveRouteData({ variables: { orderId: order?.id, routes: routeMarkers } });
    }
  };

  const addRouteMarker = (latlng: { lat: number; lng: number }) => {
    setRouteMarkers([...routeMarkers, { index: routeMarkers.length, latitude: latlng.lat, longitude: latlng.lng }]);
  };

  return (
    <>
      <Modal
        level={2}
        title={t('desktop.pages.orders.show.inventoryCreate.title')}
        visible={showModal === InventoryModalEnum.INVENTORY_CREATE}
        onCancel={onDismissInventoryModal}
        tabComponents={[
          {
            tabName: t('desktop.pages.orders.show.inventoryCreate.title'),
            component: (
              <InventoryForm
                alertModalLevel={3}
                order={order || undefined}
                pile={selectedPile}
                createEnabled={userCanCreateInventory()}
                onSubmit={onDismissInventoryModal}
              />
            ),
          },
        ]}
      />

      <StyledCustomMapControls className='leaflet-control-custom leaflet-bar leaflet-control'>
        <StyledPilesSearch piles={piles} onSearch={onSearch} />
      </StyledCustomMapControls>
      <MapControl position={POSITION_CLASSES.BOTTOM_LEFT}>
        {showRoute && userCanEditRoutes() && (
          <>
            <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
              <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={deleteLastRouteCoordinate}>
                <IonIcon slot='icon-only' icon={closeCircleOutline} color={'primary'} />
              </a>
            </StyledCustomControl>
            <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
              <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={saveRoute}>
                <IonIcon slot='icon-only' icon={saveOutline} color={'primary'} />
              </a>
            </StyledCustomControl>
          </>
        )}
        {
          <StyledCustomControl className='leaflet-control-custom leaflet-bar leaflet-control'>
            <a href='/' className='leaflet-bar-part leaflet-bar-part-single' onClick={toggleShowRoute}>
              <IonIcon slot='icon-only' icon={analyticsOutline} color={'primary'} />
            </a>
          </StyledCustomControl>
        }
      </MapControl>

      <Map
        markers={markers}
        markerHeight={DefaultMarkerHeight}
        onMarkerClick={onMarkerClick}
        showRoute={showRoute}
        toggleShowRoute={setShowRoute}
        addRouteMarker={addRouteMarker}
        routeMarkers={routeMarkers}
        orderId={order?.id}
      />
    </>
  );
};
