import React, { FC, useContext } from 'react';
import { Wrapper } from '@src/components/Elements';
import { Form } from '../Form';
import { TestIds } from '@src/global';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { FormValues } from '@src/components/Desktop/Pages/Private/DeliveryStatus/Edit/Form/Form';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';

export interface AddProps {
  closeModal: () => void;
}

export const Add: FC<AddProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [createDeliveryStatus, { error }] = useMutation(DeliveryStatus.createDeliveryStatus);

  const onSubmit = async (variables: FormValues) => {
    await createDeliveryStatus({
      variables,
      refetchQueries: ['deliveryStatuses'],
    })
      .then(() =>
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.deliveryStatus.add.success.message'),
          description: t('desktop.pages.deliveryStatus.add.success.description'),
        }),
      )
      .finally(closeModal);
  };

  return (
    <div data-testid={TestIds.pages.orders.add.identifier}>
      <Wrapper>
        <Form onSubmit={onSubmit} error={error} />
      </Wrapper>
    </div>
  );
};
