import styled from 'styled-components';
import { Button } from 'antd';

import { Page } from '@src/components/Mobile/Sections/Page';
import { StyledIonContent } from '@src/components/Mobile/Sections/Page/Page.styles';
import { PilesSearch } from '@src/components/Elements/PilesSearch';

export const StyledPage = styled(Page)`
  ${StyledIonContent} {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --overflow: hidden;
  }
`;

export const StyledH4 = styled.h4`
  margin: 1em;
`;

export const StyledCustomMapControls = styled.div`
  &.landscape-primary { /** Top (and a possible display notch) of the device is left **/
    width: calc(100% - 20px - 10px - 34px - env(safe-area-inset-right) - env(safe-area-inset-left));
    left: calc(10px + 34px + env(safe-area-inset-left)); /** Map control has a 10px margin and a 34px width. */
  }

  &.landscape-secondary	{ /** Top (and a possible display notch) of the device is left **/
    width: calc(100% - 20px - 10px - 34px - env(safe-area-inset-right));
  }

  width: calc(100% - 20px - 10px - 34px);
  margin: 10px;
  top: 0;
  left: calc(10px + 34px); /** Map control has a 10px margin and a 34px width. */
  position: absolute;
  display: flex;
  z-index: 401; /** Leaflet-Pane Z-Index + 1 */
`;

export const StyledPilesSearch = styled(PilesSearch)`

`;

export const StyledButton = styled(Button)`
  height: auto;
  margin-left: 10px;
`;
