import React, { FC } from 'react';
import { IonPage } from '@ionic/react';
import { FullHeightPageWrapper } from '@src/components/Mobile/Sections/Page/Page.styles';
import { PageContent } from '@src/components/Mobile/Sections/Page/PageContent';

interface PageProps {
  className?: string;
  inTab?: boolean;
  renderContentHeader?: () => JSX.Element;
  renderFooter?: JSX.Element;
}

export const Page: FC<PageProps> = ({ children, renderContentHeader, renderFooter, className, inTab }) =>
  inTab ? (
    <FullHeightPageWrapper className={className}>
      <PageContent renderContentHeader={renderContentHeader} renderFooter={renderFooter}>
        {children}
      </PageContent>
    </FullHeightPageWrapper>
  ) : (
    <IonPage className={className}>
      <PageContent renderContentHeader={renderContentHeader} renderFooter={renderFooter}>
        {children}
      </PageContent>
    </IonPage>
  );
