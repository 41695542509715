import gql from 'graphql-tag';
import {
  PileNodeFragment,
  PileWoodtypeNodeFragment,
  PileStatusNodeFragment,
  PileDistrictNodeFragment,
  PileSortNodeFragment,
  PileTypeNodeFragment,
  PileCategoryNodeFragment,
  PileLocationNodeFragment,
  DriverPileNoLimitNodeFragment,
} from './Fragments';
import { InventoryNodeFragment } from '@src/graphql/Inventory/Fragments';
import { PlotNodeFragment } from '@src/graphql/Plot/Fragments';

export const piles = gql`
  query piles {
    piles {
      edges {
        node {
          ...pileNode
          plot {
            ...plotNode
          }
        }
      }
    }
  }
  ${PileNodeFragment}
  ${PlotNodeFragment}
`;

export const getDetailPile = gql`
  query($pileId: ID) {
    pile(pileId: $pileId) {
      ...pileNode
      inventories {
        ...inventoryNode
      }
      plot {
        id
        number
      }
    }
  }
  ${PileNodeFragment}
  ${InventoryNodeFragment}
`;

export const getPilesNoLimit = gql`
  query pilesNoLimit(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $client_Name_Icontains: String
    $plotId: String
    $plot_Number: Int
    $number_Contains: String
    $number: String
    $woodtype: [String]
    $plot_Number_Contains: Int
    $sort: [String]
    $status: [String]
    $includeBroached: Boolean
    $location: [String]
    $logLength: [String]
    $logLength_Gte: Float
    $logLength_Lte: Float
    $plot_Company_Name: [String]
    $orderBy: String
    $district: [String]
    $district_Isnull: Boolean
    $category: [String]
    $category_Isnull: Boolean
    $type: [String]
    $timber: [String]
  ) {
    pilesNoLimit(
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: $orderBy
      timber: $timber
      client_Name_Icontains: $client_Name_Icontains
      plot_Number: $plot_Number
      plot_Number_Contains: $plot_Number_Contains
      plotId: $plotId
      number: $number
      logLength: $logLength
      number_Contains: $number_Contains
      woodtype: $woodtype
      sort: $sort
      status: $status
      includeBroached: $includeBroached
      location: $location
      logLength_Gte: $logLength_Gte
      logLength_Lte: $logLength_Lte
      plot_Company_Name: $plot_Company_Name
      district: $district
      district_Isnull: $district_Isnull
      category: $category
      category_Isnull: $category_Isnull
      type: $type
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...driverPileNoLimitNode
        }
      }
    }
  }
  ${DriverPileNoLimitNodeFragment}
`;

export const getPaginatedPiles = gql`
  query piles(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $plotId: String
    $plot_Number: Int
    $number_Contains: String
    $number: String
    $woodtype: [String]
    $plot_Number_Contains: Int
    $sort: [String]
    $status: [String]
    $includeBroached: Boolean
    $location: [String]
    $logLength: [String]
    $logLength_Gte: Float
    $logLength_Lte: Float
    $orderBy: String
    $district: [String]
    $district_Isnull: Boolean
    $category: [String]
    $category_Isnull: Boolean
    $type: [String]
    $plot_Company_Name: [String]
    $isBroached: Boolean
  ) {
    piles(
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: $orderBy
      logLength: $logLength
      plot_Number: $plot_Number
      plot_Number_Contains: $plot_Number_Contains
      plotId: $plotId
      number: $number
      number_Contains: $number_Contains
      woodtype: $woodtype
      sort: $sort
      status: $status
      includeBroached: $includeBroached
      isBroached: $isBroached
      location: $location
      logLength_Gte: $logLength_Gte
      logLength_Lte: $logLength_Lte
      plot_Company_Name: $plot_Company_Name
      district: $district
      district_Isnull: $district_Isnull
      category: $category
      category_Isnull: $category_Isnull
      type: $type
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...pileNode
          plot {
            ...plotNode
          }
        }
      }
    }
  }
  ${PileNodeFragment}
  ${PlotNodeFragment}
`;

export const logLengths = gql`
  query logLengths($page: Int, $pageSize: Int, $filterValue: String) {
    logLengths(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const suppliers = gql`
  query supplier($page: Int, $pageSize: Int, $filterValue: String) {
    supplier(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const qualityTypes = gql`
  query qualityTypes($page: Int, $pageSize: Int, $filterValue: String) {
    qualityTypes(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const timbers = gql`
  query timbers($page: Int, $pageSize: Int, $filterValue: String) {
    timbers(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const clients = gql`
  query pileClients($page: Int, $pageSize: Int, $filterValue: String) {
    pileClients(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const usageSorts = gql`
  query usageSorts($page: Int, $pageSize: Int, $filterValue: String) {
    usageSorts(page: $page, pageSize: $pageSize, filterValue: $filterValue) {
      page
      pages
      hasNext
      hasPrevious
      strings
    }
  }
`;

export const getPileWoodtypes = gql`
  query pileWoodtypes($first: Int, $after: String, $woodtype_Icontains: String) {
    woodtypes(first: $first, after: $after, woodtype_Icontains: $woodtype_Icontains) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...pileWoodtypeNode
        }
      }
    }
  }
  ${PileWoodtypeNodeFragment}
`;

export const getPileStatus = gql`
  query {
    pileStatus {
      edges {
        node {
          ...pileStatusNode
        }
      }
    }
  }
  ${PileStatusNodeFragment}
`;

export const getPileLocations = gql`
  query pileLocations($first: Int, $after: String, $searchString: String) {
    pileLocations(first: $first, after: $after, searchString: $searchString) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...pileLocationNode
        }
      }
    }
  }
  ${PileLocationNodeFragment}
`;

export const getPileSorts = gql`
  query pileSorts($first: Int, $after: String, $sort_Icontains: String) {
    pileSorts(first: $first, after: $after, sort_Icontains: $sort_Icontains) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...pileSortNode
        }
      }
    }
  }
  ${PileSortNodeFragment}
`;

export const getPileTypes = gql`
  query {
    pileTypes {
      edges {
        node {
          ...pileTypeNode
        }
      }
    }
  }
  ${PileTypeNodeFragment}
`;

export const getPileDistricts = gql`
  query pileDistricts($first: Int, $after: String, $district_Icontains: String) {
    pileDistricts(first: $first, after: $after, district_Icontains: $district_Icontains) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...pileDistrictNode
        }
      }
    }
  }
  ${PileDistrictNodeFragment}
`;

export const getPileCategories = gql`
  query pileCategories($first: Int, $after: String, $category_Icontains: String) {
    pileCategories(first: $first, after: $after, category_Icontains: $category_Icontains) {
      pageInfo {
        endCursor
      }
      edges {
        node {
          ...pileCategoryNode
        }
      }
    }
  }
  ${PileCategoryNodeFragment}
`;
