import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { Content as UserDropdownContent } from './Content';
import { StyledButton, StyledChevronIcon, StyledUserIcon } from './UserDropdown.styles';
import { arrowDown, personCircle } from 'ionicons/icons';

export const UserDropdown: FC = () => (
  <Dropdown overlay={<UserDropdownContent />} trigger={['click']}>
    <StyledButton>
      <StyledUserIcon className='icon' slot='start' icon={personCircle} />
      <StyledChevronIcon className='icon' icon={arrowDown} mode='ios' color='black' />
    </StyledButton>
  </Dropdown>
);
