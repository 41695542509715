import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { IonCard, IonImg, IonItem, IonPopover, IonText, isPlatform } from '@ionic/react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { PlusOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';

import { FilesystemRoutes } from '@src/global';
import { FileContext, OfflineHandlerContext } from '@src/components/Mobile/Providers';
import { ToastContext } from '@src/components/Providers';

import { StyledIonItemGroup, StyledUpload } from './FileUpload.styles';

interface FileUploadProps {
  fileName: string;
  folderName: FilesystemRoutes;
}

export const FileUpload: FC<FileUploadProps> = ({ folderName, fileName }) => {
  const { t } = useTranslation();
  const { saveFile, getFileAsBase64 } = useContext(FileContext);
  const { setIonToast } = useContext(ToastContext);
  const { addJob } = useContext(OfflineHandlerContext);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const galleryInputRef = useRef<any>(null);

  /**
   * Side Effect:
   * Look if file for fileName is already existing and show it to the user
   */
  useEffect(() => {
    async function checkFile() {
      try {
        const imageBase64 = await getFileAsBase64(folderName, fileName);

        setImageUrl(imageBase64);
      } catch (error) {
        // This just means file does not exist yet - which is okay
      }
    }

    checkFile();
  }, [getFileAsBase64, setImageUrl, fileName, folderName]);

  /**
   * Change Handler
   * Handle change of file: save to filesystem and add job to offlineHandler queue
   */
  const handleFileChange = async ({ file }) => {
    setShowPopover(false);
    const mimeType = file.name.split('.').pop();
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });

    try {
      const result = await saveFile(compressedFile, folderName, `${fileName}.${mimeType}`);

      addJob({
        fileName: `${fileName}.${mimeType}`,
        file: file,
      });

      setImageUrl(result);
    } catch (error) {
      setIonToast({
        show: true,
        message: t('containers.fileUpload.fileSystemError'),
      });
    }
  };

  const takePicture = async () => {
    setShowPopover(false);
    const image = await Camera.getPhoto({
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    if (image.dataUrl) {
      try {
        // Transform image to dataUrl and compress it
        const compressedFile = await imageCompression(
          await imageCompression.getFilefromDataUrl(image.dataUrl, `${fileName}.jpeg`),
          {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: 'image/jpeg',
          },
        );

        // Add job to upload the compressed file as jpeg
        addJob({
          fileName: `${fileName}.jpeg`,
          file: compressedFile,
        });

        // Set image url to the uncompressed dataUrl
        setImageUrl(image.dataUrl);
      } catch (error) {
        setIonToast({
          show: true,
          message: t('containers.fileUpload.fileSystemError'),
        });
      }
    }
  };

  const handleCameraFileChange = (event: any) => {
    const file = event.target?.files[0];

    if (file) {
      handleFileChange({ file });
    }

    // TODO: in the future we need to enable the app to sleep again in ios, right now it is not possible to do something
    //   after a picture was taken or the process is cancelled. In the future we will change this component most likely.
    //   For now we will keep it as it is.
  };

  const openGallery = () => {
    galleryInputRef?.current?.click();
  };

  const handleUploadClick = () => {
    if (isPlatform('ios')) {
      // ios handles switching camera and gallery natively
      // so we open the gallery directly
      openGallery();
    } else {
      // for android we show options between camera and gallery
      setShowPopover(true);
    }
  };

  return (
    <>
      <input type='file' accept='image/*' onChange={handleCameraFileChange} ref={galleryInputRef} hidden />

      <IonPopover isOpen={showPopover} onDidDismiss={(e) => setShowPopover(false)}>
        <StyledIonItemGroup>
          <IonItem onClick={openGallery}>
            <IonText>{t('containers.fileUpload.chose')}</IonText>
          </IonItem>

          <IonItem onClick={takePicture}>
            <IonText>{t('containers.fileUpload.capture')}</IonText>
          </IonItem>
        </StyledIonItemGroup>
      </IonPopover>

      <IonCard>
        <StyledUpload onClick={handleUploadClick}>
          {imageUrl ? (
            <IonImg src={imageUrl} />
          ) : (
            <>
              <PlusOutlined />

              <div className='ant-upload-text'>{t('mobile.pages.orders.detail.uploadCustomer')}</div>
            </>
          )}
        </StyledUpload>
      </IonCard>
    </>
  );
};
