import styled from 'styled-components';
import { IonContent } from '@ionic/react';

export const StyledIonContent = styled(IonContent)`
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
`;

export const FullHeightPageWrapper = styled.div`
  height: 100%;
`;

export const StyledIonContentHeader = styled.div`
  padding-left: var(--ion-padding, 30px);
  padding-right: var(--ion-padding, 30px);
  padding-top: var(--ion-padding, 30px);
`;
