import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverRow.styles';
import { closePopups } from '@src/helpers/DomManipulations';
import { ModalEnum } from '../List';
import { PlotNode } from '@src/generated/schema';
import { MeContext, Permissions } from '@src/components/Providers';

export interface PopoverRowProps {
  plot: PlotNode;
  toggleModal: (modalType: ModalEnum, user: PlotNode) => void;
}

export const PopoverRow: FC<PopoverRowProps> = ({ toggleModal, plot }) => {
  const { t } = useTranslation();
  const { userHasPermission, me } = useContext(MeContext);

  return (
    <StyledPopover>
      <StyledPopoverOption
        onClick={() => {
          closePopups();
          toggleModal(ModalEnum.SHOW, plot);
        }}
      >
        {t('desktop.pages.accounts.list.popover.show')}
      </StyledPopoverOption>
      {userHasPermission(Permissions.CAN_CHANGE_PLOT) && me?.client?.canChangePlotPile && (
        <StyledPopoverOption
          onClick={() => {
            closePopups();
            toggleModal(ModalEnum.CHANGE, plot);
          }}
        >
          {t('desktop.pages.accounts.list.popover.edit')}
        </StyledPopoverOption>
      )}
    </StyledPopover>
  );
};
