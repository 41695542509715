import { createDeliveryStatus, changeDeliveryStatus } from '@src/graphql/DeliveryStatus/Mutations';
import {
  getDeliveryStatuses,
  getDeliveryStatusDetail,
  getDeliveryStatusUnitSelectOptions,
  getDeliveryStatusTypeSelectOptions,
} from '@src/graphql/DeliveryStatus/Queries';

export const DeliveryStatus = {
  createDeliveryStatus,
  changeDeliveryStatus,
  getDeliveryStatuses,
  getDeliveryStatusDetail,
  getDeliveryStatusUnitSelectOptions,
  getDeliveryStatusTypeSelectOptions,
};
