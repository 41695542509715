import React, { FC } from 'react';
import { StyledBackdrop, StyledIconWrapper } from './Overlay.styles';
import { LoadingOutlined } from '@ant-design/icons';

export const Overlay: FC = () => (
  <StyledBackdrop>
    <StyledIconWrapper>
      <LoadingOutlined spin />
    </StyledIconWrapper>
  </StyledBackdrop>
);
