import React, { FC } from 'react';
import { FormItem, FormItemProps, Select, SelectProps } from 'formik-antd';
import { useTranslation } from 'react-i18next';

export interface SearchSelectProps extends Omit<SelectProps, 'options'>, Pick<FormItemProps, 'label'> {
  options?: Array<{ title: string; value: string }>;
  required?: boolean;
}

export const SearchSelect: FC<SearchSelectProps> = ({ options, label, name, required, placeholder, ...props }) => {
  const { t } = useTranslation();

  return (
    <FormItem labelCol={{ span: 24 }} style={{ width: '100%' }} label={label} name={name} required={required}>
      <Select
        {...props}
        allowClear
        placeholder={placeholder || t('forms.validation.info.noValueSet')}
        name={name}
        showSearch
        optionFilterProp='children'
      >
        {options &&
          options.length > 0 &&
          options.map(({ value, title }, index) => (
            <Select.Option key={`${name}_${index}`} value={value}>
              {title}
            </Select.Option>
          ))}
      </Select>
    </FormItem>
  );
};
