import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { IonCard, IonCardContent, IonButton, IonAlert } from '@ionic/react';

import { OfflineMapTilesHandlerContext } from '@src/components/Mobile/Providers';
import { Page } from '@src/components/Mobile/Sections/Page';

import { StyledIonProgressBar } from './OfflineMaps.styles';

export const OfflineMaps: FC = () => {
  const { t } = useTranslation();
  const { activeDownloadId, downloads, deleteDownloads } = useContext(OfflineMapTilesHandlerContext);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleDeleteClick = () => {
    setShowAlert(true);
  };

  const renderInProgress = ({ id, name, tilesLoaded, tilesToLoad, active }) => (
    <IonCard key={id}>
      <IonCardContent>
        <h2>{name}</h2>
        <StyledIonProgressBar value={tilesLoaded / tilesToLoad} color={active ? 'success' : 'warning'} />
        <p>{active ? t('mobile.pages.offlineMaps.downloading') : t('mobile.pages.offlineMaps.downloadCancelled')}</p>
      </IonCardContent>
    </IonCard>
  );

  const renderFinished = ({ id, name, date }) => (
    <IonCard key={id}>
      <IonCardContent>
        <h2>{name}</h2>
        <p>
          {t('mobile.pages.offlineMaps.downloadedAt')} <Moment format='DD.MM.YYYY'>{date}</Moment>
        </p>
      </IonCardContent>
    </IonCard>
  );

  return (
    <Page>
      <h2>{t('mobile.navigation.offlineMaps')}</h2>
      {downloads.map(mapDownload =>
        mapDownload.tilesLoaded === mapDownload.tilesToLoad
          ? renderFinished(mapDownload)
          : renderInProgress({ ...mapDownload, active: activeDownloadId === mapDownload.id }),
      )}
      {!!downloads.length ? (
        <IonButton expand='block' onClick={handleDeleteClick}>
          {t('mobile.pages.offlineMaps.deleteDownloads')}
        </IonButton>
      ) : (
        <p>{t('mobile.pages.offlineMaps.noDownloads')}</p>
      )}
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('mobile.pages.offlineMaps.deleteDownloads')}
        subHeader={t('mobile.pages.offlineMaps.alert.description')}
        buttons={[
          {
            text: t('general.cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('general.confirm'),
            handler: deleteDownloads,
          },
        ]}
      />
    </Page>
  );
};
