import React, { FC } from 'react';
import { TimeSheetNode } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { StyledPopover, StyledPopoverOption } from './PopoverRow.styles';
import { closePopups } from '@src/helpers/DomManipulations';
import { TimeSheetModalEnum } from '../Show';
import { ApolloError } from 'apollo-client';
import { APIError } from '@src/components/Elements/Wrapper/Form';
export interface PopoverRowProps {
  error?: ApolloError;
  handleDeleteTimeSheet: (timesheet: string) => Promise<void>;
  timesheet: TimeSheetNode;
  toggleModal: (modalType: TimeSheetModalEnum, timeSheet: TimeSheetNode | undefined) => void;
}

export const PopoverRow: FC<PopoverRowProps> = ({ toggleModal, handleDeleteTimeSheet, timesheet, error }) => {
  const { t } = useTranslation();

  const handleDeleteTimeSheetFunction = () => {
    handleDeleteTimeSheet(timesheet?.id);
    closePopups();
  };

  return (
    <StyledPopover>
      <StyledPopoverOption
        onClick={() => {
          toggleModal(TimeSheetModalEnum.TIME_SHEET_EDIT, timesheet);
          closePopups();
        }}
      >
        {t('desktop.pages.accounts.list.popover.edit')}
      </StyledPopoverOption>
      <StyledPopoverOption onClick={handleDeleteTimeSheetFunction}>
        {t('desktop.pages.accounts.list.popover.delete')}
      </StyledPopoverOption>
      {error && <APIError errors={error} />}
    </StyledPopover>
  );
};
